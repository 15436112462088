import React, { useEffect, useState } from "react";

import InputForm from "./InputForm";
import { formNum } from "../../providers/reducer";
import { useStateValue } from "../../providers/StateProvider";
import { IconRemove } from "../../assets/icons/icons";
import IconButton from "@mui/material/IconButton";
import { formatPrice } from "../../shared/utils/helpers/format";

const InputEditInstallment = ({
  installment,
  installmentsValues,
  setInstallmentsValues,
}) => {
  const [installmentValue, setInstallmentValue] = useState(null);
  const [{ activeMoeda }] = useStateValue();

  useEffect(() => {
    setInstallmentValue(installment?.value);
  }, [installment?.value]);

  const deleteInstallment = () => {
    const installmentValuesChange = installmentsValues.filter((value) => {
      return value?.index !== installment?.index;
    });
    setInstallmentsValues(installmentValuesChange);
  };

  return (
    <article className="myne-modal__modal-section-edit-value-installment__input myne-modal--animation">
      <InputForm
        value={formatPrice(installmentValue, activeMoeda)}
        label={`Parcela ${formNum(installment?.index)}`}
        onChange={(e) => {
          const changeValue =
            parseFloat(e.target.value.replace(/[^\d.-]/g, "")) / 100;
          setInstallmentValue(formatPrice(changeValue, activeMoeda));

          if (installmentValue !== null) {
            const installmentValues = installmentsValues.map((p) =>
              p?.index === installment?.index
                ? {
                    ...p,
                    value: changeValue,
                  }
                : p
            );

            setInstallmentsValues(installmentValues);
          }
        }}
      />
      {installment?.index > 1 && (
        <IconButton
          onClick={() => deleteInstallment()}
          className="myne-icon-button"
          aria-label="delete"
        >
          <IconRemove />
        </IconButton>
      )}
    </article>
  );
};

export default InputEditInstallment;
