import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export const gerateId = () => Math.random().toString(16).slice(2);

export const handleDownloadImage = async (
  recibo,
  activeLanguage,
  printRef,
  setModalDownload
) => {
  const element = printRef.current;
  const canvas = await html2canvas(element);

  const data = canvas.toDataURL("image/png");
  const link = document.createElement("a");

  if (typeof link.download === "string") {
    link.href = data;
    link.download = `${activeLanguage?.sales?.list_sales?.item_sale?.sale_details?.title_receipt}${recibo}.png`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setModalDownload(false);
  } else {
    window.open(data);
  }
};

export const handleDownloadPdf = async (
  recibo,
  activeLanguage,
  printRef,
  setModalDownload,
  companie
) => {
  const element = printRef.current;
  const canvas = await html2canvas(element);
  const data = canvas.toDataURL("image/png");
  let pdf = new jsPDF();
  if (companie) {
    pdf = new jsPDF("p", "mm", [297, 180]);
  }
  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
  pdf.save(
    `${activeLanguage?.sales?.list_sales?.item_sale?.sale_details?.title_receipt}${recibo}.pdf`
  );
  setModalDownload(false);
};
