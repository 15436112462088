import { auth, db } from "../services/Firebase";
import {
  getProductsDocs,
  InstallmentsService,
  ProductSaleService,
  ServiceSaleService,
} from "./reducer";

let dbRef = db.collection("users").doc(auth?.currentUser?.uid);

const addDB = async (batch, collectionName, objectsToAdd, id, erro, modo) => {
  const ref = db
    .collection("users")
    .doc(auth?.currentUser?.uid)
    .collection(collectionName);
  // const batch = db.batch();
  // if (modo === 1) {
  //   if (id) {
  //     return await batch.set(ref.doc(id), objectsToAdd);
  //   } else {
  //     return await batch.set(ref.doc(), objectsToAdd);
  //   }
  // } else {
  if (id) {
    await ref.doc(id).set(objectsToAdd).catch(erro);
  } else {
    await ref.doc().set(objectsToAdd).catch(erro);
  }
  // }
  // return await batch.commit().catch(erro);
};
const removeDB = async (collectionName, id, erro) => {
  const ref = db
    .collection("users")
    .doc(auth?.currentUser?.uid)
    .collection(collectionName);

  return await ref.doc(id).delete().catch(erro);
};

const ServiceSalesFirestore = {
  collectionReference: dbRef.collection("serviceSales"),
  add: async (batch, serviceSales, erro, modo) => {
    await serviceSales.map(async (item) => {
      await addDB(batch, "serviceSales", item, null, erro, modo ? modo : 1);
    });
  },
  remove: async (serviceSales, erro) => {
    await serviceSales?.map(async (item) => {
      await removeDB("serviceSales", item.id, erro);
    });
  },
};

const InstallmentsFirestore = {
  collectionReference: dbRef.collection("installments"),
  add: async (batch, installments, erro, modo) => {
    await installments.map(async (item) => {
      await addDB(batch, "installments", item, null, erro, modo ? modo : 1);
    });
  },
  remove: async (installments, erro) => {
    await installments?.map(async (item) => {
      await removeDB("installments", item.id, erro);
    });
  },
};

const operation = (stockOperation, product) => {
  let stock = 0;
  if (stockOperation.add) {
    // add stock in product
    stock = product?.product?.stock + stockOperation.value;
  } else if (stockOperation.remove) {
    // remove stock in product
    stock = product?.product?.stock - stockOperation.value;
  }
  return stock;
};

const ProductSalesFirestore = {
  collectionReference: dbRef.collection("productSales"),
  add: async (batch, productSales, erro, modo) => {
    await productSales?.map(async (item) => {
      let stockOperation = { add: false, remove: true, value: item.amount };
      let stocknew = operation(stockOperation, item);
      let newproduct = {
        ...item,
        product: { ...item.product, stock: stocknew },
      };
      await addDB(
        batch,
        "productSales",
        newproduct,
        null,
        erro,
        modo ? modo : 1
      );
      await updateStock(stockOperation, item);
    });
  },
  remove: async (productSales, erro) => {
    await productSales?.map(async (item) => {
      let stockOperation = {
        add: true,
        remove: false,
        value: item.data().amount,
      };

      await removeDB("productSales", item.id, erro);
      await updateStock(stockOperation, item.data());
    });
  },
  listProductsByRef: async (ref) => {
    let products = await getProductsDocs();

    return products?.filter((doc) => {
      return ref?.id === doc?.id;
    });
  },
};

const addProduct = async (product) => {
  let productSalesbyProduct = await ProductSalesFirestore.listProductsByRef(
    product.productRef
  );
  // console.log(
  //   product.product,
  //   productSalesbyProduct,
  //   productSalesbyProduct[0].id
  // );
  db.collection("users")
    .doc(auth?.currentUser?.uid)
    .collection("products")
    .doc(productSalesbyProduct[0]?.id)
    .set(product.product);
};

export const updateStock = async (stockOperation, product) => {
  let stocknew = operation(stockOperation, product);

  let productnew = {
    ...product,
    product: { ...product.product, stock: stocknew },
  };
  
  await addProduct(productnew);
};

// const ifPaid = (item) => {
//   let filter = item?.installments?.filter((parcela) => {
//     return parcela?.status === 1;
//   });
//   return filter?.length >= item?.installments?.length;
// };

export const saveSale = async (e, saleRef, item, id, then, erro, history) => {
  // ADD SALE
  e.preventDefault();
  // setLoading(true);
  // const batch = db.batch();

  // transform date in firebase timestamp
  // const date = firebase.firestore.Timestamp.fromDate(new Date(item.date));
  // item.date = date;

  // productSales add
  // const now = firebase.firestore.Timestamp.fromDate(new Date());
  // let productSales = [];
  // item.productSales.map((productSale) => {
  //   const product = productSale?.data;
  //   const productRef = db.doc(
  //     `users/${auth?.currentUser?.uid}/products/${productSale?.id}`
  //   );
  //   productSale.total = product?.salePrice * productSale?.amount;
  //   productSale.gain =
  //     productSale?.total - productSale?.amount * product?.costPrice;

  //   productSales.push({
  //     amount: productSale?.amount,
  //     client: item.client,
  //     clientRef: item.clientRef,
  //     discountRef: item.discountRef,
  //     gain: productSale?.gain,
  //     product: { ...product, stock: product.stock },
  //     productRef,
  //     saleDate: item?.date,
  //     saleRef,
  //     total: productSale?.total,
  //     updatedAt: now,
  //   });
  // });

  // item.productSales = productSales;
  await history.push("/loading");

  addDB(null, "sales", item, id, erro, 1);

  await ProductSalesFirestore.add(null, item.productSales, erro);

  // serviceSales add
  // let serviceSales = [];
  // item.serviceSales.map((serviceSale) => {
  //   const service = serviceSale?.data;
  //   const serviceRef = db.doc(
  //     `users/${auth?.currentUser?.uid}/services/${serviceSale?.id}`
  //   );
  //   serviceSale.total = service?.salePrice;
  //   serviceSale.gain = service?.salePrice - service?.costPrice;

  //   serviceSales.push({
  //     client: item.client,
  //     clientRef: item.clientRef,
  //     discountRef: item.discountRef,
  //     gain: serviceSale?.gain,
  //     saleDate: item?.date,
  //     saleRef,
  //     service: service,
  //     serviceRef,
  //     total: serviceSale?.total,
  //     updatedAt: now,
  //   });
  // });

  // item.serviceSales = serviceSales;
  await ServiceSalesFirestore.add(null, item.serviceSales, erro);

  // installments saleRef
  // let installments = [];
  // item.installments.map((item) => {
  //   installments.push({
  //     ...item,
  //     saleRef,
  //   });
  // });
  // item.installments = installments;
  await InstallmentsFirestore.add(null, item.installments, erro);

  // total gain
  // let gain = 0;
  // let totalProductSalesGain = 0;
  // let totalServicesSalesGain = 0;
  // item.productSales?.map((select) => {
  //   let total = select?.gain;

  //   totalProductSalesGain += total;
  // });
  // item.serviceSales?.map((select) => {
  //   let total = select?.gain;

  //   totalServicesSalesGain += total;
  // });
  // gain = totalProductSalesGain + totalServicesSalesGain;
  // item.gain = gain;
  // item.paid = ifPaid(item);
  // item.status = ifPaid(item) ? 1 : 2;

  // if (isMountedRef.current) {
  then();
  // }

  // return await batch.commit().catch(erro);
  // batch.commit().catch(erro);
};

const removeInstallments = async (item, saleRef, erro) => {
  let installmentsSalesBySaleQuery =
    await InstallmentsService.installmentsBySale(saleRef, saleRef.id);
  await InstallmentsFirestore.remove(installmentsSalesBySaleQuery, erro);
};
const removeProductSales = async (item, saleRef, erro) => {
  let productSalesBySaleQuery = await ProductSaleService.productSalesBySale(
    saleRef,
    saleRef.id
  );
  await ProductSalesFirestore.remove(productSalesBySaleQuery, erro);
};
const removeServiceSales = async (item, saleRef, erro) => {
  let serviceSalesBySaleQuery = await ServiceSaleService.serviceSalesBySale(
    saleRef,
    saleRef.id
  );
  await ServiceSalesFirestore.remove(serviceSalesBySaleQuery, erro);
};

export const removeSale = async (
  e,
  history,
  setModalRemove,
  dispatch,
  data,
  then,
  erro
) => {
  e.preventDefault();
  const saleRef = db.doc(`users/${auth?.currentUser?.uid}/sales/${data.id}`);

  await history.push("/loading");

  await removeServiceSales(data.item, saleRef, erro);
  await removeProductSales(data.item, saleRef, erro);
  await removeInstallments(data.item, saleRef, erro);
  await removeDB("sales", data.id, erro);

  await then();
};

export const editSale = async (
  e,
  history,
  dispatch,
  setmodal,
  data,
  then,
  erro
) => {
  // EDIT SALE
  e.preventDefault();

  const saleRef = db.doc(`users/${auth?.currentUser?.uid}/sales/${data.id}`);

  await dispatch({
    type: "SET_MESSAGES",
    messages: {
      loadingSales: true,
    },
  });
  await history.push("/loading");

  await removeDB("sales", data.id, erro);
  await addDB(null, "sales", data.item, data.id, erro, 2);
  await removeProductSales(data.item, saleRef, erro);
  await ProductSalesFirestore.add(null, data.item.productSales, erro, 2);
  await removeInstallments(data.item, saleRef, erro);
  await InstallmentsFirestore.add(null, data.item.installments, erro, 2);
  await removeServiceSales(data.item, saleRef, erro);
  await ServiceSalesFirestore.add(null, data.item.serviceSales, erro, 2);

  then();
};

export const updateInstallments = async (installments, data, then, erro) => {
  const saleRef = db.doc(`users/${auth?.currentUser?.uid}/sales/${data.id}`);

  const ifPaid = (installments) => {
    let filter = installments?.filter((parcela) => {
      return parcela?.status === 1;
    });
    return filter?.length >= installments?.length;
  };

  await db
    .collection("users")
    .doc(auth?.currentUser?.uid)
    .collection("sales")
    .doc(data.id)
    .update({
      installments,
      paid: ifPaid(installments),
      status: ifPaid(installments) ? 1 : 2,
    })
    .catch(erro);
  await removeInstallments(data.item, saleRef, erro);
  await InstallmentsFirestore.add(null, installments, erro, 2);

  then();
};
