import { getDataUser } from "../../../../providers/reducer";

export const checkIfUserPlus = async () => {
  const user = await getDataUser();

  return (
    user?.subscriptionStatus === 0 ||
    user?.expirationDate?.toDate() > new Date()
  );
};
