import { toLowerStr } from "../../../providers/reducer";

export const getProductFilters = (activeLanguage) => [
  {
    index: 1,
    name: activeLanguage?.registers?.filter?.f1,
    id: "A-Z",
    fieldFirestoreQueryOrder: {
      fieldPath: "name",
      directionStr: "asc",
    },
  },
  {
    index: 2,
    name: activeLanguage?.registers?.filter?.f2,
    id: "Z-A",
    fieldFirestoreQueryOrder: {
      fieldPath: "name",
      directionStr: "desc",
    },
  },
  {
    index: 3,
    name: activeLanguage?.registers?.filter?.f3,
    id: "menor-stock",
    fieldFirestoreQueryOrder: {
      fieldPath: "stock",
      directionStr: "asc",
    },
  },
  {
    index: 4,
    name: activeLanguage?.registers?.filter?.f4,
    id: "maior-stock",
    fieldFirestoreQueryOrder: {
      fieldPath: "stock",
      directionStr: "desc",
    },
  },
  {
    index: 5,
    name: activeLanguage?.registers?.filter?.f5,
    id: "menor-preco",
    fieldFirestoreQueryOrder: {
      fieldPath: "salePrice",
      directionStr: "asc",
    },
  },
  {
    index: 6,
    name: activeLanguage?.registers?.filter?.f6,
    id: "maior-preco",
    fieldFirestoreQueryOrder: {
      fieldPath: "salePrice",
      directionStr: "desc",
    },
  },
];

export const filterAZ = (list, key1, key2) =>
  list?.sort((x, y) => {
    let a = "",
      b = "";
    if (key2) {
      a = toLowerStr(x[key1][key2]);
      b = toLowerStr(y[key1][key2]);
    } else {
      a = toLowerStr(x[key1]);
      b = toLowerStr(y[key1]);
    }

    return a?.localeCompare(b);
  });
export const filterNumberAsc = (list, key1, key2) =>
  list?.sort((x, y) => {
    let a = "",
      b = "";
    if (key2) {
      a = toLowerStr(x[key1][key2]);
      b = toLowerStr(y[key1][key2]);
    } else {
      a = toLowerStr(x[key1]);
      b = toLowerStr(y[key1]);
    }
    
    return a - b;
  });
