import React from "react";
import { useStateValue } from "../../../providers/StateProvider";

const ModalYesNo = ({
  modal,
  setmodal,
  icon,
  title,
  addclass,
  yesNoButtons,
  attentionNo,
  yesFunction,
  noFunction,
  titleButton,
  children,
}) => {
  const [{ activeLanguage }] = useStateValue();

  return (
    <div
      style={{
        opacity: modal ? "5" : "0",
        visibility: modal ? "visible" : "hidden",
        zIndex: 999999999,
      }}
      className="myne-modal"
    >
      <div
        style={{
          transform: modal ? "scale(1)" : "scale(0.7)",
        }}
        className={
          addclass
            ? `myne-modal__modal-add-item ${addclass}`
            : "myne-modal__modal-add-item"
        }
      >
        {modal ? (
          <>
            <header className="myne-modal__modal-add-item-header myne-modal--animation">
              {icon ? icon : ""}
              <p style={{ margin: !icon ? 0 : '' }}>{title}</p>
            </header>

            <div
              className={
                "myne-modal__modal-add-item-content myne-modal--animation-left"
              }
            >
              {children}

              {yesNoButtons ? (
                <div className={attentionNo ? "attentionNo" : ""}>
                  <button
                    onClick={() => {
                      setmodal(false);
                      if (noFunction) noFunction();
                    }}
                  >
                    {activeLanguage?.services?.btn?.no}
                  </button>
                  <button onClick={yesFunction}>
                    {activeLanguage?.services?.btn?.yes}
                  </button>
                </div>
              ) : (
                <div>
                  <button></button>
                  <button
                    onClick={() => {
                      yesFunction();
                      setmodal(false);
                    }}
                  >
                    {titleButton}
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ModalYesNo;
