import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../../providers/StateProvider";
import { IconRecibo } from "../../../Cards/ItemVenda";
import { IconClose, IconRemove2 } from "../../../../assets/icons/icons";
import InputForm from "../../../inputs/InputForm";
import toast from "react-hot-toast";
import LoadingInButton from "../../../Loading/LoadingInButton";
import { changeUploadImg } from "../../../../shared/utils/helpers/upload";
import InputFormDigitWhatsapp from "../../../inputs/InputFormDigitWhatsapp";
import { phoneMask, userId } from "../../../../providers/reducer";
import SwitchShowReceipt from "../../../Switch/SwitchShowReceipt";
import "../../../../styles/Modals/CustomizeReceipt.css";
import {
  getCompanieByUserId,
  saveCompanie,
} from "../../../../providers/companies";
import LoadingInModal from "../../../Loading/LoadingInModal";
import { gerateId } from "../../../../shared/utils/helpers/helpers";

const ModalCustomizeReceipt = ({ modal, setmodal }) => {
  const [{ activeLanguage }] = useStateValue();

  const [nameStore, setNameStore] = useState("");

  const [brandPhoto, setBrandPhoto] = useState(null);
  const [uploadBrandPhoto, setUploadBrandPhoto] = useState(null);
  const [telStore, setTelStore] = useState("");
  const [numberDigit, setNumberDigit] = useState("");
  const [address, setAddress] = useState("");
  const [footerType, setFooterType] = useState("");
  const [onShowReceipt, setOnShowReceipt] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [companie, setCompanie] = useState(null);
  const [loadingProgressUpload, setLoadingProgressUpload] = useState(0);

  useEffect(() => {
    if (modal) {
      setLoadingData(true);
      const getDataCompanie = async () => {
        const getCompanie = await getCompanieByUserId(userId());
        setCompanie(getCompanie);
        setLoadingData(false);
      };
      getDataCompanie();
    }
  }, [modal]);

  useEffect(() => {
    if (modal && companie) {
      const companieData = companie?.data();
      setAddress(companieData?.address1);
      setOnShowReceipt(companieData?.customizedReceipt?.showCustomReceipt);
      setFooterType(companieData?.customizedReceipt?.footer);
      setNumberDigit(companieData?.ddi);
      setAddress(companieData?.address?.address1);
      setBrandPhoto(companieData?.logo);
      setNameStore(companieData?.name);
      setTelStore(companieData?.phone);
    }
  }, [modal, companie]);

  const saveCustomizeReceipt = async () => {
    setLoading(true);

    const idCompanie = companie?.id ? companie?.id : gerateId();
    const dataCompanie = companie?.data();

    const companieToSave = {
      id: idCompanie,
      data: {
        ...dataCompanie,
        address: {
          ...dataCompanie?.address,
          address1: address,
          // address2: "",
          // city: "",
          // country: "",
          // postCode: "",
          // state: "",
        },
        // cnpj: null,
        customizedReceipt: {
          footer: footerType,
          showCustomReceipt: onShowReceipt,
        },
        ddi: numberDigit,
        name: nameStore,
        phone: telStore,
        userId: userId(),
      },
    };

    await saveCompanie(
      companieToSave,
      activeLanguage,
      setLoading,
      uploadBrandPhoto,
      setLoadingProgressUpload,
      () => {
        setmodal(false);
        toast.success(`Recibo personalizado com sucesso!`);
        setLoading(false);
        setLoadingProgressUpload(0);
      },
      (error) => {
        setmodal(false);
        console.log(error);
        toast.error(activeLanguage?.alerts?.erro);
        setLoading(false);
        setLoadingProgressUpload(0);
      }
    );
  };

  const validate = nameStore && telStore && address;

  return (
    <div
      style={{
        zIndex: 9999999,
        opacity: modal ? "5" : "0",
        visibility: modal ? "visible" : "hidden",
      }}
      className="myne-modal"
    >
      <div
        style={{
          height: 534,
          transform: modal ? "scale(1)" : "scale(0.7)",
        }}
        className="myne-modal__modal-edit-cadastro"
      >
        {modal ? (
          <>
            <header className="myne-modal__modal-edit-header myne-modal--animation">
              <section className="myne-modal__modal-edit-header__left">
                <IconRecibo />
                <p style={{ textTransform: "initial" }}>
                  {activeLanguage?.options?.settings?.receipt?.description}
                </p>
              </section>

              <section className="myne-modal__modal-edit-header__icons">
                <IconClose onClick={() => setmodal(!modal)} />
              </section>
            </header>

            {loadingData ? (
              <LoadingInModal />
            ) : (
              <>
                <div className="myne-modal__modal-edit-cadastro-content myne-modal--animation">
                  <div className="myne-modal__modal-edit-cadastro-content__customize-receipt">
                    <section className="myne-modal__modal-content__customize-receipt--upload">
                      <label
                        htmlFor={`upload-brand-store-${nameStore}`}
                        className="myne-modal__modal-content__customize-receipt--upload__label"
                      >
                        {loadingProgressUpload > 0 && (
                          <div
                            style={{ height: `${loadingProgressUpload}%` }}
                            className="myne-loading-upload-image myne-modal--animation"
                          ></div>
                        )}
                        {brandPhoto ? (
                          <img src={brandPhoto} alt="Marca do negócio" />
                        ) : (
                          <div>
                            <IconAddPhoto />
                            <p className="myne-text">
                              Adicionar marca do seu negócio
                            </p>
                          </div>
                        )}
                      </label>

                      {brandPhoto && (
                        <button
                          onClick={() => {
                            setUploadBrandPhoto(null);
                            setBrandPhoto(null);
                          }}
                          className="myne-btn-border"
                        >
                          <IconRemove2 />
                          DELETAR
                        </button>
                      )}

                      <input
                        onChange={(e) =>
                          changeUploadImg(e, setUploadBrandPhoto, setBrandPhoto)
                        }
                        style={{ display: "none" }}
                        type="file"
                        id={`upload-brand-store-${nameStore}`}
                      />
                    </section>
                    <div
                      style={{ overflow: "initial" }}
                      className="myne-modal__modal-edit-cadastro-content__inputs"
                    >
                      <InputForm
                        value={nameStore}
                        type="text"
                        onChange={(e) => setNameStore(e.target.value)}
                        label={`Nome do Negócio/Loja`}
                      />
                      <InputFormDigitWhatsapp
                        value={telStore}
                        marginTop={numberDigit !== "" ? 20 : ""}
                        valueDigit={numberDigit}
                        type={"text"}
                        label={"Telefone"}
                        setNumberDigit={setNumberDigit}
                        onChange={(e) => setTelStore(phoneMask(e.target.value))}
                      />
                      <div style={{ marginTop: 16 }}></div>
                      <InputForm
                        value={address}
                        type="text"
                        onChange={(e) => setAddress(e.target.value)}
                        label={`Endereço`}
                      />
                      <InputForm
                        value={footerType}
                        type="text"
                        onChange={(e) => setFooterType(e.target.value)}
                        label={`Texto do rodapé`}
                      />
                    </div>
                  </div>
                </div>
                <footer
                  style={{ height: 104 }}
                  className="myne-modal--animation myne-modal__modal-new-sale_bottom"
                >
                  <SwitchShowReceipt
                    onShowReceipt={onShowReceipt}
                    setOnShowReceipt={setOnShowReceipt}
                    key={"MODAL-CUSTOMIZE-RECEIPT"}
                  />
                  <div className="myne-modal__modal-new-sale_bottom--right">
                    {loading ? (
                      <button disabled className="active">
                        <LoadingInButton />
                      </button>
                    ) : validate ? (
                      <button onClick={saveCustomizeReceipt} className="active">
                        <span>
                          {
                            activeLanguage?.services?.product?.modal_add
                              ?.save_btn
                          }
                        </span>
                      </button>
                    ) : (
                      <button disabled>
                        <span>
                          {
                            activeLanguage?.services?.product?.modal_add
                              ?.save_btn
                          }
                        </span>
                      </button>
                    )}
                  </div>
                </footer>
              </>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ModalCustomizeReceipt;

const IconAddPhoto = () => (
  <svg
    width="31"
    height="29"
    viewBox="0 0 31 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 9.6875V7H1.3125C0.625 7 0 6.375 0 5.6875C0 4.9375 0.625 4.3125 1.3125 4.3125H4V1.6875C4 0.9375 4.625 0.3125 5.3125 0.3125C6.0625 0.3125 6.6875 0.9375 6.6875 1.6875V4.3125H9.3125C10.0625 4.3125 10.6875 4.9375 10.6875 5.6875C10.6875 6.375 10.0625 7 9.3125 7H6.6875V9.6875C6.6875 10.375 6.0625 11 5.3125 11C4.625 11 4 10.375 4 9.6875ZM13.3125 17.6875C13.3125 19.875 15.125 21.6875 17.3125 21.6875C19.5625 21.6875 21.3125 19.875 21.3125 17.6875C21.3125 15.4375 19.5625 13.6875 17.3125 13.6875C15.125 13.6875 13.3125 15.4375 13.3125 17.6875ZM28 7C29.4375 7 30.6875 8.1875 30.6875 9.6875V25.6875C30.6875 27.125 29.4375 28.3125 28 28.3125H6.6875C5.1875 28.3125 4 27.125 4 25.6875V11.9375C4.375 12.1875 4.8125 12.3125 5.3125 12.3125C6.8125 12.3125 8 11.125 8 9.6875V8.3125H9.3125C10.8125 8.3125 12 7.125 12 5.6875C12 5.1875 11.875 4.75 11.625 4.3125H20.1875C20.9375 4.3125 21.625 4.625 22.125 5.1875L23.75 7H28ZM17.3125 24.3125C21 24.3125 24 21.375 24 17.6875C24 14 21 11 17.3125 11C13.625 11 10.6875 14 10.6875 17.6875C10.6875 21.375 13.625 24.3125 17.3125 24.3125Z"
      fill="black"
      fill-opacity="0.36"
    />
  </svg>
);
