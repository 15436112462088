export const PtLangFlag = ({ ...props }) => (
  <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_10061_13160)">
        <path
          d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
          fill="#6DA544"
        />
        <path
          d="M12 4.695L21.913 12 12 19.304 2.087 12 12 4.695z"
          fill="#FFDA44"
        />
        <path
          d="M12 16.174a4.174 4.174 0 100-8.348 4.174 4.174 0 000 8.348z"
          fill="#F0F0F0"
        />
        <path
          d="M9.913 11.74c-.726 0-1.426.11-2.086.315a4.173 4.173 0 007.591 2.34 7.034 7.034 0 00-5.505-2.656zM16.097 12.8a4.174 4.174 0 00-7.932-2.447 8.607 8.607 0 017.932 2.447z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_10061_13160">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
);

export const EnLangFlag = ({ ...props }) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_10061_13045)">
      <path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
        fill="#F0F0F0"
      />
      <path
        d="M11.478 12H24c0-1.084-.144-2.133-.413-3.13H11.478V12zM11.478 5.739h10.76a12.063 12.063 0 00-2.768-3.13h-7.992v3.13zM12 24c2.824 0 5.42-.977 7.47-2.61H4.53A11.948 11.948 0 0012 24zM1.761 18.26H22.24a11.926 11.926 0 001.348-3.13H.413c.301 1.116.759 2.168 1.348 3.13z"
        fill="#D80027"
      />
      <path
        d="M5.559 1.874h1.093l-1.017.739.389 1.196-1.018-.74-1.017.74.336-1.033c-.896.746-1.68 1.62-2.328 2.594h.35l-.647.47c-.1.168-.197.34-.29.513l.31.951-.578-.419C1 7.19.868 7.5.75 7.817l.34 1.048h1.258l-1.017.74.388 1.195-1.017-.739-.61.443C.033 10.994 0 11.494 0 12h12V0C9.63 0 7.42.688 5.559 1.874zm.465 8.926l-1.018-.739-1.017.739.389-1.196-1.017-.739h1.257l.388-1.195.389 1.195h1.257l-1.017.74.389 1.195zm-.389-4.691l.389 1.195-1.018-.739-1.017.74.389-1.196-1.017-.74h1.257l.388-1.195.389 1.196h1.257l-1.017.739zm4.693 4.691l-1.017-.739-1.017.739.388-1.196-1.017-.739h1.257l.389-1.195.388 1.195h1.258l-1.018.74.389 1.195zm-.389-4.691l.389 1.195-1.017-.739-1.017.74.388-1.196-1.017-.74h1.257l.389-1.195.388 1.196h1.258l-1.018.739zm0-3.496l.389 1.196-1.017-.74-1.017.74.388-1.196-1.017-.739h1.257L9.311.678l.388 1.196h1.258l-1.018.739z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_10061_13045">
        <path fill="#fff" d="M0 0H24V24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const EsLangFlag = ({ ...props }) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_10061_13040)">
      <path
        d="M0 12c0 1.467.264 2.873.746 4.174L12 17.216l11.254-1.043c.482-1.3.746-2.707.746-4.174 0-1.468-.264-2.874-.746-4.174L12 6.782.746 7.826A11.974 11.974 0 000 12z"
        fill="#FFDA44"
      />
      <path
        d="M23.254 7.826C21.558 3.256 17.16 0 12 0 6.84 0 2.442 3.257.746 7.826h22.508zM.746 16.174C2.442 20.744 6.84 24 12 24c5.16 0 9.558-3.257 11.254-7.826H.746z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_10061_13040">
        <path fill="#fff" d="M0 0H24V24H0z" />
      </clipPath>
    </defs>
  </svg>
);
