import React, { useEffect, useMemo, useRef, useState } from "react";
import { useStateValue } from "../../providers/StateProvider";
import { setLanguageSystem } from "../../providers/lang/languageSystem";
import {
  PtLangFlag,
  EnLangFlag,
  EsLangFlag,
} from "../../assets/icons/Lang/index.js";
import { useOnClickOutside } from "../../providers/reducer.js";
import toast from "react-hot-toast";
import { langPt } from "../../providers/lang/pt.js";
import { langEn } from "../../providers/lang/en.js";
import { langEs } from "../../providers/lang/es.js";

const SelectChangeLang = () => {
  const [{ activeLanguage }, dispatch] = useStateValue();
  const [openSelect, setOpenSelect] = useState(false);
  const openSelectRef = useRef();
  useOnClickOutside(openSelectRef, () => setOpenSelect(false));

  const [languageSelect, setLanguageSelect] = useState();

  const languages = useMemo(
    () => [
      {
        icon: <PtLangFlag />,
        title: activeLanguage?.lang_pt,
        id: "pt",
      },
      {
        icon: <EnLangFlag />,
        title: activeLanguage?.lang_en,
        id: "en",
      },
      {
        icon: <EsLangFlag />,
        title: activeLanguage?.lang_es,
        id: "es",
      },
    ],
    [activeLanguage]
  );

  const languageActiveID = localStorage.getItem("activeLanguageID");
  useEffect(() => {
    const getActiveLocalStorageLanguage = languages.filter(
      (lang) => lang?.id === languageActiveID
    );
    setLanguageSelect(getActiveLocalStorageLanguage[0]);
  }, [languageActiveID, languages]);

  return (
    <div className="myne-modal__custom-select">
      <div
        onClick={() => setOpenSelect(!openSelect)}
        className="myne-modal__custom-select-active"
      >
        {languageSelect?.icon}
        <IconExpandMore
          style={{
            transform: openSelect ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </div>

      <section
        ref={openSelectRef}
        className="myne-modal__custom-select-modal myne-modal--animation-top"
        style={{
          height: openSelect ? 112 : 0,
          opacity: openSelect ? 1 : 0,
          visibility: openSelect ? "visible" : "hidden",
        }}
      >
        {openSelect &&
          languages?.map((lang) => (
            <div
              className="myne-modal--animation-top"
              key={lang?.id}
              onClick={() => {
                if (lang?.id !== languageActiveID) {
                  setLanguageSystem(dispatch, lang?.id);
                  setLanguageSelect(lang);

                  let messageToast = langPt;
                  if (lang?.id === "pt") {
                    messageToast = langPt;
                  } else if (lang?.id === "en") {
                    messageToast = langEn;
                  } else {
                    messageToast = langEs;
                  }
                  toast.success(messageToast?.language_change);
                }
                setOpenSelect(false);
              }}
            >
              {lang?.icon}
              <p className="myne-text">{lang?.title}</p>
            </div>
          ))}
      </section>
    </div>
  );
};

export default SelectChangeLang;

const IconExpandMore = ({ ...rest }) => (
  <svg
    width="10"
    height="6"
    {...rest}
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.91797 0.960938C8.19922 0.679688 8.69141 0.679688 8.97266 0.960938C9.25391 1.27734 9.25391 1.73438 8.97266 2.01562L5.52734 5.46094C5.24609 5.77734 4.75391 5.77734 4.47266 5.46094L1.02734 2.01562C0.746094 1.73438 0.746094 1.27734 1.02734 0.960938C1.30859 0.679688 1.80078 0.679688 2.08203 0.960938L5 3.87891L7.91797 0.960938Z"
      fill={"black"}
      fillOpacity={"0.87"}
    />
  </svg>
);
