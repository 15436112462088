import React, { useEffect, useMemo, useState } from "react";

//
import FilterDates from "../../components/Filters/FilterDates";

//
import Header from "../../layouts/Header/Header";

//
import "../../styles/Dashboard/Dashborad.css";

//
import Moneys from "../../assets/icons/Moneys.svg";
import ilustracao from "../../assets/images/ILUSTRATORS/ilustracao 2.svg";

//
import { useStateValue } from "../../providers/StateProvider";
import { getDatesRange } from "../../providers/reducer";

//
import { auth, db } from "../../services/Firebase";

//
import ListVendas from "../../components/Dashboard-items/ListVendas";
import VendasFlexLeft from "../../components/Dashboard-items/VendasFlexLeft";
import ModalNewSale from "../../components/Modals/Sales/ModalNewSale";
import "../../services/Firebase";
import ModalPlus from "../../components/Modals/Plus/ModalPlus";
import { useLocation, useHistory } from "react-router-dom";
import ModalPlusWelcome from "../../components/Modals/Plus/ModalPlusWelcome";

function Dashboard() {
  const [{ activeDate }] = useStateValue();

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (
      location.pathname !== "/" &&
      location.pathname !== `/welcome-plus-${auth?.currentUser?.uid}`
    ) {
      history.push("/");
    }
  }, [location, history]);

  // const [subscriptions, setSubscriptions] = useState([]);
  // const [products, setProducts] = useState([]);

  // useEffect(() => {
  //   async function effect() {
  //     const products = await getProducts(payments, {
  //       includePrices: true,
  //       activeOnly: true,
  //     });
  //     setProducts(products);
  //   }
  //   effect();
  // }, []);

  // useEffect(() => {
  //   async function effect() {
  //     const subscriptions = await getCurrentUserSubscriptions(payments);
  //     setSubscriptions(subscriptions);
  //   }
  //   if (user) effect();
  // }, [user]);

  const [{ activeBtn, ifPlusVerified }] = useStateValue();
  const [{ calendarDates }] = useStateValue();
  const [{ mesesNew, activeLanguage }] = useStateValue();
  const [sales, setSales] = useState([]);
  const [modalNewSale, setModalNewSale] = useState(false);

  useEffect(() => {
    const fecthsales = async () => {
      const docReference = db
        .collection("users")
        .doc(auth?.currentUser?.uid)
        .collection("sales");
      try {
        await docReference.orderBy("date", "asc").onSnapshot((snapshot) => {
          setSales(
            snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
          );
        });
      } catch (e) {}
    };
    fecthsales();
  }, []);

  // const fecthsales = useCallback(async () => {
  //   const docReference = db
  //     .collection("users")
  //     .doc(auth?.currentUser?.uid)
  //     .collection("sales");

  //   await docReference.orderBy("date", "asc").onSnapshot((snapshot) => {
  //     setSales(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })));
  //   });
  // }, []);

  // useEffect(() => {
  //   fecthsales();
  // }, [fecthsales]);

  const getRangeOfMonths = (startDate, endDate) => {
    const dates = [];
    const dateCounter = new Date(startDate);

    dateCounter.setDate(1);
    while (dateCounter < endDate) {
      dates.push(
        `${mesesNew[dateCounter.getMonth()]}, ${dateCounter.getFullYear()}`
      );
      dateCounter.setMonth(dateCounter.getMonth() + 1);
    }
    return dates;
  };

  const [freeLimitUtrapassed, setFreeLimitUtrapassed] = useState(null);
  const [loading, setLoading] = useState(true);

  const listSalesToday = async () => {
    const filtersalesUpdateAt = sales?.filter((sale) => {
      let date = sale?.data?.updatedAt?.toDate();
      let today = `${new Date().getDate()} ${
        mesesNew[new Date().getMonth()]
      }, ${new Date().getFullYear()}`;

      return (
        `${date?.getDate()} ${
          mesesNew[date?.getMonth()]
        }, ${date?.getFullYear()}` === today
      );
    });
    if (filtersalesUpdateAt?.length < 1) {
      setFreeLimitUtrapassed("false");
      setLoading(false);
    } else {
      setFreeLimitUtrapassed("true");
      setLoading(false);
    }
  };

  const filterCalendar = useMemo(() => {
    if (calendarDates?.type == "Dia") {
      let dates = getDatesRange(
        new Date(calendarDates?.startDate),
        new Date(calendarDates?.endDate),
        mesesNew
      );

      return sales.filter((sale) => {
        let date = sale.data.date.toDate();

        let compare = `${date.getDate()} ${
          mesesNew[date.getMonth()]
        }, ${date.getFullYear()}`;

        let filter = dates.filter((v) => {
          return compare.toString() === v;
        });
        return filter == compare.toString();
      });
    } else {
      let dates = getRangeOfMonths(
        new Date(calendarDates?.startDate),
        new Date(calendarDates?.endDate)
      );

      return sales.filter((sale) => {
        let date = sale.data.date.toDate();

        let compare = `${mesesNew[date.getMonth()]}, ${date.getFullYear()}`;

        let filter = dates.filter((v) => {
          return compare.toString() === v;
        });

        return filter == compare.toString();
      });
    }
  }, [sales, calendarDates, activeDate]);

  const filterSales = useMemo(() => {
    if (activeBtn === "Dia") {
      var dayInPast = new Date();
      dayInPast.setDate(dayInPast.getDate() - activeDate);

      return sales.filter((sale) => {
        let date = sale?.data?.date.toDate();

        return date >= dayInPast;
      });
      // return sales.filter((sale) => {
      //   let date = sale?.data?.date.toDate();

      //   return (
      //     `${date.getDate()} ${
      //       mesesNew[date.getMonth()]
      //     }, ${date.getFullYear()}` === activeDate
      //   );
      // });
    } else if (activeBtn === "Mês") {
      return sales.filter((sale) => {
        let date = sale?.data?.date.toDate();
        let mes = `${mesesNew[date.getMonth()]} ${date.getFullYear()}`;

        return mes.toString() === activeDate;
      });
    } else {
      return filterCalendar;
    }
  }, [sales, calendarDates, activeDate]);

  // useEffect(() => {
  //   if (
  //     messages.messageSucesseAddVenda ||
  //     messages.messageSucesseRemoveVenda ||
  //     messages.messageErro ||
  //     messages.messageSucesseUpdateVenda
  //   ) {
  //     const timer = setTimeout(() => {
  //       dispatch({
  //         type: "SET_MESSAGES",
  //         messages: {
  //           messageSucesseAddVenda: false,
  //           messageSucesseRemoveVenda: false,
  //           messageErro: false,
  //           messageSucesseUpdateVenda: false,
  //         },
  //       });
  //     }, [2500]);
  //     return () => {
  //       clearTimeout(timer);
  //     };
  //   }
  // }, [messages]);

  const [modalPlus, setModalPlus] = useState(false);

  // useEffect(() => {
  //   if (!ifPlusVerified) {
  //     console.log("Oi");
  //     if (loading) {
  //       setModalNewSale(false);
  //       setModalPlus(false);
  //     }
  //     // listSalesToday().then(() => {
  //     //   if (!loading) {
  //     //     if (freeLimitUtrapassed === "true") {
  //     //       setModalNewSale(false);
  //     //     } else if (freeLimitUtrapassed === "false") {
  //     //       setModalPlus(false);
  //     //     }
  //     //   }
  //     // });
  //   }
  // }, [loading, listSalesToday, freeLimitUtrapassed, ifPlusVerified]);

  return (
    <div className="myne-dashboard myne-modal--animation-top">
      <Header sales={sales} />
      {/* <div
        style={{
          opacity: messages.messageSucesseUpdateVenda ? "1" : 0,
          visibility: messages.messageSucesseUpdateVenda ? "visible" : "hidden",
          right: messages.messageSucesseUpdateVenda ? "21px" : "-100%",
          zIndex: 99999999999,
        }}
        className="myne-modal_message myne-modal_message-sucess"
      >
        <IconSucess />
        {activeLanguage?.alerts?.update}
      </div>

      <div
        style={{
          opacity: messages.messageSucesseAddVenda ? "0.87" : 0,
          visibility: messages.messageSucesseAddVenda ? "visible" : "hidden",
          right: messages.messageSucesseAddVenda ? "21px" : "-100%",
        }}
        className="myne-modal_message myne-modal_message-sucess"
      >
        <IconSucess />
        {activeLanguage?.alerts?.register_sale}
      </div>

      <div
        style={{
          opacity: messages.messageSucesseRemoveVenda ? "0.87" : 0,
          visibility: messages.messageSucesseRemoveVenda ? "visible" : "hidden",
          right: messages.messageSucesseRemoveVenda ? "21px" : "-100%",
        }}
        className="myne-modal_message myne-modal_message-sucess"
      >
        <IconSucess />
        {activeLanguage?.alerts?.remove_sale}
      </div>

      <div
        style={{
          opacity: messages.messageErro ? 1 : 0,
          visibility: messages.messageErro ? "visible" : "hidden",
          right: messages.messageErro ? "21px" : "-100%",
        }}
        className="myne-modal_message myne-modal_message-erro"
      >
        <IconErro />
        Algo deu errado. Tente novamente mais tarde.
      </div> */}
      {/*  */}

      {/* MODAL NEW SALE */}

      <ModalPlusWelcome
        modal={location.pathname === `/welcome-plus-${auth?.currentUser?.uid}`}
      />

      <ModalNewSale
        key={"NEW-SALE-DASHBOARD"}
        modal={modalNewSale}
        setmodal={setModalNewSale}
      />

      {/* MODAL ALERT */}

      {/* <div
        style={{
          opacity: modalAlert ? "5" : "0",
          visibility: modalAlert ? "visible" : "hidden",
          zIndex: 999999999,
        }}
        className="myne-modal"
      >
        <div
          style={{
            transform: modalAlert ? "scale(1)" : "scale(0.7)",
          }}
          className="myne-modal__modal-add-item__alert"
        >
          <div className="myne-modal__modal-add-item-content__alert">
            <section>
              <IconInfo />
              <div>
                <h5>Espera só mais um pouco!</h5>

                <p>Essa funcionalidade ainda não está disponível para a web.</p>
              </div>
            </section>

            <button onClick={() => setModalAlert(false)}>ENTENDI</button>
          </div>
        </div>
      </div> */}

      <ModalPlus
        modal={modalPlus}
        setmodal={setModalPlus}
        message_locked={activeLanguage?.be_plus?.limit}
      />

      <div className="dashboard__content myne-modal--animation">
        <div className="dashboard-content__filter">
          <p className="myne-text">{activeLanguage?.sales?.title}</p>
          {sales.length !== 0 ? <FilterDates /> : ""}
        </div>

        <div className="dashboard-content__grid" id={"dashboard-grid"}>
          <div className="dashboard-content__grid-vendas">
            {/* <BannerVendas images={[imgBanner, imgBanner, imgBanner]} /> */}

            <ListVendas ilustracao={ilustracao} filterSales={filterSales} />

            <div
              onClick={() => {
                if (ifPlusVerified) {
                  setModalNewSale(!modalNewSale);
                  setLoading(false);
                } else {
                  listSalesToday().then(() => {
                    if (!loading) {
                      if (freeLimitUtrapassed === "true") {
                        setModalPlus(!modalPlus);
                      } else if (freeLimitUtrapassed === "false") {
                        setModalNewSale(!modalNewSale);
                      }
                    }
                  });
                  // setModalAlert(true);
                }
              }}
              className="dashboard-content__grid-vendas__button myne-btn"
            >
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9 8H8.89999V13C8.89999 13.2652 8.79464 13.5196 8.6071 13.7071C8.41957 13.8946 8.16521 14 7.89999 14C7.63478 14 7.38042 13.8946 7.19289 13.7071C7.00535 13.5196 6.89999 13.2652 6.89999 13V8H1.89999C1.63478 8 1.38042 7.89464 1.19289 7.70711C1.00535 7.51957 0.899994 7.26522 0.899994 7C0.899994 6.73478 1.00535 6.48043 1.19289 6.29289C1.38042 6.10536 1.63478 6 1.89999 6H6.89999V1C6.89999 0.734784 7.00535 0.48043 7.19289 0.292893C7.38042 0.105357 7.63478 0 7.89999 0C8.16521 0 8.41957 0.105357 8.6071 0.292893C8.79464 0.48043 8.89999 0.734784 8.89999 1V6H13.9C14.1652 6 14.4196 6.10536 14.6071 6.29289C14.7946 6.48043 14.9 6.73478 14.9 7C14.9 7.26522 14.7946 7.51957 14.6071 7.70711C14.4196 7.89464 14.1652 8 13.9 8Z"
                  fill="white"
                />
              </svg>
              <p className="myne-text">
                {activeLanguage?.sales?.list_sales?.btn_add}
              </p>
            </div>
          </div>
          <VendasFlexLeft
            filterSales={filterSales}
            sales={sales}
            Moneys={Moneys}
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
