import React, { useEffect, useState } from "react";
import { useStateValue } from "./providers/StateProvider";
import { actionTypes, logout } from "./providers/reducer";
import Routes from "./routes/Routes";
import { auth } from "./services/Firebase";
import "./styles/App.css";
import Login from "./views/Login/Login";
import Cadastro from "./views/Login/Cadastro";
import Loading from "./components/Loading/Loading";
import { useAuthState } from "react-firebase-hooks/auth";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Options from "./views/Options/Options";
import LoadingProcess from "./components/Loading/LoadingProcess";
import Privacy from "./views/Privacy";
import { setLanguageSystem } from "./providers/lang/languageSystem";
import { checkIfUserPlus } from "./shared/utils/user/plus";

function App() {
  const [{ user }, dispatch] = useStateValue();
  const [, loading] = useAuthState(auth);
  const [loadingVerifiedPlus, setLoadingVerifiedPlus] = useState(true);

  useEffect(() => {
    setLanguageSystem(dispatch);
  }, [dispatch]);

  useEffect(() => {
    auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        await dispatch({
          type: actionTypes?.SET_USER,
          user: authUser,
        });
      } else {
        await auth.signOut();
        return logout;
      }
    });
  }, [user, dispatch]);

  useEffect(() => {
    if (!loading) {
      if (user) {
        const setUserPlus = async () => {
          const isPlus = await checkIfUserPlus();

          dispatch({
            type: actionTypes?.SET_CHECK_USER_IF_PLUS,
            ifPlusVerified: isPlus,
          });
        };
        setUserPlus().then(() => {
          setLoadingVerifiedPlus(false);
        });
      }
    }
  }, [dispatch, user, loading]);

  useEffect(() => {
    const intervalCheckIfNoUserPlus = setTimeout(() => {
      if (!user) {
        setLoadingVerifiedPlus(false);
      }
    }, 2800);

    return () => clearInterval(intervalCheckIfNoUserPlus);
  }, [user]);

  if (window.location.pathname === "/privacy") return <Privacy />;
  if (loadingVerifiedPlus) return <Loading />;
  if (!user)
    return (
      <BrowserRouter>
        <Switch>
          <Route component={LoadingProcess} path="/loading" exact />
          <Route path="/" exact>
            <Login />
          </Route>
          <Route path="/cadastro">
            <Cadastro />
          </Route>
          <Route path="/opcoes">
            <Options />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  return <Routes />;
}

export default App;
