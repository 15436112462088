import React from "react";

//
import "../../styles/Buttons/index.css";

//
import { logout } from "../../providers/reducer";
import { auth } from "../../services/Firebase";

//
import { useStateValue } from "../../providers/StateProvider";
import { analyticsEvent } from "../../services/analytics";
import { useHistory, useLocation } from "react-router-dom";
import { IconLogout } from "../../assets/icons/icons";

function Sair() {
  const [{ activeLanguage }, dispach] = useStateValue();
  const history = useHistory();
  const location = useLocation();
  return (
    <button
      className="myne-btn-logout"
      onClick={() => {
        dispach({
          type: "SET_USER",
          user: null,
        });
        auth.signOut().then(() => {
          if (location.pathname !== "/") {
            history.push("/");
            window.location.href = "/";
          }

          return logout;
        });
        analyticsEvent("OPTION_LOGOUT_CLICKED");
      }}
    >
      <IconLogout />
      <p className="myne-text">
        {activeLanguage?.layouts?.sidebar?.logout_btn}
      </p>
    </button>
  );
}

export default Sair;
