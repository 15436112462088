import React, { useRef, useState } from "react";
import { useOnClickOutside } from "../../providers/reducer";

function Tooltip({ icon, direction, text, hover }) {
  const [tooltipModal, setTooltipModal] = useState(false);

  const messageCopyRef = useRef();
  useOnClickOutside(messageCopyRef, () => setTooltipModal(false));

  return (
    <section className="myne-modal__modal-sucess-publicate_copy">
      <div
        onMouseEnter={() => (hover ? setTooltipModal(!tooltipModal) : {})}
        onMouseLeave={() => (hover ? setTooltipModal(!tooltipModal) : {})}
        className="icon-area"
        onClick={() => setTooltipModal(!tooltipModal)}
      >
        {icon}
      </div>
      {tooltipModal ? (
        <div
          style={{
            bottom: direction === "bottom" ? 0 : "",
            top: direction === "bottom" ? 30 : "",
          }}
          className="myne-modal__modal-sucess-publicate_copy-tooltip myne-modal--animation-scale"
          ref={messageCopyRef}
        >
          {text}
          <PolygonTooltip
            style={{
              transform: direction === "bottom" ? "rotate(180deg)" : "",
              top: direction === "bottom" ? -5 : "",
              bottom: direction === "bottom" ? "initial" : "",
            }}
          />
        </div>
      ) : (
        ""
      )}
    </section>
  );
}

export default Tooltip;

const PolygonTooltip = ({ ...a }) => (
  <svg
    width="22"
    height="6"
    {...a}
    viewBox="0 0 22 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0003 4.8453C11.7627 5.55983 10.2379 5.55983 9.00027 4.8453L0.607971 1.81705e-06L21.3926 0L13.0003 4.8453Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);
