import React, { useEffect, useState } from "react";
import { IconClose } from "../../../assets/icons/icons";
import InputForm from "../../inputs/InputForm";
import { useStateValue } from "../../../providers/StateProvider";
import { removeCategorie, saveCategorie } from "../../../providers/reducer";
import toast from "react-hot-toast";

function ModalAddCategorie({
  modal,
  setmodal,
  item,
  setSelectCategories,
  backIcon,
}) {
  const [categorieName, setCategorieName] = useState("");
  const [{ activeLanguage }, dispatch] = useStateValue();
  const [modalRemove, setModalRemove] = useState(false);

  useEffect(() => {
    if (item) {
      setCategorieName(item?.name);
    } else {
      setCategorieName("");
    }
  }, [item]);

  const save = () => {
    const gerateId = item ? item?.id : Math.random().toString(16).slice(2);

    const itemadd = {
      name: categorieName,
      id: gerateId,
    };

    saveCategorie(
      gerateId,
      itemadd,
      (then) => {
        setmodal(!modal);
        setSelectCategories([categorieName]);
        setCategorieName("");

        toast.success(
          `${activeLanguage?.alerts?.save_service?.alert}${
            activeLanguage?.services?.categories?.title_min
          }${" "}${activeLanguage?.alerts?.save_service?.no_plural?.replaceAll(
            "o",
            "a"
          )}${" "}${activeLanguage?.alerts?.save_service?.p2}`
        );

        // dispatch({
        //   type: "SET_MESSAGES",
        //   messages: {
        //     messageSucesseEdit: false,
        //     messageSucesseAdd: true,
        //     messageSucesseRemove: false,
        //     aPt: true,
        //     messageErro: false,
        //     type: "Categorias",
        //   },
        // });
      },
      (erro) => {
        toast.error(activeLanguage?.alerts?.erro);
        // dispatch({
        //   type: "SET_MESSAGES",
        //   messages: {
        //     messageSucesseEdit: false,
        //     messageSucesseAdd: false,
        //     messageSucesseRemove: false,
        //     messageErro: true,
        //     type: "Categorias",
        //   },
        // });
      }
    );
  };

  const remove = () => {
    removeCategorie(
      item?.id,
      (then) => {
        setSelectCategories([]);
        setCategorieName("");
        setmodal(!modal);
        setModalRemove(false);

        toast.success(
          `${
            activeLanguage?.services?.categories?.title_min
          }${" "}${activeLanguage?.alerts?.delete_service?.replaceAll(
            "excluído",
            "excluída"
          )}`
        );
        // dispatch({
        //   type: "SET_MESSAGES",
        //   messages: {
        //     messageSucesseEdit: false,
        //     messageSucesseAdd: false,
        //     messageSucesseRemove: true,
        //     messageErro: false,
        //     aPt: true,
        //     type: "Categorias",
        //   },
        // });
      },
      (erro) => {
        toast.error(activeLanguage?.alerts?.erro);

        // dispatch({
        //   type: "SET_MESSAGES",
        //   messages: {
        //     messageSucesseEdit: false,
        //     messageSucesseAdd: false,
        //     messageSucesseRemove: false,
        //     messageErro: true,
        //     type: "Categorias",
        //   },
        // });
      }
    );
  };
  return (
    <div
      style={{
        zIndex: 9999999999,
        opacity: modal ? "5" : "0",
        visibility: modal ? "visible" : "hidden",
      }}
      className="myne-modal"
    >
      {/* MODAL REMOVE */}
      <div
        style={{
          opacity: modalRemove ? "5" : "0",
          visibility: modalRemove ? "visible" : "hidden",
          zIndex: 999999999,
          transition: "all 0.3s ease",
        }}
        className="myne-modal"
      >
        <div
          style={{
            transform: modalRemove ? "scale(1)" : "scale(0.7)",
            transition: "all 0.3s ease",
          }}
          className="myne-modal__modal-add-item"
        >
          {modalRemove && (
            <>
              <header className="myne-modal__modal-add-item-header">
                <p style={{ margin: 0 }}>
                  {activeLanguage?.services?.modal_remove?.title} categoria
                </p>
              </header>

              <div className="myne-modal__modal-add-item-content">
                <p>{activeLanguage?.services?.modal_remove?.ask2} categoria?</p>

                <div>
                  <button onClick={() => setModalRemove(false)}>
                    {activeLanguage?.services?.btn?.no}
                  </button>
                  <button onClick={() => remove()}>
                    {activeLanguage?.services?.btn?.yes}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* MODAL ADD / EDIT */}
      <div
        style={{ transform: modal ? "scale(1)" : "scale(0.7)", height: 269 }}
        className="myne-modal__modal-edit-cadastro myne-modal__modal-add-product-sale-item"
      >
        {modal && (
          <>
            <header className="myne-modal__modal-edit-header">
              <section className="myne-modal__modal-edit-header__left">
                <IconCategorie />
                <p style={{ textTransform: "initial" }}>
                  {item ? "EDITAR" : "NOVA"} CATEGORIA
                </p>
              </section>

              <section className="myne-modal__modal-edit-header__icons">
                <IconClose onClick={() => setmodal(!modal)} />
              </section>
            </header>

            <div
              style={{
                transform: modal ? "scale(1)" : "scale(0.7)",
              }}
              className="myne-modal__modal-add-product-sale-item__container"
            >
              <InputForm
                value={categorieName}
                onChange={(e) => setCategorieName(e.target.value)}
                label={"Categoria"}
                placeholder="Ex: Cama"
                type="text"
                onKeyUp={(e) => {
                  if (e.key?.toLowerCase() === "enter") {
                    save();
                  }
                }}
              />

              <div className="myne-modal__modal-add-item-content">
                <div>
                  {item?.id ? (
                    <button
                      onClick={() => setModalRemove(true)}
                      className="text text-active"
                    >
                      EXCLUIR CATEGORIA
                    </button>
                  ) : (
                    <button
                      style={{ opacity: 0, visibility: "hidden" }}
                    ></button>
                  )}

                  <button style={{ width: 117 }} onClick={save}>
                    SALVAR
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ModalAddCategorie;

const IconCategorie = () => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.15625 1.42188C8.53125 0.765625 9.46875 0.765625 9.84375 1.42188L13.5469 7.46875C13.9688 8.125 13.5 9.01562 12.7031 9.01562H5.29688C4.5 9.01562 4.03125 8.125 4.45312 7.46875L8.15625 1.42188ZM9.98438 15.4844C9.98438 17.9688 12 19.9844 14.4844 19.9844C16.9688 19.9844 18.9844 17.9688 18.9844 15.4844C18.9844 13 16.9688 10.9844 14.4844 10.9844C12 10.9844 9.98438 13 9.98438 15.4844ZM0.984375 19.5156C0.46875 19.5156 0 19.0469 0 18.4844V12.4844C0 11.9688 0.46875 11.5 0.984375 11.5H6.98438C7.54688 11.5 8.01562 11.9688 8.01562 12.4844V18.4844C8.01562 19.0469 7.54688 19.5156 6.98438 19.5156H0.984375Z"
      fill="#AA00FF"
    />
  </svg>
);
