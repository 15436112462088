export const langPt = {
  lang_pt: "Português",
  lang_en: "English",
  lang_es: "Español",
  language_change: "Idioma alterado com sucesso",
  // LOGIN PAGE
  login: {
    titleMain: "O <strong>Myne</strong> chegou ao seu PDV",
    descriptionMain:
      "Controle seu estoque e registre suas vendas agora também no seu ponto de venda.",
    inputs: {
      email: "E-mail",
      password: "Senha",
      forgot_password: "Esqueci a senha",
    },
    btns: {
      submit: "ENTRAR",
      submit_google: "ENTRAR COM O GOOGLE",
      create_account: "CRIAR UMA CONTA",
      create_account_google: "CRIAR COM O GOOGLE",
    },
    forgot_password_view: {
      title: "Recuperar senha",
      submit: "ENVIAR",
      feedback: {
        title: "Enviamos um e-mail de recuperação da senha para o endereço:",
        submit: "OK",
      },
    },
    create_account_view: {
      title: "Criar conta",
      confirm_password: "Confirma senha",
      error_confirm_password: "A senha não é igual a confirmação!",
    },
    text_error: "Preencha todos os campos!",
    text_error_email: "Preencha o email para a recuperação de senha!",
  },
  // LAYOUTS
  layouts: {
    sidebar: {
      links: {
        vendas: "Vendas",
        registers: "Estoque e cadastros",
        options: "Opções",
      },
      logout_btn: "Sair",
    },
    header: {
      filter_dates: {
        day: "Dia",
        month: "Mês",
        day_plural: "Dias",
        monthy_plural: "Mêses",
        datefilter_modal: {
          dayandinitial: "Dia inicial",
          dayandfinal: "Dia final",
          daymonthinitial: "Mês inicial",
          daymonthfinal: "Mês final",
        },
      },
      plus_btn: "SEJA PLUS",
    },
  },
  // ALERTS
  alerts: {
    update: "Atualizado com sucesso!",
    register_sale: "Venda registrada com sucesso!",
    remove_sale: "Venda excluída com sucesso!",
    update_service: "Alteração salva com sucesso!",
    erro: "Algo deu errado. Tente novamente mais tarde.",
    moeda_change: "Moeda alterada com sucesso!",
    save_service: {
      alert: "",
      plural: "salvos",
      no_plural: "salvo",
      p2: "com sucesso!",
    },
    product_show_catalogo: "Produto disponível no catálogo online",
    product_remove_catalogo: "Produto removido no catálogo online",
    delete_service: "excluído com sucesso!",
    error_upload_image: "Erro ao fazer upload da imagem. Tente novamente.",
  },
  // SALES
  sales: {
    title: "Vendas",
    list_sales: {
      remove_sale: {
        title: "Excluir venda",
        question: "Você tem certeza que quer excluir a venda?",
      },
      no_itens: "Suas vendas ficarão registradas aqui",
      title: "Registros das vendas",
      filter: {
        f1: "Total crescente",
        f2: "Total decrescente",
        f3: "Data crescente",
        f4: "Data decrescente",
      },
      item_sale: {
        status: {
          paid: "Pago",
          notpaid: "Não pago",
          cash: "À vista",
          installments: "Parcelado",
          paid_day: "Pagamento em dia",
          overdue: "Em atraso",
        },
        sale_details: {
          title_receipt: "RECIBO",
          title_clt: "Cliente",
          title_pdt: "Produtos",
          title_srv: "Serviços",
          checkout: {
            subtotal: "Subtotal",
            discount: "Desconto",
            total: "Total",
          },
          btn_edit: "EDITAR",
          export: {
            title: "Exportar em",
            download: "Baixar recibo",
          },
          recibo: {
            sale_date: "Data da venda:",
            infos_sale: {
              title: "Cliente",
              title_payment_sale: "Condições da venda",
              title_status_sale: "Status da venda",
            },
            tables: {
              title_pdt: "Itens",
              title_srv: "Serviços",
              qty: "Quantidade",
              price: "Preço unitário",
              total: "Total",
              checkout: {
                subtotal: "Subtotal",
                discount: "Desconto",
                total_sale: "Total da venda",
              },
            },
            installment_infos: {
              title: "Informações do parcelamento",
              table: {
                t1: "Parcela",
                t2: "Data",
                t3: "Status",
                t4: "Valor",
              },
            },
            footer: {
              title: "Recibo gerado por <b>Myne App.</b>",
            },
          },
        },
      },
      btn_add: "Nova venda",
    },
    money_sales: {
      faturamento_brt: "Faturamento bruto",
      faturamento_liq: "Faturamento líquido",
      tiket: "Ticket médio",
    },
    biggestbuy_sales: {
      title: "Maiores compradores",
      no_itens: "Aqui serão mostrados os clientes que mais copram.",
    },
    graph_sales: {
      no_itens:
        "O gráfico aparecerá no momento em que você registrar a primeira venda.",
      title_pdt: "Vendas de produtos",
      title_clt: "Vendas por clientes",
      title_srv: "Vendas de serviços",
      title_units: "Unid.",
      title_sales: "vendas",
      btn_more: "VER MAIS",
    },
  },
  // BE PLUS
  be_plus: {
    title: "Cresça seu negócio com o Myne Plus",
    title_global: "<b>+100 Mil negócios</b> no mundo todo",
    check1: "Vendas ilimitadas",
    check2: "Myne para PC",
    check3: "Sem anúncios",
    check4: "Relatórios e gráficos incríveis",
    check5: "Categorias de produtos sem limites",
    locked_plus:
      "<strong>Funcionalidade PLUS.</strong> Assine agora para ter acesso ilimitado",
    limit_products: `O novo limite para usuários FREE é <strong>${40} produtos</strong>`,
    limit:
      "Você atingiu o limite de <strong>1 venda diária</strong>.<br />Para adicionar mais vendas torne-se um<br /><strong>usuário PLUS.</strong>",
    btns: {
      plan: {
        title: "Plano",
        month: "mês",
        cobra_year: "*Cobrança única de R$119,99 ao ano.",
        cobra_month: "*Cobrado Mensalmente.",
      },
      month: "Mensal",
      year: "Anual",
      btn: "ASSINAR",
    },
    // welcome
    welcome: {
      title: "Bem-vindo ao Myne Plus!",
      desc: "Confirmamos sua assinatura e agora você tem acesso total ao <b>Myne.</b>",
      check1: "Registros de vendas ilimitados",
      check2: "Acesso total a futuras novas funcionalidades",
      check3: "Sem anúncios",
    },
  },
  copy_link: "Link copiado!",
  // OPTIONS
  options: {
    title: "Opções",
    money: {
      title: "Moeda",
      input: "Pesquisar...",
    },
    be_plus: "Seja plus",
    catalog_online: {
      title: "Meu catálogo online",
      publicate: {
        title: "Catálogo publicado com sucesso!",
        description:
          "Aproveite! <b>Copie o link abaixo</b> e compartilhe com seus clientes.",
        view_catalog: "Visualizar catálogo no navegador",
        delete_catalog: "Deletar catálogo",
        ask_delete: "Quer mesmo deletar seu catálogo online?",
        sucess_delete: "Seu catálogo online foi deletado com sucesso.",
      },
    },
    settings: {
      title: "Ajustes",
      account: {
        title: "Conta",
        description: "Alterar dados",
      },
      categories: {
        title: "Categorias",
        description: "Configure as categorias",
      },
      coin: {
        title: "Moeda",
      },
      delete_account: {
        title: "Excluir conta",
        description: "Parar de usar o Myne",
      },
      receipt: {
        title: "Recibo",
        description: "Personalizar recibo",
      },
    },
    batch_actions: {
      title: "Ações em lote",
      products: "Produtos",
      customers: "Clientes",
      description: {
        p1: "Adicionar",
        p2: "em lote",
      },
    },
  },
  // MODALS
  modals: {
    // NEW SALE
    new_sale: {
      title: "NOVA VENDA",
      edit: "EDITAR VENDA",
      filter_btns: {
        f1: "DETALHES",
        f2: "FECHAMENTO",
      },
      details: {
        modals_btns: {
          input: "Pesquisar...",
          no_itens: {
            p1: "Seus",
            p2: "cadastrados serão mostrados aqui.",
          },
          discount_greater:
            "O desconto não pode ser maior que o valor da venda",
          title_type: "Novo", // +type
          product: {
            modal_add: {
              title: "Adicionar produto na venda",
              unit_stock: "unidades em estoque",
              price_unit: "Preço Unitário",
              add_btn: "ADICIONAR",
            },
          },
        },
        input_desc: "Alguma observação?",
        next_btn: "PRÓXIMO",
      },
      closure: {
        title_value_sale: "Valor da venda",
        checkout: {
          subtotal: "Subtotal",
          discount: {
            title: "Desconto",
            btn: "ADICIONAR",
          },
          total: "Total",
        },
        //
        installments: "Parcelamento",
        //
        installments_paid: "Alguma parcela já foi paga?",
        //
        save_btn: "SALVAR",
      },
    },
    // ADD IN LOTE
    modal_add_lote: {
      title: {
        p1: "Adicionar",
        p2: "em lote",
      },
      import_file: {
        title: "Importar arquivo",
        download_file: {
          p1: "<b>Faça o download do arquivo modelo</b> e comece a<br />cadastrar seus",
          p2: "com muito mais eficiência.",
        },
        download_btn: "Baixar",
        //
        file_sucess: {
          title: "Arquivo importado com sucesso.",
          cancel_file: "Cancelar arquivo",
        },
        drag_file:
          "Arraste aqui o seu arquivo ou<br /><b>selecione no seu computador</b>",
        file_error: {
          title:
            "<b>Arquivo inválido.</b> Por favor,<br />forneça um arquivo no formato<br />XLSX para prosseguir.",
          drag_file:
            "Arraste aqui um novo arquivo ou<br />selecione no seu computador",
        },
        format:
          "Formato permitido: <b>Excel (XLSX)</b> | Tamanho máximo: <b>15 MB</b>",
      },
      review_dados: {
        title: "Revisar dados",
        error: {
          p1: "erros foram encontrados",
          p2: "Alguns campos obrigatórios podem estar vazios ou existem campos preenchidos incorretamente.",
        },
        alert:
          "<b>Revise os dados importados</b> e se tiver algo errado, volte para a página anterior,<br /> ajuste o arquivo e importe novamente.",
      },
      next_btn: "PRÓXIMO",
      save_btn: "SALVAR",
    },
  },
  // REGISTERS
  registers: {
    title: "Estoque e cadastros",
    filter_types: {
      pdt: "Produtos",
      clt: "Clientes",
      srv: "Serviços",
      disc: "Descontos",
    },
    filter: {
      f1: "Nome Crescente",
      f2: "Nome Decrescente",
      f3: "Estoque Crescente",
      f4: "Estoque Decrescente",
      f5: "Preço Crescente",
      f6: "Preço Decrescente",
    },
    no_itens: {
      p1: "Seus",
      p2: "cadastrados serão mostrados aqui.",
    },
    title_registers: "cadastrados", // en: title_registers + type
    add_btn: {
      title: "NOVO",
      add_lote: {
        p1: "Adicionar",
        p2: "em lote",
      },
      add_manual: {
        p1: "Adicionar",
        p2: "manualmente",
      },
    },
    export_btn: "EXPORTAR ESTOQUE",
    resume_stock: {
      title: "Resumo do estoque",
      qty_pdt: "Quantidade de Produtos",
      qty_stock: "Quantidade atual do estoque",
      value_stock: "Valor atual do estoque",
      based_price_cost: "(Baseado no preço de custo dos produtos)",
      value_stock_sale: "Valor atual de venda do estoque",
      based_price_salwe: "(Baseado no preço de venda dos produtos)",
    },
    sales_clients: {
      no_click: "Clique no cliente e veja as vendas dele aqui.",
      title: "Vendas por clientes",
      total_sales: "Número total de vendas",
      total_sold: "Total vendido",
      sale_made: "Venda realizada em",
      no_itens: "Nenhuma venda encontrada para esse cliente.",
    },
  },
  // TYPES
  services: {
    btn: {
      no: "NÃO",
      yes: "SIM",
    },
    modal_new: "NOVO",
    inputs: {
      required_fields: "Preencha os campos requeridos",
      no_item: "Não preenchido",
      search: "Pesquisar",
      name: "Nome", // *
      qty: "Quantidade",
      price_cost: "Preço de custo",
      price_sale: "Preço de venda", // *
      stock_min: "Estoque mínimo",
      description: "Descrição",
      phone: "Fone",
      email: "E-mail",
      comment: "Comentários",
    },
    modal_remove: {
      title: "Excluir", // type
      ask: "Você tem certeza que quer excluir o", // ?
      ask2: "Você tem certeza que quer excluir essa", // ?
    },
    // PRODUTO
    product: {
      title: "Produtos",
      title_min: "Produto",
      value: "Valor",
      stock: "Estoque",
      // ADD
      modal_add: {
        stock_min_value: "Qual é o estoque mínimo?",
        other_stock: "OUTRO",
        photo: "Foto",
        save_btn: "SALVAR",
      },
      // STOCK
      modal_stock: {
        title: "Estoque mínimo",
      },
    },
    // CLIENT
    client: {
      title: "Clientes",
      title_min: "Cliente",
      telephone: "Telefone",
    },
    // SERVICE
    service: {
      title: "Serviços",
      title_min: "Serviço",
      value: "Valor",
    },
    // CATEGORIE
    categories: {
      title: "Categorias",
      title_min: "Categoria",
    },
    // DISCOUNT
    discount: {
      title: "Descontos",
      title_min: "Desconto",
      qty: "Quantidade",
      // ADD
      modal_add: {
        type: "Qual é o tipo do desconto?",
        money: "Em dinheiro",
        porcent: "Em porcentagem",
        input_name: "Nome do desconto", // *
        value_discount: "Valor do desconto", // *
      },
    },
  },
  //
};
