import React, { useEffect, useState } from "react";
import { IconArrowBack, IconClose } from "../../../assets/icons/icons";
import sucess from "../../../assets/icons/Check/Check.gif";
import sucessParado from "../../../assets/icons/Check/CheckStop.png";
import InputForm from "../../inputs/InputForm";
import { firebase } from "../../../services/Firebase";
import ReLogin from "../../../views/Login/ReLogin";

function ModalChange({
  modalemail,
  setmodalemail,
  modalpassword,
  setmodalpassword,
  setmodalconfig,
}) {
  const [gifanimationsucess, setGifanimationsucess] = useState(true);
  const [pageSucess, setPageSucess] = useState(false);
  const [emailChange, setEmailChange] = useState("");
  const [emailAtual, setEmailAtual] = useState("");

  const [passwordAtual, setPasswordAtual] = useState("");
  const [passwordChange, setPasswordChange] = useState("");
  const [passwordChangeConfirm, setPasswordChangeConfirm] = useState("");

  useEffect(() => {
    if (pageSucess === 2) {
      setTimeout(() => {
        setGifanimationsucess(false);
      }, [800]);
    }
  }, [pageSucess, gifanimationsucess]);

  function isValidEmailAddress(address) {
    return !!address.match(/.+@.+/);
  }

  const changeEmail = (e) => {
    e.preventDefault();

    if (isValidEmailAddress(emailChange)) {
      firebase
        .auth()
        .currentUser.updateEmail(emailChange)
        .then((auth) => {
          setPageSucess(true);
        })
        .catch((erro) => {
          setPageSucess("erro");
        });
    } else {
      alert("O email digitado está INVÁLIDO. Tente novamente.");
    }
  };

  const changePassword = (e) => {
    e.preventDefault();

    if (passwordChange === passwordChangeConfirm) {
      firebase
        .auth()
        .currentUser.updatePassword(passwordChange)
        .then((auth) => {
          setPageSucess(true);
        })
        .catch((erro) => {
          console.log(erro);
          setPageSucess("erro");
        });
    } else {
      alert(
        "A sua NOVA SENHA está DIFERENTE com a senha de CONFIRMAÇÃO. Tente novamente."
      );
    }
  };

  const reset = () => {
    setPageSucess(false);
    setEmailAtual("");
    setPasswordChange("");
    setPasswordChangeConfirm("");
  };

  const PageErro = () => (
    <div className="myne-modal__modal-sucess-publicate myne-modal--animation-left">
      <svg
        style={{ marginTop: 74, marginBottom: 24 }}
        width="55"
        height="54"
        viewBox="0 0 55 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.5 0.375C42.25 0.375 54.125 12.25 54.125 27C54.125 41.75 42.25 53.625 27.5 53.625C12.75 53.625 0.875 41.75 0.875 27C0.875 12.25 12.75 0.375 27.5 0.375ZM27.5 29.625C29 29.625 30.125 28.5 30.125 27V16.375C30.125 14.875 29 13.625 27.5 13.625C26 13.625 24.875 14.875 24.875 16.375V27C24.875 28.5 26 29.625 27.5 29.625ZM30.125 40.375V35H24.875V40.375H30.125Z"
          fill="#FE2543"
        />
      </svg>

      <div>
        <p>Algo deu errado.</p>
        <span>Aguarde alguns instantes e tente novamente.</span>
        <button
          className="myne-btn btn-entrar"
          onClick={() => setPageSucess(false)}
        >
          TENTAR NOVAMENTE
        </button>
      </div>
    </div>
  );

  const back = () => {
    setmodalemail(false);
    setmodalpassword(false);
  };

  const [RElogin, setRELogin] = useState(true);

  return !RElogin ? (
    <>
      {/* CHANGE EMAIL */}
      <div
        style={{
          opacity: modalemail ? "5" : "0",
          visibility: modalemail ? "visible" : "hidden",
          background: "initial",
          zIndex: 999999999,
        }}
        className="myne-modal"
      >
        <div
          style={{
            transform: modalemail ? "translateX(0)" : "translateX(50px)",
            opacity: modalemail ? "5" : "0",
          }}
          className="myne-modal__modal-edit-cadastro"
        >
          {modalemail && (
            <>
              <header className="myne-modal__modal-edit-header">
                <section className="myne-modal__modal-edit-header__left">
                  {!pageSucess ? (
                    <IconArrowBack
                      onClick={() => {
                        setmodalemail(false);
                        setmodalconfig(true);
                      }}
                      style={{ marginRight: 12, cursor: "pointer" }}
                    />
                  ) : (
                    ""
                  )}

                  <p style={{ textTransform: "initial", fontSize: 13 }}>
                    Alterar e-mail
                  </p>
                </section>

                <section className="myne-modal__modal-edit-header__icons">
                  <IconClose
                    onClick={() => {
                      setmodalemail(!modalemail);
                      setmodalconfig(false);
                      reset();
                    }}
                  />
                </section>
              </header>
              {!pageSucess ? (
                <div className="myne-modal__modal-edit-cadastro-content">
                  <div
                    style={{
                      width: "100%",
                      flex: "initial",
                      overflow: "initial",
                    }}
                    className="myne-modal__modal-edit-cadastro-content__inputs"
                  >
                    <div
                      style={{ marginBottom: 16 }}
                      className="myne-modal-import-products-content__area-import-file__form-info"
                    >
                      <IconInfoMin />
                      <p>Digite seu novo e-mail</p>
                    </div>

                    <InputForm
                      value={emailChange}
                      onChange={(e) => setEmailChange(e.target.value)}
                      label={"Novo e-mail"}
                      onKeyDown={(e) => {
                        if (emailChange !== "") {
                          if (e.key === "Enter") {
                            changeEmail(e);
                          }
                        }
                      }}
                    />
                  </div>

                  <div
                    style={{ padding: 0, borderLeft: "initial" }}
                    className="myne-modal__modal-edit-cadastro-content__right-subimit"
                  >
                    {emailChange !== "" ? (
                      <button
                        onClick={changeEmail}
                        style={{ width: 129, height: 44, marginBottom: 4 }}
                      >
                        <p style={{ fontWeight: 500, fontSize: 14 }}>SALVAR</p>
                      </button>
                    ) : (
                      <button
                        style={{
                          width: 129,
                          height: 44,
                          marginBottom: 4,
                          cursor: "not-allowed",
                        }}
                        disabled={true}
                      >
                        <p style={{ fontWeight: 500, fontSize: 14 }}>SALVAR</p>
                      </button>
                    )}
                  </div>
                </div>
              ) : pageSucess === "erro" ? (
                <PageErro />
              ) : (
                <div className="myne-modal__modal-sucess-publicate myne-modal--animation-left">
                  {gifanimationsucess ? (
                    <img
                      style={{ marginTop: 50, marginBottom: 10 }}
                      src={sucess}
                      alt="Sucess"
                    />
                  ) : (
                    <img
                      className="myne-modal__modal-sucess-publicate-sucess-stop myne-modal--animation"
                      style={{
                        marginTop: 50,
                        marginBottom: 10,
                      }}
                      src={sucessParado}
                      alt="Sucess"
                    />
                  )}

                  <div>
                    <p>E-mail alterado com sucesso!</p>
                    <span>
                      A partir de agora, sempre que acessar o <b>Myne</b>,
                      utilize o e-mail <b>{emailChange}</b>.
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {/* CHANGE PASSWORD */}
      <div
        style={{
          opacity: modalpassword ? "5" : "0",
          visibility: modalpassword ? "visible" : "hidden",
          background: "initial",
          zIndex: 999999999,
        }}
        className="myne-modal"
      >
        <div
          style={{
            transform: modalpassword ? "translateX(0)" : "translateX(50px)",
            opacity: modalpassword ? "5" : "0",
          }}
          className="myne-modal__modal-edit-cadastro"
        >
          {modalpassword && (
            <>
              <header className="myne-modal__modal-edit-header">
                <section className="myne-modal__modal-edit-header__left">
                  {!pageSucess ? (
                    <IconArrowBack
                      onClick={() => {
                        setmodalpassword(false);
                        setmodalconfig(true);
                      }}
                      style={{ marginRight: 12, cursor: "pointer" }}
                    />
                  ) : (
                    ""
                  )}

                  <p style={{ textTransform: "initial", fontSize: 13 }}>
                    Alterar senha
                  </p>
                </section>

                <section className="myne-modal__modal-edit-header__icons">
                  <IconClose
                    onClick={() => {
                      setmodalpassword(!modalpassword);
                      setmodalconfig(false);
                      reset();
                    }}
                  />
                </section>
              </header>
              {!pageSucess ? (
                <div className="myne-modal__modal-edit-cadastro-content">
                  <div
                    style={{
                      width: "100%",
                      flex: "initial",
                      overflow: "initial",
                    }}
                    className="myne-modal__modal-edit-cadastro-content__inputs"
                  >
                    <div
                      style={{ marginBottom: 16 }}
                      className="myne-modal-import-products-content__area-import-file__form-info"
                    >
                      <IconInfoMin />
                      <p>
                        Crie uma senha exclusiva de pelo menos seis caracteres.
                      </p>
                    </div>

                    {/* <InputForm
                      value={passwordAtual}
                      onChange={(e) => setPasswordAtual(e.target.value)}
                      label={"Senha atual"}
                    /> */}
                    <InputForm
                      senha={true}
                      value={passwordChange}
                      onChange={(e) => setPasswordChange(e.target.value)}
                      label={"Nova senha (6 a 60 caracteres)"}
                      style={{ marginTop: "15px", marginBottom: -35 }}
                    />
                    <InputForm
                      senha={true}
                      value={passwordChangeConfirm}
                      onChange={(e) => setPasswordChangeConfirm(e.target.value)}
                      label={"Confirme a nova senha"}
                      style={{ marginTop: "15px" }}
                      onKeyDown={(e) => {
                        if (
                          passwordChange !== "" &&
                          passwordChangeConfirm !== ""
                        ) {
                          if (e.key === "Enter") {
                            changePassword(e);
                          }
                        }
                      }}
                    />
                  </div>

                  <div
                    style={{ padding: 0, borderLeft: "initial" }}
                    className="myne-modal__modal-edit-cadastro-content__right-subimit"
                  >
                    {
                      // passwordAtual !== "" &&
                      passwordChange !== "" && passwordChangeConfirm !== "" ? (
                        <button
                          onClick={changePassword}
                          style={{
                            width: 129,
                            height: 44,
                            marginBottom: 4,
                          }}
                        >
                          <p style={{ fontWeight: 500, fontSize: 14 }}>
                            SALVAR
                          </p>
                        </button>
                      ) : (
                        <button
                          style={{
                            width: 129,
                            height: 44,
                            marginBottom: 4,
                            cursor: "not-allowed",
                          }}
                          disabled={true}
                        >
                          <p style={{ fontWeight: 500, fontSize: 14 }}>
                            SALVAR
                          </p>
                        </button>
                      )
                    }
                  </div>
                </div>
              ) : pageSucess === "erro" ? (
                <PageErro />
              ) : (
                <div className="myne-modal__modal-sucess-publicate myne-modal--animation-left">
                  {gifanimationsucess ? (
                    <img
                      style={{ marginTop: 50, marginBottom: 10 }}
                      src={sucess}
                      alt="Sucess"
                    />
                  ) : (
                    <img
                      style={{
                        transform: "initial",
                        marginTop: 50,
                        marginBottom: 10,
                      }}
                      src={sucessParado}
                      alt="Sucess"
                    />
                  )}

                  <div>
                    <p>Senha alterada com sucesso!</p>
                    <span>
                      A partir de agora, sempre que acessar o <b>Myne</b>,
                      utilize a nova senha.
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  ) : modalemail || modalpassword ? (
    <ReLogin
      login={RElogin}
      setlogin={setRELogin}
      email={emailAtual}
      back={back}
      setmodalconfig={setmodalconfig}
      senha={passwordAtual}
      setEmail={setEmailAtual}
      setSenha={setPasswordAtual}
    />
  ) : (
    ""
  );
}

export default ModalChange;

const IconInfoMin = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.34375 3.65625H7.65625V5H6.34375V3.65625ZM6.34375 6.34375H7.65625V10.3438H6.34375V6.34375ZM7 0.34375C3.3125 0.34375 0.34375 3.3125 0.34375 7C0.34375 10.6875 3.3125 13.6562 7 13.6562C10.6875 13.6562 13.6562 10.6875 13.6562 7C13.6562 3.3125 10.6875 0.34375 7 0.34375ZM7 12.3438C4.0625 12.3438 1.65625 9.9375 1.65625 7C1.65625 4.0625 4.0625 1.65625 7 1.65625C9.9375 1.65625 12.3438 4.0625 12.3438 7C12.3438 9.9375 9.9375 12.3438 7 12.3438Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);
