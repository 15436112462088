import React from "react";

const LoadingInModal = () => {
  return (
    <div className="myne-loading-in-modal">
      <div className="myne-circle-loading-2"></div>
    </div>
  );
};

export default LoadingInModal;
