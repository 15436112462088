import InputForm from "../../components/inputs/InputForm";
import IconInfo from "../../assets/icons/EmailInfo.svg";
import { useState } from "react";
import { forgotPasswordAccount } from "../../shared/utils/user/auth/forgotpassword";

export const FormForgotPassword = ({
  setLoading,
  activeLanguage,
  setPageContent,
}) => {
  const [emailRecuperar, setEmailRecuperar] = useState("");
  const [modalMessage, setModalMessage] = useState(false);

  return (
    <section>
      <InputForm
        senha={false}
        value={emailRecuperar}
        type="text"
        onChange={(e) => setEmailRecuperar(e.target.value)}
        label={activeLanguage?.login?.inputs?.email}
        onKeyDown={(e) => {
          if (emailRecuperar) {
            if (e.key === "Enter") {
              e.preventDefault();
              forgotPasswordAccount(
                emailRecuperar,
                setModalMessage,
                setLoading,
                activeLanguage
              );
            }
          }
        }}
      />

      <button
        style={{ textTransform: "initial" }}
        onClick={() =>
          forgotPasswordAccount(
            emailRecuperar,
            setModalMessage,
            setLoading,
            activeLanguage
          )
        }
        className="myne-btn btn-entrar"
      >
        {activeLanguage?.login?.forgot_password_view?.submit}
      </button>

      {modalMessage ? (
        <div className="myne-modal myne-modal--animation">
          <div className="modal-message myne-modal--animation-scale">
            <img src={IconInfo} alt="" />
            <p>
              {activeLanguage?.login?.forgot_password_view?.feedback?.title}{" "}
              <strong
                onClick={() => window.open(`https://mail.google.com/`)}
                style={{ fontWeight: "500", cursor: "pointer" }}
              >
                {emailRecuperar}
              </strong>
            </p>
            <button
              onClick={() => {
                setPageContent("Entrar");
                setEmailRecuperar("");
                setModalMessage(false);
              }}
              className="myne-btn"
            >
              {activeLanguage?.login?.forgot_password_view?.feedback?.submit}
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </section>
  );
};
