import React from "react";
import { useStateValue } from "../../../providers/StateProvider";
import icon_plus from "../../../assets/icons/Plus.gif";
import parse from "html-react-parser";

function ModalPlusWelcome({ modal }) {
  const [{ activeLanguage }] = useStateValue();

  return (
    <div
      style={{
        opacity: modal ? "5" : "0",
        visibility: modal ? "visible" : "hidden",
        zIndex: 999999999,
      }}
      className="myne-modal"
    >
      <div
        style={{
          transform: modal ? "scale(1)" : "scale(0.7)",
        }}
        className="myne-modal__modal-welcome-plus"
      >
        {modal && (
          <>
            <IconClose onClick={() => (window.location.href = "/")} />
            <div className="myne-modal__modal-welcome-plus__content">
              <img
                style={{
                  width: 172,
                  height: 172,
                }}
                src={icon_plus}
                alt="plus"
              />

              <section>
                <IconMynePlus />
                <p>{activeLanguage?.be_plus?.welcome?.title}</p>
                <h5>{parse(activeLanguage?.be_plus?.welcome?.desc)}</h5>
              </section>

              <div className="myne-modal__modal-plus__content-vantagens">
                <article>
                  <IconCheck />
                  <p style={{ width: 130 }}>
                    {activeLanguage?.be_plus?.welcome?.check1}
                  </p>
                </article>
                <article>
                  <IconCheck />
                  <p style={{ width: 165 }}>
                    {activeLanguage?.be_plus?.welcome?.check2}
                  </p>
                </article>
                <article>
                  <IconCheck />
                  <p style={{ width: 103 }}>
                    {activeLanguage?.be_plus?.welcome?.check3}
                  </p>
                </article>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ModalPlusWelcome;

const IconCheck = () => (
  <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 10.1719L15.8906 0.28125C16.0781 0.09375 16.3125 0 16.5938 0C16.875 0 17.1094 0.09375 17.2969 0.28125C17.7344 0.78125 17.7344 1.25 17.2969 1.6875L6.70312 12.2812C6.51562 12.5 6.28125 12.6094 6 12.6094C5.71875 12.6094 5.48438 12.5 5.29688 12.2812L1.125 8.10938C0.9375 7.92188 0.84375 7.6875 0.84375 7.40625C0.84375 7.125 0.9375 6.89062 1.125 6.70312C1.3125 6.51562 1.54688 6.42188 1.82812 6.42188C2.10938 6.42188 2.34375 6.51562 2.53125 6.70312L6 10.1719Z"
      fill="white"
    />
  </svg>
);

const IconClose = ({ ...rest }) => (
  <svg
    {...rest}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM14.3125 14.3125C13.8906 14.6875 13.2812 14.6875 12.9062 14.3125L10 11.4062L7.09375 14.3125C6.71875 14.6875 6.10938 14.6875 5.6875 14.3125C5.3125 13.8906 5.3125 13.2812 5.6875 12.9062L8.59375 10L5.6875 7.09375C5.3125 6.71875 5.3125 6.10938 5.6875 5.6875C6.10938 5.3125 6.71875 5.3125 7.09375 5.6875L10 8.59375L12.9062 5.6875C13.2812 5.3125 13.8906 5.3125 14.3125 5.6875C14.6875 6.10938 14.6875 6.71875 14.3125 7.09375L11.4062 10L14.3125 12.9062C14.6875 13.2812 14.6875 13.8906 14.3125 14.3125Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

const IconMynePlus = () => (
  <svg
    width={516}
    height={291}
    viewBox="0 0 516 291"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M504.553 94.017A153.744 153.744 0 00377.359.747a3.585 3.585 0 00-3.881 3.598v57.58a3.606 3.606 0 003.107 3.549 89.54 89.54 0 0174.676 74.675 3.606 3.606 0 003.549 3.108h57.579a3.587 3.587 0 003.599-3.882 152.283 152.283 0 00-11.435-45.358z"
      fill="url(#paint0_linear_4935_13768)"
    />
    <path
      d="M425.423 216.532a88.513 88.513 0 01-143.449-27.359 88.525 88.525 0 015.993-82.318 88.516 88.516 0 0171.292-41.59 3.61 3.61 0 003.513-3.574V20.582a3.595 3.595 0 00-1.081-2.58 3.585 3.585 0 00-2.604-1.018 136.774 136.774 0 10140.668 140.581 3.589 3.589 0 00-3.599-3.684H455.06a3.682 3.682 0 00-3.599 3.512 88.124 88.124 0 01-26.038 59.139z"
      fill="url(#paint1_linear_4935_13768)"
    />
    <path
      d="M74.544 216.532a88.53 88.53 0 11151.365-62.639h48.257A136.787 136.787 0 0040.448 57.208a137.008 137.008 0 0093.05 233.68 3.596 3.596 0 002.604-1.019 3.576 3.576 0 001.081-2.579v-41.097a3.684 3.684 0 00-3.513-3.598 88.224 88.224 0 01-59.126-26.063z"
      fill="url(#paint2_linear_4935_13768)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4935_13768"
        x1={444.739}
        y1={0.734436}
        x2={444.739}
        y2={143.257}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4935_13768"
        x1={363.008}
        y1={16.9822}
        x2={363.008}
        y2={290.475}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4935_13768"
        x1={137.317}
        y1={16.9349}
        x2={137.317}
        y2={290.89}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
