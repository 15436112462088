import React, { useEffect, useState } from "react";
import { IconRecibo } from "../../components/Cards/ItemVenda";
import { useStateValue } from "../../providers/StateProvider";
import {
  ParcelaEmAtraso,
  strFormatDate,
  userId,
} from "../../providers/reducer";
import { formatPrice } from "../../shared/utils/helpers/format";
import logo from "../../assets/images/LOGOS/logo 1.svg";
import { getCompanieByUserId } from "../../providers/companies";
import ReceiptCustomCard from "../../components/Cards/ReceiptCustomCard";
import "../../styles/Modals/CustomizeReceipt.css";
import parse from "html-react-parser";
import {
  IconClose,
  IconExportPdf,
  IconExportPng,
} from "../../assets/icons/icons";
import ReactToPrint from "react-to-print";
import { exportComponentAsPNG } from "react-component-export-image";

const Receipt = ({
  modalRecibo,
  setModalRecibo,
  printRef,
  status,
  subtotal,
  discount,
  total,
  props,
}) => {
  const [{ activeLanguage, mesesNew, SaleFirestore, activeMoeda }] =
    useStateValue();
  const reciboName = `${
    activeLanguage?.sales?.list_sales?.item_sale?.sale_details?.title_receipt
  }#${props?.id?.substring(0, 6)}`;

  const [companie, setCompanie] = useState(null);
  const [getData, setGetData] = useState(false);
  const [loadImage, setLoadImage] = useState(false);

  useEffect(() => {
    if (modalRecibo) {
      if (!getData) {
        const getDataCompanie = async () => {
          const getCompanie = await getCompanieByUserId(userId());
          if (getCompanie) {
            setCompanie({ id: getCompanie?.id, ...getCompanie?.data() });
          }
        };
        getDataCompanie();
        setGetData(true);
      }
    }
  }, [getData, modalRecibo]);

  const showCustomReceipt =
    companie && companie?.customizedReceipt?.showCustomReceipt;

  return (
    <div
      style={{
        top: modalRecibo ? "" : "100%",
        zIndex: 9999999,
      }}
      className="myne-recibo"
    >
      {modalRecibo && (
        <>
          <IconClose
            style={{
              opacity: modalRecibo ? 1 : 0,
              visibility: modalRecibo ? "visible" : "hidden",
            }}
            onClick={() => {
              setModalRecibo(false);
            }}
          />

          <div
            style={{
              opacity: modalRecibo ? 1 : 0,
              visibility: modalRecibo ? "visible" : "hidden",
            }}
            className="myne-recibo--buttons myne-modal--animation-left"
          >
            <ReactToPrint
              trigger={() => (
                <button
                  className="myne-btn myne-recibo--button"
                  style={{
                    margin: 0,
                  }}
                >
                  <IconExportPdf />
                  {
                    activeLanguage?.sales?.list_sales?.item_sale?.sale_details
                      ?.export?.title
                  }{" "}
                  <b style={{ marginLeft: 4 }}>PDF</b>
                </button>
              )}
              bodyClass="print-body"
              documentTitle={reciboName}
              content={() => printRef.current}
            />

            <button
              className="myne-btn myne-recibo--button"
              style={{
                margin: 0,
              }}
              onClick={() => {
                if (showCustomReceipt) {
                  if (loadImage) {
                    exportComponentAsPNG(printRef, { fileName: reciboName });
                  }
                } else {
                  exportComponentAsPNG(printRef, { fileName: reciboName });
                }
              }}
            >
              <IconExportPng />
              {
                activeLanguage?.sales?.list_sales?.item_sale?.sale_details
                  ?.export?.title
              }{" "}
              <b style={{ marginLeft: 4 }}>PNG</b>
            </button>
          </div>

          {/* RECEIPT REF */}
          <div
            ref={printRef}
            style={{ minHeight: showCustomReceipt ? 981 : "" }}
            className="myne-recibo-content"
          >
            <div
              style={{ paddingBottom: showCustomReceipt ? 32 : "" }}
              className="myne-recibo-content__main"
            >
              {showCustomReceipt && (
                <ReceiptCustomCard
                  setLoadImage={setLoadImage}
                  key={companie?.id}
                  companie={companie}
                />
              )}

              <header>
                <div>
                  <IconRecibo />
                  <p style={{ textTransform: "initial" }}>
                    {
                      activeLanguage?.sales?.list_sales?.item_sale?.sale_details
                        ?.title_receipt
                    }{" "}
                    <b style={{ textTransform: "initial" }}>
                      #{props?.id?.substring(0, 6)}
                    </b>
                  </p>
                </div>

                <p className="myne-text">
                  {
                    activeLanguage?.sales?.list_sales?.item_sale?.sale_details
                      ?.recibo?.sale_date
                  }{" "}
                  {strFormatDate(props.date.toDate(), mesesNew)}
                </p>
              </header>

              <div className="myne-recibo-content__main--info">
                <section>
                  <p>
                    {
                      activeLanguage?.sales?.list_sales?.item_sale?.sale_details
                        ?.recibo?.infos_sale?.title
                    }
                  </p>
                  <h5>{props?.client?.name}</h5>
                </section>
                <section>
                  <p>
                    {
                      activeLanguage?.sales?.list_sales?.item_sale?.sale_details
                        ?.recibo?.infos_sale?.title_payment_sale
                    }
                  </p>
                  <h5>
                    {props?.installments?.length > 1
                      ? activeLanguage?.sales?.list_sales?.item_sale?.status
                          ?.installments
                      : activeLanguage?.sales?.list_sales?.item_sale?.status
                          ?.cash}
                    {props?.installments?.length > 1
                      ? `, ${props?.installments?.length}x`
                      : ""}
                    {/* Dinheiro */}
                  </h5>
                </section>
                <section>
                  <p>
                    {
                      activeLanguage?.sales?.list_sales?.item_sale?.sale_details
                        ?.recibo?.infos_sale?.title_status_sale
                    }
                  </p>
                  <h5>
                    {props?.installments?.length > 1 &&
                    status === SaleFirestore.Paid
                      ? activeLanguage?.sales?.list_sales?.item_sale?.status
                          ?.paid_day
                      : status === SaleFirestore.Paid ||
                        props?.installments?.paid
                      ? activeLanguage?.sales?.list_sales?.item_sale?.status
                          ?.paid
                      : status === SaleFirestore.Overdue
                      ? activeLanguage?.sales?.list_sales?.item_sale?.status
                          ?.overdue
                      : "-"}
                  </h5>
                </section>
              </div>

              <div className="myne-recibo-content__main--itens">
                {props?.data?.productSales?.length > 0 ? (
                  <div className="myne-recibo-content__main--table">
                    <div className="myne-recibo-content__main--table-titles">
                      <p style={{ width: 175, marginRight: 0 }}>
                        {
                          activeLanguage?.sales?.list_sales?.item_sale
                            ?.sale_details?.recibo?.tables?.title_pdt
                        }
                      </p>
                      <p style={{ width: 74, marginRight: 32 }}>
                        {
                          activeLanguage?.sales?.list_sales?.item_sale
                            ?.sale_details?.recibo?.tables?.qty
                        }
                      </p>
                      <p style={{ width: 86, marginRight: 32 }}>
                        {
                          activeLanguage?.sales?.list_sales?.item_sale
                            ?.sale_details?.recibo?.tables?.price
                        }
                      </p>
                      <p style={{ width: 82, marginRight: 0 }}>
                        {
                          activeLanguage?.sales?.list_sales?.item_sale
                            ?.sale_details?.recibo?.tables?.total
                        }
                      </p>
                    </div>
                    <div className="myne-recibo-content__main--table-itens">
                      {props?.data?.productSales?.map((item, i) => (
                        <div
                          key={item?.product?.name + i}
                          className="myne-recibo-content__main--table-itens-item"
                        >
                          <p style={{ width: 175, marginRight: 0 }}>
                            {item?.product?.name}
                          </p>
                          <p style={{ width: 74, marginRight: 32 }}>
                            {item?.amount}
                          </p>
                          <p style={{ width: 86, marginRight: 32 }}>
                            {formatPrice(item?.product?.salePrice, activeMoeda)}
                          </p>
                          <p style={{ width: 82, marginRight: 0 }}>
                            {formatPrice(
                              item?.amount * item?.product?.salePrice,
                              activeMoeda
                            )}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {props?.data?.serviceSales?.length > 0 ? (
                  <div className="myne-recibo-content__main--table">
                    <div className="myne-recibo-content__main--table-titles">
                      <p style={{ width: 175, marginRight: 0 }}>
                        {
                          activeLanguage?.sales?.list_sales?.item_sale
                            ?.sale_details?.recibo?.tables?.title_srv
                        }
                      </p>
                      <p style={{ width: 74, marginRight: 32 }}>
                        {
                          activeLanguage?.sales?.list_sales?.item_sale
                            ?.sale_details?.recibo?.tables?.qty
                        }
                      </p>
                      <p style={{ width: 86, marginRight: 32 }}>
                        {
                          activeLanguage?.sales?.list_sales?.item_sale
                            ?.sale_details?.recibo?.tables?.price
                        }
                      </p>
                      <p style={{ width: 82, marginRight: 0 }}>
                        {
                          activeLanguage?.sales?.list_sales?.item_sale
                            ?.sale_details?.recibo?.tables?.total
                        }
                      </p>
                    </div>
                    <div className="myne-recibo-content__main--table-itens">
                      {props?.data?.serviceSales?.map((item, i) => (
                        <div
                          key={item?.service?.name + i}
                          className="myne-recibo-content__main--table-itens-item"
                        >
                          <p style={{ width: 175, marginRight: 0 }}>
                            {item?.service?.name}
                          </p>
                          <p style={{ width: 74, marginRight: 32 }}>
                            {item?.amount ? item?.amount : "-"}
                          </p>
                          <p style={{ width: 86, marginRight: 32 }}>
                            {formatPrice(item?.service?.salePrice, activeMoeda)}
                          </p>
                          <p style={{ width: 82, marginRight: 0 }}>
                            {formatPrice(item?.total, activeMoeda)}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="myne-recibo-content__main--table">
                  <div
                    style={{ border: "none" }}
                    className="myne-recibo-content__main--table-titles"
                  >
                    <p style={{ width: 175, marginRight: 0 }}>
                      {
                        activeLanguage?.sales?.list_sales?.item_sale
                          ?.sale_details?.recibo?.tables?.checkout?.subtotal
                      }
                    </p>
                    <h5>{formatPrice(subtotal, activeMoeda)}</h5>
                  </div>
                  <div className="myne-recibo-content__main--table-titles">
                    <p style={{ width: 175, marginRight: 0 }}>
                      {
                        activeLanguage?.sales?.list_sales?.item_sale
                          ?.sale_details?.recibo?.tables?.checkout?.discount
                      }
                    </p>
                    <h5>{formatPrice(discount, activeMoeda)}</h5>
                  </div>
                  <div className="myne-recibo-content__main--table-itens">
                    <div className="myne-recibo-content__main--table-itens-item">
                      <h5 style={{ width: 175, marginRight: 0 }}>
                        {
                          activeLanguage?.sales?.list_sales?.item_sale
                            ?.sale_details?.recibo?.tables?.checkout?.total_sale
                        }
                      </h5>
                      <b>{formatPrice(total, activeMoeda)}</b>
                    </div>
                  </div>
                </div>
              </div>

              <div className="myne-recibo-content__main--info_parcelamento">
                <p>
                  {
                    activeLanguage?.sales?.list_sales?.item_sale?.sale_details
                      ?.recibo?.installment_infos?.title
                  }
                </p>
                <div className="myne-recibo-content__main--table">
                  <div className="myne-recibo-content__main--table-titles">
                    <p style={{ width: 47, marginRight: 48.67 }}>
                      {
                        activeLanguage?.sales?.list_sales?.item_sale
                          ?.sale_details?.recibo?.installment_infos?.table?.t1
                      }
                    </p>
                    <p style={{ width: 96, marginRight: 48.67 }}>
                      {
                        activeLanguage?.sales?.list_sales?.item_sale
                          ?.sale_details?.recibo?.installment_infos?.table?.t2
                      }
                    </p>
                    <p style={{ width: 96, marginRight: 48.67 }}>
                      {
                        activeLanguage?.sales?.list_sales?.item_sale
                          ?.sale_details?.recibo?.installment_infos?.table?.t3
                      }
                    </p>
                    <p style={{ width: 96, marginRight: 0 }}>
                      {
                        activeLanguage?.sales?.list_sales?.item_sale
                          ?.sale_details?.recibo?.installment_infos?.table?.t4
                      }
                    </p>
                  </div>
                  <div className="myne-recibo-content__main--table-itens">
                    {props?.installments
                      ?.sort((installment) => installment?.index)
                      ?.map((installment, i) => (
                        <div
                          key={installment?.index}
                          className="myne-recibo-content__main--table-itens-item"
                        >
                          <p
                            style={{
                              width: 63,
                              marginRight: 43.33,
                              color: ParcelaEmAtraso(installment)
                                ? "#FE2543"
                                : "",
                            }}
                          >
                            {installment?.index}
                          </p>
                          <p
                            style={{
                              width: 96,
                              marginRight: 43.33,
                              color: ParcelaEmAtraso(installment)
                                ? "#FE2543"
                                : "",
                            }}
                          >
                            {(
                              "0" + installment?.payday?.toDate().getDate()
                            ).slice(-2) +
                              "/" +
                              (
                                "0" +
                                (installment?.payday?.toDate().getMonth() + 1)
                              ).slice(-2) +
                              "/" +
                              installment?.payday?.toDate().getFullYear()}
                          </p>
                          <p
                            style={{
                              width: 96,
                              marginRight: 43.33,
                              color: ParcelaEmAtraso(installment)
                                ? "#FE2543"
                                : "",
                            }}
                          >
                            {installment?.status === SaleFirestore.Paid
                              ? activeLanguage?.sales?.list_sales?.item_sale
                                  ?.status?.paid
                              : ParcelaEmAtraso(installment)
                              ? activeLanguage?.sales?.list_sales?.item_sale
                                  ?.status?.overdue
                              : "-"}
                          </p>
                          <p
                            style={{
                              color: ParcelaEmAtraso(installment)
                                ? "#FE2543"
                                : "",
                              width: 96,
                              marginRight: 0,
                            }}
                          >
                            {formatPrice(installment?.value, activeMoeda)}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <footer
              className={
                showCustomReceipt ? "myne-recibo--footer-companie" : ""
              }
            >
              {showCustomReceipt ? (
                <div className="myne-recibo--footer-companie--info">
                  <img
                    className="myne-recibo-custom-card--companie__image"
                    src={companie?.logo}
                    onLoad={() => setLoadImage(true)}
                    alt="Brand companie"
                  />
                  <p className="myne-text">
                    {companie?.customizedReceipt?.footer}
                  </p>
                </div>
              ) : (
                <p id={`recibo-footer-title-${props?.id}`}>
                  {parse(
                    activeLanguage?.sales?.list_sales?.item_sale?.sale_details
                      ?.recibo?.footer?.title
                  )}
                </p>
              )}

              <img src={logo} alt="logo-myne" />
            </footer>
          </div>
        </>
      )}
    </div>
  );
};

export default Receipt;
