import React, { useEffect, useState } from "react";
import {
  COPY,
  getDataUser,
  handleCpfCnpjChange,
  phoneMask,
  updatedAt,
} from "../../../providers/reducer";
import InputForm from "../../inputs/InputForm";
import InputFormDigitWhatsapp from "../../inputs/InputFormDigitWhatsapp";

import { cpf, cnpj } from "cpf-cnpj-validator";
import {
  IconArrowBack,
  IconCheck,
  IconCheckBox,
  IconCheckBoxChecked,
  IconCopy,
  IconOpenNewPage,
  IconRemove2,
  IconSecurityIlustre,
} from "../../../assets/icons/icons";
import parse from "html-react-parser";

import sucess from "../../../assets/icons/Check/Check.gif";
import sucessParado from "../../../assets/icons/Check/CheckStop.png";

import {
  addStore,
  alreadyHasStore,
  removeStore,
} from "../../../providers/catalogo";
import Tooltip from "../../Tooltip/tooltip";
import { useStateValue } from "../../../providers/StateProvider";
import toast from "react-hot-toast";
import LoadingInButton from "../../Loading/LoadingInButton";
import LoadingInModal from "../../Loading/LoadingInModal";

const ModalConfigureCatalogo = ({
  modal,
  setmodal,
  setGifanimationsucess,
  gifanimationsucess,
}) => {
  const [{ activeLanguage }] = useStateValue();
  const [pageStep, setPageStep] = useState(1);

  const [cpfCnpj, setcpfCnpjValue] = useState("");
  const [storeName, setStoreName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [numberWhatsapp, setNumberWhatsapp] = useState("");
  const [numberDigit, setNumberDigit] = useState("");
  const [isValidCpfCnpj, setIsValidCpfCnpj] = useState(false);
  const [isvalidCpfCnpjType, setIsValidCpfCnpjType] = useState("");

  const [loading, setLoading] = useState(false);

  const verifiedShop = async () => {
    setLoading(true);
    const userFirestore = await getDataUser();
    if (userFirestore?.storeName) {
      setPageStep(3);
      setStoreName(userFirestore?.storeName);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (modal) {
      verifiedShop();
    }
  }, [modal]);

  useEffect(() => {
    if (cpfCnpj !== "" && modal) {
      if (cpfCnpj.length > 14) {
        setIsValidCpfCnpj(cnpj.isValid(cpfCnpj));
        setIsValidCpfCnpjType("cnpj");
      } else {
        setIsValidCpfCnpj(cpf.isValid(cpfCnpj));
        setIsValidCpfCnpjType("cpf");
      }
    }
  }, [cpfCnpj, modal]);

  const [checkTerms, setCheckTerms] = useState(false);

  const add = async () => {
    setLoading(true);
    if (checkTerms) {
      const userFirestore = await getDataUser();

      const store = {
        ownerName,
        storeName,
        storeLink: `${storeName}.myneapp.shop`,
        whatsApp: `${numberDigit} ${numberWhatsapp}`,
        email: userFirestore?.email,
        currencyId: userFirestore?.preference?.currencyId ?? "BRL",
        currencySymbol: userFirestore?.preference?.currencySymbol ?? "R$",
        language: "pt",
        updatedAt,
      };

      const lang = navigator.language || navigator.userLanguage;

      if (lang !== "pt-BR" && lang !== "es") {
        store.language = "en";
      } else {
        store.language = lang === "pt-BR" ? "pt" : lang;
      }

      await addStore(store, cpfCnpj);
      setPageStep(3);
      setGifanimationsucess(true);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pageStep === 3 && modal) {
      setTimeout(() => {
        setGifanimationsucess(false);
      }, [1000]);
    }
  }, [pageStep, gifanimationsucess, modal, setGifanimationsucess]);

  return (
    <div
      style={{
        opacity: modal ? "5" : "0",
        visibility: modal ? "visible" : "hidden",
      }}
      className="myne-modal"
    >
      <div
        style={{ transform: modal ? "scale(1)" : "scale(0.7)" }}
        className="myne-modal__modal-edit-cadastro"
      >
        {modal && (
          <>
            <header className="myne-modal__modal-edit-header">
              <section className="myne-modal__modal-edit-header__left">
                {pageStep === 2 ? (
                  <IconArrowBack
                    onClick={() => {
                      setPageStep(1);
                    }}
                    style={{ marginRight: 12, cursor: "pointer" }}
                  />
                ) : (
                  ""
                )}
                <p style={{ textTransform: "initial", fontSize: 13 }}>
                  {activeLanguage?.options?.catalog_online?.title}
                </p>
              </section>

              <section className="myne-modal__modal-edit-header__icons">
                <IconClose onClick={() => setmodal(!modal)} />
              </section>
            </header>

            {loading ? (
              <LoadingInModal />
            ) : pageStep === 1 ? (
              <div className="myne-modal__modal-edit-cadastro-content">
                <div
                  style={{
                    width: "100%",
                    flex: "initial",
                    overflow: "initial",
                  }}
                  className="myne-modal__modal-edit-cadastro-content__inputs"
                >
                  <section>
                    <IconCatalogoIlustre />
                    <p>Vamos configurar seu catálogo online!</p>
                  </section>
                  <InputForm
                    value={storeName}
                    onChange={(e) => {
                      const newValue = e.target.value?.replace(
                        /[^a-zA-Z1-9\s]/,
                        ""
                      );
                      setStoreName(
                        newValue?.toString()?.replaceAll(" ", "")?.toLowerCase()
                      );
                    }}
                    label={"Nome da loja*"}
                  />
                  <InputForm
                    value={cpfCnpj}
                    onChange={(e) => handleCpfCnpjChange(e, setcpfCnpjValue)}
                    label={"CPF ou CNPJ*"}
                    type="text"
                  />
                  <InputFormDigitWhatsapp
                    value={numberWhatsapp}
                    marginTop={numberDigit !== "" ? 20 : ""}
                    valueDigit={numberDigit}
                    type={"text"}
                    setNumberDigit={setNumberDigit}
                    onChange={(e) =>
                      setNumberWhatsapp(phoneMask(e.target.value))
                    }
                  />
                  <InputForm
                    value={ownerName}
                    onChange={(e) => setOwnerName(e.target.value)}
                    label={"Seu nome*"}
                  />
                </div>

                <div
                  style={{
                    width: 220,
                    maxWidth: 220,
                    minWidth: 220,
                    flex: "initial",
                  }}
                  className="myne-modal__modal-edit-cadastro-content__right-subimit"
                >
                  <div className="myne-modal__modal-info-card">
                    <IconInfo />
                    <p>
                      O <b>nome, CPF/CNPJ e WhatsApp</b> não serão exibidos no
                      seu catálogo online. Esses dados servem apenas para
                      validar sua conta e preserlet sua privacidade.
                    </p>
                  </div>

                  {storeName?.length > 2 &&
                  numberDigit !== "" &&
                  numberWhatsapp !== "" &&
                  ownerName !== "" &&
                  cpfCnpj !== "" ? (
                    <button
                      onClick={async () => {
                        if (isValidCpfCnpj) {
                          if (await alreadyHasStore(storeName)) {
                            setPageStep(2);
                          } else {
                            alert(
                              "Já existe esse nome de loja cadastrado. Tente outro nome."
                            );
                          }
                        } else {
                          alert(`Insira um ${isvalidCpfCnpjType} válido`);
                        }
                      }}
                      style={{ width: 129, height: 44, marginBottom: 4 }}
                    >
                      <p style={{ fontWeight: 500, fontSize: 14 }}>PRÓXIMO</p>
                    </button>
                  ) : (
                    <button
                      style={{
                        width: 129,
                        height: 44,
                        marginBottom: 4,
                        cursor: "not-allowed",
                      }}
                      disabled={true}
                    >
                      <p style={{ fontWeight: 500, fontSize: 14 }}>PRÓXIMO</p>
                    </button>
                  )}
                </div>
              </div>
            ) : pageStep === 2 ? (
              <div className="myne-modal__modal-accept-terms myne-modal--animation-left">
                <section className="myne-modal__modal-accept-terms__info">
                  <IconSecurityIlustre />
                  <p>Segurança e condições de uso do catálogo online</p>
                </section>

                <div className="myne-modal__modal-accept-terms_checks">
                  <section>
                    <IconCheck />
                    <p>
                      Eu confirmo que todas as informações de cadastro são
                      minhas e verdadeiras.
                    </p>
                  </section>
                  <section>
                    <IconCheck />
                    <p>Que não estou vendendo produtos ilícitos.</p>
                  </section>
                  <section>
                    <IconCheck />
                    <p>
                      Que não pratico fraudes (receber o dinheiro e não entregar
                      o produto).
                    </p>
                  </section>
                  <section>
                    <IconCheck />
                    <p>
                      Concordo com os{" "}
                      <a
                        target="_blank"
                        href="https://web.myneapp.com.br/privacy"
                        rel="noreferrer"
                      >
                        Termos de uso
                      </a>{" "}
                      e{" "}
                      <a
                        target="_blank"
                        href="https://web.myneapp.com.br/privacy"
                        rel="noreferrer"
                      >
                        Política de privacidade.
                      </a>
                    </p>
                  </section>
                </div>
                <div className="myne-modal__modal-accept-terms_btns">
                  <div onClick={() => setCheckTerms(!checkTerms)}>
                    {!checkTerms ? <IconCheckBox /> : <IconCheckBoxChecked />}
                    <p>Confirmo todas as informações acima.</p>
                  </div>
                  {loading ? (
                    <button>
                      <LoadingInButton />
                    </button>
                  ) : (
                    <button onClick={add} disabled={!checkTerms}>
                      Publicar meu catálogo
                    </button>
                  )}
                </div>
              </div>
            ) : pageStep === 3 ? (
              <div className="myne-modal__modal-sucess-publicate myne-modal--animation-left">
                {gifanimationsucess ? (
                  <img src={sucess} alt="Sucess" />
                ) : (
                  <img
                    className="myne-modal__modal-sucess-publicate-sucess-stop myne-modal--animation"
                    src={sucessParado}
                    alt="Sucess"
                  />
                )}

                <div>
                  <p>
                    {activeLanguage?.options?.catalog_online?.publicate?.title}
                  </p>
                  <span>
                    {parse(
                      activeLanguage?.options?.catalog_online?.publicate
                        ?.description
                    )}
                  </span>
                  <div>
                    <p>{`${storeName}.myneapp.shop`}</p>{" "}
                    <Tooltip
                      icon={
                        <IconCopy
                          onClick={() => COPY(`${storeName}.myneapp.shop`)}
                        />
                      }
                      text={activeLanguage?.copy_link}
                    />
                  </div>
                </div>

                <button
                  style={{ marginTop: "10px" }}
                  className="myne-btn-border"
                  onClick={() =>
                    window.open(`https://${storeName}.myneapp.shop/`)
                  }
                >
                  <IconOpenNewPage />
                  {
                    activeLanguage?.options?.catalog_online?.publicate
                      ?.view_catalog
                  }
                </button>
                <button
                  style={{ marginTop: "5px" }}
                  className="myne-btn-border"
                  onClick={() => {
                    if (
                      window.confirm(
                        activeLanguage?.options?.catalog_online?.publicate
                          ?.ask_delete
                      )
                    ) {
                      setmodal(false);
                      removeStore(storeName);
                      toast.success(
                        activeLanguage?.options?.catalog_online?.publicate
                          ?.sucess_delete
                      );
                      setPageStep(1);
                    }
                  }}
                >
                  <IconRemove2 />{" "}
                  {
                    activeLanguage?.options?.catalog_online?.publicate
                      ?.delete_catalog
                  }
                </button>
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ModalConfigureCatalogo;

const IconCatalogoIlustre = () => (
  <svg
    width="64"
    height="44"
    viewBox="0 0 64 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.625 8.625C10.625 7.25 11.875 6 13.375 6H56C57.5 6 58.625 4.75 58.625 3.375C58.625 1.875 57.5 0.625 56 0.625H10.625C7.75 0.625 5.375 3.125 5.375 6V35.375H4C1.75 35.375 0 37.125 0 39.375C0 41.5 1.75 43.375 4 43.375H37.375V35.375H10.625V8.625ZM61.375 11.375H45.375C43.875 11.375 42.625 12.5 42.625 14V40.625C42.625 42.125 43.875 43.375 45.375 43.375H61.375C62.75 43.375 64 42.125 64 40.625V14C64 12.5 62.75 11.375 61.375 11.375ZM58.625 35.375H48V16.625H58.625V35.375Z"
      fill="#AA00FF"
    />
  </svg>
);

const IconInfo = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM10 15.0156C9.4375 15.0156 9.01562 14.5469 9.01562 13.9844V10C9.01562 9.4375 9.4375 9.01562 10 9.01562C10.5625 9.01562 10.9844 9.4375 10.9844 10V13.9844C10.9844 14.5469 10.5625 15.0156 10 15.0156ZM10.9844 7H9.01562V4.98438H10.9844V7Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

const IconClose = ({ ...rest }) => (
  <svg
    {...rest}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM14.3125 14.3125C13.8906 14.6875 13.2812 14.6875 12.9062 14.3125L10 11.4062L7.09375 14.3125C6.71875 14.6875 6.10938 14.6875 5.6875 14.3125C5.3125 13.8906 5.3125 13.2812 5.6875 12.9062L8.59375 10L5.6875 7.09375C5.3125 6.71875 5.3125 6.10938 5.6875 5.6875C6.10938 5.3125 6.71875 5.3125 7.09375 5.6875L10 8.59375L12.9062 5.6875C13.2812 5.3125 13.8906 5.3125 14.3125 5.6875C14.6875 6.10938 14.6875 6.71875 14.3125 7.09375L11.4062 10L14.3125 12.9062C14.6875 13.2812 14.6875 13.8906 14.3125 14.3125Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);
