import React from "react";

// import Image from "./DefaultPhoto.jpeg";
import Image from "./svgnull.svg";

function Photo(props) {
  return props?.image === null ||
    props?.image === undefined ||
    props?.image === "" ? (
    <img src={Image} style={props.style} alt="" />
  ) : (
    <img
      style={{ ...props.style }}
      src={Image}
      onLoad={(e) => {
        e.target.src = props?.image;
      }}
      alt=""
    />
  );
}

export default Photo;
