import React, { useEffect, useState } from "react";
import { auth, db } from "../../services/Firebase";
import { IconAddCircle } from "../../assets/icons/icons";
import ModalNewCategorie from "../Modals/Categorie/ModalNewCategorie";
import { Draggable } from "../Draggable/Draggable";
import { strAllCategoriesProducts } from "../../providers/reducer";

function Categories({
  setLoading,
  selectCategories,
  setSelectCategories,
  padding,
  setCheckModal,
}) {
  const [categories, setCategories] = useState([]);
  // GET CATEGORIES
  useEffect(() => {
    const fecthcategories = () => {
      db.collection("users")
        .doc(auth?.currentUser?.uid)
        ?.collection("categories")
        .onSnapshot((snapshot) => {
          setCategories(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              ...{ ...doc.data(), id: doc.id },
            }))
          );
        });
    };
    fecthcategories();
  }, []);

  // used in version old
  // const removeCategorie = (categorie) => {
  //   const filter = selectCategories?.filter((cate) => cate !== categorie);

  //   setSelectCategories(filter);
  // };

  const existCategorie = (categorie) => {
    // return selectCategories.includes(categorie);
    return (
      selectCategories?.id === categorie?.id &&
      selectCategories?.name === categorie?.name
    );
  };

  const [modalNewCategorie, setModalNewCategorie] = useState(false);

  useEffect(() => {
    if (setCheckModal) {
      setCheckModal(modalNewCategorie);
    }
  }, [modalNewCategorie, setCheckModal]);

  return (
    <>
      <div
        style={{ paddingLeft: padding === "0" ? 0 : "" }}
        className="dashboard-content__grid-vendas__vendas-categories"
      >
        <Draggable id="myne-filter-dates-ref" className="myne-filter-dates">
          <button
            id="btn-calendar"
            style={{
              fontWeight:
                selectCategories[0] === strAllCategoriesProducts
                  ? "500"
                  : "",
              border:
                selectCategories[0] === strAllCategoriesProducts
                  ? "1px solid #aa00ff"
                  : "",
              color:
                selectCategories[0] === strAllCategoriesProducts
                  ? "#aa00ff"
                  : "",
              background:
                selectCategories[0] === strAllCategoriesProducts
                  ? "#faefff"
                  : "",
            }}
            onClick={() => {
              setSelectCategories([strAllCategoriesProducts]);
            }}
          >
            Todos
          </button>

          <button
            id="btn-calendar"
            onClick={() => {
              setModalNewCategorie(true);
            }}
          >
            Nova categoria <IconAddCircle style={{ marginLeft: 8 }} />
          </button>

          {categories?.map((item, i) => (
            <button
              key={item?.name}
              id="btn-calendar"
              style={{
                fontWeight: existCategorie(item) ? "500" : "",
                border: existCategorie(item) ? "1px solid #aa00ff" : "",
                color: existCategorie(item) ? "#aa00ff" : "",
                background: existCategorie(item) ? "#faefff" : "",
              }}
              onClick={() => {
                // removeCategorie(strAllCategoriesProducts);

                if (existCategorie(item)) {
                  // removeCategorie(item);
                  // if (selectCategories?.length === 1) {
                  //   setSelectCategories([strAllCategoriesProducts]);
                  // }
                } else {
                  // setSelectCategories((i) => [...i, item?.name]);
                  setSelectCategories(item);
                }
              }}
            >
              {item?.name}
            </button>
          ))}
        </Draggable>
      </div>

      <ModalNewCategorie
        modal={modalNewCategorie}
        setmodal={setModalNewCategorie}
        categories={categories}
        setmodalback={setModalNewCategorie}
        // backIcon
      />
    </>
  );
}

export default Categories;
