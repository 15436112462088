import IconBrasil from "./Brazil (BR).svg";
import IconArgentina from "./Argentina (AR).svg";
import IconColombia from "./Colombia (CO).svg";
import IconChile from "./Chile (CL).svg";
import IconEquador from "./Ecuador (EC).svg";
import IconVenezuela from "./Venezuela (Bolivarian Republic of) (VE).svg";
import IconPeru from "./Peru (PE).svg";
import IconUruguai from "./Uruguay (UY).svg";
import IconSuriname from "./Suriname (SR).svg";
import IconBolivia from "./Bolivia (Plurinational State of) (BO).svg";
import IconAruba from "./Aruba (AW).svg";
import IconParaguai from "./Paraguay (PY).svg";
import IconTrindadeeTobago from "./Trinidad and Tobago (TT).svg";
import IconGuianaFrancesa from "./French Guiana (GF).svg";
import IconMexico from "./Mexico (MX).svg";
import IconOutro from "./Avatar.svg";

export const countries = [
  {
    name: "Brasil",
    digit: "+55",
    Icon: IconBrasil,
  },
  {
    name: "Argentina",
    digit: "+54",
    Icon: IconArgentina,
  },
  {
    name: "Colombia",
    digit: "+57",
    Icon: IconColombia,
  },
  {
    name: "Chile",
    digit: "+56",
    Icon: IconChile,
  },
  {
    name: "Equador",
    digit: "+593",
    Icon: IconEquador,
  },
  {
    name: "Venezuela",
    digit: "+58",
    Icon: IconVenezuela,
  },
  {
    name: "Peru",
    digit: "+51",
    Icon: IconPeru,
  },
  {
    name: "Uruguai",
    digit: "+598",
    Icon: IconUruguai,
  },
  {
    name: "Suriname",
    digit: "+597",
    Icon: IconSuriname,
  },
  {
    name: "Bolívia",
    digit: "+591",
    Icon: IconBolivia,
  },
  {
    name: "Aruba",
    digit: "+297",
    Icon: IconAruba,
  },
  {
    name: "Paraguai",
    digit: "+595",
    Icon: IconParaguai,
  },
  {
    name: "Trinidade e Tobago",
    digit: "+1",
    Icon: IconTrindadeeTobago,
  },
  {
    name: "Guiana Francesa",
    digit: "+594",
    Icon: IconGuianaFrancesa,
  },
  {
    name: "México",
    digit: "+52",
    Icon: IconMexico,
  },
  {
    name: "Outro",
    digit: "",
    Icon: IconOutro,
  },
];
