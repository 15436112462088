import React, { useState, useEffect } from "react";

//
import { IconButton } from "@material-ui/core";
//
import { FcGoogle } from "react-icons/fc";
//
import "../../styles/Login/Login.css";
import "../../styles/Buttons/index.css";
//
import ilustracao from "../../assets/images/ILUSTRATORS/ilustracao 1.svg";

//
import InputForm from "../../components/inputs/InputForm";

//
import { useHistory } from "react-router-dom";
import { analyticsEvent } from "../../services/analytics";
import { useStateValue } from "../../providers/StateProvider";

// auth
import { auth } from "../../services/Firebase";
import {
  setFirebaseAuthPersistence,
  signAccountGoogle,
} from "../../shared/utils/user/auth/login";
import { FormForgotPassword } from "./FormForgotPassword";
import LoadingInButton from "../../components/Loading/LoadingInButton";

function Login() {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [senhaNot, setSenhaNot] = useState(false);
  const [pageContent, setPageContent] = useState("Entrar");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [textErro, setTextErro] = useState("Preencha todos os campos!");

  const [{ activeLanguage }] = useStateValue();

  const entrarGoogle = (e) => {
    e.preventDefault();

    signAccountGoogle(history, "SIGN_IN", (e) => {
      setSenhaNot(true);
      setTextErro(`${e.code} ${e.message}`);
    });
    // firebase
    //   .auth()
    //   .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    //   .then(() => {
    //     auth
    //       .signInWithPopup(providerGoogle)
    //       .then((authUser) => {
    //         db.collection("users")
    //           .doc(authUser?.user?.uid)
    //           .onSnapshot((snapshot) => {
    //             if (snapshot.exists) {
    //               history.push("/");
    //             } else {
    //               db.collection("users")
    //                 .doc(authUser?.user?.uid)
    //                 .set({
    //                   email: authUser?.user?.email,
    //                   source: "WEB",
    //                   createdAt: updatedAt,
    //                 })
    //                 .then(() => {
    //                   history.push("/");
    //                 });

    //               analyticsEvent("SIGN_IN");
    //               analyticsEvent("SIGN_IN_GOOGLE");
    //             }
    //           });
    //       })
    //       .catch((e) => {
    //         let errorCode = e.code;
    //         let errorMessage = e.message;

    //         setSenhaNot(true);
    //         setTextErro(`${errorCode} ${errorMessage}`);
    //       });
    //   })
    //   .catch((e) => {
    //     let errorCode = e.code;
    //     let errorMessage = e.message;

    //     alert(`${errorCode} ${errorMessage}`);
    //   });
  };

  const entrarEmailSenha = (e) => {
    e.preventDefault();

    setLoading(true);

    if (email !== "" && senha !== "") {
      setSenhaNot(false);
      setFirebaseAuthPersistence(
        () => {
          auth
            .signInWithEmailAndPassword(email, senha)
            .then(() => {
              setLoading(false);
              setEmail("");
              setSenha("");

              history.push("/");

              analyticsEvent("SIGN_IN");
              analyticsEvent("SIGN_IN_FORM");
            })
            .catch((e) => {
              setLoading(false);
              setSenhaNot(true);
              setTextErro(`${e.code} ${e.message}`);
            });
        },
        (e) => {
          setLoading(false);
          alert(`${e.code} ${e.message}`);
        }
      );
    } else {
      setSenhaNot(true);
      setLoading(false);
      setTextErro(activeLanguage?.login?.text_error);
    }
  };

  useEffect(() => {
    document.getElementById("myne-text-main-login").innerHTML =
      activeLanguage?.login?.titleMain;
  }, [activeLanguage]);

  return (
    <div className="myne-login">
      <div className="login__titles">
        <p
          className="myne-text myne-modal--animation-top"
          id="myne-text-main-login"
        >
          O <strong>Myne</strong> chegou ao seu PDV
        </p>

        <span className="myne-modal--animation">
          {activeLanguage?.login?.descriptionMain}
        </span>

        <img
          className="myne-modal--animation"
          src={ilustracao}
          alt="Logo Myne"
        />
      </div>

      <form
        style={{
          position: pageContent === "Entrar" ? "relative" : "fixed",
          right: pageContent === "Entrar" ? "0" : "30%",
          opacity: pageContent === "Entrar" ? "1" : "0",
          visibility: pageContent === "Entrar" ? "visible" : "hidden",
          zIndex: pageContent === "Entrar" ? "0" : "-99999999",
          height: senhaNot ? "500px" : "",
        }}
        onSubmit={entrarEmailSenha}
        className="login__content myne-modal--animation-left"
      >
        <section>
          <InputForm
            senha={false}
            value={email}
            type="text"
            onChange={(e) => setEmail(e.target.value)}
            label={activeLanguage?.login?.inputs?.email}
          />
          <InputForm
            senha={true}
            style={{ marginTop: "15px" }}
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            label={activeLanguage?.login?.inputs?.password}
            onKeyDown={(e) => {
              if (email !== "" && senha !== "") {
                if (e.key === "Enter") {
                  entrarEmailSenha(e);
                }
              }
            }}
          />

          {senhaNot ? <p>{textErro}</p> : ""}
          <span onClick={() => setPageContent("Esqueci a senha")}>
            {activeLanguage?.login?.inputs?.forgot_password}
          </span>
        </section>
        {loading ? (
          <button
            style={{ opacity: "0.3", cursor: "auto" }}
            className="myne-btn btn-entrar"
          >
            <LoadingInButton />
          </button>
        ) : (
          <button
            type="submit"
            onClick={entrarEmailSenha}
            className="myne-btn btn-entrar"
          >
            {activeLanguage?.login?.btns?.submit}
          </button>
        )}
        <button onClick={entrarGoogle} className="myne-btn btn-google">
          <FcGoogle />
          <p className="myne-text">
            {activeLanguage?.login?.btns?.submit_google}
          </p>
        </button>

        <a onClick={() => history.push("/cadastro")}>
          {activeLanguage?.login?.btns?.create_account}
        </a>
      </form>

      <div
        style={{
          position: pageContent !== "Entrar" ? "relative" : "fixed",
          right: pageContent !== "Entrar" ? "0" : "30%",
          opacity: pageContent !== "Entrar" ? "5" : "0",
          visibility: pageContent !== "Entrar" ? "visible" : "hidden",
          zIndex: pageContent !== "Entrar" ? "0" : "-99999999",
        }}
        className="login__content-recuperar__senha"
      >
        <div className="login__back">
          <IconButton onClick={() => setPageContent("Entrar")}>
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.6"
                d="M14.9844 7.01562C15.2656 7.01562 15.5 7.10938 15.6875 7.29688C15.9062 7.48438 16.0156 7.71875 16.0156 8C16.0156 8.28125 15.9062 8.51562 15.6875 8.70312C15.5 8.89062 15.2656 8.98438 14.9844 8.98438H3.82812L8.70312 13.8594C9.14062 14.3281 9.14062 14.8125 8.70312 15.3125C8.51562 15.5 8.28125 15.5938 8 15.5938C7.71875 15.5938 7.48438 15.5 7.29688 15.3125L0.6875 8.70312C0.5 8.51562 0.40625 8.28125 0.40625 8C0.40625 7.71875 0.5 7.48438 0.6875 7.29688L7.29688 0.6875C7.48438 0.5 7.71875 0.40625 8 0.40625C8.28125 0.40625 8.51562 0.5 8.70312 0.6875C9.14062 1.1875 9.14062 1.67188 8.70312 2.14062L3.82812 7.01562H14.9844Z"
                fill="black"
              />
            </svg>
          </IconButton>

          <p className="myne-text">
            {activeLanguage?.login?.forgot_password_view?.title}
          </p>
        </div>

        <FormForgotPassword
          setLoading={setLoading}
          activeLanguage={activeLanguage}
          setPageContent={setPageContent}
        />
      </div>
    </div>
  );
}

export default Login;
