import { db } from "../services/Firebase";
import {
  getCategoriesDocs,
  getDataUser,
  getProductsDocs,
  ProductService,
  updateUser,
  userId,
} from "./reducer";

const collectionReference = db.collection("stores");

export const addStore = async (store, cpfCnpj) => {
  const storeName = store?.storeName;

  const batch = db.batch();
  batch.set(collectionReference.doc(storeName), store);

  const products = await getProductsDocs();

  products?.forEach((product) => {
    const pd = product.data();
    const productRef = collectionReference
      .doc(storeName)
      .collection("products")
      .doc(product.id);
    batch.set(productRef, pd);
  });

  await ProductService.saveAllProductsToAlgolia(storeName, products);

  const categories = await getCategoriesDocs();

  categories?.forEach((category) => {
    const cate = category.data();
    const cateRef = collectionReference
      .doc(storeName)
      .collection("categories")
      .doc(category.id);
    batch.set(cateRef, cate);
  });

  const userFirestore = await getDataUser();

  if (cpfCnpj.length > 14) {
    // cnpj
    userFirestore.cnpj = cpfCnpj;
    userFirestore.cpf = null;
  } else {
    // cpf
    userFirestore.cpf = cpfCnpj;
    userFirestore.cnpj = null;
  }
  userFirestore.storeName = store.storeName;
  userFirestore.storeLink = store.storeLink;
  userFirestore.whatsApp = store.whatsApp;

  const userDocRef = db.collection("users").doc(userId());
  userFirestore.documentPath = userDocRef.path;

  userFirestore.productsAlgoliaMigrateToSearch = true;

  await updateUser(userFirestore);

  await batch.commit();
};

export const alreadyHasStore = async (storeName) => {
  const query = collectionReference.where("storeName", "==", storeName);

  const querySnapshot = (await query.get()).empty;

  return querySnapshot;
};

export const removeStore = async (storeName) => {
  const batch = db.batch();

  batch.delete(collectionReference.doc(storeName));

  // Update User
  const userFirestore = await getDataUser();

  userFirestore.storeLink = null;
  userFirestore.storeName = null;

  const userDocRef = db.collection("users").doc(userId());
  batch.set(userDocRef, userFirestore);

  // Delete Products
  const productsCollection = collectionReference
    .doc(storeName)
    .collection("products");

  const queryProductsSnapshot = await productsCollection.get();
  queryProductsSnapshot.forEach((it) => {
    const documentReference = productsCollection.doc(it?.id);
    batch.delete(documentReference);
  });

  // Delete Categories

  const categoriesCollection = collectionReference
    .doc(storeName)
    .collection("categories");

  const querySnapshotCategories = await categoriesCollection.get();
  querySnapshotCategories.forEach((it) => {
    const documentReference = categoriesCollection.doc(it?.id);
    batch.delete(documentReference);
  });

  await batch.commit();
};

export const StoreService = {
  hasProductInStore: async (product) => {
    const userFirestore = await getDataUser();

    const storename = userFirestore?.storeName;

    if (storename) {
      const Collection = collectionReference
        .doc(storename)
        .collection("products");

      const productGet = [];
      await Collection.doc(product?.id)
        .get()
        .then((data) => {
          productGet.push(data?.data());
        });

      return productGet?.length > 0 && productGet[0] ? true : false;
    } else {
      return false;
    }
  },
  addItemToCatalog: async (itemFirestore, id, collection) => {
    const userFirestore = await getDataUser();

    const storename = userFirestore?.storeName;

    if (storename) {
      const Collection = collectionReference
        .doc(storename)
        .collection(collection);
      await Collection.doc(id).set(itemFirestore);
    } else return;
  },
  removeItemToCatalog: async (id, collection) => {
    const userFirestore = await getDataUser();

    const storename = userFirestore.storeName;

    if (storename) {
      const Collection = collectionReference
        .doc(storename)
        .collection(collection);
      await Collection.doc(id).delete();
    } else return;
  },
};
