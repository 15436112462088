import React from 'react';

//
import logo from '../../assets/images/LOGOS/logo 2.svg';

function Loading() {
  return (
    <div className="myne-loading">
      <img src={logo} alt="" />

      <div className="myne-circle-loading">
        
      </div>
    </div>
  )
}

export default Loading;