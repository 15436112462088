import React, { useEffect, useMemo, useRef, useState } from "react";
import { countries } from "../../assets/icons/Countries/countries";
import { IconSearch } from "../../assets/icons/icons";
import { useOnClickOutside } from "../../providers/reducer";
import Radio from "@mui/material/Radio";

const InputFormDigitWhatsapp = ({
  value,
  valueDigit,
  setNumberDigit,
  label,
  marginTop,
  ...rest
}) => {
  let numero = Math.random() * 10;

  const [selectCountry, setSelectCountry] = useState({});
  const [modalChangeCountry, setModalChangeCountry] = useState(false);
  const [valueSearch, setValueSearch] = useState("");

  useEffect(() => {
    setSelectCountry(countries[0]);
    setNumberDigit(countries[0].digit);
  }, []);

  const modalRef = useRef();
  useOnClickOutside(modalRef, () => setModalChangeCountry(false));

  const countriesfilter = useMemo(() => {
    return countries?.filter((item) => {
      return (
        item?.name?.toLowerCase()?.includes(valueSearch?.toLowerCase()) ||
        item?.digit?.toLowerCase()?.includes(valueSearch?.toLowerCase())
      );
    });
  }, [valueSearch]);

  const searchByDigit = (id) => {
    return countries?.filter((item) => {
      return item?.digit === id;
    });
  };

  const handleChange = (e) => {
    const digit = e.target.value;
    const country = searchByDigit(digit)[0];
    setNumberDigit(e.target.value);
    setModalChangeCountry(false);
    setSelectCountry(country);
  };

  return (
    <form
      style={{ marginTop: marginTop }}
      onSubmit={(event) => event.preventDefault()}
      className="input-form-whatsapp"
    >
      {selectCountry?.digit === "" ? (
        <div className="form__group field">
          <input
            autoComplete="new-password"
            {...rest}
            value={value}
            className="form__field"
            required
            placeholder="WhatsApp*"
            id={"WhatsApp" + numero}
          />
          <label htmlFor={"WhatsApp" + numero} className="form__label">
            {label ? label : "WhatsApp*"}
          </label>
        </div>
      ) : (
        <>
          <p className="form__label">{label ? label : "WhatsApp*"}</p>

          <div style={{ position: "relative" }}>
            {/* MODAL */}
            <section
              ref={modalRef}
              className="myne-modal__modal-new-sale-content__area-modal-search myne-modal__modal-moeda"
              style={{
                opacity: modalChangeCountry ? 1 : 0,
                visibility: modalChangeCountry ? "visible" : "hidden",
                transform: modalChangeCountry ? "scale(1)" : "scale(0)",
                right: 0,
                bottom: 47,
              }}
            >
              {/* SEARCH */}
              <section className="myne-modal__modal-new-sale-content__area-modal-search-area__seach">
                <div className="myne-modal__modal-new-sale-content__area-modal-search__input">
                  <input
                    placeholder="Pesquisar..."
                    value={valueSearch}
                    type="text"
                    id={"search-countries"}
                    autoFocus
                    onChange={(e) => setValueSearch(e.target.value)}
                  />
                  <IconSearch
                    onClick={() =>
                      document.getElementById("search-countries").focus()
                    }
                  />
                </div>
              </section>
              {/* ITENS */}
              <div className="myne-modal__modal-new-sale-content__area-modal-search__itens">
                {countriesfilter?.map((item) => (
                  <label
                    key={item?.name}
                    htmlFor={item?.name}
                    className="myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda"
                  >
                    <section>
                      <img src={item?.Icon} alt={item?.name} />
                      <div>
                        <p>{item?.name}</p>
                        {item?.digit !== "" && <span>{item?.digit}</span>}
                      </div>
                    </section>
                    <Radio
                      checked={selectCountry?.digit === item?.digit}
                      onChange={handleChange}
                      value={item?.digit}
                      name={item?.name}
                      id={item?.name}
                      inputProps={{
                        "aria-label": item?.name,
                      }}
                    />
                  </label>
                ))}
              </div>
            </section>
            {/* DIGIT */}
            <div
              onClick={() => setModalChangeCountry(true)}
              className="input-form-whatsapp__digit"
            >
              <div>
                <img src={selectCountry?.Icon} alt={selectCountry?.name} />
                <p>{selectCountry?.digit}</p>
              </div>

              <Arrow />
            </div>
          </div>

          <div className="form__group field">
            <input
              autoComplete="new-password"
              {...rest}
              value={value}
              className="form__field"
              required
              placeholder="Escreva 11 dígitos"
              id={"number" + numero}
            />
            <label htmlFor={"number" + numero} className="form__label">
              Escreva 11 dígitos
            </label>
          </div>
        </>
      )}
    </form>
  );
};

export default InputFormDigitWhatsapp;

const Arrow = () => (
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.28125 2.71875L4.70312 5.29688C4.32812 5.67188 3.67187 5.67188 3.29687 5.29688L0.718749 2.71875C0.0624989 2.0625 0.531249 0.984376 1.42187 0.984376L6.57812 0.984376C7.46875 0.984376 7.9375 2.0625 7.28125 2.71875Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);
