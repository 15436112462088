import { updatedAt } from "../../../../providers/reducer";
import {
  auth,
  db,
  firebase,
  providerGoogle,
} from "../../../../services/Firebase";
import { analyticsEvent } from "../../../../services/analytics";

export const setFirebaseAuthPersistence = (authSign, error) => {
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(authSign)
    .catch((e) => error(e));
};

export const signAccountGoogle = (history, signType, error) => {
  setFirebaseAuthPersistence(() => {
    auth
      .signInWithPopup(providerGoogle)
      .then((authUser) => {
        const userRef = db.collection("users").doc(authUser?.user?.uid);

        userRef.onSnapshot((snapshot) => {
          if (snapshot.exists) {
            history.push("/");
          } else {
            userRef
              .set({
                email: authUser?.user?.email,
                source: "WEB",
                createdAt: updatedAt,
              })
              .then(() => {
                history.push("/");
              });
            // signType: 'SIGN_IN' || 'SIGN_UP'
            analyticsEvent(signType);
            analyticsEvent(`${signType}_GOOGLE`);
          }
        });
      })
      .catch((e) => error(e));
  }, error);
};
