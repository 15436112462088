export const IconLogout = ({ ...rest }) => (
  <svg
    width="20"
    {...rest}
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.98438 2.01562V15.9844H10V18H1.98438C1.45312 18 0.984375 17.7969 0.578125 17.3906C0.203125 16.9844 0.015625 16.5156 0.015625 15.9844V2.01562C0.015625 1.48438 0.203125 1.01563 0.578125 0.609375C0.984375 0.203125 1.45312 0 1.98438 0H10V2.01562H1.98438ZM15.0156 3.98438L19.9844 9L15.0156 14.0156L13.6094 12.5625L16.1875 9.98438H6.01562V8.01562H16.1875L13.6094 5.39062L15.0156 3.98438Z"
      fill="black"
      fillOpacity="0.60"
    />
  </svg>
);


export const IconSearch = ({ ...rest }) => (
  <svg
    {...rest}
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5156 12.0156H12.7188L12.4375 11.7344C13.6094 10.3281 14.2656 8.40625 13.8906 6.39062C13.4219 3.625 11.125 1.375 8.3125 1.04688C4.09375 0.53125 0.53125 4.09375 1.04688 8.3125C1.375 11.125 3.625 13.4219 6.39062 13.8906C8.40625 14.2656 10.3281 13.6094 11.7344 12.4375L12.0156 12.7188V13.5156L16.2344 17.7344C16.6562 18.1562 17.3125 18.1562 17.7344 17.7344C18.1562 17.3594 18.1562 16.6562 17.7344 16.2812L13.5156 12.0156ZM7.51562 12.0156C5.03125 12.0156 3.01562 10 3.01562 7.51562C3.01562 5.03125 5.03125 3.01562 7.51562 3.01562C10 3.01562 12.0156 5.03125 12.0156 7.51562C12.0156 10 10 12.0156 7.51562 12.0156Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

export const IconNotService = () => (
  <svg
    width="28"
    height="22"
    viewBox="0 0 28 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.1135 8.48438H7.15237C6.99864 8.48438 6.87402 8.60899 6.87402 8.76272V8.76283C6.87402 8.91655 6.99864 9.04117 7.15237 9.04117H9.1135C9.26722 9.04117 9.39184 8.91655 9.39184 8.76283V8.76272C9.39184 8.60899 9.26722 8.48438 9.1135 8.48438Z"
      fill="#C6C6C6"
    />
    <path
      d="M14.2427 17.6992H20.6002V18.4888H15.0322C14.8228 18.4888 14.622 18.4056 14.4739 18.2575C14.3259 18.1094 14.2427 17.9086 14.2427 17.6992V17.6992Z"
      fill="#C6C6C6"
    />
    <path
      d="M16.0243 15.5804H13.0949L12.0977 13.5703H16.0243V15.5804Z"
      fill="#C6C6C6"
    />
    <path
      d="M22.5174 13.5774C22.502 13.5978 22.4853 13.6172 22.4675 13.6354L20.4337 15.7104L18.6517 17.5279L18.6262 17.5541H10.1289L10.1657 17.5279L11.1903 16.8031L16.881 12.7754H22.106C22.1992 12.7754 22.2905 12.8012 22.37 12.8498C22.4495 12.8984 22.514 12.9679 22.5565 13.0508C22.5991 13.1337 22.6179 13.2267 22.611 13.3196C22.6041 13.4125 22.5717 13.5017 22.5174 13.5774Z"
      fill="#D5D5D5"
    />
    <path
      d="M23.2623 11.6971C23.2598 11.6989 23.258 11.7008 23.2555 11.7027L22.7301 12.0908L22.1379 12.5283L21.5764 12.9428L16.8395 12.9213L16.0086 13.5863H12.1025V13.2641C12.102 12.841 12.2387 12.4292 12.4921 12.0904L12.5052 12.0736C12.6881 11.8332 12.9242 11.6383 13.1949 11.5043C13.4656 11.3702 13.7637 11.3006 14.0658 11.3008H23.1228C23.1689 11.3008 23.2139 11.3151 23.2515 11.3418C23.2891 11.3684 23.3175 11.4061 23.3328 11.4495C23.3481 11.493 23.3496 11.5402 23.3369 11.5845C23.3243 11.6289 23.2982 11.6682 23.2623 11.6971V11.6971Z"
      fill="#E0E0E0"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.25">
      <path
        d="M22.1379 12.527L21.5764 12.9415L16.8394 12.9199L16.0085 13.585H15.3843L16.8814 12.5254H22.1065C22.1168 12.5257 22.1274 12.5264 22.1379 12.527Z"
        fill="#E0E0E0"
      />
    </g>
    <path
      d="M22.5174 13.5775C15.5509 13.5414 13.1725 16.0501 12.3732 17.5541H11.3276C11.2827 17.316 11.2359 17.0599 11.1904 16.8031L16.8811 12.7754H22.1061C22.1993 12.7754 22.2906 12.8012 22.3701 12.8498C22.4496 12.8984 22.5142 12.968 22.5567 13.0509C22.5992 13.1338 22.618 13.2268 22.6111 13.3197C22.6041 13.4127 22.5717 13.5019 22.5174 13.5775V13.5775Z"
      fill="#D2D2D2"
    />
    <path
      d="M23.2698 11.6916L23.2623 11.6972L22.7301 12.0904H12.4922L12.5052 12.0736C12.6882 11.8332 12.9243 11.6383 13.195 11.5042C13.4658 11.3702 13.7639 11.3005 14.066 11.3008H23.1416C23.1872 11.3008 23.2316 11.3152 23.2685 11.342C23.3054 11.3687 23.3329 11.4065 23.3471 11.4498C23.3613 11.4931 23.3615 11.5398 23.3476 11.5832C23.3337 11.6266 23.3065 11.6645 23.2698 11.6916V11.6916Z"
      fill="white"
    />
    <path
      d="M11.2097 17.6265H4.67529V15.9961H12.3366L11.2097 17.6265Z"
      fill="#C6C6C6"
    />
    <path
      d="M6.56333 19.0258C6.56359 19.3174 6.5145 19.607 6.41812 19.8822C6.3882 19.9686 6.35343 20.0533 6.31399 20.1358C6.06651 20.6585 5.65158 21.0835 5.13505 21.3434C4.61851 21.6033 4.02996 21.6833 3.4628 21.5706C2.89563 21.4579 2.38236 21.159 2.00442 20.7214C1.62649 20.2837 1.40554 19.7324 1.37666 19.1549C1.37537 19.1306 1.37483 19.1062 1.37418 19.082C1.37353 19.0633 1.37354 19.0446 1.37354 19.0259C1.37337 18.8307 1.39507 18.636 1.43821 18.4456C1.44759 18.4057 1.45751 18.3652 1.46872 18.327C1.62672 17.7606 1.9725 17.2646 2.44936 16.9204C2.92623 16.5762 3.50588 16.4043 4.09329 16.4327C4.6807 16.4612 5.24101 16.6884 5.68235 17.0771C6.1237 17.4658 6.41989 17.9929 6.52236 18.572C6.54962 18.7217 6.56333 18.8736 6.56333 19.0258V19.0258Z"
      fill="#C6C6C6"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
      <path
        d="M6.52313 18.5718C6.44149 18.1089 6.23517 17.677 5.92635 17.3226C5.8595 17.2461 5.78827 17.1736 5.713 17.1055C5.99334 17.4475 6.18109 17.8558 6.25826 18.2913C6.28533 18.4413 6.29886 18.5934 6.29869 18.7458C6.29871 19.2488 6.15249 19.741 5.87783 20.1624C5.60317 20.5839 5.21193 20.9164 4.75171 21.1194C4.29149 21.3225 3.78216 21.3874 3.28572 21.3063C2.78928 21.2251 2.32714 21.0014 1.95557 20.6623C2.29339 21.0785 2.75185 21.3798 3.2679 21.5248C3.78395 21.6698 4.33226 21.6514 4.83741 21.4721C5.34257 21.2927 5.77976 20.9613 6.08888 20.5234C6.39801 20.0855 6.56387 19.5625 6.56367 19.0265C6.56381 18.874 6.55024 18.7218 6.52313 18.5718V18.5718Z"
        fill="#F0F0F0"
      />
    </g>
    <path
      d="M3.96858 20.7614C4.92636 20.7614 5.70279 19.985 5.70279 19.0272C5.70279 18.0694 4.92636 17.293 3.96858 17.293C3.01081 17.293 2.23438 18.0694 2.23438 19.0272C2.23438 19.985 3.01081 20.7614 3.96858 20.7614Z"
      fill="#E2E2E2"
    />
    <path
      d="M3.96887 20.0862C4.55412 20.0862 5.02856 19.6117 5.02856 19.0265C5.02856 18.4412 4.55412 17.9668 3.96887 17.9668C3.38362 17.9668 2.90918 18.4412 2.90918 19.0265C2.90918 19.6117 3.38362 20.0862 3.96887 20.0862Z"
      fill="#C6C6C6"
    />
    <path
      d="M3.969 19.5112C4.23691 19.5112 4.4541 19.294 4.4541 19.0261C4.4541 18.7582 4.23691 18.541 3.969 18.541C3.70108 18.541 3.48389 18.7582 3.48389 19.0261C3.48389 19.294 3.70108 19.5112 3.969 19.5112Z"
      fill="white"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
      <path
        d="M6.5635 19.0258C6.56376 19.3174 6.51466 19.607 6.41829 19.8822L1.37715 19.1549C1.37586 19.1306 1.37532 19.1062 1.37467 19.082C1.37402 19.0633 1.37402 19.0446 1.37402 19.0259C1.37386 18.8307 1.39556 18.636 1.4387 18.4456C1.44808 18.4057 1.458 18.3652 1.46921 18.327C1.62721 17.7606 1.97299 17.2646 2.44985 16.9204C2.92672 16.5762 3.50637 16.4043 4.09378 16.4327C4.68119 16.4612 5.2415 16.6884 5.68284 17.0771C6.12419 17.4658 6.42038 17.9929 6.52286 18.572C6.55 18.7217 6.5636 18.8736 6.5635 19.0258V19.0258Z"
        fill="#F0F0F0"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }} opacity="0.25">
      <path
        d="M16.8271 11.3008C16.6051 11.6679 16.2629 11.9469 15.8585 12.0905C15.1935 12.3511 13.5467 12.8385 12.5814 12.0905C12.5612 12.0749 12.5309 12.0798 12.5054 12.0737C12.6883 11.8333 12.9244 11.6384 13.1952 11.5044C13.4659 11.3703 13.764 11.3007 14.0661 11.3009L16.8271 11.3008Z"
        fill="#E0E0E0"
      />
    </g>
    <path
      d="M7.12301 19.0256C7.12301 19.0337 7.12237 19.0412 7.12237 19.0493H0.814122C0.814122 19.0412 0.813477 19.0337 0.813477 19.0256C0.813357 18.7882 0.8399 18.5515 0.892604 18.32C0.903385 18.2721 0.916321 18.223 0.929365 18.1755C1.12163 17.4869 1.54208 16.884 2.12182 16.4657C2.70157 16.0473 3.40623 15.8383 4.12032 15.8728C4.83442 15.9074 5.5156 16.1835 6.05225 16.6558C6.5889 17.1282 6.94919 17.7688 7.07407 18.4728C7.09981 18.6126 7.11544 18.754 7.12085 18.896C7.12236 18.939 7.12301 18.982 7.12301 19.0256Z"
      fill="#CECECE"
    />
    <path
      d="M1.15785 18.3209C1.16928 18.2726 1.18158 18.2239 1.19473 18.176C1.37406 17.5365 1.75046 16.9699 2.27041 16.5567C2.79036 16.1435 3.42737 15.9047 4.09082 15.8744C4.0504 15.8729 4.00997 15.8711 3.96911 15.8711C3.28046 15.8712 2.61073 16.0965 2.06202 16.5126C1.51332 16.9288 1.11572 17.5129 0.929855 18.176C0.916595 18.2234 0.904308 18.273 0.892989 18.3209C0.840247 18.5523 0.813737 18.7889 0.813966 19.0262C0.813966 19.0341 0.813966 19.0421 0.813966 19.05H1.07884C1.07884 19.0421 1.07884 19.0341 1.07884 19.0262C1.07862 18.7889 1.10512 18.5523 1.15785 18.3209V18.3209Z"
      fill="#D8D8D8"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.3">
      <path
        d="M7.07386 18.4738C6.94453 17.7443 6.56252 17.0837 5.99481 16.6078C5.4271 16.1319 4.70996 15.8711 3.96916 15.8711C3.80717 15.8713 3.64543 15.8838 3.48535 15.9086C4.13563 16.009 4.73818 16.3104 5.20854 16.7705C5.6789 17.2306 5.99347 17.8264 6.10817 18.4743C6.14112 18.6566 6.15761 18.8416 6.15743 19.0269C6.15743 19.0349 6.15679 19.0426 6.15679 19.0506H7.12291C7.12291 19.0426 7.12355 19.0349 7.12355 19.0269C7.12363 18.8414 7.107 18.6563 7.07386 18.4738V18.4738Z"
        fill="#D6D6D6"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }} opacity="0.22">
      <path
        d="M7.12098 18.8968C5.27229 19.029 3.77201 16.8362 3.24658 15.9549C3.66405 15.8567 4.09716 15.8447 4.51945 15.9195C4.94173 15.9944 5.34431 16.1546 5.70257 16.3903C6.06083 16.6261 6.36724 16.9324 6.60308 17.2906C6.83892 17.6488 6.99922 18.0513 7.07419 18.4736C7.09993 18.6134 7.11556 18.7548 7.12098 18.8968V18.8968Z"
        fill="#D2D2D2"
      />
    </g>
    <path
      d="M18.8337 17.5273V18.2024H6.25656C5.9703 18.2023 5.69368 18.0989 5.47747 17.9113L5.03613 17.5273H18.8337Z"
      fill="#D2D2D2"
    />
    <path d="M13.8794 16.9199H5.0625V17.5328H13.8794V16.9199Z" fill="#C6C6C6" />
    <path
      d="M8.06931 15.9929L5.55063 17.3243L5.08256 17.5722C5.08165 17.5725 5.0808 17.5729 5.08007 17.5735L4.28234 16.7987C3.98944 16.5143 3.70172 16.2232 3.40311 15.9447C2.99055 15.5602 3.1792 14.9362 3.28625 14.4546C3.3947 13.9671 3.50606 13.4791 3.64459 12.9993C4.22855 10.9811 5.11867 9.6117 5.93139 9.1972C6.06848 9.12421 6.2191 9.08018 6.37391 9.06784C6.76534 9.04294 8.16526 9.0548 7.84358 9.55586C7.66225 9.83884 7.49861 10.3231 7.37421 10.8966C7.14168 11.9667 7.04821 13.3487 7.24086 14.3139C7.46972 15.4556 8.06931 15.9929 8.06931 15.9929Z"
      fill="#D5D5D5"
    />
    <path
      d="M7.00066 10.6903C6.63414 11.9968 6.41735 13.699 6.58476 14.9072C6.65747 15.4668 6.83383 16.0078 7.1048 16.5027L8.06909 15.993C8.06909 15.993 7.46949 15.4557 7.24074 14.3133C7.0482 13.3478 7.14167 11.966 7.37409 10.8959C7.49817 10.3225 7.66203 9.83819 7.84346 9.55521C7.96646 9.36364 7.83742 9.24366 7.60964 9.16992C7.3895 9.51694 7.17757 10.057 7.00066 10.6903Z"
      fill="#D2D2D2"
    />
    <path
      d="M5.42958 16.8502C5.23981 16.6875 5.06425 16.5089 4.90479 16.3163C4.6377 15.9651 4.44935 15.5605 4.35253 15.13C4.18992 14.4277 4.18657 13.698 4.34272 12.9943C4.74859 11.0225 5.48143 9.62325 6.22612 9.08984C6.12386 9.11296 6.02494 9.14893 5.93171 9.19689C5.119 9.61139 4.22845 10.9807 3.64492 12.999C3.50672 13.4766 3.39611 13.9627 3.28798 14.4479C3.19851 14.8498 3.05664 15.4068 3.26147 15.7954C3.33293 15.8923 3.41783 15.9784 3.51361 16.0513L3.81869 16.3476C4.23934 16.7562 4.66012 17.1647 5.08105 17.5731C5.29364 17.585 5.50892 17.5985 5.72161 17.6033C5.82866 17.6058 6.26752 17.6109 6.26752 17.6109C6.26752 17.6109 5.98281 17.3522 5.91177 17.2902C5.74823 17.1477 5.59354 16.9955 5.42958 16.8502Z"
      fill="#D2D2D2"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.25">
      <path
        d="M7.84352 9.5559C7.6622 9.83888 7.49855 10.3231 7.37415 10.8966L5.44385 9.55612C5.59477 9.47947 6.2308 9.07662 6.37374 9.06789C6.76517 9.04288 8.16509 9.05473 7.84352 9.5559Z"
        fill="#D9D9D9"
      />
    </g>
    <path
      d="M22.1871 19.0273C22.1867 19.7103 21.917 20.3656 21.4365 20.8511C20.9561 21.3365 20.3035 21.613 19.6205 21.6204C18.9376 21.6278 18.2792 21.3656 17.7883 20.8907C17.2973 20.4158 17.0135 19.7665 16.9982 19.0836C16.9976 19.0648 16.9976 19.046 16.9976 19.0273C16.9973 18.8321 17.019 18.6374 17.0622 18.4471C17.0716 18.4074 17.0817 18.3669 17.0926 18.328C17.2509 17.7617 17.5968 17.266 18.0736 16.922C18.5505 16.5781 19.13 16.4062 19.7173 16.4347C20.3045 16.4631 20.8647 16.6902 21.3061 17.0787C21.7474 17.4671 22.0437 17.994 22.1465 18.5729C22.1736 18.7228 22.1872 18.8749 22.1871 19.0273V19.0273Z"
      fill="#C6C6C6"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
      <path
        d="M22.1466 18.5714C22.0857 18.2276 21.9559 17.8995 21.7651 17.607C21.5743 17.3145 21.3264 17.0635 21.0363 16.8691C21.119 16.9425 21.1971 17.021 21.2699 17.1041C21.5787 17.4586 21.785 17.8904 21.8667 18.3534C21.8937 18.5033 21.9073 18.6554 21.9071 18.8079C21.907 19.2785 21.779 19.7403 21.5366 20.1438C21.2942 20.5472 20.9466 20.8772 20.5311 21.0983C20.1156 21.3193 19.6478 21.4232 19.1777 21.3988C18.7077 21.3744 18.2532 21.2225 17.8628 20.9596C18.236 21.2938 18.6981 21.5128 19.1931 21.5901C19.6881 21.6674 20.1949 21.5997 20.6522 21.3952C21.1096 21.1907 21.498 20.8581 21.7704 20.4377C22.0429 20.0173 22.1878 19.5269 22.1876 19.0259C22.1876 18.8735 22.1739 18.7214 22.1466 18.5714V18.5714Z"
        fill="#F0F0F0"
      />
    </g>
    <path
      d="M19.5926 20.7614C20.5504 20.7614 21.3268 19.985 21.3268 19.0272C21.3268 18.0694 20.5504 17.293 19.5926 17.293C18.6348 17.293 17.8584 18.0694 17.8584 19.0272C17.8584 19.985 18.6348 20.7614 19.5926 20.7614Z"
      fill="#E2E2E2"
    />
    <path
      d="M5.20644 9.49394C5.60874 9.49394 5.93486 9.16782 5.93486 8.76553C5.93486 8.36323 5.60874 8.03711 5.20644 8.03711C4.80415 8.03711 4.47803 8.36323 4.47803 8.76553C4.47803 9.16782 4.80415 9.49394 5.20644 9.49394Z"
      fill="#E9E9E9"
    />
    <path
      d="M5.44385 7.91406H6.44964C6.64087 7.91406 6.83023 7.95173 7.0069 8.02491C7.18357 8.09809 7.3441 8.20535 7.47932 8.34057C7.61454 8.47579 7.7218 8.63632 7.79498 8.81299C7.86816 8.98966 7.90583 9.17902 7.90583 9.37025C7.90583 9.41934 7.88633 9.46642 7.85161 9.50113C7.8169 9.53584 7.76982 9.55535 7.72073 9.55535H5.44385V7.91406Z"
      fill="#D5D5D5"
    />
    <path
      d="M5.44385 9.16016V9.55493H7.73442C7.77988 9.55493 7.82348 9.53687 7.85562 9.50472C7.88777 9.47258 7.90583 9.42898 7.90583 9.38352V9.25287C7.90583 9.22171 7.90486 9.19088 7.90335 9.16016H5.44385Z"
      fill="white"
    />
    <path
      d="M19.5924 20.0862C20.1777 20.0862 20.6521 19.6117 20.6521 19.0265C20.6521 18.4412 20.1777 17.9668 19.5924 17.9668C19.0072 17.9668 18.5327 18.4412 18.5327 19.0265C18.5327 19.6117 19.0072 20.0862 19.5924 20.0862Z"
      fill="#C6C6C6"
    />
    <path
      d="M5.44425 9.55535L5.32567 9.16575C5.19279 8.72849 4.9623 8.32714 4.65159 7.992V7.992C4.64543 7.98539 4.64134 7.97712 4.63982 7.96821C4.6383 7.9593 4.63942 7.95014 4.64304 7.94185C4.64666 7.93357 4.65262 7.92653 4.66019 7.92159C4.66776 7.91665 4.67662 7.91404 4.68565 7.91406H5.44415L5.44425 9.55535Z"
      fill="white"
    />
    <path
      d="M9.10276 8.52539H7.14163C6.9879 8.52539 6.86328 8.65001 6.86328 8.80374V8.80384C6.86328 8.95757 6.9879 9.08219 7.14163 9.08219H9.10276C9.25648 9.08219 9.3811 8.95757 9.3811 8.80384V8.80374C9.3811 8.65001 9.25648 8.52539 9.10276 8.52539Z"
      fill="#C6C6C6"
    />
    <path
      d="M6.49414 8.108C6.49414 8.108 6.69239 6.1556 7.39968 6.40009"
      stroke="#CCCCCC"
      stroke-miterlimit="10"
    />
    <path
      d="M8.14897 6.5125C8.27394 6.05301 8.18981 5.63008 7.96104 5.56786C7.73227 5.50564 7.44551 5.82768 7.32053 6.28717C7.19555 6.74665 7.27969 7.16958 7.50846 7.23181C7.73722 7.29403 8.02399 6.97198 8.14897 6.5125Z"
      fill="#C6C6C6"
    />
    <path
      d="M19.5924 19.5423C19.8776 19.5423 20.1087 19.3112 20.1087 19.026C20.1087 18.7409 19.8776 18.5098 19.5924 18.5098C19.3073 18.5098 19.0762 18.7409 19.0762 19.026C19.0762 19.3112 19.3073 19.5423 19.5924 19.5423Z"
      fill="white"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
      <path
        d="M22.1768 19.0258C22.177 19.3174 22.128 19.607 22.0316 19.8822L16.9904 19.1549C16.9891 19.1306 16.9886 19.1062 16.988 19.082C16.9873 19.0633 16.9873 19.0446 16.9873 19.0259C16.9871 18.8307 17.0088 18.636 17.052 18.4456C17.0614 18.4057 17.0713 18.3652 17.0825 18.327C17.2405 17.7606 17.5863 17.2646 18.0631 16.9204C18.54 16.5762 19.1197 16.4043 19.7071 16.4327C20.2945 16.4612 20.8548 16.6884 21.2961 17.0771C21.7375 17.4658 22.0337 17.9929 22.1361 18.572C22.1633 18.7217 22.1769 18.8736 22.1768 19.0258V19.0258Z"
        fill="#F0F0F0"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }} opacity="0.22">
      <path
        d="M18.652 17.529H18.8339V18.204H16.3545C16.3645 18.1386 16.3776 18.0738 16.3919 18.01C16.4027 17.962 16.4156 17.9129 16.4287 17.8654C16.4577 17.76 16.4928 17.6563 16.534 17.555C16.5367 17.5461 16.54 17.5374 16.5439 17.5288C16.8439 16.7914 17.4113 16.1945 18.1326 15.8575C18.8538 15.5205 19.6757 15.4683 20.4338 15.7113L18.652 17.529Z"
        fill="#D2D2D2"
      />
    </g>
    <path
      d="M22.7461 19.0506C22.7461 19.0426 22.7468 19.0348 22.7468 19.0268C22.747 18.8415 22.7305 18.6566 22.6976 18.4743C22.5726 17.7704 22.2123 17.13 21.6757 16.6577C21.1391 16.1854 20.458 15.9094 19.744 15.8748C19.03 15.8402 18.3254 16.0491 17.7457 16.4673C17.1659 16.8854 16.7454 17.4881 16.5529 18.1765C16.5397 18.2239 16.5274 18.2735 16.516 18.3214C16.4633 18.5528 16.4368 18.7894 16.437 19.0267C16.437 19.0346 16.437 19.0426 16.437 19.0505L22.7461 19.0506Z"
      fill="#CECECE"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.3">
      <path
        d="M22.6979 18.4738C22.5685 17.7443 22.1865 17.0838 21.6188 16.6079C21.0511 16.1319 20.334 15.8711 19.5932 15.8711C19.4312 15.8713 19.2695 15.8838 19.1094 15.9086C19.7597 16.009 20.3622 16.3104 20.8326 16.7705C21.303 17.2306 21.6176 17.8264 21.7323 18.4743C21.7652 18.6566 21.7817 18.8416 21.7815 19.0269C21.7815 19.0349 21.7809 19.0426 21.7808 19.0506H22.7469C22.7469 19.0426 22.7476 19.0349 22.7476 19.0269C22.7476 18.8414 22.731 18.6563 22.6979 18.4738V18.4738Z"
        fill="#D6D6D6"
      />
    </g>
    <path
      d="M18.8447 11.3117L27.0408 11.3225C27.0735 11.3225 27.106 11.3161 27.1362 11.3036C27.1665 11.2911 27.194 11.2728 27.2172 11.2497C27.2404 11.2265 27.2588 11.199 27.2713 11.1688C27.2838 11.1385 27.2903 11.1061 27.2902 11.0733V11.0733C27.2902 11.0073 27.264 10.9439 27.2173 10.8972C27.1706 10.8505 27.1072 10.8242 27.0411 10.8242H19.4465L18.8447 11.3117Z"
      fill="#D5D5D5"
    />
    <path
      d="M19.935 9.9082L27.228 9.9082V6.96446L19.935 6.96446V9.9082Z"
      fill="#EEEEEE"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.25">
      <path
        d="M19.9351 7.0746L27.2281 7.65673V6.9668H19.9351V7.0746Z"
        fill="#EEEEEE"
      />
    </g>
    <path
      d="M19.4467 10.8301L26.7397 10.8301V9.9088H19.4467V10.8301Z"
      fill="#E9E9E9"
    />
    <path d="M26.7398 10.8297V9.9082H19.4468" fill="#E9E9E9" />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.25">
      <path d="M26.7398 10.0959V9.9082H19.4468" fill="#E9E9E9" />
    </g>
    <g style={{ mixBlendMode: "multiply" }} opacity="0.55">
      <path
        d="M24.5473 6.96484V8.55298L24.2749 8.39128L24.0438 8.51881L23.8126 8.39128L23.5815 8.51881L23.3504 8.39128L23.1193 8.51881L22.8881 8.39128L22.6157 8.55298V6.96484H24.5473Z"
        fill="#E9E9E9"
      />
    </g>
    <path
      d="M19.2866 6.96484L26.5796 6.96484V6.04357L19.2866 6.04357V6.96484Z"
      fill="#E9E9E9"
    />
    <path
      d="M23.4124 6.04404C23.2384 6.04345 23.0661 6.07855 22.9062 6.14715C22.7463 6.21575 22.6022 6.31641 22.4827 6.44291C22.215 6.71363 21.9945 7.0272 21.8302 7.37065C21.6813 7.66709 21.5624 7.97767 21.4753 8.29774C21.3322 8.81153 21.1509 9.32057 21.0187 9.83565C20.9462 10.1178 20.8638 10.3992 20.7965 10.6818C20.7636 10.82 20.5894 11.212 20.7804 11.3224H20.8958C20.8788 11.3468 20.8551 11.3655 20.8275 11.3764C20.7853 11.3869 20.7409 11.3836 20.7008 11.3668C20.5595 11.2956 20.5879 11.1067 20.6104 10.982C20.653 10.7467 20.7074 10.5138 20.7696 10.2803C20.9244 9.6982 21.0912 9.11973 21.2655 8.54008C21.4898 7.79625 21.7149 6.99022 22.4113 6.35742C22.6377 6.15173 22.9759 5.92923 23.3763 6.00868C23.4027 6.01415 23.4274 6.02603 23.4482 6.04328L23.4124 6.04404Z"
      fill="#CDCDCD"
    />
    <path
      d="M24.2196 6.00726C24.3336 5.99309 24.4487 6.02416 24.5402 6.09382C24.6316 6.16348 24.6921 6.26619 24.7088 6.37993C24.7588 6.58735 24.8035 6.7954 24.8459 7.00357C24.9513 7.52231 25.0371 8.04267 25.1171 8.56325C25.2465 9.40561 25.3816 10.2527 25.4272 11.0991C25.4306 11.1637 25.4609 11.2655 25.4227 11.3279C25.3392 11.4648 24.893 11.4108 24.7628 11.3226H24.9552C24.9552 11.3226 25.2382 11.3678 25.2949 11.3029C25.3031 11.2935 25.298 11.2546 25.2983 11.2382C25.3002 11.1511 25.2923 11.0636 25.2859 10.9765C25.2625 10.6631 25.2261 10.3501 25.188 10.0372C25.0828 9.17577 24.9554 8.31519 24.7999 7.45687C24.7567 7.22219 24.7131 6.98751 24.6646 6.75314C24.6424 6.64606 24.6192 6.53905 24.5948 6.43211C24.5535 6.25219 24.5021 6.03734 24.1338 6.04295H24.0841C24.0841 6.04295 24.0762 6.01028 24.2196 6.00726Z"
      fill="#CDCDCD"
    />
    <path
      d="M10.3421 15.1333L9.36049 15.3981L9.0828 12.6746C8.9917 11.8338 9.53168 11.1616 10.244 10.7051C12.2327 9.43023 14.3556 9.63139 14.4972 9.61716L14.639 10.1012C14.7379 10.4386 14.838 10.7757 14.9355 11.1136C14.957 11.1877 14.9976 11.2795 14.9139 11.3251C14.8396 11.3466 14.7627 11.3575 14.6854 11.3574C14.6114 11.3685 14.5377 11.3809 14.4642 11.3946C14.3109 11.423 14.1587 11.4566 14.0076 11.4955C13.9173 11.5188 13.6104 11.6734 13.5517 11.5893C13.4877 11.5004 13.4007 11.4308 13.3 11.3878C13.1993 11.3448 13.0888 11.3302 12.9804 11.3454C12.6938 11.3762 12.4111 11.4363 12.1367 11.5247C11.609 11.6701 11.111 11.9073 10.6655 12.2254C10.5974 12.2755 10.5413 12.3402 10.5016 12.4149C10.4619 12.4895 10.4395 12.5721 10.436 12.6566L10.3421 15.1333Z"
      fill="#CECECE"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.35">
      <g style={{ mixBlendMode: "multiply" }} opacity="0.35">
        <path
          d="M14.9143 11.3254C14.8402 11.347 14.7634 11.3581 14.6862 11.3584C14.612 11.3692 14.5379 11.3821 14.4643 11.3952C14.3116 11.4239 14.1589 11.4575 14.0081 11.4962C13.9178 11.5199 13.6111 11.6745 13.5524 11.5903C13.4884 11.5013 13.4013 11.4316 13.3004 11.3886C13.1996 11.3456 13.0889 11.3311 12.9803 11.3466C12.6939 11.3772 12.4112 11.4371 12.137 11.5254C11.6094 11.6709 11.1115 11.908 10.6661 12.2261C10.5978 12.2763 10.5417 12.3412 10.5019 12.4159C10.4621 12.4907 10.4396 12.5735 10.4361 12.6581L10.342 15.1338L10.2347 15.1624L10.0178 13.0339C9.92685 12.1925 10.4666 11.5206 11.179 11.0643C12.2114 10.421 13.3903 10.05 14.6051 9.98633C14.6159 10.0249 14.6275 10.063 14.6388 10.1017C14.7378 10.4389 14.8382 10.7761 14.9354 11.1139C14.9567 11.1883 14.9979 11.28 14.9143 11.3254Z"
          fill="#CECECE"
        />
      </g>
    </g>
    <path
      d="M16.9748 9.35506V10.0644C16.9748 10.7787 16.3153 11.4481 15.6017 11.6308C15.4794 11.6631 15.3535 11.6796 15.2271 11.68L14.5321 11.6706L14.0347 11.6644L13.9161 10.5611L13.749 9.00977L16.9748 9.35506Z"
      fill="#CECECE"
    />
    <path
      d="M16.3657 11.0223C16.1194 11.2311 15.8564 11.4256 15.6015 11.6306C15.4737 11.7328 15.3478 11.8381 15.2275 11.9491C15.0432 12.1156 14.8782 12.3023 14.7357 12.5056C14.5913 12.7304 14.4874 12.9787 14.4285 13.2392C14.3736 13.4492 14.3331 13.6631 14.2907 13.8753C14.1824 14.4514 14.1304 15.0367 14.1355 15.6229C14.1406 16.2223 14.1764 16.821 14.2426 17.4168C14.2457 17.4448 14.2558 17.5209 14.2706 17.6268C14.3367 18.1011 14.4907 19.1725 14.4533 19.1849L13.4291 19.5299C12.9225 17.1446 12.1545 14.3662 13.0633 12.0423C13.27 11.5075 13.5576 11.0077 13.916 10.5601C14.2217 10.1759 14.585 9.84139 14.993 9.56836L15.5758 10.0851C15.7111 10.1897 15.8532 10.2914 15.9941 10.3949C16.1448 10.5038 16.2896 10.6207 16.4279 10.7451C16.5633 10.8708 16.4848 10.9219 16.3657 11.0223Z"
      fill="#CECECE"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.35">
      <path
        d="M16.9749 9.9082V10.0634C16.9749 10.7777 16.3154 11.4472 15.6018 11.6298C15.4741 11.732 15.3481 11.8373 15.2278 11.9482C15.0436 12.1147 14.8786 12.3014 14.736 12.5048C14.5917 12.7296 14.4877 12.9779 14.4288 13.2384C14.3739 13.4484 14.3334 13.6623 14.291 13.8744C14.1827 14.4506 14.1307 15.0359 14.1358 15.6221C14.141 16.2215 14.1767 16.8202 14.243 17.416C14.2461 17.444 14.2561 17.5201 14.271 17.626L14.243 17.6983C13.022 14.7857 13.5804 12.8778 14.5322 11.6693C14.9405 11.1611 15.4355 10.7294 15.9944 10.394C16.3066 10.204 16.6346 10.0415 16.9749 9.9082V9.9082Z"
        fill="#CECECE"
      />
    </g>
    <path
      d="M10.3342 16.6764H9.594L9.49268 15.3827L10.3227 15.3711L10.3342 16.6764Z"
      fill="white"
    />
    <path
      d="M10.4279 15.5232L9.33712 15.6789L9.28516 15.2219L10.407 14.9336L10.4279 15.5232Z"
      fill="#D5D5D5"
    />
    <path
      d="M14.5591 21.1016L13.8405 21.1699L13.6631 19.9278L14.4188 19.6992L14.5591 21.1016Z"
      fill="white"
    />
    <path
      d="M14.6535 19.7954L13.4693 20.0002L13.3447 19.4652L14.568 19.1016L14.6535 19.7954Z"
      fill="#D5D5D5"
    />
    <path
      d="M14.6628 4.24333C14.6628 4.24333 14.7852 4.8039 14.5591 5.2226C14.3759 5.56261 14.6967 5.85475 15.046 5.87415C15.3441 5.89075 15.8458 5.81346 15.8302 5.51528C15.7996 4.86666 15.8655 4.2171 16.026 3.58789L14.6628 4.24333Z"
      fill="#F7F7F7"
    />
    <path
      d="M14.082 5.34417C14.082 5.34417 14.5252 5.24596 14.6103 5.12403C14.7935 4.86305 15.8085 4.78252 15.8168 5.06119C15.8229 5.27032 16.3506 5.25976 16.3506 5.25976C16.3506 5.25976 15.9071 6.1322 14.9923 6.03766C14.4586 5.98235 14.082 5.34417 14.082 5.34417Z"
      fill="#F7F7F7"
    />
    <path
      d="M14.5874 5.16581C14.7896 5.07579 15.4684 4.74732 15.4802 4.38964C15.4863 4.20443 14.882 4.23192 14.6641 4.24777C14.6742 4.29822 14.7645 4.77654 14.5874 5.16581Z"
      fill="#F3F3F3"
    />
    <path
      d="M16.7038 3.72488C16.6409 3.69707 16.5868 3.65256 16.5474 3.59616C16.2161 4.21193 15.6095 4.56444 14.9367 4.42537C14.2699 4.28739 13.9538 3.74622 13.8681 3.04982C13.8421 2.82907 13.8357 2.60645 13.849 2.38458C13.8551 2.25998 13.8705 2.13602 13.8951 2.01374C13.9165 1.90644 13.9473 1.80122 13.987 1.69928C14.2775 0.966227 14.9687 0.789755 15.6579 0.931946C15.8788 0.97858 16.0849 1.07863 16.2581 1.22333C16.4314 1.36804 16.5665 1.55299 16.6518 1.76202C16.7791 2.062 16.8306 2.38876 16.8016 2.71337C16.8545 2.68437 16.9131 2.66748 16.9733 2.66392C17.0335 2.66036 17.0938 2.67021 17.1497 2.69278C17.3778 2.79196 16.932 3.82341 16.7038 3.72488Z"
      fill="#F7F7F7"
    />
    <path
      d="M16.6875 3.37783C16.7162 3.33396 16.7548 3.2975 16.8003 3.27143C16.8231 3.25861 16.8484 3.25096 16.8745 3.249C16.9005 3.24704 16.9267 3.25084 16.9512 3.26011C16.9753 3.27017 16.9956 3.28761 17.0091 3.30993C17.0227 3.33224 17.0288 3.35828 17.0266 3.3843C17.0247 3.39705 17.0202 3.40928 17.0134 3.42023C17.0065 3.43118 16.9976 3.44062 16.987 3.44798C16.9764 3.45535 16.9644 3.46048 16.9518 3.46306C16.9391 3.46564 16.9261 3.46562 16.9135 3.463C16.8911 3.45399 16.8725 3.43767 16.8606 3.4167C16.8487 3.39572 16.8443 3.37134 16.8481 3.34754C16.8572 3.29992 16.8814 3.2565 16.9171 3.22378C17.0038 3.13474 17.1187 3.07845 17.2421 3.06445"
      stroke="#F3F3F3"
      stroke-miterlimit="10"
    />
    <path
      d="M16.6518 1.76224C16.5682 1.8502 16.4324 3.58894 16.4004 3.6188C16.6217 2.26837 13.6601 2.07076 13.9872 1.69928C14.2777 0.966227 14.969 0.789755 15.6581 0.931946C15.879 0.978617 16.085 1.0787 16.2583 1.22345C16.4315 1.36819 16.5666 1.55318 16.6518 1.76224V1.76224Z"
      fill="#F3F3F3"
    />
    <path
      d="M16.5856 3.80482C16.6171 3.85613 16.3709 4.1085 16.0419 4.28777C15.8251 4.39835 15.5857 4.45766 15.3423 4.46112C15.3605 4.46112 15.3931 4.4374 15.4087 4.42975C15.4691 4.40043 15.5286 4.36949 15.5875 4.33747C15.7357 4.26645 15.8742 4.17686 15.9998 4.07088C16.1194 3.95865 16.1938 3.80741 16.3141 3.69605C16.4269 3.59202 16.5229 3.70295 16.5856 3.80482Z"
      fill="#F0F0F0"
    />
    <path
      d="M8.76819 8.44243C9.0199 8.72055 8.47055 8.9437 8.27435 8.99836C7.97886 9.08072 7.9904 9.05646 7.81824 8.85164C7.64608 8.64682 7.65136 8.46894 7.81683 8.36125C7.98231 8.25356 8.58773 8.2431 8.76819 8.44243Z"
      fill="#F7F7F7"
    />
    <path
      d="M7.98809 8.55645C7.92076 8.61497 7.85018 8.66964 7.77669 8.7202C7.75243 8.73579 7.72345 8.74234 7.69485 8.73869C7.66624 8.73504 7.63984 8.72143 7.62027 8.70026V8.70026C7.61097 8.69079 7.60385 8.6794 7.59943 8.66689C7.59501 8.65438 7.59338 8.64106 7.59466 8.62785C7.59595 8.61464 7.60011 8.60188 7.60686 8.59045C7.61362 8.57903 7.62279 8.56923 7.63374 8.56173C7.63374 8.56173 7.80967 8.45318 7.8723 8.42342C7.92674 8.39744 7.97375 8.38246 8.01309 8.42601C8.05233 8.46956 8.03466 8.50761 7.98809 8.55645Z"
      fill="#F7F7F7"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.7">
      <path
        d="M7.66338 8.72997C7.68154 8.73779 7.70134 8.74099 7.72104 8.7393C7.74073 8.7376 7.7597 8.73107 7.77625 8.72027C7.84975 8.66971 7.92032 8.61504 7.98765 8.55652C8.00818 8.53795 8.02365 8.51447 8.0326 8.48828C8.01333 8.49289 7.99447 8.49909 7.97622 8.50682C7.90777 8.53464 7.71254 8.6378 7.71254 8.6378C7.69641 8.64688 7.68321 8.66038 7.6745 8.67671C7.66579 8.69304 7.66193 8.71152 7.66338 8.72997V8.72997Z"
        fill="#F6F6F6"
      />
    </g>
    <path
      d="M8.10484 8.66331C8.03311 8.72475 7.95815 8.78233 7.88029 8.83579C7.85572 8.85254 7.82603 8.86008 7.79645 8.85708C7.76686 8.85409 7.73929 8.84074 7.71858 8.81941V8.81941C7.70932 8.80936 7.70237 8.7974 7.69823 8.78438C7.69409 8.77135 7.69286 8.75758 7.69463 8.74403C7.6964 8.73048 7.70113 8.71748 7.70847 8.70595C7.71581 8.69443 7.72559 8.68465 7.73712 8.67732C7.73712 8.67732 7.92309 8.56187 7.98906 8.52963C8.04641 8.50171 8.09567 8.48511 8.13491 8.52866C8.17448 8.57265 8.15497 8.6121 8.10484 8.66331Z"
      fill="#F7F7F7"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.7">
      <path
        d="M7.76708 8.85016C7.78575 8.85664 7.80567 8.8587 7.82528 8.85617C7.84488 8.85365 7.86363 8.84661 7.88005 8.83561C7.95792 8.78215 8.03288 8.72457 8.10461 8.66312C8.12837 8.6428 8.14568 8.61597 8.15441 8.58594C8.12731 8.58895 8.10086 8.59623 8.07604 8.6075C8.00748 8.6352 7.81214 8.73805 7.81214 8.73805C7.79283 8.74865 7.77778 8.76561 7.76957 8.78605C7.76135 8.80649 7.76048 8.82914 7.76708 8.85016V8.85016Z"
        fill="#F6F6F6"
      />
    </g>
    <path
      d="M8.19653 8.75503C8.12482 8.8165 8.04986 8.87408 7.97198 8.92752C7.94743 8.94428 7.91774 8.95184 7.88816 8.94886C7.85858 8.94588 7.831 8.93256 7.81028 8.91124V8.91124C7.80104 8.90118 7.79411 8.88921 7.78999 8.87619C7.78587 8.86316 7.78466 8.84939 7.78644 8.83584C7.78822 8.8223 7.79294 8.80931 7.80028 8.79778C7.80762 8.78626 7.8174 8.77649 7.82892 8.76916C7.82892 8.76916 8.01489 8.65359 8.08086 8.62147C8.13821 8.59344 8.18747 8.57694 8.22671 8.6205C8.26628 8.66426 8.24677 8.70372 8.19653 8.75503Z"
      fill="#F7F7F7"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.7">
      <path
        d="M7.89685 8.94884C7.92367 8.94999 7.95015 8.94242 7.97232 8.92728C8.0502 8.87385 8.12516 8.81627 8.19687 8.7548C8.20107 8.75049 8.20484 8.74628 8.20861 8.74219C8.19783 8.74434 8.18705 8.74715 8.17627 8.75049C8.11558 8.76849 7.9404 8.83996 7.9404 8.83996C7.93026 8.84408 7.92106 8.85021 7.91333 8.85797C7.90561 8.86573 7.89954 8.87498 7.89547 8.88514C7.8914 8.89531 7.88943 8.90619 7.88967 8.91713C7.8899 8.92808 7.89235 8.93886 7.89685 8.94884V8.94884Z"
        fill="#F6F6F6"
      />
    </g>
    <path
      d="M8.26755 8.88897C8.20011 8.93451 8.13034 8.9765 8.05852 9.01477C8.03648 9.02677 8.01088 9.03054 7.98632 9.02541C7.96175 9.02027 7.93981 9.00657 7.92442 8.98674V8.98674C7.91776 8.97711 7.91328 8.96614 7.91131 8.9546C7.90933 8.94306 7.9099 8.93122 7.91298 8.91992C7.91607 8.90863 7.92158 8.89814 7.92914 8.8892C7.93671 8.88025 7.94613 8.87307 7.95676 8.86816C7.95676 8.86816 8.12666 8.78731 8.18584 8.76596C8.23737 8.74753 8.2807 8.73783 8.30884 8.77804C8.33622 8.81879 8.31531 8.85026 8.26755 8.88897Z"
      fill="#F7F7F7"
    />
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M8.2658 8.43298C8.18333 8.40064 7.80613 8.56741 7.74005 8.40797C7.73293 8.39072 7.774 8.40096 7.85583 8.34329C7.98494 8.28779 8.12 8.24732 8.25836 8.22266C8.31778 8.25629 8.36167 8.31186 8.38061 8.37746C8.38564 8.38829 8.3874 8.40035 8.38568 8.41216C8.38395 8.42398 8.37882 8.43503 8.3709 8.44397C8.35107 8.46176 8.29146 8.443 8.2658 8.43298Z"
        fill="#F6F6F6"
      />
    </g>
    <path
      d="M8.26347 8.42044C8.18089 8.3881 7.80251 8.53331 7.73955 8.40815C7.73104 8.39133 7.7651 8.37204 7.84736 8.3148C7.96323 8.25707 8.08777 8.21869 8.21604 8.20117C8.3362 8.22017 8.45116 8.26369 8.55378 8.32903C8.5983 8.3632 8.44113 8.38551 8.45676 8.43564C8.46398 8.45828 8.48296 8.50733 8.46409 8.52382C8.44523 8.54032 8.28913 8.43047 8.26347 8.42044Z"
      fill="#F7F7F7"
    />
    <path
      d="M14.2322 5.22656C14.2403 5.49229 14.0581 5.79468 13.9966 6.04963L13.9567 6.21608L13.7255 7.17659C13.6158 7.26283 13.3746 7.41968 13.1147 7.583C12.9919 7.66094 12.8648 7.73942 12.7463 7.81295C12.4483 7.99621 8.92915 8.91964 8.63819 8.84946L8.49805 8.28889L10.064 7.60726C10.4017 7.47225 10.7317 7.31843 11.0522 7.14651C11.5355 6.86806 11.9477 6.4637 12.3867 6.1224C12.6383 5.91154 12.9044 5.71844 13.1828 5.54447C13.3355 5.4582 13.4985 5.39148 13.6679 5.3459C13.853 5.2928 14.0415 5.25292 14.2322 5.22656V5.22656Z"
      fill="#E9E9E9"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.25">
      <path
        d="M13.9967 6.05055L13.9568 6.217L13.7256 7.17751C13.6159 7.26375 13.3747 7.42061 13.1147 7.58393C13.1172 6.82845 13.5111 5.91602 13.5111 5.91602C13.5111 5.91602 13.7268 5.97509 13.9967 6.05055Z"
        fill="#D8D8D8"
      />
    </g>
    <path
      d="M17.1681 6.161L17.1674 6.73505L17.1668 7.57461V7.74979L17.1661 8.39487L17.1363 8.66783C17.1232 8.78997 17.1094 8.91275 17.0964 9.03489C17.0627 9.3478 17.0289 9.66043 16.9948 9.97277C16.9961 10.0942 16.9827 10.2154 16.9549 10.3337C16.9386 10.3735 16.9073 10.4053 16.8677 10.4222C16.8309 10.437 16.7916 10.4448 16.7519 10.4453C16.7189 10.4466 16.6851 10.4471 16.6521 10.4471C16.5961 10.4471 16.5399 10.4458 16.4838 10.4428C16.4489 10.4415 16.4135 10.439 16.3785 10.4365C16.252 10.4272 16.1255 10.4128 16.0012 10.396C15.515 10.3311 15.0392 10.2035 14.5858 10.0164C14.3969 9.93873 14.2125 9.85052 14.0335 9.7522C13.8509 9.65184 13.7243 9.62284 13.7156 9.41004C13.717 9.0641 13.6962 8.71842 13.6533 8.37514C13.5966 8.05098 13.5704 7.93078 13.5224 7.62344C13.5068 7.52308 13.4931 7.43209 13.4813 7.3479C13.412 6.85546 13.4064 6.57755 13.5112 5.915V5.91435L14.1944 5.22938L14.5696 5.16211C14.5696 5.16211 14.6559 5.47937 15.0794 5.50988C15.5986 5.54729 15.8093 5.22442 15.8398 5.17138C15.8417 5.16825 15.843 5.16577 15.8436 5.16448L15.8443 5.16383L15.8454 5.16513L17.1681 6.161Z"
      fill="#E9E9E9"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.25">
      <path
        d="M16.3831 6.58594C16.3831 6.58594 16.4563 7.13411 16.5182 7.82469C16.5046 7.78631 16.4973 7.80647 16.5 7.90759C16.5156 8.49974 15.6547 8.46266 15.6547 8.46266C15.6547 8.46266 15.4975 8.49187 15.334 9.08403C15.6486 9.1941 16.3198 9.32119 16.6007 9.62574C16.5916 9.98148 16.5575 10.2772 16.4841 10.4434C16.5402 10.4465 16.5963 10.4477 16.6524 10.4477C16.6855 10.4477 16.7191 10.4471 16.7521 10.4459C16.7918 10.4455 16.8311 10.4376 16.868 10.4228C16.9076 10.4059 16.939 10.3742 16.9553 10.3343C16.9831 10.2161 16.9965 10.0949 16.9952 9.9734C17.0288 9.66048 17.0627 9.34786 17.0968 9.03552C17.1098 8.91338 17.1236 8.79059 17.1367 8.66845C17.1466 8.57747 17.1566 8.48648 17.1666 8.3955L17.1673 7.75041V7.57513L17.1679 6.73557L16.3831 6.58594Z"
        fill="#D8D8D8"
      />
    </g>
    <path
      d="M18.227 8.79203C18.1795 8.31543 18.1334 7.77729 18.0653 7.30307C18.0105 6.92328 17.9575 6.52388 17.7872 6.17201C17.5931 5.77002 17.2572 5.55571 16.8522 5.39584C16.5257 5.27582 16.1851 5.19793 15.8389 5.16406L16.1713 6.28402C16.2821 6.59979 16.4172 6.90654 16.5753 7.20152C16.6923 7.44849 16.7993 7.70032 16.8987 7.95505C16.9202 8.01186 17.1274 8.66655 17.0902 8.66256L18.227 8.79203Z"
      fill="#E9E9E9"
    />
    <path
      d="M15.0777 8.47343C14.9885 8.49164 14.8983 8.50467 14.8076 8.51245C14.7789 8.51417 14.7508 8.50449 14.7292 8.48553C14.7077 8.46656 14.6946 8.43985 14.6927 8.41123V8.41123C14.6893 8.38274 14.6973 8.35407 14.715 8.33147C14.7326 8.30888 14.7585 8.29418 14.787 8.2906C14.787 8.2906 14.995 8.2837 15.0639 8.2893C15.1237 8.29426 15.171 8.30526 15.1766 8.36627C15.1822 8.42783 15.1451 8.45316 15.0777 8.47343Z"
      fill="#F7F7F7"
    />
    <path
      d="M14.71 8.4612C14.7198 8.47784 14.7341 8.49142 14.7513 8.5004C14.7684 8.50939 14.7877 8.51343 14.807 8.51209C14.8977 8.5043 14.9879 8.49127 15.0771 8.47306C15.1057 8.46644 15.1323 8.45302 15.1546 8.43393C15.1361 8.42792 15.1171 8.42367 15.0977 8.42121C15.0254 8.41043 14.8049 8.40234 14.8049 8.40234C14.7853 8.40289 14.7662 8.40862 14.7495 8.41896C14.7328 8.4293 14.7192 8.44387 14.71 8.4612V8.4612Z"
      fill="#F7F7F7"
    />
    <path
      d="M15.1082 8.63314C15.0136 8.65187 14.918 8.6654 14.8218 8.67367C14.7924 8.67628 14.7632 8.66716 14.7405 8.6483C14.7178 8.62944 14.7035 8.60236 14.7006 8.57298V8.57298C14.698 8.5436 14.7071 8.51436 14.7259 8.49165C14.7448 8.46895 14.7719 8.45463 14.8012 8.45181C14.8012 8.45181 15.0217 8.44373 15.0946 8.44912C15.1579 8.45375 15.2079 8.46443 15.2131 8.52544C15.2193 8.58646 15.1798 8.61255 15.1082 8.63314Z"
      fill="#F7F7F7"
    />
    <path
      d="M14.7217 8.62665C14.7329 8.64248 14.7481 8.6551 14.7657 8.66325C14.7833 8.6714 14.8027 8.6748 14.822 8.67311C14.9182 8.66485 15.0138 8.65132 15.1085 8.63258C15.141 8.62587 15.171 8.6104 15.1954 8.58784C15.1713 8.57618 15.1453 8.56888 15.1187 8.56628C15.0464 8.5555 14.8259 8.54688 14.8259 8.54688C14.8023 8.5474 14.7795 8.55541 14.7608 8.56975C14.742 8.58409 14.7284 8.60401 14.7217 8.62665V8.62665Z"
      fill="#F7F7F7"
    />
    <path
      d="M15.1278 8.76779C15.0331 8.78654 14.9375 8.80007 14.8414 8.80833C14.812 8.811 14.7827 8.80191 14.76 8.78306C14.7373 8.76421 14.723 8.73713 14.7202 8.70775C14.7175 8.67836 14.7266 8.6491 14.7455 8.62639C14.7643 8.60368 14.7914 8.58937 14.8208 8.58658C14.8208 8.58658 15.0413 8.57849 15.1141 8.58388C15.1775 8.58852 15.2274 8.59919 15.2327 8.66021C15.2388 8.72112 15.1994 8.7471 15.1278 8.76779Z"
      fill="#F7F7F7"
    />
    <path
      d="M14.7676 8.78693C14.789 8.80233 14.8153 8.80952 14.8415 8.8072C14.9376 8.79894 15.0332 8.78541 15.1278 8.76667C15.1339 8.76494 15.1394 8.763 15.145 8.76128C15.1353 8.75783 15.1248 8.7547 15.1137 8.75179C15.0537 8.73616 14.8685 8.70932 14.8685 8.70932C14.845 8.70734 14.8216 8.71421 14.803 8.72857C14.7843 8.74293 14.7717 8.76374 14.7676 8.78693V8.78693Z"
      fill="#F7F7F7"
    />
    <path
      d="M15.1049 8.92775C15.0233 8.93406 14.9414 8.93586 14.8596 8.93315C14.8345 8.93241 14.8108 8.92179 14.7936 8.90361C14.7763 8.88543 14.767 8.86117 14.7676 8.83612V8.83612C14.7683 8.81108 14.779 8.78735 14.7971 8.77012C14.8153 8.75289 14.8396 8.74356 14.8646 8.74417C14.8646 8.74417 15.0511 8.75958 15.1126 8.77144C15.1655 8.78222 15.2065 8.79581 15.2052 8.84777C15.2032 8.89962 15.1672 8.91762 15.1049 8.92775Z"
      fill="#F7F7F7"
    />
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M15.5695 8.37808C15.5115 8.31253 15.5766 7.90871 15.3899 7.90041C15.3696 7.89954 15.3924 7.93404 15.3561 8.02869C15.3355 8.16507 15.3324 8.30353 15.3468 8.44071C15.4027 8.48548 15.4735 8.5075 15.545 8.50237C15.5579 8.50339 15.5708 8.50108 15.5826 8.49566C15.5943 8.49025 15.6045 8.4819 15.6121 8.47143C15.6244 8.44718 15.5875 8.39834 15.5695 8.37808Z"
        fill="#F7F7F7"
      />
    </g>
    <path
      d="M15.5031 8.34751C15.4451 8.28197 15.4879 7.8748 15.3374 7.86736C15.2728 7.86424 15.3069 7.90294 15.2707 7.99759C15.2439 8.12196 15.2398 8.25017 15.2588 8.37597C15.2771 8.46706 15.472 8.63394 15.4916 8.64548C15.5405 8.67534 15.5181 8.52269 15.5754 8.52064C15.6012 8.51978 15.6453 8.52064 15.6573 8.49779C15.6693 8.47493 15.5211 8.36789 15.5031 8.34751Z"
      fill="#F7F7F7"
    />
    <path
      d="M18.2332 8.83993C18.23 9.12496 17.6657 9.34832 16.9542 9.34832C16.4511 9.32971 15.9538 9.23522 15.479 9.06804L15.6562 8.48181C15.6562 8.48181 16.487 8.45518 16.9437 8.45518C17.6548 8.45497 18.2379 8.44063 18.2332 8.83993Z"
      fill="#E9E9E9"
    />
    <path
      d="M15.7781 8.78456C15.6846 9.16489 15.2349 9.08921 15.0427 9.03563C14.7532 8.955 14.7773 8.93861 14.7608 8.65833C14.7443 8.37804 14.7135 8.29547 14.9126 8.28393C15.1117 8.2724 15.8736 8.39605 15.7781 8.78456Z"
      fill="#F7F7F7"
    />
    <path
      d="M9.10276 8.52539H7.14163C6.9879 8.52539 6.86328 8.65001 6.86328 8.80374V8.80384C6.86328 8.95757 6.9879 9.08219 7.14163 9.08219H9.10276C9.25648 9.08219 9.3811 8.95757 9.3811 8.80384V8.80374C9.3811 8.65001 9.25648 8.52539 9.10276 8.52539Z"
      fill="#C6C6C6"
    />
    <path
      d="M10.3966 16.1583V17.4828H7.64356C7.64343 17.4017 7.65328 17.3208 7.67288 17.2422C7.71806 17.0614 7.81256 16.8968 7.94583 16.7666C8.09412 16.6211 8.28289 16.5238 8.48743 16.4874C8.50759 16.483 8.52801 16.4799 8.54855 16.4781C8.59073 16.4725 8.63322 16.4698 8.67576 16.47H8.75176L8.88263 16.4693H8.94624L9.0715 16.4687H9.31266L9.46659 16.468V16.1582L10.3966 16.1583Z"
      fill="#E6E6E6"
    />
    <path
      d="M8.52492 16.7203C8.5228 16.7262 8.5189 16.7313 8.51376 16.7349C8.50862 16.7384 8.5025 16.7403 8.49625 16.7403C8.49258 16.7402 8.48894 16.7396 8.48546 16.7384C8.48164 16.737 8.47814 16.7349 8.47516 16.7321C8.47218 16.7293 8.46977 16.726 8.46809 16.7223C8.46641 16.7186 8.46547 16.7145 8.46535 16.7105C8.46522 16.7064 8.4659 16.7023 8.46735 16.6985C8.49188 16.6312 8.49896 16.5587 8.48794 16.4879C8.5081 16.4835 8.52852 16.4804 8.54907 16.4785C8.56173 16.5599 8.55342 16.6431 8.52492 16.7203V16.7203Z"
      fill="#CCCCCC"
    />
    <path
      d="M8.73022 16.7306C8.7279 16.7366 8.72388 16.7417 8.71866 16.7453C8.71345 16.749 8.70726 16.751 8.70089 16.7512C8.69722 16.7512 8.69359 16.7505 8.69011 16.7494C8.68626 16.7479 8.68273 16.7457 8.67974 16.7429C8.67674 16.74 8.67433 16.7366 8.67265 16.7329C8.67097 16.7291 8.67005 16.725 8.66993 16.7209C8.66982 16.7168 8.67052 16.7127 8.672 16.7088C8.69987 16.6326 8.70529 16.5499 8.68763 16.4707H8.75231C8.76881 16.5577 8.76117 16.6476 8.73022 16.7306Z"
      fill="#CCCCCC"
    />
    <path
      d="M8.92475 16.7292C8.92254 16.7352 8.91855 16.7404 8.91331 16.7441C8.90807 16.7478 8.90183 16.7497 8.89543 16.7498C8.89176 16.7499 8.8881 16.7492 8.88466 16.748C8.87694 16.7449 8.87069 16.739 8.86722 16.7314C8.86375 16.7239 8.86331 16.7153 8.866 16.7074C8.89404 16.631 8.89965 16.5482 8.88218 16.4688H8.94577C8.96259 16.5559 8.95532 16.6459 8.92475 16.7292V16.7292Z"
      fill="#CCCCCC"
    />
    <path
      d="M9.11733 16.743C9.11525 16.749 9.11138 16.7541 9.10625 16.7578C9.10112 16.7615 9.09497 16.7635 9.08866 16.7636C9.08492 16.7636 9.08124 16.7627 9.07788 16.7611C9.07402 16.7597 9.07048 16.7576 9.06747 16.7549C9.06446 16.7521 9.06203 16.7487 9.06034 16.745C9.05865 16.7413 9.05772 16.7373 9.05763 16.7332C9.05753 16.7291 9.05825 16.725 9.05976 16.7212C9.08906 16.6402 9.09342 16.5523 9.07227 16.4688H9.13587C9.15603 16.5601 9.14959 16.6552 9.11733 16.743V16.743Z"
      fill="#CCCCCC"
    />
    <path
      d="M9.29665 16.7511C9.29432 16.757 9.29028 16.762 9.28505 16.7656C9.27983 16.7691 9.27365 16.771 9.26733 16.771C9.26366 16.771 9.26002 16.7704 9.25654 16.7692C9.24907 16.766 9.24306 16.7602 9.23971 16.7528C9.23635 16.7454 9.2359 16.737 9.23844 16.7293C9.26876 16.6456 9.27252 16.5546 9.24922 16.4688H9.31282C9.3353 16.5624 9.32967 16.6606 9.29665 16.7511V16.7511Z"
      fill="#CCCCCC"
    />
    <path
      d="M10.3966 16.2129V17.4825H7.64356C7.64343 17.4014 7.65328 17.3206 7.67288 17.2419C8.12565 17.2311 9.26361 17.2964 9.72414 17.2163C10.0823 17.1541 10.2919 16.6062 10.3966 16.2129Z"
      fill="#E2E2E2"
    />
    <path
      d="M14.5939 20.5176V21.842H11.8408C11.8407 21.7609 11.8506 21.6801 11.8701 21.6014C11.9153 21.4207 12.0098 21.2561 12.1431 21.1259C12.2914 20.9804 12.4802 20.8831 12.6847 20.8467C12.7049 20.8423 12.7253 20.8392 12.7458 20.8373C12.788 20.8318 12.8304 20.8291 12.8729 20.8292H12.9489L13.0798 20.8286H13.1434L13.2687 20.8279H13.5099L13.6639 20.8273V20.5176H14.5939Z"
      fill="#E6E6E6"
    />
    <path
      d="M12.7217 21.0777C12.7196 21.0836 12.7157 21.0887 12.7106 21.0923C12.7054 21.0959 12.6993 21.0978 12.693 21.0977C12.6894 21.0976 12.6857 21.097 12.6822 21.0958C12.6784 21.0944 12.6749 21.0923 12.6719 21.0895C12.6689 21.0867 12.6665 21.0834 12.6649 21.0797C12.6632 21.076 12.6623 21.072 12.6621 21.0679C12.662 21.0638 12.6627 21.0598 12.6641 21.056C12.6887 20.9886 12.6957 20.9161 12.6847 20.8453C12.7049 20.8409 12.7253 20.8378 12.7458 20.8359C12.7585 20.9173 12.7502 21.0005 12.7217 21.0777V21.0777Z"
      fill="#CCCCCC"
    />
    <path
      d="M12.927 21.088C12.9247 21.094 12.9207 21.0991 12.9154 21.1028C12.9102 21.1064 12.904 21.1085 12.8977 21.1086C12.894 21.1086 12.8904 21.108 12.8869 21.1068C12.883 21.1053 12.8795 21.1031 12.8765 21.1003C12.8735 21.0974 12.8711 21.094 12.8694 21.0903C12.8677 21.0865 12.8668 21.0825 12.8667 21.0783C12.8666 21.0742 12.8673 21.0701 12.8688 21.0663C12.8967 20.99 12.9021 20.9074 12.8844 20.8281H12.9491C12.9656 20.9152 12.9579 21.005 12.927 21.088V21.088Z"
      fill="#CCCCCC"
    />
    <path
      d="M13.1215 21.0886C13.1193 21.0946 13.1153 21.0998 13.1101 21.1034C13.1048 21.1071 13.0986 21.1091 13.0922 21.1092C13.0885 21.1092 13.0849 21.1086 13.0814 21.1073C13.0737 21.1043 13.0675 21.0983 13.064 21.0908C13.0605 21.0833 13.0601 21.0747 13.0628 21.0668C13.0908 20.9904 13.0964 20.9076 13.0789 20.8281H13.1425C13.1594 20.9152 13.1521 21.0053 13.1215 21.0886V21.0886Z"
      fill="#CCCCCC"
    />
    <path
      d="M13.3141 21.1024C13.312 21.1083 13.3081 21.1135 13.303 21.1172C13.2979 21.1209 13.2917 21.1229 13.2854 21.123C13.2817 21.123 13.278 21.1221 13.2747 21.1205C13.2708 21.1191 13.2672 21.117 13.2642 21.1142C13.2612 21.1115 13.2588 21.1081 13.2571 21.1044C13.2554 21.1007 13.2545 21.0966 13.2544 21.0926C13.2543 21.0885 13.255 21.0844 13.2565 21.0806C13.2858 20.9996 13.2902 20.9116 13.269 20.8281H13.3326C13.3528 20.9194 13.3464 21.0146 13.3141 21.1024V21.1024Z"
      fill="#CCCCCC"
    />
    <path
      d="M13.4934 21.1105C13.4911 21.1163 13.4871 21.1214 13.4818 21.1249C13.4766 21.1285 13.4704 21.1304 13.4641 21.1304C13.4604 21.1304 13.4568 21.1297 13.4533 21.1286C13.4459 21.1254 13.4398 21.1195 13.4365 21.1122C13.4331 21.1048 13.4327 21.0964 13.4352 21.0887C13.4655 21.005 13.4693 20.914 13.446 20.8281H13.5096C13.5321 20.9218 13.5264 21.02 13.4934 21.1105Z"
      fill="#CCCCCC"
    />
    <path
      d="M14.5934 20.5723V21.8418H11.8403C11.8402 21.7607 11.8501 21.6799 11.8697 21.6012C12.3224 21.5905 13.4603 21.6559 13.9209 21.5757C14.279 21.5134 14.4887 20.9655 14.5934 20.5723Z"
      fill="#E2E2E2"
    />
    <path
      d="M17.5926 2.32068C17.4539 3.4679 16.7866 4.20096 16.2989 3.96519C15.7841 3.71617 16.399 3.19032 16.4391 2.8225C16.485 2.40207 13.5349 2.04406 13.5076 1.88041C13.4713 1.66244 13.8059 1.75159 13.8052 1.69715C13.7877 0.526531 14.9586 -0.105943 15.9549 0.0145799C16.9984 0.141247 17.7313 1.17345 17.5926 2.32068Z"
      fill="#E6E6E6"
    />
    <path
      d="M16.5587 3.78766C16.0438 3.53853 16.6587 3.01278 16.6988 2.64496C16.7437 2.23456 13.9324 1.88345 13.7746 1.71484C13.693 1.728 13.4781 1.70255 13.5077 1.87956C13.5349 2.04321 16.485 2.40143 16.4391 2.82165C16.3989 3.18925 15.784 3.715 16.299 3.96435C16.5048 4.06385 16.7425 3.99022 16.959 3.78615C16.8965 3.8158 16.8282 3.8313 16.759 3.83157C16.6898 3.83183 16.6214 3.81683 16.5587 3.78766V3.78766Z"
      fill="#DFDFDF"
    />
    <path
      d="M15.955 0.0143075C15.6996 -0.0153361 15.441 0.0011685 15.1914 0.0630339C15.3593 0.0420459 15.5291 0.0413947 15.6972 0.0610937C16.7402 0.187222 17.4733 1.21942 17.3344 2.36665C17.2321 3.2129 16.8422 3.83276 16.4491 4.0061C16.9139 4.04922 17.4673 3.35282 17.5918 2.31987C17.7309 1.17264 16.9978 0.140328 15.955 0.0143075Z"
      fill="#E1E1E1"
    />
  </svg>
);

export const IconNotProduct = () => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    style={{ opacity: "0.36" }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.36"
      d="M18 14.5099C18.0005 14.691 17.9514 14.8688 17.8579 15.0239C17.7645 15.1791 17.6303 15.3056 17.47 15.3899L9.57 19.8299C9.40304 19.9471 9.204 20.01 9 20.01C8.796 20.01 8.59696 19.9471 8.43 19.8299L0.530004 15.3899C0.369683 15.3056 0.235516 15.1791 0.142077 15.0239C0.048638 14.8688 -0.00049917 14.691 3.82324e-06 14.5099V5.50988C-0.00049917 5.32877 0.048638 5.15098 0.142077 4.99584C0.235516 4.8407 0.369683 4.71412 0.530004 4.62988L8.43 0.189877C8.59696 0.072659 8.796 0.00976563 9 0.00976562C9.204 0.00976562 9.40304 0.072659 9.57 0.189877L17.47 4.62988C17.6303 4.71412 17.7645 4.8407 17.8579 4.99584C17.9514 5.15098 18.0005 5.32877 18 5.50988V14.5099ZM9 2.15988L7.11 3.22988L13 6.61988L14.96 5.50988L9 2.15988ZM3.04 5.50988L9 8.85988L10.96 7.75988L5.08 4.35988L3.04 5.50988ZM2 13.9199L8 17.2999V10.5899L2 7.21988V13.9199ZM16 13.9199V7.21988L10 10.5899V17.2999L16 13.9199Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

export const IconRemove = () => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 10.6562C1 11.4062 1.59375 12 2.34375 12H7.65625C8.40625 12 9 11.4062 9 10.6562V4C9 3.28125 8.40625 2.65625 7.65625 2.65625H2.34375C1.59375 2.65625 1 3.28125 1 4V10.6562ZM9 0.65625H7.34375L6.875 0.1875C6.75 0.0625 6.5625 0 6.40625 0H3.59375C3.4375 0 3.25 0.0625 3.125 0.1875L2.65625 0.65625H1C0.625 0.65625 0.34375 0.96875 0.34375 1.34375C0.34375 1.6875 0.625 2 1 2H9C9.375 2 9.65625 1.6875 9.65625 1.34375C9.65625 0.96875 9.375 0.65625 9 0.65625Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

export const IconRemove2 = ({ ...rest }) => (
  <svg
    width="14"
    {...rest}
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 15.9844C1 17.1094 1.89062 18 3.01562 18H10.9844C12.1094 18 13 17.1094 13 15.9844V6C13 4.92188 12.1094 3.98438 10.9844 3.98438H3.01562C1.89062 3.98438 1 4.92188 1 6V15.9844ZM13 0.984375H10.5156L9.8125 0.28125C9.625 0.09375 9.34375 0 9.10938 0H4.89062C4.65625 0 4.375 0.09375 4.1875 0.28125L3.48438 0.984375H1C0.4375 0.984375 0.015625 1.45312 0.015625 2.01562C0.015625 2.53125 0.4375 3 1 3H13C13.5625 3 13.9844 2.53125 13.9844 2.01562C13.9844 1.45312 13.5625 0.984375 13 0.984375Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

export const IconSecurityIlustre = () => (
  <svg
    width="43"
    height="57"
    viewBox="0 0 43 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.5 19.375H34.875V14C34.875 6.625 28.875 0.625 21.5 0.625C14.125 0.625 8.125 6.625 8.125 14V19.375H5.5C2.625 19.375 0.125 21.75 0.125 24.625V51.375C0.125 54.25 2.625 56.625 5.5 56.625H37.5C40.375 56.625 42.875 54.25 42.875 51.375V24.625C42.875 21.75 40.375 19.375 37.5 19.375ZM21.5 43.375C18.625 43.375 16.125 40.875 16.125 38C16.125 35.125 18.625 32.625 21.5 32.625C24.375 32.625 26.875 35.125 26.875 38C26.875 40.875 24.375 43.375 21.5 43.375ZM13.5 19.375V14C13.5 9.625 17.125 6 21.5 6C25.875 6 29.5 9.625 29.5 14V19.375H13.5Z"
      fill="#AA00FF"
    />
  </svg>
);

export const IconCheck = () => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 11.1719L2.53125 7.70312C2.15625 7.32812 1.5 7.32812 1.125 7.70312C0.75 8.07812 0.75 8.73438 1.125 9.10938L5.29688 13.2812C5.67188 13.7031 6.32812 13.7031 6.70312 13.2812L17.2969 2.6875C17.6719 2.3125 17.6719 1.70312 17.2969 1.28125C16.9219 0.90625 16.2656 0.90625 15.8906 1.28125L6 11.1719Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);
export const IconCheckWhite = () => (
  <svg
    width={15}
    height={16}
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5.512A7.472 7.472 0 0114.988 8 7.472 7.472 0 017.5 15.488 7.472 7.472 0 01.012 8 7.472 7.472 0 017.5.512zM5.46 11.234c.317.282.774.282 1.056 0L12.21 5.54c.316-.316.316-.773 0-1.055a.705.705 0 00-1.055 0L5.988 9.617 3.844 7.473c-.317-.282-.774-.282-1.055 0a.705.705 0 000 1.054l2.672 2.707z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);

export const IconArrowBack = ({ ...rest }) => (
  <svg
    {...rest}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9844 7.01562H3.82812L8.70312 2.14062C9.07812 1.71875 9.07812 1.10938 8.70312 0.6875C8.32812 0.3125 7.67188 0.3125 7.29688 0.6875L0.6875 7.29688C0.3125 7.67188 0.3125 8.32812 0.6875 8.70312L7.29688 15.3125C7.67188 15.6875 8.32812 15.6875 8.70312 15.3125C9.07812 14.8906 9.07812 14.2812 8.70312 13.8594L3.82812 8.98438H14.9844C15.5469 8.98438 16.0156 8.5625 16.0156 8C16.0156 7.4375 15.5469 7.01562 14.9844 7.01562Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

export const IconCheckBox = ({ ...rest }) => (
  <svg
    {...rest}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15.9844H3C2.4375 15.9844 2.01562 15.5625 2.01562 15V3C2.01562 2.4375 2.4375 2.01562 3 2.01562H15C15.5625 2.01562 15.9844 2.4375 15.9844 3V15C15.9844 15.5625 15.5625 15.9844 15 15.9844ZM15.9844 0H2.01562C0.890625 0 0 0.890625 0 2.01562V15.9844C0 17.1094 0.890625 18 2.01562 18H15.9844C17.1094 18 18 17.1094 18 15.9844V2.01562C18 0.890625 17.1094 0 15.9844 0Z"
      fill="#AA00FF"
    />
  </svg>
);

export const IconCheckBoxChecked = ({ ...rest }) => (
  <svg
    {...rest}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9844 0H2.01562C0.890625 0 0 0.890625 0 2.01562V15.9844C0 17.1094 0.890625 18 2.01562 18H15.9844C17.1094 18 18 17.1094 18 15.9844V2.01562C18 0.890625 17.1094 0 15.9844 0ZM7.6875 13.3125C7.3125 13.6875 6.70312 13.6875 6.28125 13.3125L2.71875 9.70312C2.29688 9.32812 2.29688 8.67188 2.71875 8.29688C3.09375 7.92188 3.75 7.92188 4.125 8.29688L6.98438 11.1562L13.875 4.3125C14.25 3.89062 14.9062 3.89062 15.2812 4.3125C15.7031 4.6875 15.7031 5.29688 15.2812 5.71875L7.6875 13.3125Z"
      fill="#AA00FF"
    />
  </svg>
);

export const IconCopy = ({ ...rest }) => (
  <svg
    {...rest}
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 18.0156H2.51562V4.98438C2.51562 4.46875 2.04688 4 1.48438 4C0.96875 4 0.5 4.46875 0.5 4.98438V18.0156C0.5 19.0938 1.39062 19.9844 2.51562 19.9844H12.5C13.0625 19.9844 13.4844 19.5625 13.4844 19C13.4844 18.4375 13.0625 18.0156 12.5 18.0156ZM17.5156 13.9844V1.98438C17.5156 0.90625 16.5781 0.015625 15.5 0.015625H6.5C5.42188 0.015625 4.48438 0.90625 4.48438 1.98438V13.9844C4.48438 15.1094 5.42188 16 6.5 16H15.5C16.5781 16 17.5156 15.1094 17.5156 13.9844ZM15.5 13.9844H6.5V1.98438H15.5V13.9844Z"
      fill="#AA00FF"
    />
  </svg>
);

export const IconOpenNewPage = ({ ...rest }) => (
  <svg
    {...rest}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15.9844H3C2.4375 15.9844 2.01562 15.5625 2.01562 15V3C2.01562 2.4375 2.4375 2.01562 3 2.01562H8.01562C8.53125 2.01562 9 1.54688 9 0.984375C9 0.46875 8.53125 0 8.01562 0H2.01562C0.890625 0 0 0.890625 0 2.01562V15.9844C0 17.1094 0.890625 18 2.01562 18H15.9844C17.1094 18 18 17.1094 18 15.9844V9.98438C18 9.46875 17.5312 9 17.0156 9C16.4531 9 15.9844 9.46875 15.9844 9.98438V15C15.9844 15.5625 15.5625 15.9844 15 15.9844ZM11.0156 0.984375C11.0156 1.54688 11.4375 2.01562 12 2.01562H14.5781L5.4375 11.1094C5.0625 11.5312 5.0625 12.1406 5.4375 12.5625C5.85938 12.9375 6.46875 12.9375 6.89062 12.5625L15.9844 3.42188V6C15.9844 6.5625 16.4531 6.98438 17.0156 6.98438C17.5312 6.98438 18 6.5625 18 6V0.984375C18 0.46875 17.5312 0 17.0156 0H12C11.4375 0 11.0156 0.46875 11.0156 0.984375Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

export const IconAddProductsManual = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25 3.5C0.828125 3.5 0.511719 3.85156 0.511719 4.23828V14.0117C0.511719 14.8203 1.17969 15.4883 1.98828 15.4883H11.7617C12.1484 15.4883 12.5 15.1719 12.5 14.75C12.5 14.3281 12.1484 14.0117 11.7617 14.0117H2.76172C2.33984 14.0117 1.98828 13.6602 1.98828 13.2383V4.23828C1.98828 3.85156 1.67188 3.5 1.25 3.5ZM14.0117 0.511719H5.01172C4.16797 0.511719 3.5 1.17969 3.5 1.98828V10.9883C3.5 11.832 4.16797 12.5 5.01172 12.5H14.0117C14.8203 12.5 15.4883 11.832 15.4883 10.9883V1.98828C15.4883 1.17969 14.8203 0.511719 14.0117 0.511719ZM12.5 7.26172H10.25V9.51172C10.25 9.89844 9.89844 10.25 9.51172 10.25C9.08984 10.25 8.73828 9.89844 8.73828 9.51172V7.26172H6.48828C6.10156 7.26172 5.75 6.91016 5.75 6.48828C5.75 6.10156 6.10156 5.75 6.48828 5.75H8.73828V3.5C8.73828 3.07812 9.08984 2.76172 9.51172 2.76172C9.89844 2.76172 10.25 3.07812 10.25 3.5V5.75H12.5C12.9219 5.75 13.2383 6.10156 13.2383 6.48828C13.2383 6.91016 12.9219 7.26172 12.5 7.26172Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

export const IconAddProductsImport = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5547 4.55469L8.93359 0.933594C8.65234 0.652344 8.26562 0.511719 7.87891 0.511719H2.5C1.69141 0.511719 1.02344 1.17969 1.02344 1.98828L0.988281 14.0117C0.988281 14.8203 1.65625 15.4883 2.5 15.4883H11.5C12.3086 15.4883 13.0117 14.8203 13.0117 14.0117V5.60938C13.0117 5.22266 12.8359 4.83594 12.5547 4.55469ZM9.10938 10.25H7.73828V12.5C7.73828 12.9219 7.42188 13.2383 7 13.2383C6.57812 13.2383 6.26172 12.9219 6.26172 12.5V10.25H4.89062C4.57422 10.25 4.39844 9.82812 4.64453 9.61719L6.75391 7.50781C6.89453 7.36719 7.14062 7.36719 7.28125 7.50781L9.35547 9.61719C9.60156 9.82812 9.42578 10.25 9.10938 10.25ZM8.51172 5.75C8.08984 5.75 7.73828 5.39844 7.73828 5.01172V1.63672L11.8867 5.75H8.51172Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

export const IconClose = ({ ...rest }) => (
  <svg
    width="20"
    {...rest}
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.92188 2.96875C4.89062 1 7.25 0.015625 10 0.015625C12.75 0.015625 15.0938 1 17.0312 2.96875C19 4.90625 19.9844 7.25 19.9844 10C19.9844 12.75 19 15.1094 17.0312 17.0781C15.0938 19.0156 12.75 19.9844 10 19.9844C7.25 19.9844 4.89062 19.0156 2.92188 17.0781C0.984375 15.1094 0.015625 12.75 0.015625 10C0.015625 7.25 0.984375 4.90625 2.92188 2.96875ZM14.3125 14.3125C14.75 13.8125 14.75 13.3438 14.3125 12.9062L11.4062 10L14.3125 7.09375C14.75 6.65625 14.75 6.1875 14.3125 5.6875C13.8125 5.25 13.3438 5.25 12.9062 5.6875L10 8.59375L7.09375 5.6875C6.65625 5.25 6.1875 5.25 5.6875 5.6875C5.25 6.1875 5.25 6.65625 5.6875 7.09375L8.59375 10L5.6875 12.9062C5.25 13.3438 5.25 13.8125 5.6875 14.3125C6.1875 14.75 6.65625 14.75 7.09375 14.3125L10 11.4062L12.9062 14.3125C13.3438 14.75 13.8125 14.75 14.3125 14.3125Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

export const IconInfoMin = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.34375 3.65625H7.65625V5H6.34375V3.65625ZM6.34375 6.34375H7.65625V10.3438H6.34375V6.34375ZM7 0.34375C3.3125 0.34375 0.34375 3.3125 0.34375 7C0.34375 10.6875 3.3125 13.6562 7 13.6562C10.6875 13.6562 13.6562 10.6875 13.6562 7C13.6562 3.3125 10.6875 0.34375 7 0.34375ZM7 12.3438C4.0625 12.3438 1.65625 9.9375 1.65625 7C1.65625 4.0625 4.0625 1.65625 7 1.65625C9.9375 1.65625 12.3438 4.0625 12.3438 7C12.3438 9.9375 9.9375 12.3438 7 12.3438Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

export const IconNativigationNext = ({ ...Pathrest }) => (
  <svg
    width="8"
    height="13"
    viewBox="0 0 8 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      {...Pathrest}
      d="M0.828125 1.20312C0.40625 1.57812 0.40625 2.23438 0.828125 2.60938L4.67188 6.5L0.828125 10.3906C0.40625 10.7656 0.40625 11.4219 0.828125 11.7969C1.20312 12.1719 1.8125 12.1719 2.23438 11.7969L6.82812 7.20312C7.20312 6.82812 7.20312 6.17188 6.82812 5.79688L2.23438 1.20312C1.85938 0.828125 1.20312 0.828125 0.828125 1.20312Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

export const IconExcel = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.581 15.85L8.51199 13.9V28.309C8.51212 28.4656 8.54309 28.6205 8.60311 28.7651C8.66314 28.9097 8.75105 29.041 8.86183 29.1516C8.97261 29.2622 9.10409 29.3499 9.24876 29.4097C9.39343 29.4695 9.54846 29.5002 9.70499 29.5H28.805C28.9617 29.5004 29.1169 29.4699 29.2618 29.4102C29.4067 29.3506 29.5385 29.2629 29.6495 29.1523C29.7604 29.0417 29.8485 28.9103 29.9087 28.7656C29.9688 28.6209 29.9999 28.4657 30 28.309V23L19.581 15.85Z"
      fill="#185C37"
    />
    <path
      d="M19.581 3.5H9.70499C9.54846 3.49987 9.39343 3.53057 9.24876 3.59035C9.10409 3.65014 8.97261 3.73783 8.86183 3.84842C8.75105 3.95902 8.66314 4.09035 8.60311 4.23492C8.54309 4.37949 8.51212 4.53446 8.51199 4.691V10L19.581 16.5L25.442 18.45L30 16.5V10L19.581 3.5Z"
      fill="#21A366"
    />
    <path d="M8.51199 10H19.581V16.5H8.51199V10Z" fill="#107C41" />
    <path
      opacity="0.1"
      d="M16.434 8.69995H8.51199V24.95H16.434C16.7497 24.9484 17.052 24.8225 17.2755 24.5996C17.499 24.3766 17.6256 24.0746 17.628 23.759V9.89095C17.6256 9.57529 17.499 9.27327 17.2755 9.05034C17.052 8.82741 16.7497 8.70152 16.434 8.69995Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M15.783 9.34998H8.51199V25.6H15.783C16.0987 25.5984 16.401 25.4725 16.6245 25.2496C16.848 25.0267 16.9746 24.7246 16.977 24.409V10.541C16.9746 10.2253 16.848 9.9233 16.6245 9.70037C16.401 9.47743 16.0987 9.35155 15.783 9.34998Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M15.783 9.34998H8.51199V24.3H15.783C16.0987 24.2984 16.401 24.1725 16.6245 23.9496C16.848 23.7267 16.9746 23.4246 16.977 23.109V10.541C16.9746 10.2253 16.848 9.9233 16.6245 9.70037C16.401 9.47743 16.0987 9.35155 15.783 9.34998Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M15.132 9.34998H8.51199V24.3H15.132C15.4477 24.2984 15.75 24.1725 15.9735 23.9496C16.197 23.7267 16.3236 23.4246 16.326 23.109V10.541C16.3236 10.2253 16.197 9.9233 15.9735 9.70037C15.75 9.47743 15.4477 9.35155 15.132 9.34998Z"
      fill="black"
    />
    <path
      d="M3.194 9.34998H15.132C15.4482 9.34971 15.7516 9.47501 15.9755 9.69834C16.1994 9.92166 16.3255 10.2247 16.326 10.541V22.459C16.3255 22.7752 16.1994 23.0783 15.9755 23.3016C15.7516 23.5249 15.4482 23.6502 15.132 23.65H3.194C3.03738 23.6502 2.88224 23.6196 2.73746 23.5599C2.59267 23.5002 2.46108 23.4125 2.35019 23.3019C2.2393 23.1913 2.1513 23.0599 2.09121 22.9153C2.03113 22.7707 2.00013 22.6156 2 22.459V10.541C2.00013 10.3844 2.03113 10.2293 2.09121 10.0847C2.1513 9.94003 2.2393 9.80865 2.35019 9.69804C2.46108 9.58744 2.59267 9.49977 2.73746 9.44004C2.88224 9.38032 3.03738 9.34971 3.194 9.34998Z"
      fill="url(#paint0_linear_5714_16856)"
    />
    <path
      d="M5.70001 20.373L8.21101 16.489L5.91101 12.627H7.75801L9.01301 15.1C9.12901 15.334 9.21301 15.508 9.25101 15.624H9.26801C9.35001 15.436 9.43701 15.255 9.52801 15.078L10.87 12.631H12.57L10.211 16.471L12.63 20.376H10.821L9.37101 17.665C9.30369 17.5484 9.24648 17.4263 9.20001 17.3H9.17601C9.13373 17.423 9.07736 17.5408 9.00801 17.651L7.51501 20.373H5.70001Z"
      fill="white"
    />
    <path
      d="M28.806 3.5H19.581V10H30V4.691C29.9999 4.53438 29.9689 4.37932 29.9088 4.23469C29.8487 4.09005 29.7607 3.95868 29.6498 3.84807C29.5389 3.73746 29.4073 3.64979 29.2625 3.59007C29.1178 3.53034 28.9626 3.49974 28.806 3.5Z"
      fill="#33C481"
    />
    <path d="M19.581 16.5H30V23H19.581V16.5Z" fill="#107C41" />
    <defs>
      <linearGradient
        id="paint0_linear_5714_16856"
        x1="4.494"
        y1="8.41398"
        x2="13.832"
        y2="24.586"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18884F" />
        <stop offset="0.5" stopColor="#117E43" />
        <stop offset="1" stopColor="#0B6631" />
      </linearGradient>
    </defs>
  </svg>
);

export const IconDownload = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.44531 5.25H8.25V1.48828C8.25 1.10156 7.89844 0.75 7.51172 0.75H4.48828C4.10156 0.75 3.75 1.10156 3.75 1.48828V5.25H2.55469C1.88672 5.25 1.53516 6.05859 2.02734 6.51562L5.47266 9.96094C5.75391 10.2773 6.24609 10.2773 6.52734 9.96094L9.97266 6.51562C10.4297 6.05859 10.1133 5.25 9.44531 5.25ZM0.761719 12.7383C0.761719 13.1602 1.07812 13.5117 1.5 13.5117H10.5C10.9219 13.5117 11.2383 13.1602 11.2383 12.7383C11.2383 12.3516 10.9219 12 10.5 12H1.5C1.07812 12 0.761719 12.3516 0.761719 12.7383Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

export const IconExportPdf = () => (
  <svg viewBox="0 0 24 24" width="14" height="16">
    <path
      d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"
      fill="black"
      fillOpacity="0.36"
    ></path>
  </svg>
);

export const IconExportPng = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.75 12.2383V1.76172C13.75 0.917969 13.082 0.25 12.2383 0.25H1.76172C0.917969 0.25 0.25 0.917969 0.25 1.76172V12.2383C0.25 13.082 0.917969 13.75 1.76172 13.75H12.2383C13.082 13.75 13.75 13.082 13.75 12.2383ZM4.67969 8.47656L6.26172 10.375L8.58203 7.38672C8.72266 7.21094 9.03906 7.21094 9.17969 7.38672L11.8164 10.9023C11.9922 11.1484 11.8164 11.5 11.5 11.5H2.5C2.18359 11.5 2.04297 11.1484 2.21875 10.9023L4.08203 8.51172C4.22266 8.30078 4.50391 8.30078 4.67969 8.47656Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

export const IconConfigureAccount = ({ ...r }) => (
  <svg
    {...r}
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.6875 10.0312C8.26562 10.9219 7.98438 11.9531 7.98438 12.9844C7.98438 14.4844 8.45312 15.8906 9.25 17.0156H1C0.4375 17.0156 0.015625 16.5469 0.015625 15.9844V14.3438C0.015625 13.3125 0.53125 12.3281 1.375 11.8125C3.29688 10.6875 5.59375 9.98438 7.98438 9.98438C8.21875 9.98438 8.45312 10.0312 8.6875 10.0312ZM4 5.01562C4 7.21875 5.78125 9 7.98438 9C10.1875 9 12.0156 7.21875 12.0156 5.01562C12.0156 2.8125 10.1875 0.984375 7.98438 0.984375C5.78125 0.984375 4 2.8125 4 5.01562ZM18.7656 12.9844C18.7656 13.2188 18.7188 13.4062 18.6719 13.6406L19.5156 14.3438C19.7031 14.5312 19.75 14.7656 19.6094 15L19.0469 16.0312C18.9062 16.2188 18.6719 16.3125 18.4375 16.2188L17.4062 15.8906C17.0781 16.125 16.7031 16.3594 16.3281 16.5L16.0938 17.5781C16.0469 17.8125 15.8594 18 15.5781 18H14.4062C14.1719 18 13.9844 17.8125 13.9375 17.5781L13.7031 16.5C13.3281 16.3594 12.9531 16.125 12.625 15.8906L11.5469 16.2188C11.3594 16.3125 11.0781 16.2188 10.9844 16.0312L10.375 15C10.2812 14.7656 10.3281 14.5312 10.4688 14.3438L11.3125 13.6406C11.3125 13.4062 11.2656 13.2188 11.2656 12.9844C11.2656 12.7969 11.3125 12.5625 11.3125 12.375L10.4688 11.625C10.3281 11.4844 10.2812 11.2031 10.375 11.0156L10.9844 9.98438C11.0781 9.79688 11.3594 9.70312 11.5469 9.75L12.625 10.125C12.9531 9.84375 13.3281 9.65625 13.7031 9.51562L13.9375 8.39062C13.9844 8.20312 14.1719 8.01562 14.4062 8.01562H15.5781C15.8594 8.01562 16.0469 8.20312 16.0938 8.39062L16.3281 9.51562C16.7031 9.65625 17.0781 9.84375 17.4062 10.125L18.4375 9.75C18.6719 9.70312 18.9062 9.79688 19.0469 9.98438L19.6094 11.0156C19.75 11.2031 19.7031 11.4844 19.5156 11.625L18.6719 12.375C18.7188 12.5625 18.7656 12.7969 18.7656 12.9844ZM15.0156 15C16.0938 15 16.9844 14.1094 16.9844 12.9844C16.9844 11.9062 16.0938 11.0156 15.0156 11.0156C13.8906 11.0156 13 11.9062 13 12.9844C13 14.1094 13.8906 15 15.0156 15Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

export const IconAddCircle = ({ ...r }) => (
  <svg
    {...r}
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0.84375C10.6875 0.84375 13.6562 3.8125 13.6562 7.5C13.6562 11.1875 10.6875 14.1562 7 14.1562C3.3125 14.1562 0.34375 11.1875 0.34375 7.5C0.34375 3.8125 3.3125 0.84375 7 0.84375ZM9.65625 8.15625C10.0312 8.15625 10.3438 7.875 10.3438 7.5C10.3438 7.125 10.0312 6.84375 9.65625 6.84375H7.65625V4.84375C7.65625 4.46875 7.375 4.15625 7 4.15625C6.625 4.15625 6.34375 4.46875 6.34375 4.84375V6.84375H4.34375C3.96875 6.84375 3.65625 7.125 3.65625 7.5C3.65625 7.875 3.96875 8.15625 4.34375 8.15625H6.34375V10.1562C6.34375 10.5312 6.625 10.8438 7 10.8438C7.375 10.8438 7.65625 10.5312 7.65625 10.1562V8.15625H9.65625Z"
      fill="#AA00FF"
    />
  </svg>
);

export const IconAdd = () => (
  <button className="myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-btn">
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 7.98438H7.98438V13C7.98438 13.5625 7.5625 13.9844 7 13.9844C6.4375 13.9844 6.01562 13.5625 6.01562 13V7.98438H1C0.4375 7.98438 0.015625 7.5625 0.015625 7C0.015625 6.4375 0.4375 6.01562 1 6.01562H6.01562V1C6.01562 0.4375 6.4375 0.015625 7 0.015625C7.5625 0.015625 7.98438 0.4375 7.98438 1V6.01562H13C13.5625 6.01562 13.9844 6.4375 13.9844 7C13.9844 7.5625 13.5625 7.98438 13 7.98438Z"
        fill="white"
      />
    </svg>
  </button>
);

export const ExpandMore = ({ ...rest }) => (
  <svg
    {...rest}
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.89062 1.28125C10.2656 0.90625 10.9219 0.90625 11.2969 1.28125C11.6719 1.70312 11.6719 2.3125 11.2969 2.6875L6.70312 7.28125C6.32812 7.70312 5.67188 7.70312 5.29688 7.28125L0.703125 2.6875C0.328125 2.3125 0.328125 1.70312 0.703125 1.28125C1.07812 0.90625 1.73438 0.90625 2.10938 1.28125L6 5.17188L9.89062 1.28125Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

export const IconCategories = () => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.15625 1.42188C8.53125 0.765625 9.46875 0.765625 9.84375 1.42188L13.5469 7.46875C13.9688 8.125 13.5 9.01562 12.7031 9.01562H5.29688C4.5 9.01562 4.03125 8.125 4.45312 7.46875L8.15625 1.42188ZM9.98438 15.4844C9.98438 17.9688 12 19.9844 14.4844 19.9844C16.9688 19.9844 18.9844 17.9688 18.9844 15.4844C18.9844 13 16.9688 10.9844 14.4844 10.9844C12 10.9844 9.98438 13 9.98438 15.4844ZM0.984375 19.5156C0.46875 19.5156 0 19.0469 0 18.4844V12.4844C0 11.9688 0.46875 11.5 0.984375 11.5H6.98438C7.54688 11.5 8.01562 11.9688 8.01562 12.4844V18.4844C8.01562 19.0469 7.54688 19.5156 6.98438 19.5156H0.984375Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);
export const IconProduto = ({ className, style2, style }) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{ ...style2 }}
  >
    <path
      style={{ ...style }}
      d="M12 10.0002C12.0003 10.121 11.9676 10.2395 11.9053 10.3429C11.843 10.4464 11.7535 10.5307 11.6467 10.5869L6.38 13.5469C6.26869 13.625 6.136 13.667 6 13.667C5.864 13.667 5.73131 13.625 5.62 13.5469L0.353336 10.5869C0.246456 10.5307 0.157011 10.4464 0.094718 10.3429C0.0324253 10.2395 -0.00033278 10.121 2.54883e-06 10.0002V4.00024C-0.00033278 3.8795 0.0324253 3.76098 0.094718 3.65755C0.157011 3.55412 0.246456 3.46974 0.353336 3.41357L5.62 0.45357C5.73131 0.375425 5.864 0.333496 6 0.333496C6.136 0.333496 6.26869 0.375425 6.38 0.45357L11.6467 3.41357C11.7535 3.46974 11.843 3.55412 11.9053 3.65755C11.9676 3.76098 12.0003 3.8795 12 4.00024V10.0002ZM6 1.7669L4.74 2.48024L8.66667 4.74024L9.97333 4.00024L6 1.7669ZM2.02667 4.00024L6 6.23357L7.30667 5.50024L3.38667 3.23357L2.02667 4.00024ZM1.33334 9.6069L5.33334 11.8602V7.3869L1.33334 5.14024V9.6069ZM10.6667 9.6069V5.14024L6.66667 7.3869V11.8602L10.6667 9.6069Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

export const IconClientes = ({ className, style2, style }) => (
  <svg
    width="16"
    height="8"
    viewBox="0 0 16 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{ ...style2 }}
  >
    <path
      style={{ ...style }}
      d="M8 4.5C9.09375 4.5 10.0312 4.75 10.8125 5.09375C11.5312 5.40625 12 6.125 12 6.90625V7.34375C12 7.6875 11.6875 8 11.3438 8H4.65625C4.3125 8 4 7.6875 4 7.34375V6.9375C4 6.125 4.46875 5.40625 5.1875 5.09375C5.96875 4.75 6.90625 4.5 8 4.5ZM2.65625 4.65625C3.40625 4.65625 4 4.0625 4 3.34375C4 2.59375 3.40625 2 2.65625 2C1.9375 2 1.34375 2.59375 1.34375 3.34375C1.34375 4.0625 1.9375 4.65625 2.65625 4.65625ZM3.40625 5.40625C3.1875 5.375 2.9375 5.34375 2.65625 5.34375C2 5.34375 1.375 5.46875 0.8125 5.71875C0.3125 5.9375 0 6.40625 0 6.96875V7.34375C0 7.6875 0.3125 8 0.65625 8H3V6.9375C3 6.375 3.15625 5.84375 3.40625 5.40625ZM13.3438 4.65625C14.0625 4.65625 14.6562 4.0625 14.6562 3.34375C14.6562 2.59375 14.0625 2 13.3438 2C12.5938 2 12 2.59375 12 3.34375C12 4.0625 12.5938 4.65625 13.3438 4.65625ZM16 6.96875C16 6.40625 15.6875 5.9375 15.1875 5.71875C14.625 5.46875 14 5.34375 13.3438 5.34375C13.0625 5.34375 12.8125 5.375 12.5938 5.40625C12.8438 5.84375 13 6.375 13 6.9375V8H15.3438C15.6875 8 16 7.6875 16 7.34375V6.96875ZM8 0C9.09375 0 10 0.90625 10 2C10 3.09375 9.09375 4 8 4C6.90625 4 6 3.09375 6 2C6 0.90625 6.90625 0 8 0Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

export const IconServicos = ({ className, style2, style }) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{ ...style2 }}
  >
    <path
      style={{ ...style }}
      d="M8.0625 1.9375C6.71875 0.59375 4.71875 0.3125 3.09375 1.125L5.53125 3.53125C5.78125 3.78125 5.78125 4.21875 5.53125 4.46875L4.46875 5.53125C4.1875 5.8125 3.78125 5.8125 3.53125 5.53125L1.09375 3.125C0.3125 4.71875 0.59375 6.71875 1.9375 8.0625C3.1875 9.3125 5 9.625 6.53125 9.0625L11.8438 14.375C12.5312 15.0312 13.625 15.0312 14.3125 14.375C15 13.6875 15 12.5625 14.3125 11.875L9.03125 6.59375C9.625 5.03125 9.3125 3.1875 8.0625 1.9375Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

export const IconDescontos = ({ className, style2, style }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{ ...style2 }}
  >
    <path
      style={{ ...style }}
      d="M6.34375 2.59375C7.28125 2.59375 7.75 2.96875 7.9375 3.53125C8 3.8125 8.3125 4 8.59375 4H8.625C9.09375 4 9.46875 3.53125 9.28125 3.09375C9 2.3125 8.34375 1.71875 7.34375 1.46875V1C7.34375 0.4375 6.875 0 6.34375 0C5.78125 0 5.34375 0.4375 5.34375 1V1.4375C5.0625 1.5 4.84375 1.59375 4.59375 1.6875L5.59375 2.6875C5.8125 2.625 6.0625 2.59375 6.34375 2.59375ZM1.1875 1.09375C0.90625 1.34375 0.90625 1.75 1.1875 2.03125L3 3.84375C3 5.21875 4.03125 6 5.59375 6.46875L7.9375 8.78125C7.71875 9.125 7.25 9.40625 6.34375 9.40625C5.21875 9.40625 4.65625 9 4.4375 8.4375C4.34375 8.1875 4.125 8 3.84375 8H3.71875C3.25 8 2.90625 8.5 3.09375 8.9375C3.5 9.8125 4.375 10.3438 5.34375 10.5625V11C5.34375 11.5625 5.78125 12 6.34375 12C6.90625 12 7.34375 11.5625 7.34375 11V10.5625C7.96875 10.4375 8.5625 10.1875 8.96875 9.8125L10 10.8125C10.25 11.0938 10.6562 11.0938 10.9375 10.8125C11.1875 10.5625 11.1875 10.1562 10.9375 9.875L2.125 1.09375C1.875 0.8125 1.4375 0.8125 1.1875 1.09375Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

export const IconProduct = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 14.5001C18.0005 14.6812 17.9514 14.859 17.8579 15.0141C17.7645 15.1693 17.6303 15.2959 17.47 15.3801L9.57 19.8201C9.40304 19.9373 9.204 20.0002 9 20.0002C8.796 20.0002 8.59696 19.9373 8.43 19.8201L0.530004 15.3801C0.369683 15.2959 0.235516 15.1693 0.142077 15.0141C0.048638 14.859 -0.00049917 14.6812 3.82324e-06 14.5001V5.50011C-0.00049917 5.319 0.048638 5.14122 0.142077 4.98608C0.235516 4.83093 0.369683 4.70436 0.530004 4.62011L8.43 0.180111C8.59696 0.0628934 8.796 0 9 0C9.204 0 9.40304 0.0628934 9.57 0.180111L17.47 4.62011C17.6303 4.70436 17.7645 4.83093 17.8579 4.98608C17.9514 5.14122 18.0005 5.319 18 5.50011V14.5001ZM9 2.15011L7.11 3.22011L13 6.61011L14.96 5.50011L9 2.15011ZM3.04 5.50011L9 8.85011L10.96 7.75011L5.08 4.35011L3.04 5.50011ZM2 13.9101L8 17.2901V10.5801L2 7.21011V13.9101ZM16 13.9101V7.21011L10 10.5801V17.2901L16 13.9101Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

export const IconClient = () => (
  <svg
    width="24"
    height="12"
    viewBox="0 0 24 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 6.75C13.6406 6.75 15.0469 7.125 16.2188 7.64062C17.2969 8.10938 18 9.1875 18 10.3594V11.0156C18 11.5312 17.5312 12 17.0156 12H6.98438C6.46875 12 6 11.5312 6 11.0156V10.4062C6 9.1875 6.70312 8.10938 7.78125 7.64062C8.95312 7.125 10.3594 6.75 12 6.75ZM3.98438 6.98438C5.10938 6.98438 6 6.09375 6 5.01562C6 3.89062 5.10938 3 3.98438 3C2.90625 3 2.01562 3.89062 2.01562 5.01562C2.01562 6.09375 2.90625 6.98438 3.98438 6.98438ZM5.10938 8.10938C4.78125 8.0625 4.40625 8.01562 3.98438 8.01562C3 8.01562 2.0625 8.20312 1.21875 8.57812C0.46875 8.90625 0 9.60938 0 10.4531V11.0156C0 11.5312 0.46875 12 0.984375 12H4.5V10.4062C4.5 9.5625 4.73438 8.76562 5.10938 8.10938ZM20.0156 6.98438C21.0938 6.98438 21.9844 6.09375 21.9844 5.01562C21.9844 3.89062 21.0938 3 20.0156 3C18.8906 3 18 3.89062 18 5.01562C18 6.09375 18.8906 6.98438 20.0156 6.98438ZM24 10.4531C24 9.60938 23.5312 8.90625 22.7812 8.57812C21.9375 8.20312 21 8.01562 20.0156 8.01562C19.5938 8.01562 19.2188 8.0625 18.8906 8.10938C19.2656 8.76562 19.5 9.5625 19.5 10.4062V12H23.0156C23.5312 12 24 11.5312 24 11.0156V10.4531ZM12 0C13.6406 0 15 1.35938 15 3C15 4.64062 13.6406 6 12 6C10.3594 6 9 4.64062 9 3C9 1.35938 10.3594 0 12 0Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

export const IconImport = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4062 5.40625C16.7812 5.78125 17.0156 6.29688 17.0156 6.8125V18.0156C17.0156 19.0938 16.0781 19.9844 15 19.9844H3C1.875 19.9844 0.984375 19.0938 0.984375 18.0156L1.03125 1.98438C1.03125 0.90625 1.92188 0.015625 3 0.015625H10.1719C10.6875 0.015625 11.2031 0.203125 11.5781 0.578125L16.4062 5.40625ZM11.8125 13C12.2344 13 12.4688 12.4375 12.1406 12.1562L9.375 9.34375C9.1875 9.15625 8.85938 9.15625 8.67188 9.34375L5.85938 12.1562C5.53125 12.4375 5.76562 13 6.1875 13H8.01562V16C8.01562 16.5625 8.4375 16.9844 9 16.9844C9.5625 16.9844 9.98438 16.5625 9.98438 16V13H11.8125ZM11.0156 7H15.5156L9.98438 1.51562V6.01562C9.98438 6.53125 10.4531 7 11.0156 7Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);
