import React, { useMemo, useState, useRef } from "react";
import { saveMoeda, useOnClickOutside } from "../../../providers/reducer";
import { useStateValue } from "../../../providers/StateProvider";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

function ModalMoedas({ modal, setmodal, moedas }) {
  const [valueSearch, setValueSearch] = useState("");
  const [{ activeMoeda, activeLanguage }, dispatch] = useStateValue();
  const history = useHistory();

  const dataFilter = useMemo(() => {
    const orderOrderAlf = moedas?.sort((x, y) => {
      let a = x?.currencyName?.toLowerCase();
      let b = y?.currencyName?.toLowerCase();

      return a?.localeCompare(b);
    });
    return orderOrderAlf?.filter((moeda) => {
      return (
        moeda?.name?.toLowerCase()?.includes(valueSearch?.toLowerCase()) ||
        moeda?.alpha3?.toLowerCase()?.includes(valueSearch?.toLowerCase()) ||
        moeda?.currencyId
          ?.toLowerCase()
          ?.includes(valueSearch?.toLowerCase()) ||
        moeda?.currencyName
          ?.toLowerCase()
          ?.includes(valueSearch?.toLowerCase()) ||
        moeda?.currencySymbol
          ?.toLowerCase()
          ?.includes(valueSearch?.toLowerCase()) ||
        moeda?.id?.toLowerCase()?.includes(valueSearch?.toLowerCase())
      );
    });
  }, [moedas, valueSearch]);

  const modalRef = useRef();
  useOnClickOutside(modalRef, () => setmodal(false));

  const searchByMoedaId = (id) => {
    return moedas?.filter((moeda) => {
      return moeda?.id === id;
    });
  };

  const handleChange = async (e) => {
    const id = e.target.value;
    const moeda = searchByMoedaId(id)[0];

    await history.push("/loading/?to=/opcoes");

    await saveMoeda(moeda);

    dispatch({
      type: "SET_ACTIVE_MOEDA",
      activeMoeda: moeda,
    });
    // dispatch({
    //   type: "SET_MESSAGES",
    //   messages: {
    //     messageErro: false,
    //     messageSucesseEdit: true,
    //   },
    // });
    toast.success(activeLanguage?.alerts?.moeda_change);

    setmodal(false);
  };

  return (
    <div
      style={{
        zIndex: 9999999,
        opacity: modal ? "5" : "0",
        visibility: modal ? "visible" : "hidden",
      }}
      className="myne-modal"
    >
      <section
        ref={modalRef}
        className="myne-modal__modal-new-sale-content__area-modal-search myne-modal__modal-moeda"
        style={{
          opacity: modal ? 1 : 0,
          visibility: modal ? "visible" : "hidden",
          transform: modal ? "scale(1)" : "scale(0)",
          left: "initial",
          top: "initial",
        }}
      >
        <section className="myne-modal__modal-new-sale-content__area-modal-search-area__seach">
          <div className="myne-modal__modal-new-sale-content__area-modal-search__input">
            <input
              placeholder={activeLanguage?.options?.money?.input}
              value={valueSearch}
              type="text"
              onChange={(e) => setValueSearch(e.target.value)}
            />
            <IconSearch />
          </div>
        </section>

        <div className="myne-modal__modal-new-sale-content__area-modal-search__itens">
          {modal &&
            dataFilter?.map((moeda) => (
              <label
                key={moeda?.id}
                htmlFor={moeda?.id}
                className="myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda"
              >
                <div>
                  <p>
                    {moeda?.currencyName}({moeda?.currencyId})
                  </p>
                  <span>{moeda?.name}</span>
                </div>
                <Radio
                  checked={activeMoeda.id === moeda?.id}
                  onChange={handleChange}
                  value={moeda?.id}
                  name={moeda?.name}
                  id={moeda?.id}
                  inputProps={{
                    "aria-label": moeda?.id,
                  }}
                />
              </label>
            ))}
        </div>
      </section>
    </div>
  );
}

export default ModalMoedas;

const IconSearch = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5156 12.0156H12.7188L12.4375 11.7344C13.6094 10.3281 14.2656 8.40625 13.8906 6.39062C13.4219 3.625 11.125 1.375 8.3125 1.04688C4.09375 0.53125 0.53125 4.09375 1.04688 8.3125C1.375 11.125 3.625 13.4219 6.39062 13.8906C8.40625 14.2656 10.3281 13.6094 11.7344 12.4375L12.0156 12.7188V13.5156L16.2344 17.7344C16.6562 18.1562 17.3125 18.1562 17.7344 17.7344C18.1562 17.3594 18.1562 16.6562 17.7344 16.2812L13.5156 12.0156ZM7.51562 12.0156C5.03125 12.0156 3.01562 10 3.01562 7.51562C3.01562 5.03125 5.03125 3.01562 7.51562 3.01562C10 3.01562 12.0156 5.03125 12.0156 7.51562C12.0156 10 10 12.0156 7.51562 12.0156Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);
