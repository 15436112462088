import React, { useEffect, useRef, useState } from "react";

//
import { useStateValue } from "../../providers/StateProvider";

//
import { auth, db } from "../../services/Firebase";

//
import "../../styles/Filters/FilterDates.css";
import {
  formatDateCalendar,
  formatDateCalendar_TO_STR,
} from "../../providers/reducer";
import { Draggable } from "../Draggable/Draggable";
import { daysToSalesFilter } from "../../providers/filterDates";

function FilterDates() {
  const [{ activeDate, activeLanguage, activeBtn }, dispatch] = useStateValue();
  const [{ calendarDates }, dispatchDates] = useStateValue();
  const [tempo, setTempo] = useState([]);
  const [tempoCalendar, setTempoCalendar] = useState([]);
  const [scroolLeft, setScroolLeft] = useState(false);
  const [scroolRight, setScroolRight] = useState(true);
  const [{ mesesNew }] = useStateValue();

  const scrollContainerRef = useRef(null);

  const [fistSale, setFistSale] = useState([]);
  const [lastSale, setLastSale] = useState([]);

  const docReference = db
    .collection("users")
    .doc(auth?.currentUser?.uid)
    .collection("sales");

  useEffect(() => {
    if (activeBtn === "Mês") {
      docReference
        .orderBy("date", "asc")
        .limit(1)
        .onSnapshot((snapshot) => {
          setFistSale(
            snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
          );
        });
      docReference
        .orderBy("date", "desc")
        .limit(1)
        .onSnapshot((snapshot) => {
          setLastSale(
            snapshot.docs.map((doc) => {
              return { id: doc.id, data: doc.data() };
            })
          );
        });
    }
  }, [activeBtn]);

  function loadDays() {
    loadScrool();

    setTempo(
      daysToSalesFilter.map((filter) => ({
        time: filter?.days,
        timestamp: `${
          filter?.days
        } ${activeLanguage?.layouts?.header?.filter_dates?.day_plural?.toLowerCase()}`,
      }))
    );
  }

  const loadCalendar = () => {
    if (calendarDates?.startDate && calendarDates?.endDate) {
      if (calendarDates?.type === "Dia") {
        let startDate = new Date(calendarDates?.startDate);
        let endDate = new Date(calendarDates?.endDate);

        let options = [];

        let array = {
          time1: formatDateCalendar(new Date(startDate), mesesNew),
          time2: formatDateCalendar(new Date(endDate), mesesNew),
        };

        options.push(array);

        return setTempoCalendar(
          options.map((opt) => ({
            time: `${opt?.time1}`,
            time2: `${formatDateCalendar_TO_STR()} ${opt?.time2}`,
            timestamp: ``,
          }))
        );
      } else {
        let startDate = new Date(calendarDates?.startDate);
        let endDate = new Date(calendarDates?.endDate);

        let options = [];

        let array = {
          time1: `${mesesNew[new Date(startDate).getMonth()]}, ${new Date(
            startDate
          ).getFullYear()}`,
          time2: `${mesesNew[new Date(endDate).getMonth()]}, ${new Date(
            endDate
          ).getFullYear()}`,
        };

        options.push(array);

        return setTempoCalendar(
          options.map((opt) => ({
            time: `${opt?.time1}`,
            time2: `${formatDateCalendar_TO_STR()} ${opt?.time2}`,
            timestamp: ``,
          }))
        );
      }
    } else {
      return setTempoCalendar([]);
    }
  };

  // function getDatesRange(startDate, stopDate) {
  //   const ONE_DAY = 24 * 3600 * 1000;
  //   let currentDate = new Date(
  //     startDate.getFullYear(),
  //     startDate.getMonth(),
  //     startDate.getDate()
  //   );
  //   let days = [];
  //   while (currentDate <= stopDate) {
  //     let mes = mesesNew[new Date(currentDate).getMonth()],
  //       mesCurt = mesesNew[new Date(currentDate).getMonth()]
  //         ?.substring(0, 3)
  //         ?.toLowerCase();
  //     let year = new Date(currentDate).getFullYear();
  //     let date = new Date(currentDate).getDate();

  //     let yearAtual = new Date().getFullYear();

  //     let objday = {
  //       time: `${date} ${mes}, ${year}`,
  //       timestamp: formatDateDay(date, mesCurt, year, yearAtual),
  //     };
  //     days.push(objday);
  //     currentDate = currentDate - 1 + 1 + ONE_DAY;
  //   }

  //   return days;
  // }

  // function loadDias() {
  //   loadScrool();

  //   lastSale.map((saleLast) => {
  //     let dateLast = saleLast.data.date.toDate();

  //     fistSale.map((sale) => {
  //       let date = sale.data.date.toDate();

  //       let options = getDatesRange(date, dateLast);

  //       return setTempo(
  //         options.reverse().map((opt) => ({
  //           time: `${opt.time}`,
  //           timestamp: `${opt.timestamp}`,
  //         }))
  //       );
  //     });
  //   });
  // }

  const getRangeOfMonths = (startDate, endDate) => {
    const dates = [];
    const dateCounter = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );

    dateCounter.setDate(1);
    while (dateCounter < endDate) {
      let mes = mesesNew[dateCounter.getMonth()],
        mesCurt = mesesNew[dateCounter.getMonth()]?.substring(0, 3);
      let year = dateCounter.getFullYear(),
        yearCurt = dateCounter.getFullYear()?.toString()?.slice(-2),
        yearAtual = new Date().getFullYear();

      let objmes = {
        time: `${mes} ${year}`,
        timestamp: yearAtual === year ? `${mes}` : `${mesCurt}-${yearCurt}`,
      };

      dates.push(objmes);
      dateCounter.setMonth(dateCounter.getMonth() + 1);
    }
    return dates;
  };

  function loadMonths() {
    lastSale.forEach((saleLast) => {
      let dateLast = saleLast.data.date.toDate();

      fistSale.map((sale) => {
        let date = sale.data.date.toDate();

        let options = getRangeOfMonths(date, dateLast);

        return setTempo(
          options
            .reverse()
            .map((op) => ({ time: op?.time, timestamp: op?.timestamp }))
        );
      });
    });
  }

  const scroll = (scrollOffset) => {
    document.getElementById("myne-filter-dates-ref").scrollLeft += scrollOffset;
  };

  const loadScrool = () => {
    let dia = activeDate;
    let index = tempo.map((tempos) => tempos?.time)?.indexOf(dia);
    const btn = document?.querySelectorAll("#btn-calendar")[index];
    const position = btn?.getBoundingClientRect();
    let div = document.getElementById("myne-filter-dates-ref");

    return (div.scrollLeft += position?.left - 512);
  };

  // const handleScroll = (e) => {
  //   if (!e?.target) {
  //     const left = e.scrollLeft > 0;
  //     if (left) {
  //       setScroolLeft(true);
  //     } else {
  //       setScroolLeft(false);
  //     }

  //     const right = e.scrollWidth - e.scrollLeft === e.clientWidth;

  //     if (right) {
  //       setScroolRight(false);
  //     } else {
  //       setScroolRight(true);
  //     }
  //   } else {
  //     const left = e.target.scrollLeft > 0;
  //     if (left) {
  //       setScroolLeft(true);
  //     } else {
  //       setScroolLeft(false);
  //     }

  //     const right =
  //       e.target.scrollWidth - e.target.scrollLeft === e.target.clientWidth;

  //     if (right) {
  //       setScroolRight(false);
  //     } else {
  //       setScroolRight(true);
  //     }
  //   }
  // };

  const handleScroll = () => {
    const container = scrollContainerRef?.current;

    const left = container?.scrollLeft > 0;
    const right =
      container?.scrollWidth > container?.clientWidth + container?.scrollLeft;
    setScroolLeft(left);
    setScroolRight(right);
  };

  useEffect(() => {
    handleScroll();
  }, [scrollContainerRef, tempo, tempoCalendar]);

  useEffect(() => {
    loadScrool();
  }, [activeBtn, activeDate]);

  useEffect(() => {
    if (activeBtn === "Dia") {
      loadScrool();

      loadDays();
    } else if (activeBtn === "Mês") {
      loadMonths();
    } else {
      loadDays();
      loadCalendar();
    }
  }, [fistSale, activeBtn, lastSale, calendarDates]);

  useEffect(() => {
    loadScrool();

    if (activeBtn === "Dia") {
      // let dia;
      // lastSale.forEach((sale) => {
      //   let dates = sale?.data?.date?.toDate();

      //   dia = `${dates?.getDate()} ${
      //     mesesNew[dates?.getMonth()]
      //   }, ${dates?.getFullYear()}`;
      // });

      const dia = daysToSalesFilter[0]?.days;

      // active day
      if (!activeDate) {
        dispatch({
          type: "SET_ACTIVE_DATE",
          activeDate: dia,
        });
      }
    } else if (activeBtn === "Mês") {
      let mes;
      lastSale.forEach((sale) => {
        let dates = sale?.data?.date?.toDate();

        mes = `${mesesNew[dates?.getMonth()]} ${dates.getFullYear()}`;
      });

      // active month
      dispatch({
        type: "SET_ACTIVE_DATE",
        activeDate: mes,
      });
    } else {
      // active calendar date
      dispatch({
        type: "SET_ACTIVE_DATE",
        activeDate: 0,
      });
    }
  }, [activeBtn, fistSale, lastSale]);

  return (
    <div className="myne-filter-content myne-modal--animation-left">
      <Draggable
        onScroll={handleScroll}
        nodeRef={scrollContainerRef}
        id="myne-filter-dates-ref"
        className="myne-filter-dates"
      >
        {tempo.map((tempo, i) => (
          <button
            id="btn-calendar"
            key={tempo?.timestamp}
            onClick={() => {
              dispatch({
                type: "SET_ACTIVE_DATE",
                activeDate: tempo?.time,
              });
              if (activeBtn === "Calendar")
                if (calendarDates?.type === "Mês") {
                  dispatch({
                    type: "SET_ACTIVE",
                    activeBtn: "Mês",
                  });
                } else if (calendarDates?.type === "Dia") {
                  dispatch({
                    type: "SET_ACTIVE",
                    activeBtn: "Dia",
                  });
                }
            }}
            style={{
              fontWeight: activeDate === tempo?.time ? "500" : "",
              border: activeDate === tempo?.time ? "1px solid #aa00ff" : "",
              color: activeDate === tempo?.time ? "#aa00ff" : "",
              background: activeDate === tempo?.time ? "#faefff" : "",
            }}
          >
            {/* {tempo?.time} */}
            {tempo?.timestamp}
          </button>
        ))}
        {activeBtn !== "Mês" &&
          activeBtn !== "Dia" &&
          tempoCalendar.map((tempos, i) => (
            <button
              key={`${tempos?.time}${tempos?.time2}${i}`}
              id="btn-calendar"
              onClick={() => {
                dispatch({
                  type: "SET_ACTIVE_DATE",
                  activeDate: i,
                });
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                fontWeight: activeDate === i ? "500" : "",
                border: activeDate === i ? "1px solid #aa00ff" : "",
                color: activeDate === i ? "#aa00ff" : "",
                background: activeDate === i ? "#faefff" : "",
                paddingRight: "8px",
              }}
            >
              {tempos?.time} {tempos?.time2}
              <svg
                onClick={() => {
                  dispatchDates({
                    type: "SET_CALENDAR_DATES",
                    calendarDates: {
                      startDate: undefined,
                      endDate: undefined,
                    },
                  });

                  if (calendarDates?.type === "Mês") {
                    dispatch({
                      type: "SET_ACTIVE",
                      activeBtn: "Mês",
                    });
                  } else if (calendarDates?.type === "Dia") {
                    dispatch({
                      type: "SET_ACTIVE",
                      activeBtn: "Dia",
                    });
                  }

                  fistSale.forEach((sale) => {
                    let date = sale.data.date.toDate();

                    dispatch({
                      type: "SET_ACTIVE_DATE",
                      activeDate: `${date.getDate()} ${
                        mesesNew[date.getMonth()]
                      }, ${date.getFullYear()}`,
                    });
                  });
                  setTempo([]);
                }}
                style={{ marginLeft: "10px" }}
                width="15"
                height="15"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.28125 2.3125C3.59375 1 5.16667 0.34375 7 0.34375C8.83333 0.34375 10.3958 1 11.6875 2.3125C13 3.60417 13.6562 5.16667 13.6562 7C13.6562 8.83333 13 10.4062 11.6875 11.7188C10.3958 13.0104 8.83333 13.6562 7 13.6562C5.16667 13.6562 3.59375 13.0104 2.28125 11.7188C0.989583 10.4062 0.34375 8.83333 0.34375 7C0.34375 5.16667 0.989583 3.60417 2.28125 2.3125ZM9.875 9.875C10.1667 9.54167 10.1667 9.22917 9.875 8.9375L7.9375 7L9.875 5.0625C10.1667 4.77083 10.1667 4.45833 9.875 4.125C9.54167 3.83333 9.22917 3.83333 8.9375 4.125L7 6.0625L5.0625 4.125C4.77083 3.83333 4.45833 3.83333 4.125 4.125C3.83333 4.45833 3.83333 4.77083 4.125 5.0625L6.0625 7L4.125 8.9375C3.83333 9.22917 3.83333 9.54167 4.125 9.875C4.45833 10.1667 4.77083 10.1667 5.0625 9.875L7 7.9375L8.9375 9.875C9.22917 10.1667 9.54167 10.1667 9.875 9.875Z"
                  fill="#AA00FF"
                />
              </svg>
            </button>
          ))}
      </Draggable>

      <section
        style={{
          opacity: scroolLeft ? 1 : 0,
          visibility: scroolLeft ? "visible" : "hidden",
        }}
        className="myne-filter-dates-left"
      >
        <div>
          <IconFilterDatesLeft onClick={() => scroll(-400)} />
        </div>
        <section></section>
      </section>

      <section
        style={{
          opacity: scroolRight ? 1 : 0,
          visibility: scroolRight ? "visible" : "hidden",
        }}
        className="myne-filter-dates-right"
      >
        <section></section>
        <div>
          <IconFilterDatesRight onClick={() => scroll(400)} />
        </div>
      </section>
    </div>
  );
}

export default FilterDates;

const IconFilterDatesLeft = ({ ...props }) => (
  <svg
    {...props}
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.34"
      d="M0.28125 0.703125C0.78125 0.265625 1.25 0.265625 1.6875 0.703125L6.28125 5.29688C6.5 5.48438 6.60938 5.71875 6.60938 6C6.60938 6.28125 6.5 6.51562 6.28125 6.70312L1.6875 11.2969C1.25 11.7344 0.78125 11.7344 0.28125 11.2969C0.09375 11.1094 0 10.875 0 10.5938C0 10.3125 0.09375 10.0781 0.28125 9.89062L4.17188 6L0.28125 2.10938C0.09375 1.92188 0 1.6875 0 1.40625C0 1.125 0.09375 0.890625 0.28125 0.703125Z"
      fill="black"
    />
  </svg>
);
const IconFilterDatesRight = ({ ...props }) => (
  <svg
    {...props}
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.34"
      d="M0.28125 0.703125C0.78125 0.265625 1.25 0.265625 1.6875 0.703125L6.28125 5.29688C6.5 5.48438 6.60938 5.71875 6.60938 6C6.60938 6.28125 6.5 6.51562 6.28125 6.70312L1.6875 11.2969C1.25 11.7344 0.78125 11.7344 0.28125 11.2969C0.09375 11.1094 0 10.875 0 10.5938C0 10.3125 0.09375 10.0781 0.28125 9.89062L4.17188 6L0.28125 2.10938C0.09375 1.92188 0 1.6875 0 1.40625C0 1.125 0.09375 0.890625 0.28125 0.703125Z"
      fill="black"
    />
  </svg>
);
