import React, { useEffect, useState } from "react";
import { useStateValue } from "../../providers/StateProvider";
import { auth, db } from "../../services/Firebase";

import "../../styles/Cadastros/Cadastros.css";
import ItemVenda from "../Cards/ItemVenda";
import NULLuser from "../User/svgnull.svg";
import { formatPrice } from "../../shared/utils/helpers/format";
import CountUp from "react-countup";

function VendasClientes({ selectCliente, setSelectCliente }) {
  const [sales, setSales] = useState([]);
  const [salesClient, setSalesClient] = useState([]);

  useEffect(() => {
    if (selectCliente) {
      const docReference = db
        .collection("users")
        .doc(auth?.currentUser?.uid)
        .collection("sales")
        .orderBy("date", "asc");
      // .where(
      //   "clientRef",
      //   "==",
      //   `users/${auth?.currentUser?.uid}/clients/${selectCliente?.id}`
      // );

      const fecthsales = async () => {
        await docReference.onSnapshot((snapshot) => {
          setSales(
            snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
          );
        });
      };

      fecthsales();
    }
  }, [selectCliente]);

  const [{ activeMoeda, activeLanguage }] = useStateValue();

  useEffect(() => {
    if (selectCliente) {
      const getSales = () => {
        return sales;
      };

      const salesByClient = (ref, id) => {
        if (id) {
          let sales = getSales();

          return sales?.filter((doc) => {
            let dataRef = doc?.data?.clientRef;

            return dataRef?.path === ref;
          });
        }
      };

      const getSalesCliente = async () => {
        let refClient = `users/${auth?.currentUser?.uid}/clients/${selectCliente?.id}`;
        let salesUniqueClient = salesByClient(refClient, selectCliente?.id);

        setSalesClient(salesUniqueClient);
      };
      getSalesCliente();
    }
  }, [sales, selectCliente]);

  const [TOTALvendido, setTOTALvendido] = useState(0);

  const calculeDiscont = (discount, total, quantity) => {
    let totalDiscontDATA = 0;
    const discontoDATA = discount;
    const totalDATA = total;
    const totalDiscont = quantity;

    if (discontoDATA) {
      if (discontoDATA?.type === 0) {
        totalDiscontDATA += totalDATA - totalDATA * totalDiscont;
      } else {
        totalDiscontDATA += totalDATA - totalDiscont;
      }
    } else {
      totalDiscontDATA += totalDATA;
    }

    return totalDiscontDATA;
  };

  useEffect(() => {
    const getTOTALvendido = () => {
      let total = 0;
      salesClient?.forEach((sale) => {
        total += calculeDiscont(
          sale?.data?.discount,
          sale?.data?.total,
          sale?.data?.discount?.quantity
        );
      });
      setTOTALvendido(total);
    };
    getTOTALvendido();
  }, [salesClient]);

  return (
    <div className="dashboard-content__grid-cadastros-resume-clientes">
      <p>{activeLanguage?.registers?.sales_clients?.title}</p>

      {selectCliente ? (
        <div className="dashboard-content__grid-cadastros-resume-clientes_content">
          <div className="dashboard-content__grid-cadastros-resume-clientes_content-user">
            <img
              src={selectCliente?.image ? selectCliente?.image : NULLuser}
              style={{ borderRadius: "100%" }}
              alt=""
            />
            <p>{selectCliente?.name}</p>
          </div>

          <div className="dashboard-content__grid-cadastros-resume-clientes_content-item__info">
            <IconSale />

            <section>
              <p>{activeLanguage?.registers?.sales_clients?.total_sales}</p>
              <h5>
                <CountUp end={salesClient.length} duration={1} />
              </h5>
            </section>
          </div>

          <div className="dashboard-content__grid-cadastros-resume-clientes_content-item__info">
            <IconPrice />

            <section>
              <p>{activeLanguage?.registers?.sales_clients?.total_sold}</p>
              <h5>{formatPrice(TOTALvendido, activeMoeda)}</h5>
            </section>
          </div>

          <section />

          {salesClient?.length >= 1 ? (
            salesClient.map((sale, i) => (
              <ItemVenda
                key={sale?.id}
                salesClient={salesClient}
                //sale//
                sale={sale}
                date={sale.data?.date}
                client={sale.data?.client}
                total={sale.data?.total}
                status={sale.data?.status}
                discount={sale.data?.discount}
                installments={sale.data?.installments}
                //-  -//
                cliente={true}
              />
            ))
          ) : (
            <div className="dashboard-content__grid-cadastros__not-itens">
              <IconAlert />
              <p>{activeLanguage?.registers?.sales_clients?.no_itens}</p>
            </div>
          )}
        </div>
      ) : (
        <div className="dashboard-content__grid-cadastros__info">
          <IconInfo />
          <p>{activeLanguage?.registers?.sales_clients?.no_click}</p>
        </div>
      )}
    </div>
  );
}

export default VendasClientes;

const IconAlert = () => (
  <svg
    width="30"
    height="25"
    viewBox="0 0 30 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.30859 24.75H25.6914C27.8164 24.75 29.2109 22.3594 28.1484 20.5L17.457 2.03906C16.3281 0.179688 13.6719 0.179688 12.543 2.03906L1.85156 20.5C0.789062 22.3594 2.18359 24.75 4.30859 24.75ZM15 14.8555C14.2031 14.8555 13.6055 14.1914 13.6055 13.3945V10.6055C13.6055 9.80859 14.2031 9.14453 15 9.14453C15.7969 9.14453 16.3945 9.80859 16.3945 10.6055V13.3945C16.3945 14.1914 15.7969 14.8555 15 14.8555ZM16.3945 20.5H13.6055V17.6445H16.3945V20.5Z"
      fill="#FFBD06"
    />
  </svg>
);

const IconPrice = () => (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.8125 7.92188C3.51562 7.3125 2.8125 6.70312 2.8125 5.76562C2.8125 4.64062 3.79688 3.89062 5.48438 3.89062C6.9375 3.89062 7.64062 4.45312 7.875 5.29688C8.01562 5.71875 8.34375 6 8.76562 6H9.04688C9.70312 6 10.1719 5.34375 9.9375 4.73438C9.5625 3.5625 8.57812 2.57812 6.98438 2.20312V1.5C6.98438 0.65625 6.32812 0 5.48438 0C4.6875 0 3.98438 0.65625 3.98438 1.5V2.15625C2.0625 2.57812 0.515625 3.84375 0.515625 5.76562C0.515625 8.0625 2.39062 9.23438 5.20312 9.89062C7.6875 10.5 8.20312 11.3906 8.20312 12.3281C8.20312 12.9844 7.6875 14.1094 5.48438 14.1094C3.84375 14.1094 3 13.5 2.67188 12.6562C2.53125 12.2812 2.20312 12 1.78125 12H1.5C0.796875 12 0.328125 12.7031 0.609375 13.3125C1.17188 14.6719 2.48438 15.5156 3.98438 15.8438V16.5C3.98438 17.3438 4.6875 18 5.48438 18C6.32812 18 6.98438 17.3438 6.98438 16.5V15.8438C8.95312 15.4688 10.5 14.3438 10.5 12.2812C10.5 9.46875 8.0625 8.48438 5.8125 7.92188Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

const IconSale = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.469 8.62201L9.369 0.52201C9.20224 0.355769 9.0043 0.224085 8.78654 0.134508C8.56877 0.0449313 8.33547 -0.000775178 8.1 9.94561e-06H1.8C1.32302 0.00132941 0.865946 0.191395 0.528666 0.528675C0.191386 0.865955 0.00132003 1.32303 5.65137e-07 1.80001V8.10001C-0.000187642 8.33753 0.0466351 8.57274 0.13777 8.79208C0.228905 9.01142 0.362549 9.21056 0.531 9.37801L8.631 17.478C8.79776 17.6443 8.9957 17.7759 9.21346 17.8655C9.43123 17.9551 9.66453 18.0008 9.9 18C10.1363 18.0012 10.3705 17.9549 10.5885 17.8636C10.8066 17.7724 11.004 17.6382 11.169 17.469L17.469 11.169C17.6382 11.004 17.7724 10.8066 17.8636 10.5885C17.9548 10.3705 18.0012 10.1363 18 9.90001C17.9984 9.42066 17.8076 8.96135 17.469 8.62201ZM3.15 4.50001C2.883 4.50001 2.62199 4.42083 2.39998 4.27249C2.17797 4.12415 2.00494 3.91331 1.90276 3.66663C1.80058 3.41995 1.77385 3.14851 1.82594 2.88664C1.87803 2.62476 2.00661 2.38422 2.19541 2.19542C2.38421 2.00662 2.62475 1.87804 2.88663 1.82595C3.1485 1.77386 3.41994 1.80059 3.66662 1.90277C3.9133 2.00495 4.12414 2.17798 4.27248 2.39999C4.42082 2.622 4.5 2.88301 4.5 3.15001C4.50026 3.32737 4.46552 3.50304 4.39777 3.66694C4.33002 3.83085 4.23059 3.97978 4.10518 4.10519C3.97977 4.2306 3.83084 4.33003 3.66693 4.39778C3.50303 4.46553 3.32736 4.50027 3.15 4.50001Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

const IconInfo = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM10 15.0156C9.4375 15.0156 9.01562 14.5469 9.01562 13.9844V10C9.01562 9.4375 9.4375 9.01562 10 9.01562C10.5625 9.01562 10.9844 9.4375 10.9844 10V13.9844C10.9844 14.5469 10.5625 15.0156 10 15.0156ZM10.9844 7H9.01562V4.98438H10.9844V7Z"
      fill="#85D1F5"
    />
  </svg>
);
