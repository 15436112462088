function iframe() {
  return {
    __html:
      '<iframe src="../../privacy.html" width="100%" height="100%"></iframe>',
  };
}

function Privacy() {
  return (
    <div className="myne-w100fixed">
      <div dangerouslySetInnerHTML={iframe()} />
    </div>
  );
}

export default Privacy;
