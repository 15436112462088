import React from "react";

// routes
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoadingN from "../components/Loading/LoadingProcess";
import Sidebar from "../layouts/Sidebar/Sidebar";
import { auth } from "../services/Firebase";
import Cadastros from "../views/Cadastros/Cadastros";
import Dashboard from "../views/Dashboard/Dashboard";
import Options from "../views/Options/Options";
import { Toaster } from "react-hot-toast";
import { toastConfig } from "../shared/utils/helpers/toast";

// firebase
// import { analytics } from "../services/Firebase";

// const RoutesListener = () => {
//   const location = useLocation();

//   useEffect(() => {
//     analytics.setCurrentScreen(location.pathname);
//   }, [location]);

//   return <></>;
// };

function Routes() {
  return (
    <div className="myne-main">
      <Toaster
        position="top-right"
        containerStyle={{
          top: 70,
          right: 21,
        }}
        toastOptions={toastConfig}
      />
      <BrowserRouter>
        <Sidebar />
        {/* <RoutesListener /> */}
        <Switch>
          <Route component={LoadingN} path="/loading" exact />
          <Route component={Dashboard} path="/" exact />
          <Route component={Cadastros} path="/cadastros" />
          <Route component={Options} path="/opcoes" />
          <Route
            component={Dashboard}
            path={`/welcome-plus-${auth?.currentUser?.uid}`}
          />
          <Route component={Dashboard} path="*" />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default Routes;
