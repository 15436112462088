import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ProductService,
  migrateCategoriesIfNeed,
  strAllCategoriesProducts,
  toLowerStr,
  useOnClickOutside,
} from "../../providers/reducer";

import "../../styles/Cadastros/Cadastros.css";
import ItemCadastro from "../Cards/ItemCadastro";
import { useStateValue } from "../../providers/StateProvider";
import Categories from "../Filters/FilterCategories";
import NotFoundCadastros from "../NotFound/NotFoundCadastros";
import { filterAZ, filterNumberAsc } from "../../shared/utils/helpers/filters";

function ListCadastros({
  filterCadastros,
  setFilterCadastros,
  activeFilterCadastros,
  selectCliente,
  setSelectCliente,
  loading,
  setLoading,
  selectCategories,
  setSelectCategories,
  filters,
  setFilterSelect,
  filterSelect,
  lastKey,
  setLastKey,
  updateProducts,
  loadingPaginate,
  setLoadingPaginate,
}) {
  const modalFilterRef = useRef();

  const [modalFilter, setModalFilter] = useState(false);
  useOnClickOutside(modalFilterRef, () => setModalFilter(false));

  const [{ activeLanguage }] = useStateValue();

  // const [filterNomeDecrescente, setFilterNomeDecrescente] = useState(true);
  // const [filterNomeCrescente, setFilterNomeCrescente] = useState(false);
  // const [filterEstoqueDecrescente, setFilterEstoqueDecrescente] =
  //   useState(false);
  // const [filterEstoqueCrescente, setFilterEstoqueCrescente] = useState(false);
  // const [filterPrecoDecrescente, setFilterPrecoDecrescente] = useState(false);
  // const [filterPrecoCrescente, setFilterPrecoCrescente] = useState(false);

  const [searchModal, setSearchModal] = useState(false);
  const [inputSearch, setInputSearch] = useState("");

  const strProductLong = activeLanguage?.services?.product?.title;
  const strClientLong = activeLanguage?.services?.client?.title;
  const strServiceLong = activeLanguage?.services?.service?.title;
  const strDiscountLong = activeLanguage?.services?.discount?.title;

  const [filterItens, setFilterItens] = useState([]);

  useEffect(() => {
    setFilterItens(filterCadastros);
  }, [filterCadastros]);

  const fetchMoreProducts = (key) => {
    if (filterCadastros?.length >= ProductService.pageProductsLimit) {
      if (!inputSearch) {
        if (key?.id?.length > 0) {
          setLoading(true);
          ProductService.productsNextBatch(
            key,
            filterSelect,
            filters,
            selectCategories
          )
            .then((res) => {
              setLastKey(res.lastKey);
              setFilterCadastros(filterItens.concat(res.products));
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    }
  };

  const refScrollForPagination = useRef();

  const handleScrollProducts = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } =
      refScrollForPagination.current;

    const check = scrollHeight - Math.ceil(scrollTop) === clientHeight;

    if (check) {
      if (loading) {
      } else if (lastKey?.id?.length > 0) {
        fetchMoreProducts(lastKey);
      } else {
      }
    } else {
    }
  }, [refScrollForPagination, lastKey, loading]);

  useEffect(() => {
    if (
      refScrollForPagination?.current &&
      activeFilterCadastros === strProductLong
    ) {
      refScrollForPagination?.current?.addEventListener(
        "scroll",
        handleScrollProducts
      );
      return () =>
        refScrollForPagination?.current?.removeEventListener(
          "scroll",
          handleScrollProducts
        );
    }
  }, [
    refScrollForPagination,
    handleScrollProducts,
    activeFilterCadastros,
    strProductLong,
  ]);

  // const handleScroll = useCallback(() => {
  //   const { scrollTop, scrollHeight, clientHeight } =
  //     refScrollForPagination?.current;
  //   const check = scrollHeight - Math.ceil(scrollTop) <= clientHeight;

  //   if (check && !loading && !inputSearch) {
  //     loadMoreProductsPagination();
  //   }
  // }, [loading, inputSearch, loadMoreProductsPagination]);

  // useEffect(() => {
  //   if (
  //     refScrollForPagination?.current &&
  //     activeFilterCadastros === strProductLong
  //   ) {
  //     refScrollForPagination?.current?.addEventListener("scroll", handleScroll);
  //   }
  // }, [
  //   refScrollForPagination,
  //   activeFilterCadastros,
  //   strProductLong,
  //   handleScroll,
  // ]);

  const sortItens = useCallback(() => {
    const resutFilterAZ = filterAZ(filterItens, "data", "name");
    const resutFilterStockAsc = filterNumberAsc(filterItens, "data", "stock");
    const resutFilterPriceAsc = filterNumberAsc(
      filterItens,
      "data",
      "salePrice"
    );

    if (filterSelect === 1) {
      //A -Z
      return resutFilterAZ;
    } else if (filterSelect === 2) {
      // Z-A
      return resutFilterAZ?.reverse();
    } else if (filterSelect === 3) {
      // stock asc
      return resutFilterStockAsc;
    } else if (filterSelect === 3) {
      // stock desc
      return resutFilterStockAsc?.reverse();
    } else if (filterSelect === 4) {
      // price asc
      return resutFilterPriceAsc;
    } else if (filterSelect === 5) {
      // price desc
      return resutFilterPriceAsc?.reverse();
    } else {
      return filterItens;
    }
  }, [filterItens, filterSelect]);

  const [filterSearchProducts, setFilterSearchProducts] = useState([]);

  const checkGetFromAlgolia =
    activeFilterCadastros === strProductLong &&
    !loadingPaginate &&
    filterCadastros?.length >= 50;

  // GET FROM ALGOLIA
  useEffect(() => {
    if (inputSearch && inputSearch !== "" && checkGetFromAlgolia) {
      setLoading(true);
      const getSearch = async () => {
        const resultsSearch = await ProductService.searchProducts(
          inputSearch
        ).finally(() => {
          setLoading(false);
        });
        if (resultsSearch[0]?.hits?.length > 0) {
          setLoading(false);
        }
        setFilterSearchProducts(
          resultsSearch[0]?.hits?.map((product) => ({
            id: product?.objectID,
            data: product,
          }))
        );
      };
      const timeoutSearch = setTimeout(() => getSearch(), [2000]);
      return () => clearTimeout(timeoutSearch);
    }
  }, [
    activeFilterCadastros,
    loadingPaginate,
    filterCadastros,
    inputSearch,
    strProductLong,
    checkGetFromAlgolia,
    setLoading,
  ]);

  const filterSearch = useMemo(() => {
    const getItensToFilter = () => {
      if (activeFilterCadastros === strProductLong) {
        return filterItens;
      } else {
        return sortItens();
      }
    };
    const itensToFilter = getItensToFilter();

    if (inputSearch === "") {
      return itensToFilter;
    } else {
      if (checkGetFromAlgolia) {
        return filterSearchProducts;
      } else {
        return itensToFilter?.filter((item) => {
          const filter = (item) =>
            item
              ?.toString()
              ?.toLowerCase()
              ?.includes(inputSearch?.toLowerCase());
          return filter(item?.data?.name) || filter(item?.data?.description);
        });
      }
    }
  }, [
    inputSearch,
    activeFilterCadastros,
    strProductLong,
    filterItens,
    sortItens,
    checkGetFromAlgolia,
    filterSearchProducts,
  ]);

  // old filter
  // const filterCategories = useMemo(() => {
  //   if (activeFilterCadastros === strProductLong) {
  //     if (selectCategories[0] === strAllCategoriesProducts) {
  //       return filterSearch;
  //     } else {
  //       return filterSearch?.filter((product) => {
  //         // --> some
  //         return product?.data?.categories?.some((cate) => {
  //           return selectCategories?.includes(cate?.name);
  //         });
  //       });
  //     }
  //   } else {
  //     return filterSearch;
  //   }
  // }, [filterSearch, selectCategories, activeFilterCadastros, strProductLong]);

  useEffect(() => {
    if (filterItens?.length !== 0 && activeFilterCadastros === strProductLong) {
      async function updateCategoriesProductsForFilter() {
        await migrateCategoriesIfNeed();
      }
      updateCategoriesProductsForFilter();
    }
  }, [activeFilterCadastros, filterItens, strProductLong]);

  const hasItensFilter = filterCadastros?.length !== 0;
  const ifIsProductAndCategoriesFilter =
    activeFilterCadastros === strProductLong &&
    !(selectCategories[0] === strAllCategoriesProducts);

  const Loading = () => (
    <div
      className="myne-loading-icon--infinite"
      style={{
        textAlign: "center",
        padding: !loading ? 0 : "",
      }}
    >
      <div className="myne-loading-icon">
        <div
          style={{ width: 50, height: 50 }}
          className="myne-circle-loading-3"
        ></div>
      </div>
    </div>
  );

  return hasItensFilter || ifIsProductAndCategoriesFilter ? (
    <div
      id="dashboard-content__grid-vendas__vendas"
      className="dashboard-content__grid-vendas__vendas myne-modal--animation"
    >
      <div className="dashboard-content__grid-vendas__vendas-header">
        {searchModal ? (
          <div className="dashboard-content__grid-vendas__vendas-header--search">
            <IconLeft
              onClick={() => {
                setInputSearch("");
                setSearchModal(false);
              }}
            />

            <input
              value={inputSearch}
              onChange={(e) => setInputSearch(e.target.value)}
              placeholder={`${
                activeLanguage?.services?.inputs?.search
              } ${activeFilterCadastros?.toLowerCase()}`}
            />
          </div>
        ) : (
          <>
            <p className="myne-text">
              {activeLanguage?.registers?.title_registers === "Registered"
                ? activeLanguage?.registers?.title_registers +
                  " " +
                  activeFilterCadastros?.toLowerCase()
                : `${activeFilterCadastros} ${activeLanguage?.registers?.title_registers}`}
            </p>
            <div>
              <IconSearch
                onClick={() => {
                  setSearchModal(!searchModal);
                }}
              />
              {activeFilterCadastros !== strDiscountLong ? (
                <IconFilter onClick={() => setModalFilter(true)} />
              ) : (
                ""
              )}
            </div>

            {activeFilterCadastros !== strDiscountLong ? (
              <div
                style={{
                  opacity: modalFilter ? "5" : "0",
                  visibility: modalFilter ? "visible" : "hidden",
                  height: modalFilter
                    ? activeFilterCadastros === strClientLong ||
                      activeFilterCadastros === strServiceLong
                      ? "85px"
                      : "225px"
                    : "0",
                  marginRight:
                    activeFilterCadastros === strClientLong ? "307px" : "",
                }}
                className="myne-modal-drop"
                ref={modalFilterRef}
              >
                {filters?.map((filter) =>
                  filter?.index === 1 || filter?.index === 2 ? (
                    activeFilterCadastros !== strServiceLong ? (
                      <ItemFilter
                        filter={filter}
                        setFilterSelect={setFilterSelect}
                        setModalFilter={setModalFilter}
                        filterSelect={filterSelect}
                      />
                    ) : null
                  ) : filter?.index === 3 || filter?.index === 4 ? (
                    activeFilterCadastros === strProductLong ? (
                      <ItemFilter
                        filter={filter}
                        setFilterSelect={setFilterSelect}
                        setModalFilter={setModalFilter}
                        filterSelect={filterSelect}
                      />
                    ) : null
                  ) : filter?.index === 5 || filter?.index === 6 ? (
                    activeFilterCadastros !== strDiscountLong &&
                    activeFilterCadastros !== strClientLong ? (
                      <ItemFilter
                        filter={filter}
                        setFilterSelect={setFilterSelect}
                        setModalFilter={setModalFilter}
                        filterSelect={filterSelect}
                      />
                    ) : null
                  ) : (
                    ""
                  )
                )}

                {/* {activeFilterCadastros !== strServiceLong ? (
                  <>
                    <div
                      style={{
                        background: filterNomeDecrescente ? "#f7f4fa" : "",
                      }}
                      onClick={() => {
                        setFilterNomeDecrescente(true);
                        setFilterNomeCrescente(false);
                        setFilterEstoqueDecrescente(false);
                        setFilterEstoqueCrescente(false);
                        setFilterPrecoDecrescente(false);
                        setFilterPrecoCrescente(false);

                        setModalFilter(false);
                      }}
                    >
                      {activeLanguage?.registers?.filter?.f1}
                    </div>
                    <div
                      style={{
                        background: filterNomeCrescente ? "#f7f4fa" : "",
                      }}
                      onClick={() => {
                        setFilterNomeDecrescente(false);
                        setFilterNomeCrescente(true);
                        setFilterEstoqueDecrescente(false);
                        setFilterEstoqueCrescente(false);
                        setFilterPrecoDecrescente(false);
                        setFilterPrecoCrescente(false);

                        setModalFilter(false);
                      }}
                    >
                      {activeLanguage?.registers?.filter?.f2}
                    </div>
                  </>
                ) : (
                  ""
                )}
                {activeFilterCadastros === strProductLong ? (
                  <>
                    <div
                      style={{
                        background: filterEstoqueCrescente ? "#f7f4fa" : "",
                      }}
                      onClick={() => {
                        setFilterNomeDecrescente(false);
                        setFilterNomeCrescente(false);
                        setFilterEstoqueDecrescente(false);
                        setFilterEstoqueCrescente(true);
                        setFilterPrecoDecrescente(false);
                        setFilterPrecoCrescente(false);

                        setModalFilter(false);
                      }}
                    >
                      {activeLanguage?.registers?.filter?.f3}
                    </div>
                    <div
                      style={{
                        background: filterEstoqueDecrescente ? "#f7f4fa" : "",
                      }}
                      onClick={() => {
                        setFilterNomeDecrescente(false);
                        setFilterNomeCrescente(false);
                        setFilterEstoqueDecrescente(true);
                        setFilterEstoqueCrescente(false);
                        setFilterPrecoDecrescente(false);
                        setFilterPrecoCrescente(false);

                        setModalFilter(false);
                      }}
                    >
                      {activeLanguage?.registers?.filter?.f4}
                    </div>
                  </>
                ) : (
                  ""
                )}

                {activeFilterCadastros !== strDiscountLong &&
                activeFilterCadastros !== strClientLong ? (
                  <>
                    <div
                      style={{
                        background: filterPrecoCrescente ? "#f7f4fa" : "",
                      }}
                      onClick={() => {
                        setFilterNomeDecrescente(false);
                        setFilterNomeCrescente(false);
                        setFilterEstoqueDecrescente(false);
                        setFilterEstoqueCrescente(false);
                        setFilterPrecoDecrescente(false);
                        setFilterPrecoCrescente(true);

                        setModalFilter(false);
                      }}
                    >
                      {activeLanguage?.registers?.filter?.f5}
                    </div>
                    <div
                      style={{
                        background: filterPrecoDecrescente ? "#f7f4fa" : "",
                      }}
                      onClick={() => {
                        setFilterNomeDecrescente(false);
                        setFilterNomeCrescente(false);
                        setFilterEstoqueDecrescente(false);
                        setFilterEstoqueCrescente(false);
                        setFilterPrecoDecrescente(true);
                        setFilterPrecoCrescente(false);

                        setModalFilter(false);
                      }}
                    >
                      {activeLanguage?.registers?.filter?.f6}
                    </div>
                  </>
                ) : (
                  ""
                )} */}
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </div>

      {activeFilterCadastros === strProductLong && (
        <Categories
          setLoading={setLoading}
          selectCategories={selectCategories}
          setSelectCategories={setSelectCategories}
        />
      )}

      <div
        ref={refScrollForPagination}
        className="dashboard-content__grid-vendas__vendas-items"
      >
        {loading || filterSearch?.length > 0 ? (
          <>
            {filterSearch?.map((item, i) => (
              <ItemCadastro
                key={item?.id}
                item={item?.data}
                id={item?.id}
                type={activeFilterCadastros}
                selectCliente={selectCliente}
                setSelectCliente={setSelectCliente}
                updateProducts={updateProducts}
              />
            ))}

            {loading && <Loading />}
          </>
        ) : inputSearch && filterSearchProducts?.length <= 0 ? (
          <div className="dashboard-content__grid-vendas__not-vendas">
            <NotFoundCadastros
              key={activeFilterCadastros}
              activeFilterCadastros={activeFilterCadastros}
              activeLanguage={activeLanguage}
            />
          </div>
        ) : (
          <div className="dashboard-content__grid-vendas__not-vendas">
            <svg
              width="30"
              height="25"
              viewBox="0 0 30 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.30859 24.75H25.6914C27.8164 24.75 29.2109 22.3594 28.1484 20.5L17.457 2.03906C16.3281 0.179688 13.6719 0.179688 12.543 2.03906L1.85156 20.5C0.789062 22.3594 2.18359 24.75 4.30859 24.75ZM15 14.8555C14.2031 14.8555 13.6055 14.1914 13.6055 13.3945V10.6055C13.6055 9.80859 14.2031 9.14453 15 9.14453C15.7969 9.14453 16.3945 9.80859 16.3945 10.6055V13.3945C16.3945 14.1914 15.7969 14.8555 15 14.8555ZM16.3945 20.5H13.6055V17.6445H16.3945V20.5Z"
                fill="#FFBD06"
              />
            </svg>

            <p className="myne-text">
              Categoria não aplicada a nenhum produto.
            </p>
          </div>
        )}
      </div>

      <div className="dashboard-content__grid-vendas__vendas-bottom"></div>
    </div>
  ) : loading ? (
    <div className="dashboard-content__grid-vendas__not-vendas">
      <Loading />
    </div>
  ) : (
    <div className="dashboard-content__grid-vendas__not-vendas">
      <NotFoundCadastros
        key={activeFilterCadastros}
        activeFilterCadastros={activeFilterCadastros}
        activeLanguage={activeLanguage}
      />
    </div>
  );
}

export default ListCadastros;

const ItemFilter = ({
  filter,
  setFilterSelect,
  setModalFilter,
  filterSelect,
}) => (
  <div
    style={{
      background: filterSelect === filter?.index ? "#f7f4fa" : "",
    }}
    key={filter?.id + filter?.index}
    id={filter?.id + filter?.index}
    onClick={() => {
      setFilterSelect(filter?.index);

      setModalFilter(false);
    }}
  >
    {filter?.name}
  </div>
);

const IconLeft = ({ ...rest }) => (
  <svg
    {...rest}
    width="18"
    height="18"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.6"
      d="M14.9844 7.01562C15.2656 7.01562 15.5 7.10938 15.6875 7.29688C15.9062 7.48438 16.0156 7.71875 16.0156 8C16.0156 8.28125 15.9062 8.51562 15.6875 8.70312C15.5 8.89062 15.2656 8.98438 14.9844 8.98438H3.82812L8.70312 13.8594C9.14062 14.3281 9.14062 14.8125 8.70312 15.3125C8.51562 15.5 8.28125 15.5938 8 15.5938C7.71875 15.5938 7.48438 15.5 7.29688 15.3125L0.6875 8.70312C0.5 8.51562 0.40625 8.28125 0.40625 8C0.40625 7.71875 0.5 7.48438 0.6875 7.29688L7.29688 0.6875C7.48438 0.5 7.71875 0.40625 8 0.40625C8.28125 0.40625 8.51562 0.5 8.70312 0.6875C9.14062 1.1875 9.14062 1.67188 8.70312 2.14062L3.82812 7.01562H14.9844Z"
      fill="black"
    />
  </svg>
);

const IconFilter = ({ ...res }) => (
  <svg
    {...res}
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.984375 12C0.734375 12 0.5 11.9062 0.28125 11.7188C0.09375 11.5 0 11.2656 0 11.0156C0 10.7344 0.09375 10.5 0.28125 10.3125C0.5 10.0938 0.734375 9.98438 0.984375 9.98438H5.01562C5.26562 9.98438 5.48438 10.0938 5.67188 10.3125C5.89062 10.5 6 10.7344 6 11.0156C6 11.2656 5.89062 11.5 5.67188 11.7188C5.48438 11.9062 5.26562 12 5.01562 12H0.984375ZM0 0.984375C0 0.734375 0.09375 0.515625 0.28125 0.328125C0.5 0.109375 0.734375 0 0.984375 0H17.0156C17.2656 0 17.4844 0.109375 17.6719 0.328125C17.8906 0.515625 18 0.734375 18 0.984375C18 1.26562 17.8906 1.51562 17.6719 1.73438C17.4844 1.92188 17.2656 2.01562 17.0156 2.01562H0.984375C0.734375 2.01562 0.5 1.92188 0.28125 1.73438C0.09375 1.51562 0 1.26562 0 0.984375ZM0.984375 6.98438C0.734375 6.98438 0.5 6.89062 0.28125 6.70312C0.09375 6.51562 0 6.28125 0 6C0 5.71875 0.09375 5.48438 0.28125 5.29688C0.5 5.10938 0.734375 5.01562 0.984375 5.01562H11.0156C11.2656 5.01562 11.4844 5.10938 11.6719 5.29688C11.8906 5.48438 12 5.71875 12 6C12 6.28125 11.8906 6.51562 11.6719 6.70312C11.4844 6.89062 11.2656 6.98438 11.0156 6.98438H0.984375Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

const IconSearch = ({ ...rest }) => (
  <svg
    {...rest}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 11H11.706L11.43 10.726C12.4431 9.55111 13.0003 8.05135 13 6.5C13 4.77609 12.3152 3.1228 11.0962 1.90381C9.8772 0.684822 8.22391 0 6.5 0C4.77609 0 3.1228 0.684822 1.90381 1.90381C0.684822 3.1228 0 4.77609 0 6.5C0 8.22391 0.684822 9.8772 1.90381 11.0962C3.1228 12.3152 4.77609 13 6.5 13C8.05048 13.0011 9.54981 12.4453 10.725 11.434L11.001 11.708V12.5L16.001 17.491L17.49 16L12.502 11H12.5ZM6.5 11C5.30653 11 4.16194 10.5259 3.31802 9.68201C2.47411 8.83809 2 7.69347 2 6.5C2 5.30653 2.47411 4.16191 3.31802 3.31799C4.16194 2.47408 5.30653 2 6.5 2C7.69347 2 8.83806 2.47408 9.68198 3.31799C10.5259 4.16191 11 5.30653 11 6.5C11 7.69347 10.5259 8.83809 9.68198 9.68201C8.83806 10.5259 7.69347 11 6.5 11Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);
