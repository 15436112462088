import React, { useState } from "react";
import { IconArrowBack, IconClose } from "../../assets/icons/icons";
import { useStateValue } from "../../providers/StateProvider";
import InputForm from "../../components/inputs/InputForm";
import { auth, firebase, providerGoogle } from "../../services/Firebase";
import { analyticsEvent } from "../../services/analytics";
import { IconButton } from "@material-ui/core";
import { FcGoogle } from "react-icons/fc";
import { FormForgotPassword } from "./FormForgotPassword";
import LoadingInButton from "../../components/Loading/LoadingInButton";

function ReLogin({
  login,
  setlogin,
  email,
  setmodalconfig,
  back,
  senha,
  background,
  setEmail,
  setmodalconfig2,
  setSenha,
  removeAccount,
}) {
  const [senhaNot, setSenhaNot] = useState(false);
  const [pageContent, setPageContent] = useState("Entrar");
  const [loading, setLoading] = useState(false);
  const [textErro, setTextErro] = useState("Preencha todos os campos!");

  const [{ activeLanguage }] = useStateValue();

  const reLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    setSenhaNot(false);
    if (auth?.currentUser?.providerData[0]?.providerId === "password") {
      if (email !== "" && senha !== "") {
        const user = auth.currentUser;
        const credentials = firebase.auth.EmailAuthProvider.credential(
          email,
          senha
        );
        user
          .reauthenticateWithCredential(credentials)
          .then((credentials) => {
            console.log("re-outh", credentials);
            setLoading(false);
            setlogin(false);
            setmodalconfig(true);

            setEmail("");
            setSenha("");

            analyticsEvent("SIGN_IN");
            analyticsEvent("SIGN_IN_FORM");
          })
          .catch((e) => {
            let errorCode = e.code;
            let errorMessage = e.message;
            setLoading(false);
            setSenhaNot(true);
            setTextErro(`${errorCode} ${errorMessage}`);
          });
      } else {
        setSenhaNot(true);
        setLoading(false);
        setTextErro(activeLanguage?.login?.text_error);
      }
    } else {
      auth.currentUser
        .reauthenticateWithPopup(providerGoogle)
        .then((UserCredential) => {
          setLoading(false);
          setlogin(false);
          setmodalconfig(true);

          analyticsEvent("SIGN_IN");
          analyticsEvent("SIGN_IN_GOOGLE");
        })
        .catch((e) => {
          let errorCode = e.code;
          let errorMessage = e.message;
          setLoading(false);
          setSenhaNot(true);
          setTextErro(`${errorCode} ${errorMessage}`);
        });
    }
  };

  return (
    <>
      {/* RElogin */}
      {auth?.currentUser?.providerData[0]?.providerId === "password" ? (
        // EMAIL AND PASSWORD RELOGIN
        <div
          style={{
            opacity: login ? "5" : "0",
            visibility: login ? "visible" : "hidden",
            background: background ? "" : "initial",
            zIndex: 999999999,
          }}
          className="myne-modal"
        >
          <div
            style={{
              transform: login ? "translateX(0)" : "translateX(50px)",
              opacity: login ? "5" : "0",
              height: 530,
            }}
            className="myne-modal__modal-edit-cadastro"
          >
            {login && (
              <>
                <header className="myne-modal__modal-edit-header">
                  <section className="myne-modal__modal-edit-header__left">
                    <IconArrowBack
                      onClick={() => {
                        if (!removeAccount) {
                          setmodalconfig(true);
                        }
                        back();
                      }}
                      style={{ marginRight: 12, cursor: "pointer" }}
                    />

                    <p style={{ textTransform: "initial", fontSize: 13 }}>
                      Fazer login novamente
                    </p>
                  </section>

                  <section className="myne-modal__modal-edit-header__icons">
                    <IconClose
                      onClick={() => {
                        back();
                        if (!removeAccount) {
                          setmodalconfig(false);
                        } else {
                          setmodalconfig2(false);
                        }
                      }}
                    />
                  </section>
                </header>
                <div className="myne-modal__modal-edit-cadastro-content myne-modal__modal-edit-cadastro-content-relogin">
                  {/*  */}
                  <div
                    style={{
                      position: pageContent === "Entrar" ? "relative" : "fixed",
                      right: pageContent === "Entrar" ? "0" : "30%",
                      opacity: pageContent === "Entrar" ? "1" : "0",
                      visibility:
                        pageContent === "Entrar" ? "visible" : "hidden",
                      zIndex: pageContent === "Entrar" ? "0" : "-99999999",
                      height: senhaNot ? "500px" : "",
                    }}
                    className="login__content myne-modal--animation-left"
                  >
                    <section>
                      <InputForm
                        senha={false}
                        value={email}
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                        label={activeLanguage?.login?.inputs?.email}
                      />
                      <InputForm
                        senha={true}
                        style={{ marginTop: "15px" }}
                        value={senha}
                        onChange={(e) => setSenha(e.target.value)}
                        label={activeLanguage?.login?.inputs?.password}
                        onKeyDown={(e) => {
                          if (email !== "" && senha !== "") {
                            if (e.key === "Enter") {
                              reLogin(e);
                            }
                          }
                        }}
                      />

                      {senhaNot ? <p>{textErro}</p> : ""}
                      <span onClick={() => setPageContent("Esqueci a senha")}>
                        {activeLanguage?.login?.inputs?.forgot_password}
                      </span>
                    </section>
                    {loading ? (
                      <button
                        style={{ opacity: "0.3", cursor: "auto" }}
                        className="myne-btn btn-entrar"
                      >
                        <LoadingInButton />
                      </button>
                    ) : (
                      <button
                        onClick={reLogin}
                        className="myne-btn btn-entrar"
                        style={{ marginTop: "auto" }}
                      >
                        {activeLanguage?.login?.btns?.submit}
                      </button>
                    )}
                  </div>

                  <div
                    style={{
                      position: pageContent !== "Entrar" ? "relative" : "fixed",
                      right: pageContent !== "Entrar" ? "0" : "30%",
                      opacity: pageContent !== "Entrar" ? "5" : "0",
                      visibility:
                        pageContent !== "Entrar" ? "visible" : "hidden",
                      zIndex: pageContent !== "Entrar" ? "0" : "-99999999",
                    }}
                    className="login__content-recuperar__senha"
                  >
                    <div className="login__back">
                      <IconButton onClick={() => setPageContent("Entrar")}>
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.6"
                            d="M14.9844 7.01562C15.2656 7.01562 15.5 7.10938 15.6875 7.29688C15.9062 7.48438 16.0156 7.71875 16.0156 8C16.0156 8.28125 15.9062 8.51562 15.6875 8.70312C15.5 8.89062 15.2656 8.98438 14.9844 8.98438H3.82812L8.70312 13.8594C9.14062 14.3281 9.14062 14.8125 8.70312 15.3125C8.51562 15.5 8.28125 15.5938 8 15.5938C7.71875 15.5938 7.48438 15.5 7.29688 15.3125L0.6875 8.70312C0.5 8.51562 0.40625 8.28125 0.40625 8C0.40625 7.71875 0.5 7.48438 0.6875 7.29688L7.29688 0.6875C7.48438 0.5 7.71875 0.40625 8 0.40625C8.28125 0.40625 8.51562 0.5 8.70312 0.6875C9.14062 1.1875 9.14062 1.67188 8.70312 2.14062L3.82812 7.01562H14.9844Z"
                            fill="black"
                          />
                        </svg>
                      </IconButton>

                      <p className="myne-text">
                        {activeLanguage?.login?.forgot_password_view?.title}
                      </p>
                    </div>

                    <FormForgotPassword
                      activeLanguage={activeLanguage}
                      setLoading={setLoading}
                      setPageContent={setPageContent}
                    />
                  </div>
                  {/*  */}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        // GOOGLE RELOGIN
        <div
          style={{
            opacity: login ? "5" : "0",
            visibility: login ? "visible" : "hidden",
            background: background ? "" : "initial",
            zIndex: 999999999,
          }}
          className="myne-modal"
        >
          <div
            style={{
              transform: login ? "translateX(0)" : "translateX(50px)",
              opacity: login ? "5" : "0",
              height: 530,
            }}
            className="myne-modal__modal-edit-cadastro"
          >
            {login && (
              <>
                <header className="myne-modal__modal-edit-header">
                  <section className="myne-modal__modal-edit-header__left">
                    <IconArrowBack
                      onClick={() => {
                        if (!removeAccount) {
                          setmodalconfig(true);
                        }
                        back();
                      }}
                      style={{ marginRight: 12, cursor: "pointer" }}
                    />

                    <p style={{ textTransform: "initial", fontSize: 13 }}>
                      Fazer login novamente
                    </p>
                  </section>

                  <section className="myne-modal__modal-edit-header__icons">
                    <IconClose
                      onClick={() => {
                        back();
                        if (!removeAccount) {
                          setmodalconfig(false);
                        } else {
                          setmodalconfig2(false);
                        }
                      }}
                    />
                  </section>
                </header>
                <div className="myne-modal__modal-edit-cadastro-content myne-modal__modal-edit-cadastro-content-relogin">
                  <div
                    style={{
                      position: pageContent === "Entrar" ? "relative" : "fixed",
                      right: pageContent === "Entrar" ? "0" : "30%",
                      opacity: pageContent === "Entrar" ? "1" : "0",
                      visibility:
                        pageContent === "Entrar" ? "visible" : "hidden",
                      zIndex: pageContent === "Entrar" ? "0" : "-99999999",
                      height: senhaNot ? "500px" : "",
                    }}
                    className="login__content myne-modal--animation-left"
                  >
                    <h5 className="myne-text myne-text-info">
                      Faça login novamente com o Google para continuar:
                    </h5>
                    <section>{senhaNot ? <p>{textErro}</p> : ""}</section>
                    <button onClick={reLogin} className="myne-btn btn-google">
                      <FcGoogle />
                      <p className="myne-text">Login com o Google</p>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ReLogin;
