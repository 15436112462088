import React from "react";
import { IconImport } from "../../assets/icons/icons";

function OptionsCard({
  setmodal,
  click,
  icon,
  classAdd,
  title,
  description,
  spantxt,
  special,
  Import,
  iconArrow,
}) {
  return (
    <div
      onClick={() => (click ? click() : setmodal(true))}
      className={`myne-opcoes__component-item ${classAdd}`}
    >
      {special ? (
        <>
          <section>{icon}</section>
          <div>
            <p>{title}</p>
          </div>
          <ArrowRight />
        </>
      ) : (
        <>
          <section>
            {icon}
            <p>{title}</p>
          </section>
          <div>
            <p title={description}>{description}</p>
            <span title={spantxt}>{spantxt}</span>
          </div>
          {iconArrow ? <ArrowRight2 /> : Import ? <IconImport /> : <IconEdit />}
        </>
      )}
    </div>
  );
}

export default OptionsCard;

const ArrowRight = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.01562 12.9844H16.1719L11.2969 17.8594C10.9219 18.2812 10.9219 18.8906 11.2969 19.3125C11.6719 19.6875 12.3281 19.6875 12.7031 19.3125L19.3125 12.7031C19.6875 12.3281 19.6875 11.6719 19.3125 11.2969L12.7031 4.6875C12.3281 4.3125 11.6719 4.3125 11.2969 4.6875C10.9219 5.10938 10.9219 5.71875 11.2969 6.09375L16.1719 11.0156H5.01562C4.45312 11.0156 3.98438 11.4375 3.98438 12C3.98438 12.5625 4.45312 12.9844 5.01562 12.9844Z"
      fill="white"
      fillOpacity="0.87"
    />
  </svg>
);
const ArrowRight2 = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.01562 8.98438C1.45312 8.98438 0.984375 8.5625 0.984375 8C0.984375 7.4375 1.45312 7.01562 2.01562 7.01562H13.1719L8.29688 2.09375C7.92188 1.71875 7.92188 1.10938 8.29688 0.6875C8.67188 0.3125 9.32812 0.3125 9.70312 0.6875L16.3125 7.29688C16.6875 7.67188 16.6875 8.32812 16.3125 8.70312L9.70312 15.3125C9.32812 15.6875 8.67188 15.6875 8.29688 15.3125C7.92188 14.8906 7.92188 14.2812 8.29688 13.8594L13.1719 8.98438H2.01562Z"
      fill={"black"}
      fillOpacity={"0.6"}
    />
  </svg>
);

const IconEdit = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 17.4375V20.4844C3 20.7656 3.23438 21 3.51562 21H6.5625C6.65625 21 6.79688 20.9531 6.89062 20.8594L17.8125 9.9375L14.0625 6.1875L3.14062 17.1094C3.04688 17.2031 3 17.2969 3 17.4375ZM20.7188 7.03125C21.0938 6.65625 21.0938 6 20.7188 5.625L18.375 3.28125C18 2.90625 17.3438 2.90625 16.9688 3.28125L15.1406 5.10938L18.8906 8.85938L20.7188 7.03125Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);
