import { algoliasearch } from "algoliasearch";

// Myne Homolog
// export const clientAlgolia = algoliasearch(
//   "LI6ZYZSMLK",
//   // "72e3639ec5998ff340860d9d280986da"
//   // "dc11cc587935df0bc66b82d75fe15475"
//   "4bc3fcb75aeeed475e09173450a0a187"
// );
// Myne Produção
export const clientAlgolia = algoliasearch(
  process.env.REACT_APP_ALGOLIA_ID,
  // "6ce1895f591f6ca01a1fedf2de8a0ffe",
  process.env.REACT_APP_ALGOLIA_KEY
);
// clientAlgolia.setSettings({
//   indexName: "products",
//   indexSettings: {
//     attributesForFaceting: ["userId"],
//     searchableAttributes: ["name", "description"],
//   },
// });

// EXAMPLE CODE FROM ALGOLIA:

// const client = algoliasearch('LI6ZYZSMLK', '<YOUR_API_KEY>');

// Add a new record to your Algolia index
// const { taskID } = await client.saveObject({
//   indexName: '<YOUR_INDEX_NAME>',
//   body: {
//     title: 'My Algolia Object',
//   },
// });

// // Poll the task status to know when it has been indexed
// await client.waitForTask({ indexName: '<YOUR_INDEX_NAME>', taskID });

// // Fetch search results
// const { results } = await client.search({
//   requests: [
//     {
//       indexName: '<YOUR_INDEX_NAME>',
//       // You can make typos, we handle it
//       query: 'my aloglia ojbect',
//       hitsPerPage: 50,
//     },
//   ],
// });

// console.log('[Results]', results);
