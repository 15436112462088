import { auth } from "../../../../services/Firebase";

export const forgotPasswordAccount = (
  email,
  setModalMessage,
  setLoading,
  activeLanguage
) => {
  if (email !== "") {
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setModalMessage(true);
      })
      .catch((e) => {
        let errorCode = e.code;
        let errorMessage = e.message;
        setLoading(false);

        alert(`${errorCode} ${errorMessage}`);
      });
  } else {
    alert(activeLanguage?.login?.text_error_email);
  }
};
