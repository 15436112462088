import React from "react";

import { NavLink } from "react-router-dom";
import { useStateValue } from "../../providers/StateProvider";

function ButtonSidebar({ border, icon, top, text, ...rest }) {
  const [, dispatch] = useStateValue();
  return (
    <NavLink
      {...rest}
      onClick={() => {
        // dispatch({
        //   type: "SET_MESSAGES",
        //   messages: {
        //     messageSucesseEdit: false,
        //     messageSucesseAdd: false,
        //     messageSucesseRemove: false,
        //     messageErro: false,
        //   },
        // });
        // dispatch({
        //   type: "SET_MESSAGES",
        //   messages: {
        //     messageSucesseAddVenda: false,
        //     messageSucesseRemoveVenda: false,
        //     messageErro: false,
        //     messageSucesseUpdateVenda: false,
        //   },
        // });
      }}
      activeClassName={
        border
          ? "myne-button__sidebar-active myne-bottom-border"
          : "myne-button__sidebar-active"
      }
      className={
        border
          ? "myne-button__sidebar myne-bottom-border"
          : "myne-button__sidebar"
      }
    >
      {icon}

      <p className="myne-text" style={{ marginTop: top ? "20px" : "" }}>
        {text}
      </p>
    </NavLink>
  );
}

export default ButtonSidebar;
