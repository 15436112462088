import { mesesEn, mesesEs, mesesPt } from "./index";
import { langEn } from "./index";
import { langEs } from "./index";
import { langPt } from "./index";

export const setLanguageSystem = (dispatch, languageSet) => {
  let language = "pt";

  const activeLanguageID = localStorage.getItem("activeLanguageID");

  const lang = activeLanguageID
    ? activeLanguageID
    : navigator.language || navigator.userLanguage;

  if (lang === "pt") {
    language = "pt";
  } else if (lang !== "pt-BR" && lang !== "es") {
    language = "en";
  } else {
    language = lang === "pt-BR" ? "pt" : lang;
  }

  if (languageSet) {
    language = languageSet;
  }

  localStorage.setItem("activeLanguageID", language);

  dispatch({
    type: "SET_ACTIVE_LANGUAGE",
    activeLanguage:
      language === "pt" || language === null
        ? langPt
        : language === "en"
        ? langEn
        : language === "es"
        ? langEs
        : langEn,
    activeLanguageID: language,
  });
  dispatch({
    type: "SET_MESES",
    mesesNew:
      language === "pt" || language === null
        ? mesesPt
        : language === "en"
        ? mesesEn
        : language === "es"
        ? mesesEs
        : mesesEn,
  });
};
