export const langEs = {
  lang_pt: "Português",
  lang_en: "English",
  lang_es: "Español",
  language_change: "El idioma cambió exitosamente",
  // LOGIN PAGE
  login: {
    titleMain: "Maximiza tus ventas con <strong>Myne</strong>",
    descriptionMain: "La herramienta definitiva para microemprendedores",
    inputs: {
      email: "Correo electrónico",
      password: "Contraseña",
      forgot_password: "Olvidé mi contraseña",
    },
    btns: {
      submit: "INICIA",
      submit_google: "INICIA CON GOOGLE",
      create_account: "CREAR CUENTA",
      create_account_google: "CREAR CON GOOGLE",
    },
    forgot_password_view: {
      title: "Olvidé mi contraseña",
      submit: "Enviar",
      feedback: {
        title:
          "Hemos enviado un correo electrónico de recuperación de contraseña a la dirección:",
        submit: "OK",
      },
    },
    create_account_view: {
      title: "Crear cuenta",
      confirm_password: "Confirmar contraseña",
      error_confirm_password: "¡La contraseña no es igual a la confirmación!",
    },
    text_error: "¡Rellena todos los campos!",
    text_error_email:
      "¡Complete el correo electrónico para la recuperación de la contraseña!",
  },
  // LAYOUTS
  layouts: {
    sidebar: {
      links: {
        vendas: "Ventas",
        registers: "Registros",
        options: "Opciones",
      },
      logout_btn: "Salir",
    },
    header: {
      filter_dates: {
        day: "Día",
        month: "Mes",
        day_plural: "Días",
        monthy_plural: "Mêses",
        datefilter_modal: {
          dayandinitial: "Día inicial",
          dayandfinal: "Día final",
          daymonthinitial: "Mes inicial",
          daymonthfinal: "Mes final",
        },
      },
      plus_btn: "SEA PLUS",
    },
  },
  // ALERTS
  alerts: {
    update: "Actualizado con éxito",
    register_sale: "Venta Registrada",
    remove_sale: "¡Venta eliminada con éxito!",
    update_service: "¡Cambio guardado con éxito!",
    erro: "Algo salió mal. Inténtalo de nuevo más tarde.",
    moeda_change: "Moneda actualizada con éxito",
    save_service: {
      alert: "!",
      plural: "salvado",
      no_plural: "salvado",
      p2: "con éxito!",
    }, // !+type+..
    product_show_catalogo: "Producto disponible en el catálogo online.",
    product_remove_catalogo: "Producto eliminado del catálogo online",
    delete_service: "eliminado con éxito!",
    error_upload_image: "Error subiendo imagen. Inténtalo de nuevo.",
  },
  // SALES
  sales: {
    title: "Ventas",
    list_sales: {
      remove_sale: {
        title: "Eliminar venta",
        question: "¿Está seguro de que desea eliminar la venta?",
      },
      no_itens: "Tus ventas quedarán registradas aquí",
      title: "Registros de ventas",
      filter: {
        f1: "Aumento del total",
        f2: "Total decreciente",
        f3: "Fecha de media luna",
        f4: "Fecha descendente",
      },
      item_sale: {
        status: {
          paid: "Pagado",
          notpaid: "No pagado",
          cash: "EN EFETIVO",
          installments: "Cuotas",
          paid_day: "Pago a tiempo",
          overdue: "Atrasado",
        },
        sale_details: {
          title_receipt: "RECIBO",
          title_clt: "Cliente",
          title_pdt: "Productos",
          title_srv: "Servicios",
          checkout: {
            subtotal: "Subtotal",
            discount: "Descuento",
            total: "Total",
          },
          btn_edit: "EDITAR",
          export: {
            title: "Exportar em",
            download: "Descargar recibo",
          },
          recibo: {
            sale_date: "Fecha de venta:",
            infos_sale: {
              title: "Cliente",
              title_payment_sale: "Método de pago",
              title_status_sale: "Estado de venta",
            },
            tables: {
              title_pdt: "Productos",
              title_srv: "Servicios",
              qty: "Cantidad",
              price: "Precio unitario",
              total: "Total",
              checkout: {
                subtotal: "Subtotal",
                discount: "Descuento",
                total_sale: "Venta total",
              },
            },
            installment_infos: {
              title: "Cuotas",
              table: {
                t1: "Cuota",
                t2: "Fecha",
                t3: "Status",
                t4: "Valor",
              },
            },
            footer: {
              title: "Recibo generado por <b>Myne App.</b>",
            },
          },
        },
      },
      btn_add: "Nueva Venta",
    },
    money_sales: {
      faturamento_brt: "Facturación",
      faturamento_liq: "Ganancia",
      tiket: "Ticket medio",
    },
    biggestbuy_sales: {
      title: "Principales compradores",
      no_itens: "Aquí se mostrarán los clientes que más compran.",
    },
    graph_sales: {
      no_itens:
        "El gráfico aparecerá tan pronto como registre su primera venta.",
      title_pdt: "Venta de productos",
      title_clt: "Ventas por clientes",
      title_srv: "Venta de Servicios",
      title_units: "Unidades",
      title_sales: "ventas",
      btn_more: "Ver más",
    },
  },
  // BE PLUS
  be_plus: {
    title: "Haga crecer su negocio con Myne Plus",
    title_global: "<b>+100 mil negocios</b> en todo el mundo",
    check1: "Ventas ilimitadas",
    check2: "Myne para PC",
    check3: "Sin Anuncios",
    check4: "Informes y gráficos increíbles",
    check5: "Categorías de productos ilimitadas",
    locked_plus:
      "Esta función está bloqueada para usuarios gratuitos.<br />Conviértase en un <strong>usuario PLUS</strong> para desbloquear esta función",
    limit_products: `El nuevo límite para usuarios GRATUITOS es <strong>${40} productos</strong>`,
    limit:
      "Has alcanzado el límite de <strong>1 venta diaria</strong>.<br />Para agregar más ventas conviértase en un <strong>usuario PLUS.</strong>",
    btns: {
      plan: {
        title: "Plan",
        month: "mes",
        cobra_year: "*Cargo único de R$ 119,99 por año.",
        cobra_month: "*Facturado mensualmente.",
      },
      month: "Mensual",
      year: "Anual",
      btn: "SUSCRÍBETE",
    },
    // welcome
    welcome: {
      title: "¡Bienvenido a Myne Plus!",
      desc: "Hemos confirmado su suscripción y ahora tiene acceso completo a <b>Myne.</b>",
      check1: "Registros de ventas ilimitados",
      check2: "Acceso total a las futuras nuevas funciones",
      check3: "Sin Anuncios",
    },
  },
  // OPTIONS
  options: {
    title: "Opciones",
    money: {
      title: "Opciones",
      input: "Buscar...",
    },
    be_plus: "Sea plus",
    catalog_online: {
      title: "Mi catálogo online",
    },
    settings: {
      title: "Ajustes",
      account: {
        title: "Cuenta",
        description: "Cambiar datos",
      },
      categories: {
        title: "Categorías",
        description: "Configurar las categorías",
      },
      coin: {
        title: "Moneda",
      },
      delete_account: {
        title: "Borrar cuenta",
        description: "Dejar de usar Myne",
      },
      receipt: {
        title: "Recibo",
        description: "Personalizar recibo",
      },
    },
    batch_actions: {
      title: "Acciones por lotes",
      products: "Productos",
      customers: "Clientes",
      description: {
        p1: "Agregar",
        p2: "en lote",
      },
    },
  },
  // MODALS
  modals: {
    // NEW SALE
    new_sale: {
      title: "NUEVA VENTA",
      edit: "EDITAR VENTA",
      filter_btns: {
        f1: "DETALLE",
        f2: "CIERRE",
      },
      details: {
        modals_btns: {
          input: "Buscar...",
          no_itens: {
            p1: "Su",
            p2: "registrado se mostrará aquí.",
          },
          discount_greater:
            "El descuento no puede ser mayor que el precio de venta.",
          title_type: "Nuevo", // +type
          product: {
            modal_add: {
              title: "Agregar Venta de producto",
              unit_stock: "unidades en inventario",
              price_unit: "Precio unitario ",
              add_btn: "Añadir",
            },
          },
        },
        input_desc: "¿Alguna observación?",
        next_btn: "PRÓXIMO",
      },
      closure: {
        title_value_sale: "Valor de la venta",
        checkout: {
          subtotal: "Subtotal",
          discount: {
            title: "Descuento",
            btn: "Añadir",
          },
          total: "Total",
        },
        //
        installments: "Cuotas",
        //
        installments_paid: "¿Ya se pagó alguna cuota?",
        //
        save_btn: "SALVAR",
      },
    },
    // ADD IN LOTE
    modal_add_lote: {
      title: {
        p1: "Añadir",
        p2: "en lote",
      },
      import_file: {
        title: "Importar archivo",
        download_file: {
          p1: "<b>Descargue el archivo de plantilla</b> y comience a registrar<br /> sus ",
          p2: "de manera mucho más eficiente.",
        },
        download_btn: "Descargar",
        //
        file_sucess: {
          title: "Archivo importado con éxito.",
          cancel_file: "Cancelar archivo",
        },
        drag_file:
          "Arrastra tu archivo aquí o<br /><b>Seleccione en su computadora</b>",
        file_error: {
          title:
            "<b>Formato de archivo inválido.</b> Proporcione un archivo en<br />formato XLSX para continuar.",
          drag_file:
            "Arrastra tu archivo aquí o<br /> Seleccione en su computadora",
        },
        format:
          "Formato permitido: <b>Excel (XLSX)</b> | Tamaño máximo: <b>15 MB</b>",
      },
      review_dados: {
        title: "Revisar datos",
        error: {
          p1: "2 errores foram encontrados.",
          p2: "Alguns campos obrigatórios pueden estar vazios ou existenm campos preenchidos incorrectamente.",
        },
        alert:
          "<b>Revise los datos importados</b> y si algo sale mal, vuelva a la página<br />anterior, ajuste el archivo e importe nuevamente.",
      },
      next_btn: "PRÓXIMO",
      save_btn: "SALVAR",
    },
  },
  // REGISTERS
  registers: {
    title: "Registros",
    filter_types: {
      pdt: "Productos",
      clt: "Clientes",
      srv: "Servicios",
      disc: "Descuentos",
    },
    filter: {
      f1: "Nombre en crecimiento",
      f2: "Nombre decreciente",
      f3: "Stock creciente",
      f4: "Disminución de stock",
      f5: "Precio creciente",
      f6: "Precio decreciente",
    },
    no_itens: {
      p1: "Sus",
      p2: "registrados serán mostrado aquí.",
    },
    title_registers: "registrados",
    add_btn: {
      title: "NUEVO",
      add_lote: {
        p1: "Añadir",
        p2: "en lote",
      },
      add_manual: {
        p1: "Añadir",
      },
    },
    export_btn: "Stock de exportación",
    resume_stock: {
      title: "Resumen de inventario",
      qty_pdt: "Cantidad de productos",
      qty_stock: "Cantidad de inventario",
      value_stock: "Coste total del inventario",
      based_price_cost: "(Basado en el precio de coste de los productos)",
      value_stock_sale: "Valor total de venta del inventario",
      based_price_salwe: "(Basado en el precio de venta de los productos)",
    },
    sales_clients: {
      no_click: "Haga clic en el cliente y vea sus ventas aquí.",
      title: "Ventas por clientes",
      total_sales: "Número total de ventas",
      total_sold: "Totales vendidos",
      sale_made: "Venta realizada el",
      no_itens: "No se encontraron ventas para este cliente.",
    },
  },
  // TYPES
  services: {
    btn: {
      no: "NO",
      yes: "SÍ",
    },
    modal_new: "NUEVO",
    inputs: {
      required_fields: "Rellene los campos obligatorios",
      no_item: "No llenado",
      search: "Buscar",
      name: "Nombre", // *
      qty: "Cantidad",
      price_cost: "Precio de costo",
      price_sale: "Precio de venta", // *
      stock_min: "Mínimo Inventario",
      description: "Descripción",
      phone: "Teléfono",
      email: "Correo electrónico",
      comment: "Comentarios",
    },
    modal_remove: {
      title: "Eliminar", // +type
      ask: "¿Está seguro qué quiere borrar el", // ?
      ask2: "¿Estás seguro de lo que quieres eliminar esta", // ?
    },
    // PRODUTO
    product: {
      title: "Productos",
      title_min: "Producto",
      value: "Valor",
      stock: "Inventario",
      // ADD
      modal_add: {
        stock_min_value: "¿Cuál es el inventario mínimo?",
        other_stock: "OTRO",
        photo: "Foto",
        save_btn: "SALVAR",
      },
      // STOCK
      modal_stock: {
        title: "Mínimo Inventario",
      },
    },
    // CLIENT
    client: {
      title: "Clientes",
      title_min: "Cliente",
      telephone: "Teléfono",
    },
    // SERVICE
    service: {
      title: "Servicios",
      title_min: "Servicio",
      value: "Valor",
    },
    // CATEGORIES
    categories: {
      title: "Categorías",
      title_min: "Categoría",
    },
    // DISCOUNT
    discount: {
      title: "Descuentos",
      title_min: "Descuento",
      qty: "Cantidad",
      // ADD
      modal_add: {
        type: "¿Cuál es el tipo de descuento?",
        money: "Dinero",
        porcent: "Porcentaje",
        input_name: "Nombre de descuento", // *
        value_discount: "Valor de descuento",
      },
    },
  },
};
