import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import { useStateValue } from "../../providers/StateProvider";
import { useState } from "react";
import LoadingInButton from "../Loading/LoadingInButton";

export default function BtnExport({ products }) {
  const [{ activeLanguage }] = useStateValue();
  const [loading, setLoading] = useState(false);

  function getSheetData(data, header) {
    var fields = Object?.keys(data[0]);
    var sheetData = data?.map((row) => {
      return fields?.map((fieldName) => {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData?.unshift(header);
    return sheetData;
  }

  async function saveAsExcel() {
    setLoading(true);
    let dataExport = [];
    await products?.forEach((product) => {
      dataExport?.push({
        name: product?.data?.name,
        qty: product?.data?.stock,
        price_cost: product?.data?.costPrice,
        price_sale: product?.data?.salePrice,
        description: product?.data?.description,
        stock_min: product?.data?.minimumStock,
      });
    });

    var data = dataExport;
    let header = [
      activeLanguage?.services?.inputs?.name,
      activeLanguage?.services?.inputs?.qty,
      activeLanguage?.services?.inputs?.price_cost,
      activeLanguage?.services?.inputs?.price_sale,
      activeLanguage?.services?.inputs?.description,
      activeLanguage?.services?.inputs?.stock_min,
    ];

    XlsxPopulate?.fromBlankAsync()?.then(async (workbook) => {
      const sheet1 = workbook?.sheet(0);
      const sheetData = getSheetData(data, header);
      const totalColumns = sheetData[0]?.length;

      sheet1?.column("A").width(11.86).style("fontFamily", "Arial");
      sheet1?.column("B").width(15.71).style("fontFamily", "Arial");
      sheet1?.column("C").width(12.57).style("fontFamily", "Arial");
      sheet1?.column("D").width(12.71).style("fontFamily", "Arial");
      sheet1?.column("E").width(26.29).style("fontFamily", "Arial");
      sheet1?.column("F").width(13.29).style("fontFamily", "Arial");

      sheet1
        ?.cell("A1")
        .value(sheetData)
        .style("fontFamily", "Arial")
        .style("fontSize", 10);
      const endColumn = String.fromCharCode(64 + totalColumns);

      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1");

      const gerateId = Math.random().toString(16).slice(2).toUpperCase();

      return workbook?.outputAsync()?.then((res) => {
        saveAs(res, `export-products-${gerateId}.xlsx`);
        setLoading(false);
      });
    });
  }

  return (
    <button
      className="myne-area-add-products_export_btn myne-modal--animation"
      onClick={saveAsExcel}
    >
      {loading ? (
        <LoadingInButton
          stylesLoading={{ border: "4px solid rgba(170, 0, 255, 0.36)" }}
        />
      ) : (
        <>
          <IconExport />
          <p>{activeLanguage?.registers?.export_btn}</p>
        </>
      )}
    </button>
  );
}

const IconExport = () => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5938 6C12.4844 6 12.9062 7.07812 12.2969 7.6875L7.70312 12.2812C7.32812 12.7031 6.67188 12.7031 6.29688 12.2812L1.70312 7.6875C1.04688 7.07812 1.51562 6 2.40625 6H4V0.984375C4 0.46875 4.46875 0 4.98438 0H9.01562C9.53125 0 10 0.46875 10 0.984375V6H11.5938ZM0.015625 15.9844C0.015625 15.4688 0.4375 15 1 15H13C13.5625 15 13.9844 15.4688 13.9844 15.9844C13.9844 16.5469 13.5625 17.0156 13 17.0156H1C0.4375 17.0156 0.015625 16.5469 0.015625 15.9844Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);
