import React, { useState } from "react";
import "../../styles/Components/index.css";
import { StoreService } from "../../providers/catalogo";
import { UserService } from "../../providers/reducer";
import ModalConfigureCatalogo from "../Modals/Catalogo/ModalConfigureCatalogo";
import toast from "react-hot-toast";
import { useStateValue } from "../../providers/StateProvider";

const SwitchOnCatalog = ({ setOnCatalog, onCatalog, iD, item, isEditing }) => {
  const [modalConfigureCatalog, setModalConfigureCatalog] = useState(false);
  const [gifanimationsucess, setGifanimationsucess] = useState(true);
  const [{ activeLanguage }] = useStateValue();

  const onChangeProductOnCatalog = async () => {
    const hasStore = await UserService?.hasStore();
    if (!hasStore) {
      setModalConfigureCatalog(true);
      setOnCatalog(false);
    } else {
      setOnCatalog(!onCatalog);

      if (isEditing) {
        if (!onCatalog) {
          toast.success(activeLanguage?.alerts?.product_show_catalogo);
          await StoreService.addItemToCatalog(
            Object.assign({}, item),
            iD,
            "products"
          );
        } else {
          toast.success(activeLanguage?.alerts?.product_remove_catalogo);
          await StoreService.removeItemToCatalog(iD, "products");
        }
      }
    }
  };

  return (
    <>
      <ModalConfigureCatalogo
        modal={modalConfigureCatalog}
        setmodal={setModalConfigureCatalog}
        setGifanimationsucess={setGifanimationsucess}
        gifanimationsucess={gifanimationsucess}
      />

      <div className="myne-modal__modal-edit-cadastro-content__show-catalogo">
        <label
          htmlFor={`show-catalogo-new-${iD}`}
          style={{ cursor: "pointer" }}
        >
          <p className="myne-text">
            Mostrar produto no <b>Meu catálogo</b>
          </p>
          <label className="switch switch-primary">
            <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              onChange={() => onChangeProductOnCatalog()}
              id={`show-catalogo-new-${iD}`}
              checked={onCatalog}
            />
            <span style={{ cursor: "pointer" }} className="slider round"></span>
          </label>
        </label>
      </div>
    </>
  );
};

export default SwitchOnCatalog;
