import { useRef } from "react";
import useDraggableScroll from "use-draggable-scroll";

export const Draggable = ({ rootClass, children, nodeRef, ...rest }) => {
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(nodeRef ?? ref, {
    direction: "horizontal",
  });
  return (
    <div
      ref={nodeRef ?? ref}
      onMouseDown={onMouseDown}
      className={rootClass}
      {...rest}
    >
      {children}
    </div>
  );
};
