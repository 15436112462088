import React, { useEffect, useState } from "react";
import InputEditInstallment from "../../inputs/InputEditInstallment";
import { useStateValue } from "../../../providers/StateProvider";
import { firebase } from "../../../services/Firebase";
import { formatPrice } from "../../../shared/utils/helpers/format";
import LoadingInButton from "../../Loading/LoadingInButton";

const ModalEditInstallmentValues = ({
  modal,
  setmodal,
  installments,
  setInstallments,
  totalSale,
  setSelectedValueParcelamento,
  onSaveInstallments,
}) => {
  const [installmentsValues, setInstallmentsValues] = useState([]);
  const [valueAssociate, setValueAssociate] = useState(0);
  const [{ activeMoeda, SaleFirestore }] = useStateValue();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInstallmentsValues(installments);
  }, [installments]);

  const saveInstallmentValues = async () => {
    setLoading(true);
    setInstallments(installmentsValues);
    setmodal(false);
    if (onSaveInstallments) {
      await onSaveInstallments(setLoading, installmentsValues);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    let total = 0;
    installmentsValues?.map((installment) => {
      total += installment?.value;
    });
    setValueAssociate(totalSale - total);
  }, [installmentsValues, totalSale]);

  const addInstallment = () => {
    const n = installmentsValues[installmentsValues?.length - 1]?.index + 1;
    const dataSelect =
      installmentsValues[installmentsValues?.length - 1]?.payday?.toDate();
    let data = new Date(dataSelect);

    let dataSelectN = new Date(
      data?.getFullYear(),
      data?.getMonth() + 1,
      data?.getDate(),
      data?.getHours(),
      data?.getMinutes(),
      data?.getSeconds(),
      data?.getMilliseconds()
    );

    const installment = {
      index: n,
      value: valueAssociate > 0 ? valueAssociate : 0,
      payday: firebase.firestore.Timestamp.fromDate(dataSelectN),
      status: SaleFirestore.NotPaid,
      updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      valuePaid: 0,
    };

    setInstallmentsValues((itens) => [...itens, installment]);
  };

  return (
    <div
      style={{
        zIndex: 9999999999,
        opacity: modal ? "5" : "0",
        visibility: modal ? "visible" : "hidden",
        borderRadius: 8,
      }}
      className="myne-modal"
    >
      <div
        style={{ transform: modal ? "scale(1)" : "scale(0.7)" }}
        className="myne-modal__modal-edit-cadastro myne-modal__modal-add-product-sale-item myne-modal__modal-edit-value-installment"
      >
        {modal && (
          <>
            <header className="myne-modal__modal-edit-header">
              <section className="myne-modal__modal-edit-header__left">
                <IconEdit fill="#AA00FF" />
                <p style={{ textTransform: "initial" }}>
                  EDITAR VALOR DAS PARCELAS
                </p>
              </section>

              <section className="myne-modal__modal-edit-header__icons">
                <IconClose onClick={() => setmodal(!modal)} />
              </section>
            </header>

            <div
              style={{
                transform: modal ? "scale(1)" : "scale(0.7)",
              }}
              className="myne-modal__modal-add-product-sale-item__container myne-modal__modal-add-item-content"
            >
              <section className="myne-modal__modal-section-edit-value-installment">
                <div className="myne-modal__modal-section-edit-value-installment__value-total">
                  <section>
                    <p className="myne-text">Total da venda</p>
                    <h5 className="myne-text">
                      {formatPrice(totalSale, activeMoeda)}
                    </h5>
                  </section>

                  {valueAssociate == 0 ||
                  valueAssociate == -1.4210854715202004e-14 ||
                  valueAssociate == 1.4210854715202004e-14 ||
                  isNaN(valueAssociate) ||
                  !isFinite(valueAssociate) ? (
                    ""
                  ) : (
                    <section className="myne-modal__modal-section-edit-value-installment__value-total-associar myne-modal--animation-left">
                      <p className="myne-text">Valor para associar</p>
                      <h5 className="myne-text">
                        {formatPrice(valueAssociate, activeMoeda)}
                      </h5>
                    </section>
                  )}
                </div>

                <div className="myne-modal__modal-edit-cadastro-content__inputs">
                  {installmentsValues?.map((installment, i) => (
                    <InputEditInstallment
                      key={installment?.index}
                      installment={installment}
                      installmentsValues={installmentsValues}
                      setInstallmentsValues={setInstallmentsValues}
                    />
                  ))}
                </div>
              </section>

              <div className="myne-modal__modal-edit-value-installment-area-btns">
                <button style={{ width: 204 }} onClick={() => addInstallment()}>
                  <IconAdd />
                  NOVA PARCELA
                </button>
                {valueAssociate == 0 ? (
                  <button
                    style={{ width: 117 }}
                    onClick={() => saveInstallmentValues()}
                  >
                    {loading ? <LoadingInButton /> : "SALVAR"}
                  </button>
                ) : (
                  <button disabled style={{ width: 117 }}>
                    SALVAR
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModalEditInstallmentValues;

const IconAdd = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 7.98438H7.98438V13C7.98438 13.5625 7.5625 13.9844 7 13.9844C6.4375 13.9844 6.01562 13.5625 6.01562 13V7.98438H1C0.4375 7.98438 0.015625 7.5625 0.015625 7C0.015625 6.4375 0.4375 6.01562 1 6.01562H6.01562V1C6.01562 0.4375 6.4375 0.015625 7 0.015625C7.5625 0.015625 7.98438 0.4375 7.98438 1V6.01562H13C13.5625 6.01562 13.9844 6.4375 13.9844 7C13.9844 7.5625 13.5625 7.98438 13 7.98438Z"
      fill={"black"}
      fillOpacity={"0.87"}
    />
  </svg>
);

const IconClose = ({ ...rest }) => (
  <svg
    {...rest}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM14.3125 14.3125C13.8906 14.6875 13.2812 14.6875 12.9062 14.3125L10 11.4062L7.09375 14.3125C6.71875 14.6875 6.10938 14.6875 5.6875 14.3125C5.3125 13.8906 5.3125 13.2812 5.6875 12.9062L8.59375 10L5.6875 7.09375C5.3125 6.71875 5.3125 6.10938 5.6875 5.6875C6.10938 5.3125 6.71875 5.3125 7.09375 5.6875L10 8.59375L12.9062 5.6875C13.2812 5.3125 13.8906 5.3125 14.3125 5.6875C14.6875 6.10938 14.6875 6.71875 14.3125 7.09375L11.4062 10L14.3125 12.9062C14.6875 13.2812 14.6875 13.8906 14.3125 14.3125Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

const IconEdit = ({ fill, ...rest }) => (
  <svg
    {...rest}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.25 11.0781V13.3633C0.25 13.5742 0.425781 13.75 0.636719 13.75H2.92188C2.99219 13.75 3.09766 13.7148 3.16797 13.6445L11.3594 5.45312L8.54688 2.64062L0.355469 10.832C0.285156 10.9023 0.25 10.9727 0.25 11.0781ZM13.5391 3.27344C13.8203 2.99219 13.8203 2.5 13.5391 2.21875L11.7812 0.460938C11.5 0.179688 11.0078 0.179688 10.7266 0.460938L9.35547 1.83203L12.168 4.64453L13.5391 3.27344Z"
      style={{ fill: fill ? fill : "rgba(0, 0, 0, 0.6)" }}
    />
  </svg>
);
