import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getQueryVariable } from "../../providers/reducer";

function LoadingN() {
  const history = useHistory();

  const foo = getQueryVariable("to");

  useEffect(() => {
    const timer = setTimeout(() => {
      history.replace(foo ? foo : "/");
    }, [1200]);
    return () => {
      clearTimeout(timer);
    };
  }, [history]);

  return (
    <div className="myne-loadingn">
      <div className="myne-circle-loading"></div>
    </div>
  );
}

export default LoadingN;
