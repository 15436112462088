import { getApp } from "@firebase/app";
import { getStripePayments } from "@stripe/firestore-stripe-payments";

const app = getApp();
const payments = getStripePayments(app, {
  productsCollection: "stripPlans",
  customersCollection: "stripeUsers",
});

export { payments };
