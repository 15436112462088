import React, { useEffect, useState } from "react";
import { useStateValue } from "../../providers/StateProvider";
import { IconProduto } from "../../assets/icons/icons";
import { formatPrice } from "../../shared/utils/helpers/format";
import CountUp from "react-countup";

const ResumeStock = ({ filterCadastros }) => {
  const [{ activeMoeda, activeLanguage }] = useStateValue();

  const [TOTALqtyEstoque, setTOTALqtyEstoque] = useState(0);
  const [TOTALvalueCustoEstoque, setTOTALvalueCustoEstoque] = useState(0);
  const [TOTALvalueVendaEstoque, setTOTALvalueVendaEstoque] = useState(0);

  useEffect(() => {
    if (filterCadastros?.length > 0) {
      const getTOTALqtyEstoque = () => {
        let qtyEstoque = 0;
        filterCadastros?.forEach((produtos) => {
          qtyEstoque += produtos?.data?.stock;
        });
        setTOTALqtyEstoque(qtyEstoque);
      };
      //
      const getTOTALvalueCustoEstoque = () => {
        let valueCustoEstoque = 0;
        filterCadastros?.forEach((produtos) => {
          if (produtos?.data?.stock > 0) {
            valueCustoEstoque +=
              produtos?.data?.costPrice * produtos?.data?.stock;
          }
        });
        setTOTALvalueCustoEstoque(valueCustoEstoque);
      };
      //
      const getTOTALvalueVendaEstoque = () => {
        let valueVendaEstoque = 0;
        filterCadastros?.forEach((produtos) => {
          if (produtos?.data?.stock > 0) {
            valueVendaEstoque +=
              produtos?.data?.salePrice * produtos?.data?.stock;
          }
        });
        setTOTALvalueVendaEstoque(valueVendaEstoque);
      };

      getTOTALvalueCustoEstoque();
      getTOTALqtyEstoque();
      getTOTALvalueVendaEstoque();
    }
  }, [filterCadastros]);

  return (
    <div className="dashboard-content__grid-cadastros-resume">
      <p>{activeLanguage?.registers?.resume_stock?.title}</p>
      {/* <h5>Em breve disponibilizaremos essa funcionalidade!</h5> */}
      <div className="dashboard-content__grid-cadastros-resume-clientes_content-item__info">
        <IconProduto />
        <section>
          <p>{activeLanguage?.registers?.resume_stock?.qty_pdt}</p>
          <h5>
            <CountUp end={filterCadastros?.length} duration={1} />
          </h5>
        </section>
      </div>
      <div className="dashboard-content__grid-cadastros-resume-clientes_content-item__info">
        <IconEstoque />
        <section>
          <p>{activeLanguage?.registers?.resume_stock?.qty_stock}</p>
          <h5>
            <CountUp end={TOTALqtyEstoque} duration={1} />
          </h5>
        </section>
      </div>
      <div className="dashboard-content__grid-cadastros-resume-clientes_content-item__info">
        <IconValueCost />
        <section>
          <p>{activeLanguage?.registers?.resume_stock?.value_stock}</p>
          <h5>
            {" "}
            {TOTALvalueCustoEstoque
              ? formatPrice(TOTALvalueCustoEstoque, activeMoeda)
              : formatPrice(0, activeMoeda)}
          </h5>
          <span>
            {activeLanguage?.registers?.resume_stock?.based_price_cost}
          </span>
        </section>
      </div>
      <div className="dashboard-content__grid-cadastros-resume-clientes_content-item__info">
        <IconValueSale />
        <section>
          <p>{activeLanguage?.registers?.resume_stock?.value_stock_sale}</p>
          <h5>
            {TOTALvalueVendaEstoque
              ? formatPrice(TOTALvalueVendaEstoque, activeMoeda)
              : formatPrice(0, activeMoeda)}
          </h5>
          <span>
            {activeLanguage?.registers?.resume_stock?.based_price_salwe}
          </span>
        </section>
      </div>
      <IlustrateProducts />
    </div>
  );
};

export default ResumeStock;

const IlustrateProducts = () => (
  <svg
    width="143"
    height="142"
    viewBox="0 0 143 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.34">
      <path
        d="M142.148 64.7319L141.478 108.15L107.722 127.35L108.393 83.9273L142.148 64.7319Z"
        fill="#DEDEDE"
      />
      <path
        d="M108.394 83.9257L107.724 127.348L73.7792 106.9L74.4494 63.4814L108.394 83.9257Z"
        fill="#EAEAEA"
      />
      <path
        d="M77.1617 105.749V106.48L82.7018 109.712V108.974L77.1617 105.749Z"
        fill="#C7C7C7"
      />
      <path
        d="M78.2314 105.95L78.853 106.31V102.379L79.4713 102.738L78.5477 100.565L77.6057 101.651L78.2341 102.012L78.2314 105.95Z"
        fill="#C7C7C7"
      />
      <path
        d="M81.0104 107.57L81.6288 107.926V103.998L82.254 104.358L81.3199 102.186L80.3889 103.271L81.011 103.63L81.0104 107.57Z"
        fill="#C7C7C7"
      />
      <path
        d="M82.643 110.981L77.2142 107.816C76.6839 107.492 76.2363 107.05 75.9066 106.523C75.577 105.996 75.3743 105.4 75.3147 104.782V99.294C75.3147 98.2396 76.1688 97.8751 77.2142 98.4839L82.643 101.652C83.1745 101.974 83.6236 102.416 83.9547 102.942C84.2858 103.468 84.4898 104.064 84.5505 104.682V110.171C84.5489 111.227 83.699 111.593 82.643 110.981ZM77.2142 98.8712C76.3834 98.3811 75.6993 98.6804 75.6993 99.5176V105.008C75.7477 105.501 75.9097 105.976 76.1725 106.396C76.4354 106.815 76.7919 107.168 77.2142 107.427L82.643 110.602C83.4908 111.082 84.1674 110.789 84.1674 109.944V104.463C84.1205 103.967 83.9582 103.49 83.6935 103.069C83.4287 102.647 83.069 102.294 82.643 102.037L77.2142 98.8712Z"
        fill="#C7C7C7"
      />
      <path
        d="M94.1323 112.678C94.1229 112.675 94.1147 112.669 94.1085 112.661C94.1023 112.654 94.0984 112.644 94.0973 112.634C94.0973 112.508 93.6263 112.111 92.9624 111.717C92.2847 111.327 91.8227 111.187 91.8227 111.307C91.8227 111.323 91.8084 111.327 91.7957 111.323C91.7868 111.318 91.7794 111.311 91.7743 111.302C91.7693 111.294 91.7667 111.284 91.767 111.274C91.767 111.16 91.3808 110.823 90.8181 110.47V113.497L90.8006 113.856C90.7391 114.223 90.408 114.28 90.009 114.049C89.7874 113.916 89.5997 113.734 89.4605 113.516C89.3213 113.299 89.2344 113.052 89.2068 112.796C89.2068 112.69 89.2905 112.647 89.3849 112.707C89.4355 112.74 89.4782 112.784 89.5098 112.835C89.5414 112.887 89.5612 112.945 89.5676 113.005C89.5869 113.14 89.6367 113.269 89.7132 113.382C89.7896 113.495 89.8908 113.589 90.009 113.658C90.4912 113.935 90.4472 113.349 90.4472 113.349V110.256C89.8766 109.938 89.4935 109.84 89.4935 109.945C89.4935 109.961 89.4824 109.972 89.4617 109.961C89.4476 109.95 89.4377 109.935 89.4336 109.917C89.1378 109.521 88.7461 109.207 88.2955 109.004C87.6348 108.61 87.1701 108.471 87.1701 108.594C87.1701 108.605 87.1531 108.615 87.1325 108.605C87.1238 108.6 87.1169 108.592 87.1124 108.583C87.1079 108.573 87.1061 108.563 87.107 108.552C87.1558 107.19 88.6081 106.653 90.3688 107.555C90.3688 107.457 90.4901 107.446 90.6337 107.537C90.699 107.567 90.7565 107.612 90.8018 107.668C90.8472 107.724 90.8792 107.789 90.8955 107.859C92.6561 109.013 94.1037 111.242 94.1636 112.667C94.1643 112.671 94.1636 112.675 94.1616 112.678C94.1596 112.682 94.1565 112.684 94.1528 112.686C94.1491 112.687 94.145 112.687 94.1412 112.686C94.1375 112.684 94.1343 112.682 94.1323 112.678Z"
        fill="#C7C7C7"
      />
      <path
        d="M93.3518 117.218L87.9193 114.054C87.3874 113.732 86.9382 113.29 86.6076 112.764C86.2769 112.237 86.074 111.64 86.015 111.021V105.537C86.015 104.475 86.8664 104.112 87.9193 104.725L93.3518 107.895C93.8791 108.218 94.3243 108.659 94.652 109.183C94.9797 109.707 95.1811 110.3 95.2402 110.915V116.409C95.2402 117.469 94.3935 117.832 93.3518 117.218ZM87.9193 105.109C87.0678 104.619 86.3933 104.914 86.3933 105.759V111.243C86.439 111.739 86.601 112.217 86.8661 112.639C87.1312 113.06 87.4921 113.414 87.9193 113.67L93.3518 116.833C94.1874 117.32 94.8619 117.037 94.8619 116.187V110.693C94.8159 110.201 94.6554 109.727 94.3932 109.307C94.131 108.888 93.7744 108.536 93.3518 108.28L87.9193 105.109Z"
        fill="#C7C7C7"
      />
      <path
        d="M102.774 116.696C102.737 117.549 102.167 117.926 101.441 117.572V120.274L102.375 120.823V121.167L100.274 119.943V119.596L101.208 120.134V117.432C100.829 117.148 100.514 116.786 100.287 116.369C100.06 115.953 99.9254 115.493 99.8923 115.02V113.018L102.774 114.692V116.696Z"
        fill="#C7C7C7"
      />
      <path
        d="M104.042 123.458L98.6169 120.288C98.0854 119.967 97.636 119.527 97.3044 119.002C96.9727 118.477 96.7679 117.882 96.7063 117.264V111.775C96.7063 110.719 97.5636 110.349 98.6169 110.965L104.042 114.138C104.573 114.457 105.022 114.898 105.353 115.423C105.683 115.948 105.885 116.543 105.943 117.161V122.646C105.943 123.7 105.091 124.068 104.042 123.458ZM98.6169 111.352C97.7718 110.862 97.0957 111.154 97.0957 111.996V117.49C97.144 117.983 97.3066 118.458 97.5707 118.877C97.8347 119.296 98.193 119.648 98.6169 119.905L104.042 123.073C104.887 123.557 105.563 123.27 105.563 122.424V116.936C105.517 116.442 105.356 115.966 105.091 115.546C104.827 115.126 104.467 114.774 104.042 114.519L98.6169 111.352Z"
        fill="#C7C7C7"
      />
      <path
        d="M142.148 64.7318L108.397 83.9256L74.4516 63.4814L108.199 44.2876L142.148 64.7318Z"
        fill="#F1F1F1"
      />
      <path
        d="M95.0664 51.7534L88.0883 55.7273L122.03 76.1768L129.014 72.2067L95.0664 51.7534Z"
        fill="#FAFAFA"
      />
      <path
        d="M122.042 76.1748L129.017 72.2046L128.923 79.259L121.952 83.2011L122.042 76.1748Z"
        fill="#E7E7E7"
      />
      <path
        d="M69.2088 40.0659L68.5386 83.4844L34.7871 102.675L35.4606 59.2592L69.2088 40.0659Z"
        fill="#DEDEDE"
      />
      <path
        d="M35.4606 59.2596L34.7871 102.675L0.851852 82.2307L1.51205 38.8101L35.4606 59.2596Z"
        fill="#EAEAEA"
      />
      <path
        d="M4.2285 81.0796V81.8103L9.76118 85.0392V84.308L4.2285 81.0796Z"
        fill="#C7C7C7"
      />
      <path
        d="M5.29777 81.2772L5.9198 81.637V77.7018L6.54132 78.0679L5.61038 75.8955L4.67307 76.9806L5.2983 77.3457L5.29777 81.2772Z"
        fill="#C7C7C7"
      />
      <path
        d="M8.07362 82.8956L8.69513 83.2617V79.3302L9.31346 79.6895L8.37882 77.5171L7.45105 78.599L8.07257 78.9641L8.07362 82.8956Z"
        fill="#C7C7C7"
      />
      <path
        d="M9.70985 86.3106L4.27731 83.1421C3.74711 82.8197 3.29909 82.3785 2.96861 81.8532C2.63814 81.328 2.43425 80.7332 2.37303 80.1156V74.6296C2.37303 73.5699 3.23562 73.2075 4.27731 73.8147L9.70985 76.9832C10.2387 77.3067 10.6854 77.7483 11.015 78.2734C11.3446 78.7985 11.548 79.3928 11.6094 80.0097V85.4978C11.6094 86.5575 10.7547 86.921 9.70985 86.3106ZM4.27731 74.2004C3.44015 73.7182 2.75929 74.0096 2.75929 74.8468V80.336C2.80631 80.8304 2.96801 81.307 3.23153 81.7279C3.49506 82.1488 3.85313 82.5024 4.27731 82.7606L9.70985 85.9244C10.5502 86.4177 11.2342 86.1215 11.2342 85.278V79.7924C11.1854 79.2974 11.0223 78.8204 10.7578 78.3991C10.4933 77.9778 10.1345 77.6237 9.70985 77.3647L4.27731 74.2004Z"
        fill="#C7C7C7"
      />
      <path
        d="M21.1848 88.0069C21.1752 87.9926 21.1577 87.9773 21.1577 87.9598C21.1577 87.8384 20.6957 87.4442 20.0212 87.0468C19.3547 86.6558 18.8863 86.517 18.8863 86.6383C18.8863 86.651 18.8751 86.6558 18.8545 86.651C18.8383 86.6379 18.828 86.6188 18.8259 86.5981C18.8259 86.4921 18.4444 86.1551 17.8785 85.8006V88.8271L17.8705 89.1816C17.7948 89.5567 17.4747 89.6087 17.0758 89.3724C16.8531 89.2423 16.6641 89.0617 16.5242 88.8451C16.3843 88.6285 16.2974 88.3821 16.2704 88.1256C16.2704 88.017 16.3541 87.9757 16.45 88.0371C16.5015 88.069 16.5448 88.1126 16.5763 88.1643C16.6078 88.2161 16.6266 88.2745 16.6312 88.3349C16.6495 88.4701 16.6993 88.5992 16.7765 88.7117C16.8537 88.8243 16.9562 88.9172 17.0758 88.9829C17.5526 89.2696 17.506 88.6772 17.506 88.6772V85.5813C16.9401 85.274 16.5523 85.168 16.5523 85.2771C16.5523 85.293 16.5396 85.2994 16.5253 85.293C16.5166 85.2873 16.5097 85.2794 16.5052 85.2701C16.5007 85.2607 16.4989 85.2504 16.4998 85.2401C16.2002 84.8483 15.811 84.5342 15.3649 84.3239C14.6872 83.9313 14.222 83.7941 14.222 83.9186C14.222 83.9297 14.2109 83.9409 14.1934 83.9266C14.1845 83.9222 14.1771 83.9155 14.1719 83.9072C14.1666 83.8989 14.1638 83.8892 14.1637 83.8794C14.2167 82.5172 15.6706 81.9815 17.4318 82.8801C17.4318 82.7884 17.542 82.7741 17.692 82.8626C17.755 82.8947 17.8103 82.9401 17.8541 82.9956C17.8979 83.0512 17.9292 83.1155 17.9458 83.1842C19.7208 84.3398 21.1614 86.5721 21.2218 87.9894C21.224 88.0143 21.207 88.0175 21.1848 88.0069Z"
        fill="#C7C7C7"
      />
      <path
        d="M20.4139 92.5526L14.9782 89.381C14.4461 89.0604 13.9964 88.6197 13.6652 88.0942C13.3339 87.5686 13.1304 86.9728 13.0707 86.3545V80.8626C13.0707 79.8029 13.9238 79.4436 14.9782 80.054L20.4139 83.2209C20.9418 83.5458 21.387 83.9889 21.7145 84.5152C22.0419 85.0415 22.2426 85.6367 22.3007 86.2538V91.7383C22.3007 92.7958 21.4556 93.1636 20.4139 92.5526ZM14.9782 80.4403C14.1347 79.9549 13.457 80.2368 13.457 81.093V86.5759C13.5041 87.07 13.6662 87.5462 13.9303 87.9664C14.1945 88.3866 14.5533 88.7392 14.9782 88.9958L20.4139 92.1674C21.2479 92.6512 21.9256 92.363 21.9256 91.5194V86.0238C21.8787 85.5319 21.7177 85.0577 21.4552 84.639C21.1928 84.2202 20.8362 83.8686 20.4139 83.612L14.9782 80.4403Z"
        fill="#C7C7C7"
      />
      <path
        d="M29.823 92.0251C29.7992 92.8861 29.2365 93.2549 28.4984 92.9015V95.6095L29.4315 96.1547V96.505L27.3332 95.28V94.9313L28.2711 95.4691V92.7642C27.8903 92.481 27.575 92.1191 27.3464 91.7033C27.1179 91.2874 26.9815 90.8272 26.9465 90.3539V88.3506L29.8188 90.0297L29.823 92.0251Z"
        fill="#C7C7C7"
      />
      <path
        d="M31.1047 98.7862L25.6721 95.6225C25.1413 95.2993 24.6931 94.8567 24.3634 94.33C24.0336 93.8032 23.8313 93.2067 23.7726 92.588V87.1046C23.7726 86.0449 24.6204 85.6793 25.6721 86.2913L31.1047 89.4662C31.636 89.786 32.0848 90.2263 32.4147 90.7515C32.7446 91.2766 32.9466 91.872 33.0042 92.4895V97.9755C33.0042 99.0305 32.1607 99.404 31.1047 98.7862ZM25.6721 86.6807C24.8381 86.1906 24.1541 86.482 24.1541 87.324V92.81C24.2022 93.3041 24.3643 93.7803 24.6278 94.201C24.8912 94.6217 25.2487 94.9756 25.6721 95.2346L31.1047 98.4015C31.945 98.8901 32.6259 98.5955 32.6259 97.7519V92.2696C32.5788 91.7755 32.4167 91.2993 32.1525 90.8791C31.8884 90.4589 31.5295 90.1064 31.1047 89.8498L25.6721 86.6807Z"
        fill="#C7C7C7"
      />
      <path
        d="M69.2125 40.0682L35.4611 59.262L1.51253 38.8124L35.264 19.6187L69.2125 40.0682Z"
        fill="#F1F1F1"
      />
      <path
        d="M22.1296 27.084L15.1483 31.0579L49.0968 51.5053L56.0749 47.5314L22.1296 27.084Z"
        fill="#FAFAFA"
      />
      <path
        d="M49.1041 51.5041L56.0749 47.5303L55.9843 54.5948L49.0135 58.5353L49.1041 51.5041Z"
        fill="#E7E7E7"
      />
      <path
        d="M86.0225 79.3906L85.3591 122.808L51.6077 142L52.271 98.5828L86.0225 79.3906Z"
        fill="#DEDEDE"
      />
      <path
        d="M52.2684 98.5824L51.605 141.999L17.6597 121.554L18.3199 78.1382L52.2684 98.5824Z"
        fill="#EAEAEA"
      />
      <path
        d="M21.0428 120.407V121.14L26.5723 124.361V123.63L21.0428 120.407Z"
        fill="#C7C7C7"
      />
      <path
        d="M22.1094 120.605L22.7383 120.962V117.029L23.3567 117.389L22.4225 115.221L21.4884 116.304L22.1099 116.663L22.1094 120.605Z"
        fill="#C7C7C7"
      />
      <path
        d="M24.8911 122.229L25.5094 122.586V118.653L26.1309 119.011L25.1968 116.838L24.259 117.925L24.8911 118.292V122.229Z"
        fill="#C7C7C7"
      />
      <path
        d="M26.5305 125.645L21.0979 122.473C20.5665 122.151 20.1174 121.71 19.7863 121.184C19.4552 120.658 19.2512 120.062 19.1905 119.444V113.955C19.1905 112.895 20.0478 112.531 21.0979 113.144L26.5305 116.313C27.0594 116.636 27.5063 117.077 27.8362 117.602C28.166 118.127 28.3698 118.721 28.4316 119.338V124.829C28.4316 125.886 27.5721 126.248 26.5305 125.645ZM21.0979 113.532C20.2544 113.04 19.5704 113.333 19.5704 114.177V119.666C19.6183 120.16 19.7815 120.637 20.0467 121.057C20.312 121.477 20.672 121.83 21.0979 122.086L26.5305 125.253C27.3613 125.735 28.0485 125.456 28.0485 124.604V119.116C27.9991 118.623 27.8364 118.148 27.5731 117.728C27.3098 117.308 26.9529 116.954 26.5305 116.695L21.0979 113.532Z"
        fill="#C7C7C7"
      />
      <path
        d="M38.0006 127.333C37.9863 127.319 37.9609 127.3 37.9609 127.289C37.9609 127.163 37.5068 126.769 36.8402 126.371C36.1737 125.974 35.7085 125.841 35.7085 125.961C35.7085 125.98 35.6862 125.982 35.6767 125.98C35.6667 125.975 35.6582 125.968 35.6521 125.959C35.6461 125.949 35.6427 125.938 35.6423 125.927C35.6423 125.821 35.2639 125.486 34.6933 125.127V128.156L34.6837 128.516C34.6207 128.886 34.2943 128.935 33.889 128.705C33.6666 128.574 33.4782 128.393 33.3389 128.175C33.1995 127.958 33.1132 127.711 33.0868 127.455C33.0868 127.349 33.1673 127.302 33.2601 127.363C33.3126 127.395 33.3565 127.439 33.3881 127.492C33.4197 127.545 33.4379 127.604 33.4412 127.666C33.4628 127.8 33.5141 127.929 33.5916 128.041C33.6691 128.154 33.7707 128.247 33.889 128.315C34.3658 128.598 34.3192 128.01 34.3192 128.01V124.912C33.7502 124.598 33.3655 124.495 33.3655 124.605C33.3655 124.621 33.3575 124.624 33.3432 124.617C33.3336 124.613 33.3252 124.606 33.3186 124.597C33.3121 124.589 33.3074 124.579 33.3051 124.569C33.0072 124.178 32.6188 123.866 32.1733 123.659C31.5068 123.27 31.0479 123.126 31.0479 123.254C31.0479 123.265 31.0304 123.271 31.0098 123.265C30.9998 123.259 30.9916 123.25 30.9861 123.24C30.9805 123.23 30.9779 123.218 30.9785 123.206C31.0315 121.846 32.4854 121.31 34.2434 122.209C34.2434 122.119 34.3648 122.108 34.5036 122.196C34.5679 122.226 34.6247 122.271 34.6697 122.326C34.7147 122.381 34.7468 122.445 34.7638 122.514C36.5292 123.673 37.9762 125.902 38.0303 127.319C38.0319 127.336 38.0207 127.347 38.0006 127.333Z"
        fill="#C7C7C7"
      />
      <path
        d="M37.2217 131.876L31.7955 128.703C31.2635 128.385 30.8141 127.945 30.4835 127.42C30.1529 126.896 29.9506 126.3 29.8928 125.683V120.194C29.8928 119.131 30.7379 118.767 31.7955 119.384L37.2217 122.551C37.7525 122.871 38.2009 123.312 38.5307 123.837C38.8606 124.362 39.0628 124.957 39.1212 125.574V131.068C39.1212 132.12 38.2745 132.491 37.2217 131.876ZM31.7955 119.765C30.952 119.281 30.2711 119.568 30.2711 120.424V125.902C30.3201 126.396 30.4833 126.873 30.7479 127.294C31.0124 127.714 31.371 128.068 31.7955 128.326L37.2217 131.487C38.0652 131.974 38.7429 131.69 38.7429 130.844V125.353C38.6962 124.859 38.5343 124.383 38.2701 123.963C38.0058 123.543 37.6468 123.191 37.2217 122.935L31.7955 119.765Z"
        fill="#C7C7C7"
      />
      <path
        d="M46.6457 131.352C46.6176 132.209 46.048 132.58 45.321 132.222V134.931L46.2525 135.476V135.827L44.1511 134.603V134.252L45.0921 134.793V132.085C44.7097 131.805 44.393 131.444 44.1642 131.029C43.9355 130.614 43.8001 130.154 43.7675 129.681V127.674L46.6446 129.353L46.6457 131.352Z"
        fill="#C7C7C7"
      />
      <path
        d="M47.9194 138.117L42.4927 134.941C41.9619 134.62 41.5139 134.18 41.1845 133.654C40.8552 133.129 40.6537 132.533 40.5963 131.916V126.428C40.5963 125.377 41.4399 125.008 42.4927 125.623L47.9205 128.79C48.4523 129.11 48.9016 129.551 49.2323 130.076C49.563 130.601 49.7659 131.197 49.8247 131.815V137.307C49.8237 138.36 48.969 138.728 47.9194 138.117ZM42.4927 126.006C41.6476 125.512 40.9699 125.808 40.9699 126.654V132.144C41.0177 132.638 41.1803 133.113 41.4447 133.532C41.7092 133.951 42.0681 134.302 42.4927 134.558L47.9205 137.733C48.7576 138.215 49.4464 137.928 49.4464 137.081V131.596C49.3981 131.101 49.235 130.625 48.9701 130.205C48.7051 129.784 48.3457 129.432 47.9205 129.175L42.4927 126.006Z"
        fill="#C7C7C7"
      />
      <path
        d="M86.0224 79.3906L52.271 98.5828L18.3199 78.138L52.0813 58.9395L86.0224 79.3906Z"
        fill="#F1F1F1"
      />
      <path
        d="M38.9406 66.4087L31.9593 70.3826L65.9084 90.8347L72.8928 86.8662L38.9406 66.4087Z"
        fill="#FAFAFA"
      />
      <path
        d="M65.9253 90.8319L72.8928 86.8633L72.7995 93.9177L65.8246 97.8529L65.9253 90.8319Z"
        fill="#E7E7E7"
      />
      <path
        d="M106.293 20.4429L105.623 63.8603L71.8654 83.0578L72.5393 39.6393L106.293 20.4429Z"
        fill="#DEDEDE"
      />
      <path
        d="M72.5389 39.6386L71.8649 83.0571L37.9158 62.6049L38.5898 19.188L72.5389 39.6386Z"
        fill="#EAEAEA"
      />
      <path
        d="M41.3132 61.4624V62.1936L46.8359 65.4204V64.6823L41.3132 61.4624Z"
        fill="#C7C7C7"
      />
      <path
        d="M42.3692 61.6642L42.9945 62.0202V58.0882L43.616 58.4496L42.6819 56.2729L41.7509 57.3618L42.3692 57.7247V61.6642Z"
        fill="#C7C7C7"
      />
      <path
        d="M45.1446 63.2838L45.773 63.6446V59.7094L46.3913 60.0655L45.4572 57.8931L44.5331 58.9803L45.1446 59.3433V63.2838Z"
        fill="#C7C7C7"
      />
      <path
        d="M46.7909 66.6959L41.3599 63.5253C40.8279 63.2044 40.3781 62.7636 40.0464 62.2382C39.7147 61.7128 39.5102 61.1172 39.4493 60.4988V55.0016C39.4493 53.9504 40.3082 53.5843 41.3599 54.1963L46.7909 57.3706C47.3209 57.6919 47.7692 58.132 48.1002 58.6561C48.4312 59.1802 48.6359 59.774 48.6983 60.3907V65.8847C48.6983 66.9402 47.8326 67.2994 46.7909 66.6959ZM41.3599 54.5809C40.5227 54.094 39.8355 54.3838 39.8355 55.2337V60.7214C39.8829 61.2147 40.0456 61.69 40.3103 62.1089C40.5751 62.5279 40.9346 62.8787 41.3599 63.1332L46.7925 66.3123C47.6233 66.7892 48.3105 66.5094 48.3105 65.658V60.1708C48.2608 59.6774 48.0979 59.2022 47.8347 58.7819C47.5714 58.3617 47.2147 58.0078 46.7925 57.7478L41.3599 54.5809Z"
        fill="#C7C7C7"
      />
      <path
        d="M58.2674 68.389C58.2595 68.3837 58.2529 68.3767 58.2481 68.3686C58.2432 68.3604 58.2402 68.3513 58.2393 68.3418C58.2393 68.2189 57.7678 67.8215 57.1012 67.4225C56.4347 67.041 55.9615 66.899 55.9615 67.0204C55.9615 67.0363 55.9536 67.041 55.9377 67.0299C55.9286 67.026 55.9209 67.0194 55.9157 67.011C55.9106 67.0025 55.9083 66.9926 55.9091 66.9828C55.9091 66.8768 55.5164 66.5334 54.9553 66.1806V69.2102L54.949 69.5679C54.8764 69.9335 54.5484 69.9918 54.1542 69.7618C53.933 69.6285 53.7454 69.4461 53.6058 69.2289C53.4662 69.0117 53.3783 68.7653 53.3488 68.5087C53.3488 68.3985 53.4294 68.3556 53.5332 68.4202C53.5837 68.4527 53.6265 68.496 53.6583 68.5469C53.6902 68.5978 53.7105 68.6552 53.7176 68.7148C53.7354 68.8501 53.784 68.9794 53.8597 69.0929C53.9354 69.2063 54.0361 69.3009 54.1542 69.3692C54.6273 69.6484 54.5781 69.0603 54.5781 69.0603V65.9676C54.0154 65.6496 53.6402 65.5543 53.6402 65.6555C53.6402 65.6595 53.639 65.6635 53.6368 65.6668C53.6346 65.6702 53.6315 65.6729 53.6278 65.6746C53.6241 65.6762 53.6201 65.6768 53.6161 65.6763C53.6121 65.6757 53.6083 65.674 53.6053 65.6714C53.5948 65.6667 53.5859 65.6593 53.5793 65.65C53.5726 65.6407 53.5686 65.6298 53.5676 65.6184C53.2746 65.225 52.8877 64.9111 52.4422 64.7055C51.7725 64.3192 51.3073 64.1788 51.3073 64.2985C51.3073 64.3097 51.293 64.3224 51.2761 64.3097C51.2667 64.3054 51.2587 64.2986 51.2532 64.2899C51.2476 64.2813 51.2447 64.2712 51.2448 64.2609C51.2978 62.9034 52.7485 62.3614 54.5097 63.2648C54.5097 63.1641 54.6247 63.1588 54.7699 63.2441C54.8347 63.2753 54.8914 63.3212 54.9355 63.3782C54.9795 63.4351 55.0097 63.5015 55.0237 63.5721C56.7875 64.7214 58.2505 66.952 58.2992 68.3757C58.2987 68.3922 58.2817 68.3985 58.2674 68.389Z"
        fill="#C7C7C7"
      />
      <path
        d="M57.4885 72.9312L52.0607 69.7659C51.5283 69.4449 51.0787 69.0033 50.7479 68.4769C50.4172 67.9504 50.2146 67.3537 50.1565 66.7347V61.2518C50.1565 60.1862 51.0016 59.8212 52.0607 60.4337L57.4885 63.607C58.0172 63.9303 58.4637 64.372 58.7927 64.8971C59.1218 65.4223 59.3245 66.0167 59.3848 66.6335V72.1195C59.3843 73.1803 58.5302 73.548 57.4885 72.9312ZM52.0607 60.8226C51.213 60.3341 50.5395 60.6239 50.5395 61.4722V66.9551C50.5857 67.4505 50.7473 67.9282 51.0115 68.3498C51.2757 68.7715 51.635 69.1253 52.0607 69.3829L57.4885 72.5434C58.3257 73.0335 59.0002 72.7437 59.0002 71.9033V66.4099C58.9541 65.9171 58.7934 65.4419 58.5309 65.0223C58.2685 64.6027 57.9115 64.2503 57.4885 63.9932L52.0607 60.8226Z"
        fill="#C7C7C7"
      />
      <path
        d="M66.9124 72.4061C66.8838 73.2665 66.3115 73.6406 65.5819 73.2777V75.9889L66.5134 76.5341V76.8822L64.4152 75.6556V75.3102L65.353 75.8448V73.1426C64.971 72.8605 64.655 72.4986 64.4269 72.0821C64.1988 71.6656 64.0641 71.2045 64.0321 70.7307V68.73L66.9124 70.4027V72.4061Z"
        fill="#C7C7C7"
      />
      <path
        d="M68.1909 79.1694L62.752 76.0009C62.2216 75.6798 61.7735 75.2392 61.4435 74.7144C61.1134 74.1895 60.9105 73.5948 60.8509 72.9776V67.4831C60.8509 66.4313 61.7023 66.0594 62.752 66.6729L68.1909 69.8414C68.7201 70.1641 69.1671 70.6053 69.4968 71.1302C69.8264 71.6552 70.0296 72.2495 70.0904 72.8663V78.3588C70.0904 79.4137 69.2395 79.7761 68.1909 79.1694ZM62.752 67.0608C61.918 66.5738 61.234 66.8573 61.234 67.7072V73.2012C61.2834 73.6936 61.4462 74.1679 61.7096 74.5868C61.973 75.0058 62.3297 75.3582 62.752 75.6163L68.1909 78.7848C69.0201 79.2765 69.701 78.985 69.701 78.1399V72.6443C69.6529 72.1527 69.4916 71.6789 69.2296 71.2601C68.9675 70.8414 68.612 70.4891 68.1909 70.2309L62.752 67.0608Z"
        fill="#C7C7C7"
      />
      <path
        d="M106.293 20.4427L72.5394 39.638L38.5909 19.1885L72.3423 0L106.293 20.4427Z"
        fill="#F1F1F1"
      />
      <path
        d="M59.2115 7.46631L52.2334 11.4402L86.175 31.8876L93.16 27.9212L59.2115 7.46631Z"
        fill="#FAFAFA"
      />
      <path
        d="M86.1857 31.8874L93.1606 27.9209L93.0631 34.9785L86.0925 38.9142L86.1857 31.8874Z"
        fill="#E7E7E7"
      />
    </g>
  </svg>
);

const IconEstoque = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.36"
      d="M8 0C3.58 0 0 1.79 0 4C0 6.21 3.58 8 8 8C12.42 8 16 6.21 16 4C16 1.79 12.42 0 8 0ZM0 6V9C0 11.21 3.58 13 8 13C12.42 13 16 11.21 16 9V6C16 8.21 12.42 10 8 10C3.58 10 0 8.21 0 6ZM0 11V14C0 16.21 3.58 18 8 18C12.42 18 16 16.21 16 14V11C16 13.21 12.42 15 8 15C3.58 15 0 13.21 0 11Z"
      fill="black"
    />
  </svg>
);

const IconValueCost = () => (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.36"
      d="M5.8125 7.92188C7.21875 8.26562 8.34375 8.76562 9.1875 9.42188C10.0625 10.0781 10.5 11.0312 10.5 12.2812C10.5 13.25 10.1719 14.0469 9.51562 14.6719C8.89062 15.2656 8.04688 15.6562 6.98438 15.8438V16.5C6.98438 16.9375 6.82812 17.2969 6.51562 17.5781C6.23438 17.8594 5.89062 18 5.48438 18C5.07812 18 4.71875 17.8594 4.40625 17.5781C4.125 17.2656 3.98438 16.9062 3.98438 16.5V15.8438C2.26562 15.4688 1.14062 14.625 0.609375 13.3125C0.453125 13 0.46875 12.7031 0.65625 12.4219C0.875 12.1406 1.15625 12 1.5 12H1.78125C2.21875 12 2.51562 12.2188 2.67188 12.6562C3.04688 13.625 3.98438 14.1094 5.48438 14.1094C6.45312 14.1094 7.14062 13.9219 7.54688 13.5469C7.98438 13.1719 8.20312 12.7656 8.20312 12.3281C8.20312 11.2031 7.20312 10.3906 5.20312 9.89062C2.07812 9.17188 0.515625 7.79688 0.515625 5.76562C0.515625 4.85938 0.84375 4.09375 1.5 3.46875C2.15625 2.8125 2.98438 2.375 3.98438 2.15625V1.5C3.98438 1.09375 4.125 0.75 4.40625 0.46875C4.71875 0.15625 5.07812 0 5.48438 0C5.89062 0 6.23438 0.140625 6.51562 0.421875C6.82812 0.703125 6.98438 1.0625 6.98438 1.5V2.20312C8.51562 2.57812 9.5 3.42188 9.9375 4.73438C10.0625 5.04688 10.0312 5.34375 9.84375 5.625C9.65625 5.875 9.39062 6 9.04688 6H8.76562C8.32812 6 8.03125 5.76562 7.875 5.29688C7.625 4.35938 6.82812 3.89062 5.48438 3.89062C4.64062 3.89062 3.98438 4.0625 3.51562 4.40625C3.04688 4.75 2.8125 5.20312 2.8125 5.76562C2.8125 6.23438 3.03125 6.64062 3.46875 6.98438C3.90625 7.29688 4.6875 7.60938 5.8125 7.92188Z"
      fill="black"
    />
  </svg>
);

const IconValueSale = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.36"
      d="M17.469 8.62201L9.369 0.52201C9.20224 0.355769 9.0043 0.224085 8.78654 0.134508C8.56877 0.0449313 8.33547 -0.000775178 8.1 9.94561e-06H1.8C1.32302 0.00132941 0.865946 0.191395 0.528666 0.528675C0.191386 0.865955 0.00132003 1.32303 5.65137e-07 1.80001V8.10001C-0.000187642 8.33753 0.0466351 8.57274 0.13777 8.79208C0.228905 9.01142 0.362549 9.21056 0.531 9.37801L8.631 17.478C8.79776 17.6443 8.9957 17.7759 9.21346 17.8655C9.43123 17.9551 9.66453 18.0008 9.9 18C10.1363 18.0012 10.3705 17.9549 10.5885 17.8636C10.8066 17.7724 11.004 17.6382 11.169 17.469L17.469 11.169C17.6382 11.004 17.7724 10.8066 17.8636 10.5885C17.9548 10.3705 18.0012 10.1363 18 9.90001C17.9984 9.42066 17.8076 8.96135 17.469 8.62201ZM3.15 4.50001C2.883 4.50001 2.62199 4.42083 2.39998 4.27249C2.17797 4.12415 2.00494 3.91331 1.90276 3.66663C1.80058 3.41995 1.77385 3.14851 1.82594 2.88664C1.87803 2.62476 2.00661 2.38422 2.19541 2.19542C2.38421 2.00662 2.62475 1.87804 2.88663 1.82595C3.1485 1.77386 3.41994 1.80059 3.66662 1.90277C3.9133 2.00495 4.12414 2.17798 4.27248 2.39999C4.42082 2.622 4.5 2.88301 4.5 3.15001C4.50026 3.32737 4.46552 3.50304 4.39777 3.66694C4.33002 3.83085 4.23059 3.97978 4.10518 4.10519C3.97977 4.2306 3.83084 4.33003 3.66693 4.39778C3.50303 4.46553 3.32736 4.50027 3.15 4.50001Z"
      fill="black"
    />
  </svg>
);
