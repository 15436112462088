export const daysToSalesFilter = [
  {
    days: 7,
  },
  {
    days: 15,
  },
  {
    days: 30,
  },
  {
    days: 90,
  },
  {
    days: 180,
  },
];
