import React from "react";
import { analyticsEvent } from "../../services/analytics";
import {
  IconClientes,
  IconDescontos,
  IconProduto,
  IconServicos,
} from "../../assets/icons/icons";

function FilterCadastros({
  buttonsCadastros,
  setActiveFilterCadastros,
  activeFilterCadastros,
  setLoading,
  setFilterCadastros,
}) {
  return (
    <div style={{ width: "60%" }} className="myne-filter-content">
      <div id="myne-filter-dates-ref" className="myne-filter-dates">
        {buttonsCadastros.map((btn) => (
          <button
            key={btn}
            id="btn-calendar"
            onClick={() => {
              setLoading(true);
              setActiveFilterCadastros(btn);
              if (btn === buttonsCadastros[0]) {
                // produtos
                analyticsEvent("OPTION_PRODUCTS_CLICKED");
              } else if (btn === buttonsCadastros[1]) {
                // clientes
                analyticsEvent("OPTION_CLIENTS_CLICKED");
              } else if (btn === buttonsCadastros[2]) {
                // serviços
                analyticsEvent("OPTION_SERVICES_CLICKED");
              } else if (btn === buttonsCadastros[3]) {
                analyticsEvent("OPTION_DISCOUNTS_CLICKED");
              }
              setLoading(false);
            }}
            style={{
              fontWeight: activeFilterCadastros === btn ? "500" : "",
              border: activeFilterCadastros === btn ? "1px solid #aa00ff" : "",
              color: activeFilterCadastros === btn ? "#aa00ff" : "",
              background: activeFilterCadastros === btn ? "#faefff" : "",
            }}
          >
            {btn === buttonsCadastros[0] ? (
              <IconProduto
                className="myne-filter-dates-btn-icon"
                style={{
                  fill: activeFilterCadastros === btn ? "#aa00ff" : "",
                  fillOpacity: activeFilterCadastros === btn ? 1 : "",
                }}
              />
            ) : btn === buttonsCadastros[1] ? (
              <IconClientes
                className="myne-filter-dates-btn-icon"
                style={{
                  fill: activeFilterCadastros === btn ? "#aa00ff" : "",
                  fillOpacity: activeFilterCadastros === btn ? 1 : "",
                }}
              />
            ) : btn === buttonsCadastros[2] ? (
              <IconServicos
                className="myne-filter-dates-btn-icon"
                style={{
                  fill: activeFilterCadastros === btn ? "#aa00ff" : "",
                  fillOpacity: activeFilterCadastros === btn ? 1 : "",
                }}
              />
            ) : (
              <IconDescontos
                className="myne-filter-dates-btn-icon"
                style={{
                  fill: activeFilterCadastros === btn ? "#aa00ff" : "",
                  fillOpacity: activeFilterCadastros === btn ? 1 : "",
                }}
              />
            )}
            {btn}
          </button>
        ))}
      </div>
    </div>
  );
}

export default FilterCadastros;
