import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "meunegocio-9790f.firebaseapp.com",
  databaseURL: "https://meunegocio-9790f.firebaseio.com",
  projectId: "meunegocio-9790f",
  storageBucket: "meunegocio-9790f.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBslRmagbpO_OwFFTgL8mQUkJYTBX9O8m8",
//   authDomain: "myne-homolog.firebaseapp.com",
//   projectId: "myne-homolog",
//   storageBucket: "myne-homolog.appspot.com",
//   messagingSenderId: "823417245637",
//   appId: "1:823417245637:web:ec28d8b89f00a1980485ac",
//   measurementId: "G-YXEG6HCWLW"
// };

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();
const analytics = firebase.analytics();
const providerGoogle = new firebase.auth.GoogleAuthProvider();

export { analytics, db, storage, auth, providerGoogle, firebase };
