const IconErro = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM10 10.9844C9.4375 10.9844 9.01562 10.5625 9.01562 10V6.01562C9.01562 5.45312 9.4375 4.98438 10 4.98438C10.5625 4.98438 10.9844 5.45312 10.9844 6.01562V10C10.9844 10.5625 10.5625 10.9844 10 10.9844ZM10.9844 15.0156H9.01562V13H10.9844V15.0156Z"
      fill="#FE2543"
    />
  </svg>
);

const IconSucess = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM7.28125 14.3125L3.71875 10.7031C3.29688 10.3281 3.29688 9.67188 3.71875 9.29688C4.09375 8.92188 4.70312 8.92188 5.125 9.29688L7.98438 12.1562L14.875 5.3125C15.25 4.89062 15.9062 4.89062 16.2812 5.3125C16.7031 5.6875 16.7031 6.29688 16.2812 6.71875L8.6875 14.3125C8.3125 14.6875 7.70312 14.6875 7.28125 14.3125Z"
      fill="#58CC02"
    />
  </svg>
);

export const toastConfig = {
  duration: 6000,
  success: {
    style: {
      padding: "16px",
      height: "56px",
      fontWeight: 600,
      fontSize: 12,
      whiteSpace: "nowrap",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.12)",
      background: "#DCFFC2",
    },
    icon: <IconSucess />,
    // iconTheme: {
    //   primary: "#58CC02",
    //   secondary: "#DCFFC2",
    // },
  },
  error: {
    style: {
      padding: "16px",
      height: "56px",
      fontWeight: 600,
      fontSize: 12,
      whiteSpace: "nowrap",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.12)",
      background: "#ffdbe0",
    },
    icon: <IconErro />,
    // iconTheme: {
    //   primary: "#FE2543",
    //   secondary: "#ffdbe0",
    // },
  },
};
