import React, { useEffect, useState } from "react";
import { IconAdd, IconArrowBack, IconClose } from "../../../assets/icons/icons";
import { auth, db } from "../../../services/Firebase";
import "../../../styles/Modals/Categorie.css";
import ilustracao from "../../../assets/images/ILUSTRATORS/ilustracao_desconto_produto.svg";
import ModalAddCategorie from "./ModalAddCategorie";
import ModalPlus from "../Plus/ModalPlus";
import { useStateValue } from "../../../providers/StateProvider";

function ModalNewCategorie({
  modal,
  setmodal,
  listcategories,
  setmodalback,
  backIcon,
  selectCategoriesData,
  aplicar,
}) {
  const [{ ifPlusVerified, activeLanguage }] = useStateValue();
  const [categories, setCategories] = useState([]);
  const [selectCategories, setSelectCategories] = useState([]);
  const [modalNew, setModalNew] = useState(false);
  const [modalEdit, setModalEdit] = useState(null);
  const [modalPlus, setModalPlus] = useState(false);
  // GET CATEGORIES
  useEffect(() => {
    const fecthcategories = async () => {
      if (listcategories) {
        setCategories(listcategories);
      } else {
        await db
          .collection("users")
          .doc(auth?.currentUser?.uid)
          ?.collection("categories")
          .onSnapshot((snapshot) => {
            setCategories(
              snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
            );
          });
      }
    };
    fecthcategories();
  }, [listcategories]);

  useEffect(() => {
    if (selectCategoriesData) {
      const names = [];
      selectCategoriesData?.forEach((categorie) => {
        names.push(categorie?.name);
      });
      setSelectCategories(names);
    }
  }, [selectCategoriesData]);

  const removeCategorie = (categorie) => {
    const filter = selectCategories?.filter((cate) => cate !== categorie);

    setSelectCategories(filter);
  };

  const existCategorie = (categorie) => {
    return selectCategories.includes(categorie);
  };

  const getSelectCategories = () => {
    return categories?.filter((cate) => {
      return selectCategories?.includes(cate?.name);
    });
  };

  return (
    <>
      <div
        style={{
          opacity: modal ? "5" : "0",
          visibility: modal ? "visible" : "hidden",
          background: backIcon ? "transparent" : "",
        }}
        className="myne-modal"
      >
        <ModalPlus
          modal={modalPlus}
          setmodal={setModalPlus}
          message_locked={activeLanguage?.be_plus?.locked_plus}
        />
        <div
          style={{
            transform: backIcon ? "" : modal ? "scale(1)" : "scale(0.7)",
          }}
          className="myne-modal__modal-edit-cadastro"
        >
          {modal && (
            <>
              <header className="myne-modal__modal-edit-header">
                <section className="myne-modal__modal-edit-header__left">
                  {backIcon ? (
                    <IconArrowBack
                      style={{ marginRight: 12, cursor: "pointer" }}
                      onClick={() => setmodalback(false)}
                    />
                  ) : (
                    ""
                  )}

                  <p style={{ textTransform: "initial", fontSize: 13 }}>
                    Categoria
                  </p>
                </section>

                <section className="myne-modal__modal-edit-header__icons">
                  <IconClose
                    onClick={() => {
                      setmodal(false);
                      setmodalback(false);
                    }}
                  />
                </section>
              </header>
              <div className="myne-modal__modal-edit-cadastro-categorie-content">
                <div
                  onClick={() => {
                    if (ifPlusVerified) {
                      setModalNew(!modalNew);
                      setModalEdit(null);
                    } else {
                      setModalPlus(true);
                    }
                  }}
                  className="myne-modal__modal-new-sale-content__area-modal-search__itens-user"
                >
                  <IconAdd />
                  <p>Nova Categoria</p>
                </div>

                <div className="myne-modal__modal-edit-cadastro-categorie-content-categories">
                  {categories?.length > 0 ? (
                    <div
                      id="myne-filter-dates-ref"
                      className="myne-filter-dates"
                      style={{ flexWrap: "wrap" }}
                    >
                      {categories?.map((item, i) => (
                        <button
                          key={item?.name}
                          id="btn-calendar"
                          style={{
                            fontWeight: existCategorie(item?.name) ? "500" : "",
                            border: existCategorie(item?.name)
                              ? "1px solid #aa00ff"
                              : "",
                            color: existCategorie(item?.name) ? "#aa00ff" : "",
                            background: existCategorie(item?.name)
                              ? "#faefff"
                              : "",
                            marginBottom: 9,
                          }}
                          onClick={() => {
                            if (existCategorie(item?.name)) {
                              removeCategorie(item?.name);
                            } else {
                              if (backIcon) {
                                setSelectCategories((c) => [...c, item?.name]);
                              } else {
                                setSelectCategories([item?.name]);
                              }
                            }
                          }}
                        >
                          {item?.name}
                        </button>
                      ))}
                    </div>
                  ) : (
                    <div className="myne-modal__modal-edit-cadastro-categorie-content-categories-without">
                      <img src={ilustracao} alt="Ilustração desconto" />
                    </div>
                  )}
                </div>

                {categories?.length > 0 ? (
                  <div className="myne-modal__modal-new-sale_bottom--right myne-modal__modal-new-sale_bottom--right-row">
                    {selectCategories?.length === 1 ? (
                      <button
                        onClick={() => {
                          if (ifPlusVerified) {
                            setModalEdit(
                              categories?.filter(
                                (cate) => cate?.name === selectCategories[0]
                              )[0]
                            );
                            setModalNew(!modalNew);
                          } else {
                            setModalPlus(true);
                          }
                        }}
                        className="text text-active"
                      >
                        EDITAR CATEGORIA
                      </button>
                    ) : (
                      <button className="text">EDITAR CATEGORIA</button>
                    )}

                    {backIcon &&
                      (selectCategories?.length > 0 ? (
                        <button
                          onClick={() => {
                            if (ifPlusVerified) {
                              aplicar(getSelectCategories());
                              setmodalback(false);
                            } else {
                              setModalPlus(true);
                            }
                          }}
                          className="active"
                        >
                          APLICAR
                        </button>
                      ) : (
                        <button>APLICAR</button>
                      ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <ModalAddCategorie
        modal={modalNew}
        setmodal={setModalNew}
        item={modalEdit}
        backIcon
        setSelectCategories={setSelectCategories}
      />
    </>
  );
}

export default ModalNewCategorie;
