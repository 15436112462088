import React from "react";

const LoadingInButton = ({ stylesLoading }) => {
  return (
    <section>
      <div className="myne-loading-btn">
        <div style={stylesLoading} className="myne-circle-loading-4"></div>
      </div>
    </section>
  );
};

export default LoadingInButton;
