class Product {
  constructor(
    name,
    salePrice,
    stock,
    costPrice,
    categories,
    image,
    description,
    minimumStock,
    discountRef,
    updatedAt,
  ) {
    this.name = name;
    this.salePrice = salePrice;
    this.stock = stock;
    this.costPrice = costPrice;
    this.categories = categories;
    this.image = image;
    this.description = description;
    this.minimumStock = minimumStock;
    this.discountRef = discountRef;
    this.updatedAt = updatedAt;
  }
}

export { Product };
