import { userId } from "../../../providers/reducer";
import { storage } from "../../../services/Firebase";

export const changeUploadImg = (e, upload, setImage) => {
  if (e.target.files?.length === 0) {
    return;
  }
  const image = e.target.files[0];
  upload(image);
  if (image) {
    setImage(URL.createObjectURL(image));
  }
};

//UPLOAD IMAGE

export const uploadImage = async (
  ref,
  uploadProductImage,
  setLoading,
  activeLanguage,
  setLoadingProgressUpload
) => {
  return new Promise(function (resolve, reject) {
    const imgUpload = uploadProductImage;

    const filename = Date.now();
    const uploadTask = storage
      .ref(`${userId()}/images/${ref}/${filename}`)
      .put(imgUpload);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        if (setLoadingProgressUpload) {
          setLoadingProgressUpload(progress);
        }
        setLoading(true);
      },
      function error(err) {
        console.log("error", err);
        reject();
        setLoading(false);
        if (setLoadingProgressUpload) {
          setLoadingProgressUpload(0);
        }
      },
      () => {
        storage
          .ref(userId())
          .child(`/images/${ref}/${filename}`)
          .getDownloadURL()
          .then((url) => {
            resolve(url);
            setLoading(false);
            if (setLoadingProgressUpload) {
              setLoadingProgressUpload(0);
            }
          })
          .catch((e) => {
            let errorMessage = e.message;
            alert(activeLanguage?.alerts?.error_upload_image + errorMessage);
          });
      }
    );
  });
};
