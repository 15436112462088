import React, { useState, useEffect, useRef } from "react";

//
import "../../styles/layouts/Header.css";
import "../../styles/Buttons/index.css";

//
import { useStateValue } from "../../providers/StateProvider";

//
import { DatePicker } from "antd";
import "antd/dist/antd.css";

import moment from "moment";

import "moment/locale/pt-br";
import localePt from "antd/es/date-picker/locale/pt_BR";
import localeEn from "antd/es/date-picker/locale/en_GB";
import localeEs from "antd/es/date-picker/locale/es_ES";

import { db, auth } from "../../services/Firebase";
import { useLocation } from "react-router-dom";
import {
  useOnClickOutside,
  getMoeda,
  getDataUser,
} from "../../providers/reducer";
import ModalPlus from "../../components/Modals/Plus/ModalPlus";
import { onCurrentUserSubscriptionUpdate } from "@stripe/firestore-stripe-payments";
import { payments } from "../../services/stripe";
import SelectChangeLang from "../../components/Lang/SelectChangeLang";

function Header({ sales }) {
  const [{ activeBtn }, dispatch] = useStateValue();
  const [{ menuOpen }] = useStateValue();
  const [calendar, setCalendar] = useState(false);
  const [{ activeLanguage }, dispatchDates] = useStateValue();
  const { RangePicker } = DatePicker;
  //
  const { pathname } = useLocation();
  //
  const [typeCalendar, setTypeCalendar] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [locale, setLocale] = useState(null);

  const lang = navigator.language || navigator.userLanguage;

  useEffect(() => {
    if (lang === "pt-BR") {
      setLocale(localePt);
      moment().locale("pt-br");
    } else if (lang === "en") {
      setLocale(localeEn);
      moment().locale("en");
    } else if (lang === "es") {
      setLocale(localeEs);
      moment().locale("es");
    }
  }, [lang]);

  useEffect(() => {
    if (startDate && endDate) {
      dispatchDates({
        type: "SET_CALENDAR_DATES",
        calendarDates: {
          startDate: startDate,
          endDate: endDate,
          type: typeCalendar,
        },
      });
      setCalendar(false);
    } else {
      if (activeBtn === "Dia") {
        setCalendar(false);
      } else if (activeBtn === "Mês") {
        setCalendar(false);
      } else {
        setCalendar(true);
        dispatchDates({
          type: "SET_CALENDAR_DATES",
          calendarDates: {
            startDate: undefined,
            endDate: undefined,
            type: undefined,
          },
        });
        setStartDate(undefined);
        setEndDate(undefined);
      }
    }
  }, [startDate, endDate, calendar]);

  const calendarNot = (ref) => {
    setCalendar(ref);
    dispatchDates({
      type: "SET_CALENDAR_DATES",
      calendarDates: {
        startDate: undefined,
        endDate: undefined,
        type: undefined,
      },
    });
    setStartDate(undefined);
    setEndDate(undefined);
  };

  // //

  const activeCalendarRef = useRef();

  useOnClickOutside(activeCalendarRef, () => {
    if (
      calendar &&
      document.getElementsByClassName("ant-picker-focused").length <= 0
    ) {
      dispatch({
        type: "SET_ACTIVE",
        activeBtn: "Dia",
      });
      calendarNot(false);
    }
  });

  // load plus

  const [modalPlus, setModalPlus] = useState(false);

  useEffect(() => {
    if (auth?.currentUser) {
      onCurrentUserSubscriptionUpdate(
        payments,
        async (snapshot) => {
          const filterSubscriptions = snapshot.changes?.filter((change) => {
            return change?.subscription?.status === "active";
          });

          const isActiveStripeSubscriber = filterSubscriptions?.length > 0;

          if (isActiveStripeSubscriber) {
            db.collection("users")
              .doc(auth?.currentUser?.uid)
              .update({
                subscriptionStatus: 0,
                expirationDate: new Date(
                  filterSubscriptions[0]?.subscription.current_period_end
                ),
              });
          }

          const user = await getDataUser();

          const getDate = (date) => {
            return typeof date === "string" ? new Date(date) : date?.toDate();
          };

          const hasDaysPremium = getDate(user?.expirationDate)
            ? getDate(user?.expirationDate)?.getTime() - new Date().getTime() >
              0
            : false;
          const isActivePlaySubscriber = user?.googlePlaySubscriber;

          if (
            // VERIFICATION PLAY STORE purchaseList.isActivePlaySubscriber().not() &&
            // !isActivePlaySubscriber &&
            !isActiveStripeSubscriber &&
            !hasDaysPremium
          ) {
            db.collection("users").doc(auth?.currentUser?.uid).update({
              subscriptionStatus: 1,
              expirationDate: null,
            });
          }
          // snapshot.changes?.map((change) => {
          //   if (change?.subscription?.status === "active") {
          //     db.collection("users")
          //       .doc(auth?.currentUser?.uid)
          //       .update({
          //         subscriptionStatus: 0,
          //         expirationDate: new Date(
          //           filterSubscriptions[0]?.subscription.current_period_end
          //         ),
          //       });
          //   } else {
          //     if (
          //       change?.subscription?.status === "canceled" ||
          //       change?.subscription?.status === "incomplete_expired"
          //     ) {
          //       db.collection("users").doc(auth?.currentUser?.uid).update({
          //         subscriptionStatus: 1,
          //         expirationDate: null,
          //       });
          //     }
          //   }
          // });
        },
        console.error
      );
    }
  }, []);

  const [{ ifPlusVerified }] = useStateValue();

  // load moeda

  const setMoeda = () => {
    fetch("./moedas.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (data) => {
        const userPreference = await getMoeda();

        const filter = data?.results?.filter((item) => {
          return item?.currencyId === userPreference?.currencyId;
        });

        const preference = filter[0];

        dispatch({
          type: "SET_ACTIVE_MOEDA",
          activeMoeda: preference,
        });
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  useEffect(() => {
    if (auth?.currentUser) setMoeda();
  }, []);

  return (
    auth?.currentUser && (
      <div
        style={{ paddingLeft: menuOpen ? "50px" : "" }}
        className="header myne-bottom-border"
      >
        {/* <div className="header__left">
        <p className="myne-text">Myne - Controle de vendas e estoque</p>
      </div> */}

        {pathname === "/" ? (
          <>
            {sales.length !== 0 ? (
              <div className="header__center">
                {/* DAY */}
                <button
                  onClick={() => {
                    dispatch({
                      type: "SET_ACTIVE",
                      activeBtn: "Dia",
                    });
                    dispatch({
                      type: "SET_ACTIVE_DATE",
                      activeDate: null,
                    });
                    setCalendar(false);
                    const current = document.getElementById(`dashboard-grid`);
                    current.classList.toggle("myne-modal--animation");
                  }}
                  style={{
                    borderBottom:
                      activeBtn === "Dia" ? "4px solid #aa00ff" : "",
                    color: activeBtn === "Dia" ? "rgba(0, 0, 0, 0.87)" : "",
                  }}
                  className="header__center-btn_filter"
                >
                  {activeLanguage?.layouts?.header?.filter_dates?.day}
                </button>
                {/* MONTH */}
                <button
                  onClick={() => {
                    dispatch({
                      type: "SET_ACTIVE",
                      activeBtn: "Mês",
                    });
                    setCalendar(false);
                    const current = document.getElementById(`dashboard-grid`);
                    current.classList.toggle("myne-modal--animation");
                  }}
                  style={{
                    borderBottom:
                      activeBtn === "Mês" ? "4px solid #aa00ff" : "",
                    color: activeBtn === "Mês" ? "rgba(0, 0, 0, 0.87)" : "",
                  }}
                  className="header__center-btn_filter"
                >
                  {activeLanguage?.layouts?.header?.filter_dates?.month}
                </button>
                <button
                  id="btn-calendar"
                  onClick={() => {
                    if (activeBtn === "Calendar") {
                      dispatch({
                        type: "SET_ACTIVE",
                        activeBtn: "Dia",
                      });
                      calendarNot(false);
                    } else {
                      dispatch({
                        type: "SET_ACTIVE",
                        activeBtn: "Calendar",
                      });
                      dispatchDates({
                        type: "SET_CALENDAR_DATES",
                        calendarDates: {
                          startDate: undefined,
                          endDate: undefined,
                          type: undefined,
                        },
                      });
                      setStartDate(undefined);
                      setEndDate(undefined);
                      calendar ? calendarNot(true) : setCalendar(true);
                    }
                  }}
                  style={{
                    width: "36px",
                    borderRadius: "100%",
                    minWidth: "36px",
                    maxWidth: "36px",
                    height: "36px",
                    minHeight: "36px",
                    maxHeight: "36px",
                    padding: "0px",
                    border: activeBtn === "Calendar" ? "1px solid #aa00ff" : "",
                  }}
                  className="header__center-btn"
                >
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.65625 7.65625C8.84375 7.65625 9 7.72917 9.125 7.875C9.27083 8 9.34375 8.15625 9.34375 8.34375V10.3438C9.34375 10.5104 9.27083 10.6667 9.125 10.8125C9 10.9375 8.84375 11 8.65625 11H6.65625C6.48958 11 6.33333 10.9375 6.1875 10.8125C6.0625 10.6667 6 10.5104 6 10.3438V8.34375C6 8.15625 6.0625 8 6.1875 7.875C6.33333 7.72917 6.48958 7.65625 6.65625 7.65625H8.65625ZM8.65625 1C8.65625 0.8125 8.71875 0.65625 8.84375 0.53125C8.98958 0.40625 9.15625 0.34375 9.34375 0.34375C9.51042 0.34375 9.65625 0.40625 9.78125 0.53125C9.92708 0.65625 10 0.8125 10 1V1.65625H10.6562C11.0312 1.65625 11.3438 1.79167 11.5938 2.0625C11.8646 2.33333 12 2.64583 12 3V12.3438C12 12.6979 11.8646 13.0104 11.5938 13.2812C11.3438 13.5312 11.0312 13.6562 10.6562 13.6562H1.34375C0.96875 13.6562 0.645833 13.5312 0.375 13.2812C0.125 13.0104 0 12.6979 0 12.3438V3C0 2.64583 0.125 2.33333 0.375 2.0625C0.645833 1.79167 0.96875 1.65625 1.34375 1.65625H2V1C2 0.8125 2.0625 0.65625 2.1875 0.53125C2.33333 0.40625 2.48958 0.34375 2.65625 0.34375C2.84375 0.34375 3 0.40625 3.125 0.53125C3.27083 0.65625 3.34375 0.8125 3.34375 1V1.65625H8.65625V1ZM10 12.3438C10.1875 12.3438 10.3438 12.2812 10.4688 12.1562C10.5938 12.0104 10.6562 11.8438 10.6562 11.6562V5H1.34375V11.6562C1.34375 11.8438 1.40625 12.0104 1.53125 12.1562C1.65625 12.2812 1.8125 12.3438 2 12.3438H10Z"
                      fill={activeBtn === "Calendar" ? "#aa00ff" : "black"}
                      fillOpacity={activeBtn === "Calendar" ? "5" : "0.60"}
                    />
                  </svg>
                </button>
                <button
                  onClick={() =>
                    dispatch({
                      type: "SET_ACTIVE",
                      activeBtn: "Dia",
                    })
                  }
                  style={{
                    width: "36px",
                    borderRadius: "100%",
                    minWidth: "36px",
                    maxWidth: "36px",
                    height: "36px",
                    minHeight: "36px",
                    maxHeight: "36px",
                    padding: "0px",
                    position: "absolute",
                    zIndex: 999,
                    opacity: 0,
                    visibility: activeBtn === "Calendar" ? "visible" : "hidden",
                    top: document.getElementById("btn-calendar")
                      ? document.getElementById("btn-calendar")?.clientTop + 10
                      : "",
                    left: document.getElementById("btn-calendar")
                      ? document.getElementById("btn-calendar")?.clientLeft +
                        143
                      : "",
                  }}
                  className="header__center-btn"
                ></button>

                <div
                  style={{
                    opacity: calendar ? 1 : 0,
                    visibility: calendar ? "visible" : "hidden",
                    transform: calendar ? "scale(1)" : "scale(0)",
                  }}
                  ref={activeCalendarRef}
                >
                  <p style={{ marginTop: 0 }}>
                    {activeLanguage?.layouts?.header?.filter_dates?.day}
                  </p>
                  <RangePicker
                    onCalendarChange={(value) => {
                      if (value) {
                        setStartDate(value[0]?._d);
                        setEndDate(value[1]?._d);
                      }

                      setTypeCalendar("Dia");
                    }}
                    placeholder={[
                      activeLanguage?.layouts?.header?.filter_dates
                        ?.datefilter_modal?.dayandinitial,
                      activeLanguage?.layouts?.header?.filter_dates
                        ?.datefilter_modal?.dayandfinal,
                    ]}
                    locale={locale}
                  />
                  <p>{activeLanguage?.layouts?.header?.filter_dates?.month}</p>
                  <RangePicker
                    picker="month"
                    placeholder={[
                      activeLanguage?.layouts?.header?.filter_dates
                        ?.datefilter_modal?.daymonthinitial,
                      activeLanguage?.layouts?.header?.filter_dates
                        ?.datefilter_modal?.daymonthfinal,
                    ]}
                    onCalendarChange={(value) => {
                      if (value) {
                        setStartDate(value[0]?._d);
                        setEndDate(value[1]?._d);
                      }

                      setTypeCalendar("Mês");
                    }}
                    locale={locale}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        <div className="header__right">
          {pathname !== "/opcoes" ? (
            <>
              <ModalPlus modal={modalPlus} setmodal={setModalPlus} btn_plus />

              {!ifPlusVerified ? (
                <button
                  onClick={() => setModalPlus(!modalPlus)}
                  className="myne-btn__seje-plus"
                >
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 12L0 1L5.5 6L9 0L12.5 6L18 1L16 12H2ZM16 15C16 15.6 15.6 16 15 16H3C2.4 16 2 15.6 2 15V14H16V15Z"
                      fill="white"
                    />
                  </svg>

                  <p className="myne-text">
                    {activeLanguage?.layouts?.header?.plus_btn}
                  </p>
                </button>
              ) : (
                ""
              )}

              {/* <IconButton>
          <svg
            width="18"
            height="22"
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.34"
              d="M8 19.9844C7.4375 19.9844 6.95312 19.7969 6.54688 19.4219C6.17188 19.0156 5.98438 18.5469 5.98438 18.0156H10.0156C10.0156 18.5469 9.8125 19.0156 9.40625 19.4219C9 19.7969 8.53125 19.9844 8 19.9844ZM14 13.9844L15.3125 15.2969C15.625 15.6094 15.6875 15.9688 15.5 16.375C15.3125 16.7812 15 16.9844 14.5625 16.9844H1.39062C0.953125 16.9844 0.640625 16.7812 0.453125 16.375C0.296875 15.9688 0.375 15.6094 0.6875 15.2969L2 13.9844V9.01562C2 7.42188 2.39062 6.04688 3.17188 4.89062C3.98438 3.73437 5.09375 3 6.5 2.6875V1.98438C6.5 1.57812 6.64062 1.23438 6.92188 0.953125C7.23438 0.640625 7.59375 0.484375 8 0.484375C8.40625 0.484375 8.75 0.640625 9.03125 0.953125C9.34375 1.23438 9.5 1.57812 9.5 1.98438V2.6875C10.9062 3 12 3.73437 12.7812 4.89062C13.5938 6.04688 14 7.42188 14 9.01562V13.9844Z"
              fill="black"
            />
          </svg>
        </IconButton> */}
            </>
          ) : (
            ""
          )}

          <SelectChangeLang />
        </div>
      </div>
    )
  );
}

export default Header;
