import React from "react";
import { useStateValue } from "../../../providers/StateProvider";
import InputForm from "../../inputs/InputForm";

const ModalMinimumStock = ({
  modalAddMinimumStock,
  setModalAddMinimumStock,
  ADDminimumStock,
  setADDminimumStock,
  addminimumStock,
}) => {
  const [{ activeLanguage }] = useStateValue();

  return (
    <div
      style={{
        opacity: modalAddMinimumStock ? "5" : "0",
        visibility: modalAddMinimumStock ? "visible" : "hidden",
        zIndex: 999999999,
      }}
      className="myne-modal myne-modal--animation"
    >
      <div
        style={{
          transform: modalAddMinimumStock ? "scale(1)" : "scale(0.7)",
        }}
        className="myne-modal__modal-add-item myne-modal--animation-scale"
      >
        {modalAddMinimumStock && (
          <>
            <header className="myne-modal__modal-add-item-header">
              <p style={{ margin: 0 }}>
                {activeLanguage?.services?.product?.modal_stock?.title}
              </p>
            </header>

            <div className="myne-modal__modal-add-item-content">
              <InputForm
                value={ADDminimumStock}
                onChange={(e) => setADDminimumStock(e.target.value)}
                label={activeLanguage?.services?.inputs?.qty}
                type="number"
                onKeyUp={(e) => {
                  if (e.key?.toLowerCase() === "enter") {
                    if (!isNaN(parseFloat(ADDminimumStock))) {
                      addminimumStock();
                    }
                  }
                }}
              />

              <div>
                <button onClick={() => setModalAddMinimumStock(false)}>
                  {activeLanguage?.services?.btn?.no}
                </button>

                <button
                  disabled={isNaN(parseFloat(ADDminimumStock))}
                  onClick={() => addminimumStock()}
                >
                  {activeLanguage?.services?.btn?.yes}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModalMinimumStock;
